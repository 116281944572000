import ApiService from "./api.service";

const AuthRepository = {
    login: async (url, data) => {
        url = url + "/auth/login";
        return ApiService.put(url, data);
    },
};

export default AuthRepository;
