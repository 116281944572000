import axios from "axios";
import Vue from "vue";
import VueRouter from "vue-router";
import ApiService from "../service/api.service";

// Web
import LoginPage from "../components/Web/Login/Login.vue";
import MenuPage from "../components/Web/Menu/Menu.vue";
import PlayerPage from "../components/Web/CrawlData/Player.vue";
import HistoryBatchJobPage from "../components/Web/CrawlData/HistoryBatchJob.vue";
import MappingMatchLeaguePage from "../components/Web/CrawlData/MappingMatchLeague.vue";
import PlayerKhongChoiPage from "../components/Web/CrawlData/PlayerKhongChoi.vue";
import XemTheoWinLossPage from "../components/Web/QuetHang/XemTheoWinLoss.vue";
import ThongKeTheoMemberPage from "../components/Web/QuetHang/ThongKeTheoMember.vue";
import XemNhanhPage from "../components/Web/QuetHang/XemNhanh.vue";
import BetTopMemberPage from "../components/Web/QuetHang/BetTopMember.vue";
import TopThangThuaPage from "../components/Web/QuetHang/TopThangThua.vue";
import ReportPage from "../components/Web/QuetHang/Report.vue";
import ReportByProductPage from "../components/Web/QuetHang/ReportByProduct.vue";
import ThongKeLoDePage from "../components/Web/ThongKeLoDe/ThongKeLoDe.vue";
import TaoSoPage from "../components/Web/ThongKeLoDe/TaoSo.vue";
import ThongKeXacSuatPage from "../components/Web/ThongKeLoDe/ThongKeXacSuat.vue";
import CreditBalancePage from "../components/Web/QuetHang/CreditBalance.vue";
import TongDaiLyPage from "../components/Web/TaiKhoan/TongDaiLy.vue";
import ListUserPage from "../components/Web/Setting/ListUser.vue";

// Mobile
import LoginMobilePage from "../components/Mobile/Login/Login.vue";
import MenuMobilePage from "../components/Mobile/Menu/Menu.vue";
import PlayerMobilePage from "../components/Mobile/CrawlData/Player.vue";
import HistoryBatchJobMobilePage from "../components/Mobile/CrawlData/HistoryBatchJob.vue";
import MappingMatchLeagueMobilePage from "../components/Mobile/CrawlData/MappingMatchLeague.vue";
import PlayerKhongChoiMobilePage from "../components/Mobile/CrawlData/PlayerKhongChoi.vue";
import XemTheoWinLossMobilePage from "../components/Mobile/QuetHang/XemTheoWinLoss.vue";
import ThongKeTheoMemberMobilePage from "../components/Mobile/QuetHang/ThongKeTheoMember.vue";
import XemNhanhMobilePage from "../components/Mobile/QuetHang/XemNhanh.vue";
import BetTopMemberMobilePage from "../components/Mobile/QuetHang/BetTopMember.vue";
import TopThangThuaMobilePage from "../components/Mobile/QuetHang/TopThangThua.vue";
import ReportMobilePage from "../components/Mobile/QuetHang/Report.vue";
import ReportByProductMobilePage from "../components/Mobile/QuetHang/ReportByProduct.vue";
import ThongKeLoDeMobilePage from "../components/Mobile/ThongKeLoDe/ThongKeLoDe.vue";
import TaoSoMobilePage from "../components/Mobile/ThongKeLoDe/TaoSo.vue";
import ThongKeXacSuatMobilePage from "../components/Mobile/ThongKeLoDe/ThongKeXacSuat.vue";
import CreditBalanceMobilePage from "../components/Mobile/QuetHang/CreditBalance.vue";
import TongDaiLyMobilePage from "../components/Mobile/TaiKhoan/TongDaiLy.vue";
import ListUserMobilePage from "../components/Mobile/Setting/ListUser.vue";


Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: "/",
            component: LoginPage,
            meta: {
                guest: true,
            },
        },
        {
            path: "/login",
            component: LoginPage,
            meta: {
                guest: true,
            },
        },
        {
            path: "/menu",
            component: MenuPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/quan-ly-player",
            component: PlayerPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/history-batch-job",
            component: HistoryBatchJobPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mapping-match-league",
            component: MappingMatchLeaguePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/thong-ke-khong-choi",
            component: PlayerKhongChoiPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/xem-theo-win-loss",
            component: XemTheoWinLossPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/thong-ke-theo-member",
            component: ThongKeTheoMemberPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/xem-nhanh",
            component: XemNhanhPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/bet-top-member",
            component: BetTopMemberPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
          path: "/top-thang-thua",
          component: TopThangThuaPage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
            path: "/report",
            component: ReportPage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
          path: "/manager-agent-player",
          component: TongDaiLyPage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/credit-balance",
          component: CreditBalancePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/list-user",
          component: ListUserPage,
          meta: {
            requiresAuth: true,
            role: "ROLE_ADMIN",
          },
        },
        {
          path: "/report-by-product",
          component: ReportByProductPage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/thong-ke-lo-de",
          component: ThongKeLoDePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/tao-so",
          component: TaoSoPage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/thong-ke-xac-suat",
          component: ThongKeXacSuatPage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        // Mobile
        {
            path: "/mb",
            component: LoginMobilePage,
            meta: {
                guest: true,
            },
        },
        {
            path: "/mb-login",
            component: LoginMobilePage,
            meta: {
                guest: true,
            },
        },
        {
            path: "/mb-menu",
            component: MenuMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-quan-ly-player",
            component: PlayerMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-history-batch-job",
            component: HistoryBatchJobMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-mapping-match-league",
            component: MappingMatchLeagueMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-thong-ke-khong-choi",
            component: PlayerKhongChoiMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-xem-theo-win-loss",
            component: XemTheoWinLossMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-thong-ke-theo-member",
            component: ThongKeTheoMemberMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-xem-nhanh",
            component: XemNhanhMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
            path: "/mb-bet-top-member",
            component: BetTopMemberMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
          path: "/mb-top-thang-thua",
          component: TopThangThuaMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
            path: "/mb-report",
            component: ReportMobilePage,
            meta: {
                requiresAuth: true,
                role: "ROLE_MEMBER",
            },
        },
        {
          path: "/mb-manager-agent-player",
          component: TongDaiLyMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/mb-credit-balance",
          component: CreditBalanceMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/mb-list-user",
          component: ListUserMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_ADMIN",
          },
        },
        {
          path: "/mb-report-by-product",
          component: ReportByProductMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/mb-thong-ke-lo-de",
          component: ThongKeLoDeMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/mb-tao-so",
          component: TaoSoMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
        {
          path: "/mb-thong-ke-xac-suat",
          component: ThongKeXacSuatMobilePage,
          meta: {
            requiresAuth: true,
            role: "ROLE_MEMBER",
          },
        },
    ],
});

router.beforeEach((to, from, next) => {
    let jwt = localStorage.getItem("jwt");
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!jwt) {
            let theme = localStorage.getItem("themeColor");
            let isMiniMenu = localStorage.getItem("isMiniMenu");
            localStorage.clear();
            localStorage.setItem("themeColor", theme);
            localStorage.setItem('isMiniMenu', isMiniMenu);
            next({
                path: "/login",
                params: { nextUrl: to.fullPath },
            });
        } else {
            if (!ApiService.checkTokenValidity(jwt)) {
                let theme = localStorage.getItem("themeColor");
                let isMiniMenu = localStorage.getItem("isMiniMenu");
                localStorage.clear();
                localStorage.setItem("themeColor", theme);
                localStorage.setItem('isMiniMenu', isMiniMenu);
                next({
                    path: "/login",
                    params: { nextUrl: to.fullPath },
                });
            } else {
                let user = JSON.parse(localStorage.getItem("user"));
                if (to.matched.some((record) => record.meta.role)) {
                    if (user.roles.includes(to.meta.role)) {
                        next();
                    } else {
                        next(false);
                    }
                } else {
                    next();
                }
            }
        }
    } else if (to.matched.some((record) => record.meta.guest)) {
        if (!jwt) {
            next();
        } else {
            if (!ApiService.checkTokenValidity(jwt)) {
                let theme = localStorage.getItem("themeColor");
                let isMiniMenu = localStorage.getItem("isMiniMenu");
                localStorage.clear();
                localStorage.setItem("themeColor", theme);
                localStorage.setItem('isMiniMenu', isMiniMenu);
                next({
                    path: "/login",
                    params: { nextUrl: to.fullPath },
                });
            } else {
                router.push("/menu");
            }
        }
    } else {
        next();
    }
});

axios.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },
    (error) => {
        if (error.response && error.response.status) {
            let namePath = router.currentRoute.path;
            switch (error.response.status) {
                case 401:
                    if (namePath && namePath !== "/" && namePath !== "/login") {
                        alert("Hết thời gian đăng nhập!");
                        setTimeout(() => {
                            localStorage.removeItem("jwt");
                            localStorage.removeItem("user");
                            localStorage.removeItem("userName");
                            router.push("/login");
                        }, 2000);
                    }
                    break;
                case 403:
                    router.push("/menu");
                    break;
                // case 404:
                //   break;
            }
            return Promise.reject(error);
        }
    }
);

export default router;
