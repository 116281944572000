import ApiService from "./api.service";

const Mapping = {
    getMatchNeedMapping: async (url, data) => {
        url = url + "/bet-mapping/get-match-need-mapping";
        return ApiService.get(url, data);
    },
    findSameMatch: async (url, data) => {
        url = url + "/bet-mapping/find-same-match";
        return ApiService.get(url, data);
    },
    getLeagueNeedMapping: async (url, data) => {
        url = url + "/bet-mapping/get-league-need-mapping";
        return ApiService.get(url, data);
    },
    findSameLeague: async (url, data) => {
        url = url + "/bet-mapping/find-same-league";
        return ApiService.get(url, data);
    },
    mappingMatch: async (url, data, params) => {
        url = url + "/bet-mapping/mapping-match";
        return ApiService.put(url, data, params);
    },
    mappingLeague: async (url, data, params) => {
        url = url + "/bet-mapping/mapping-league";
        return ApiService.put(url, data, params);
    },
    hideListMatch: async (url, data, params) => {
        url = url + "/bet-mapping/hide-list-match";
        return ApiService.put(url, data, params);
    },
};

export default Mapping;
