<template>
    <div id="app" class="app" :class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
        <img v-if="$root.isLogin" class="back-img-app" :src="getImg('img-bong-da', 'jpg')" />
        <div class="content" :class="{ 'not-login': !$root.isLogin }">
            <router-view />
        </div>
        <div v-if="isLoading == 1" class="loading">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem;"></b-spinner>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
        };
    },
    watch: {},
    computed: {},
    created() {
        console.log("I am mounted to the DOM!");
        this.$root.isMobile = this.detectMob();

        this.setThemePreference();

        let _this = this;
        setInterval(() => {
            _this.isLoading = localStorage.getItem("loading") || 0;
        }, 100);

        let token = localStorage.getItem("jwt");
        if (token) {
            this.$root.isLogin = true;
            let routePath = this.$route.path;
            if (this.$root.isMobile && !routePath.includes('/mb')) {
                this.movePage(routePath);
            }
        } else {
            this.clearLocalStorage();
            this.$root.isLogin = false;
            this.movePage("login", "replace");
        }
    },
    mounted() {
    },
    methods: {
        setThemePreference(mode) {
            if (mode) {
                this.switchHtmlTheme(mode);
            } else {
                let theme = 'light';
                if (localStorage.getItem('themeColor')) {
                    theme = localStorage.getItem('themeColor');
                }
                this.isDarkMode = theme === 'dark';
                this.switchHtmlTheme(theme);
            }
        },
    },
};
</script>

<style lang="scss">
@import './assets/scss/theme/light.scss';
@import './assets/scss/theme/dark.scss';
@import './assets/scss/style-for-mobile.scss';
@import './assets/scss/style-for-web.scss';

.loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--back-loading);
}
</style>
