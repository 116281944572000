<template>
    <div id="thong-ke-khong-choi" class="thong-ke-khong-choi">
        <pc-header :title="title" :backTo="backTo" />
        <div class="history-batch-job-content">
            <b-row>
                <b-col offset="1" cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Nhà Cái</span>
                        <multi-select class="multi-select" v-model="valueMaster2" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="dataMaster2" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Tài Khoản</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="userName"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Thời Gian</span>
                        <date-range-picker v-model="dateFromTo" :locale-data="$root.localeData" :show-dropdowns="true"
                            :auto-apply="true" :ranges="ranges" :linked-calendars="false"
                            opens="center"></date-range-picker>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="onClickSearchBtn()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <div class="table-data">
                        <b-table sticky-header fixed hover small :items="dataTable" :fields="fields"
                            :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                            empty-text="Không có dữ liệu" :busy="isBusy" :per-page="perPage" :current-page="currentPage">
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(status)="scope">
                                <span>{{ scope.item.status === 0 ? "Open" : "Close" }}</span>
                            </template>
                            <template #cell(action)="scope">
                                <div class="button-action text-left">
                                    <b-button variant="primary" @click="onClickEditItemBtn(scope.item)">
                                        <svg class="icon icon-edit-25">
                                            <use xlink:href="#icon-edit-25"></use>
                                        </svg>
                                    </b-button>
                                    <b-button variant="danger" @click="onClickDeleteItemBtn(scope.item)">
                                        <svg class="icon icon-trash5">
                                            <use xlink:href="#icon-trash5"></use>
                                        </svg>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination v-model="currentPage" :total-rows="dataTable.length" :per-page="perPage" first-number
                            last-number align="center"></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Master from "../../../service/master.service";
import Crawldata from "../../../service/crawldata.service";

export default {
    name: "PlayerKhongChoi",
    data() {
        return {
            title: "THỐNG KÊ TÀI KHOẢN KHÔNG CÒN CHƠI",
            backTo: "menu",
            ranges: {
                "Tuần Trước": this.getRangeDate("tuanTruoc"),
                "2 Tuần Trước": this.getRangeDate("tuanTruoc", 2),
                "3 Tuần Trước": this.getRangeDate("tuanTruoc", 3),
                "Tháng Này": this.getRangeDate("thangNay"),
                "Tháng Trước": this.getRangeDate("thangTruoc"),
            },
            dataMaster2: [
                {
                    codeKey: "00",
                    codeName: "Tất cả",
                },
            ],
            valueMaster2: {
                codeKey: "00",
                codeName: "Tất cả",
            },
            userName: "",
            dateFromTo: {
                startDate: this.getRangeDate("tuanTruoc")[0],
                endDate: this.getRangeDate("tuanTruoc")[1],
            },
            dataTable: [],
            fields: [
                { key: "index", label: "STT", sortable: false },
                { key: "status", label: "Trạng Thái", sortable: true },
                { key: "supplier", label: "NCC", sortable: true },
                { key: "typeDomainNm", label: "Nhà Cái", sortable: true },
                { key: "userName", label: "TK Chính", sortable: true },
                { key: "loginId", label: "Login Id", sortable: true },
                { key: "passWord", label: "Mật Khẩu", sortable: true },
                { key: "passCode", label: "Mật Khẩu Code", sortable: true },
                { key: "downLine", label: "Down Line", sortable: true },
            ],
            perPage: 25,
            currentPage: 1,
            isBusy: false,
        };
    },
    computed: {
        ...mapState("PlayerKhongChoi", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListMasterCodeByClass("00002");
        this.getDataListPlayer();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("PlayerKhongChoi", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.onClickSearchBtn();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    break;
            }
        },
        getListMasterCodeByClass(codeClass) {
            let _this = this;
            Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    response.data.forEach((item) => {
                        _this.dataMaster2.push({
                            codeKey: item.codeKey,
                            codeName: item.codeName,
                        });
                    });
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getDataListPlayer() {
            this.isBusy = true;
            let _this = this;
            let params = {
                fromDate: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                toDate: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                typeDomain: this.valueMaster2.codeKey,
                userName: this.userName,
            };
            Crawldata.checkPlayerNotPlay(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data.map((item, index) => {
                        item.index = index + 1;
                        return item;
                    });
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.dataTable = [];
                    _this.isBusy = false;
                    _this.errorApi(error, _this);
                });
        },
        onClickSearchBtn() {
            this.getDataListPlayer();
        },
    },
};
</script>

<style lang="scss">
.style-for-web .thong-ke-khong-choi {}
</style>
