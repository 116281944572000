import ApiService from "./api.service";

const User = {
    getUsers: async (url, data) => {
        url = url + "/user/getUsers";
        return ApiService.get(url, data);
    },
    insert: async (url, data) => {
        url = url + "/user/insert";
        return ApiService.put(url, data);
    },
    update: async (url, data) => {
        url = url + "/user/update";
        return ApiService.put(url, data);
    },
    delete: async (url, data) => {
        url = url + "/user/delete";
        return ApiService.delete(url, data);
    },
};

export default User;
