import axios from "axios";
import jwt_decode from "jwt-decode";

const PREFIX_TOKEN = "Bearer";

const ApiService = {
	setHeader() {
		let token = localStorage.getItem("jwt");
		axios.defaults.headers.common["Authorization"] = `${PREFIX_TOKEN} ${token}`;
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	get(url, params) {
		this.setHeader();
		return axios.get(url, { params: params }).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	getWithNoToken(url, params) {
		return axios.get(url, { params: params }).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	post(resource, data) {
		this.setHeader();
		return axios.post(resource, data).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	download(resource, data, postConfig) {
		this.setHeader();
		return axios.post(resource, data, postConfig).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	put(resource, data, params) {
		this.setHeader();
		if (params) {
			return axios.put(resource, data, { params: params }).then((res) => {
				localStorage.setItem("loading", 0);
				return res;
			});
		} else {
			return axios.put(resource, data).then((res) => {
				localStorage.setItem("loading", 0);
				return res;
			});
		}
	},

	patch(resource, data) {
		this.setHeader();
		return axios.patch(resource, data).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	delete(resource, data) {
		this.setHeader();
		return axios.delete(resource, { data: data }).then((res) => {
			localStorage.setItem("loading", 0);
			return res;
		});
	},

	checkTokenValidity(token) {
		if (!token) {
			return false;
		}
		const decodedToken = jwt_decode(token);
		return decodedToken && decodedToken.exp * 1000 > new Date().getTime();
	},
};

export default ApiService;
