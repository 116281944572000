import ApiService from "./api.service";

const Loto = {
    getListTinh: async (url) => {
        url = url + "/loto/getListTinh";
        return ApiService.get(url);
    },
    getListChanLe: async (url, data) => {
        url = url + "/loto/getListChanLe";
        return ApiService.get(url, data);
    },
    getCau100: async (url, data) => {
        url = url + "/loto/getCau100";
        return ApiService.get(url, data);
    },
    getBotTrain: async (url, data) => {
        url = url + "/loto/getBotTrain";
        return ApiService.get(url, data);
    },
    doSoTrung: async (url, data) => {
        url = url + "/loto/doSoTrung";
        return ApiService.get(url, data);
    },
    listAgent: async (url, data) => {
        url = url + "/loto/listAgent";
        return ApiService.get(url, data);
    },
    saveAgent: async (url, data) => {
        url = url + "/loto/saveAgent";
        return ApiService.put(url, data);
    },
    deleteAgent: async (url, data) => {
        url = url + "/loto/deleteAgent";
        return ApiService.delete(url, data);
    },
};

export default Loto;
