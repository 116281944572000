<template>
    <div id="thong-ke-theo-member" class="thong-ke-theo-member">
        <pc-header :title="title" :backTo="backTo" />
        <div class="thong-ke-theo-member-content">
        </div>
        
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "ThongKeTheoMember",
    data() {
        return {
            title: "XEM NHANH",
            backTo: "menu",
        };
    },
    computed: {
        ...mapState("ThongKeTheoMember", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("ThongKeTheoMember", []),
    },
};
</script>

<style lang="scss">
.style-for-mobile .thong-ke-theo-member {
}
</style>
