<template>
    <div id="mapping-match-league" class="mapping-match-league">
        <pc-header :title="title" :backTo="backTo" />
        <div class="mapping-match-league-content">
            <b-row>
                <b-col offset="1" cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Chọn kiểu ghép</span>
                        <multi-select class="multi-select" v-model="mode" deselect-label="Không thể bỏ chọn" track-by="key"
                            label="label" placeholder="" :options="modeOptions" :searchable="false" :allow-empty="false"
                            @input="getData()">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Thời Gian</span>
                        <date-range-picker v-model="dateFromTo" :locale-data="$root.localeData" :show-dropdowns="true"
                            :auto-apply="true" :ranges="ranges" :linked-calendars="false"
                            opens="center"></date-range-picker>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <b-input-group>
                            <b-form-checkbox v-model="virtualSport">Bóng Ảo</b-form-checkbox>
                        </b-input-group>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="6">
                    <div class="button-action text-left">
                        <b-input-group class="mr-3 w-50">
                            <b-form-input type="search" v-model="valueFilter1"></b-form-input>
                        </b-input-group>
                        <b-button variant="success" @click="getData(1)" class="mr-3">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">
                                {{ mode.key === 0 ? "Tìm Trận Giống Nhau" : "Tìm Giải Giống Nhau" }}
                            </span>
                        </b-button>
                        <b-button variant="success" @click="mapSame()">
                            <svg class="icon icon-routing-25">
                                <use xlink:href="#icon-routing-25"></use>
                            </svg>
                            <span class="ml-2">
                                {{ mode.key === 0 ? "Ghép Trận" : "Ghép Giải" }}
                            </span>
                        </b-button>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="button-action text-left">
                        <b-input-group class="mr-3 w-50">
                            <b-form-input type="search" v-model="valueFilter2"></b-form-input>
                        </b-input-group>
                        <b-button variant="success" @click="getData(2)" class="mr-3">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">
                                {{ mode.key === 0 ? "Tìm Trận Giống Nhau" : "Tìm Giải Giống Nhau" }}
                            </span>
                        </b-button>
                        <b-button v-if="mode.key === 0" variant="success" @click="hideListMatch()">
                            <svg class="icon icon-eye-slash5">
                                <use xlink:href="#icon-eye-slash5"></use>
                            </svg>
                            <span class="ml-2">Ẩn Trận</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="6">
                    <div class="table-data">
                        <b-table ref="table1" sticky-header fixed hover small :items="dataTable1" :fields="fields1"
                            :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                            empty-text="Không có dữ liệu" empty-filtered-text="Không có dũ liệu" :filter="valueFilter1"
                            :filter-included-fields="filterOn1" @filtered="onFilteredTable1" :busy="isBusy1"
                            :per-page="pageSizeTable1" :current-page="pageTable1">
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #emptyfiltered="scope">
                                <strong>{{ scope.emptyFilteredText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(status)="scope">
                                <b-input-group>
                                    <b-form-checkbox v-model="scope.item.status"></b-form-checkbox>
                                </b-input-group>
                            </template>
                        </b-table>
                        <b-pagination v-model="pageTable1" :total-rows="totalTable1" :per-page="pageSizeTable1" first-number
                            last-number align="center"></b-pagination>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="table-data">
                        <b-table sticky-header fixed hover small :items="dataTable2" :fields="fields2"
                            :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                            empty-text="Không có dữ liệu" empty-filtered-text="Không có dũ liệu" @row-clicked="onRowClick"
                            :filter="valueFilter2" :filter-included-fields="filterOn2" @filtered="onFilteredTable2"
                            :busy="isBusy2">
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #emptyfiltered="scope">
                                <strong>{{ scope.emptyFilteredText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(hidden)="scope">
                                <b-input-group>
                                    <b-form-checkbox v-model="scope.item.hidden"></b-form-checkbox>
                                </b-input-group>
                            </template>
                        </b-table>
                        <b-pagination v-model="pageTable2" :total-rows="totalTable2" :per-page="pageSizeTable2" first-number
                            last-number align="center" @input="changePageTable()"></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Mapping from "../../../service/mapping.service";

export default {
    name: "MappingMatchLeague",
    data() {
        return {
            title: "GHÉP TRẬN ĐẤU & GIẢI ĐẤU",
            backTo: "menu",
            ranges: {
                "Hôm Nay": this.getRangeDate("homNay"),
                "Hôm Qua": this.getRangeDate("homQua"),
                "Tuần Này": this.getRangeDate("tuanNay"),
                "Tuần Trước": this.getRangeDate("tuanTruoc"),
                "Tháng Này": this.getRangeDate("thangNay"),
                "Tháng Trước": this.getRangeDate("thangTruoc"),
            },
            modeOptions: [
                {
                    key: 0,
                    label: "Ghép trận đấu",
                },
                {
                    key: 1,
                    label: "Ghép giải đấu",
                },
            ],
            mode: {
                key: 0,
                label: "Ghép trận đấu",
            },
            dateFromTo: {
                startDate: this.getRangeDate("homTruoc", 5)[0],
                endDate: this.getRangeDate("homTruoc", 5)[1],
            },
            virtualSport: false,
            valueFilter1: "",
            valueFilter2: "",
            dataTable1: [],
            filterOn1: [],
            fields1: [],
            pageTable1: 1,
            pageSizeTable1: 1000,
            totalTable1: 0,
            isBusy1: false,
            dataTable2: [],
            filterOn2: [],
            fields2: [],
            pageTable2: 1,
            pageSizeTable2: 100,
            totalTable2: 0,
            isBusy2: false,
        };
    },
    computed: {
        ...mapState("MappingMatchLeague", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getData();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("MappingMatchLeague", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.getData();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    this.mapSame();
                    e.preventDefault();
                    break;
                case 113: // f2
                    this.hideListMatch();
                    e.preventDefault();
                    break;
            }
        },
        getData(table) {
            switch (this.mode.key) {
                case 0:
                    this.filterOn1 = ["diffMatch"];
                    this.fields1 = [
                        { key: "rootMatch", label: "Trận đấu chuẩn (viva)", sortable: true },
                        { key: "diffMatch", label: "Trận cần ghép", sortable: true },
                        { key: "status", label: "Xác Nhận", sortable: true },
                    ];
                    this.filterOn2 = ["match"];
                    this.fields2 = [
                        { key: "match", label: "Trận đấu cần ghép", sortable: true },
                        { key: "hidden", label: "Ẩn trận đã tìm", sortable: true },
                    ];
                    if (table === 1) {
                        this.findSameMatch();
                    } else if (table === 2) {
                        this.getMatchNeedMapping();
                    } else {
                        this.findSameMatch();
                        this.getMatchNeedMapping();
                    }
                    break;
                case 1:
                    this.filterOn1 = ["diffLeague"];
                    this.fields1 = [
                        { key: "rootLeague", label: "Giải đấu chuẩn (viva)", sortable: true },
                        { key: "diffLeague", label: "Giải cần ghép", sortable: true },
                        { key: "status", label: "Xác Nhận", sortable: true },
                    ];
                    this.filterOn2 = ["league"];
                    this.fields2 = [{ key: "league", label: "Giải đấu cần ghép", sortable: true }];
                    if (table === 1) {
                        this.findSameLeague();
                    } else if (table === 2) {
                        this.getLeagueNeedMapping();
                    } else {
                        this.findSameLeague();
                        this.getLeagueNeedMapping();
                    }
                    break;
            }
        },
        findSameMatch() {
            this.isBusy1 = true;
            let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
            let _this = this;
            Mapping.findSameMatch(_this.$root.url, params)
                .then((response) => {
                    _this.isBusy1 = false;
                    _this.valueFilter1 = "";
                    _this.dataTable1 = response.data.map((i) => {
                        i.diffMatch = i.diff.match;
                        i.diffMatchId = i.diff.matchId;
                        i.rootMatch = i.root.match;
                        i.rootMatchId = i.root.matchId;
                        return i;
                    });
                    _this.totalTable1 = response.data.length;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getMatchNeedMapping() {
            this.isBusy2 = true;
            let params = this.setParamAPI(this.pageTable2, this.pageSizeTable2);
            let _this = this;
            Mapping.getMatchNeedMapping(_this.$root.url, params)
                .then((response) => {
                    _this.isBusy2 = false;
                    _this.valueFilter2 = "";
                    _this.dataTable2 = response.data.listMapping;
                    _this.totalTable2 = response.data.total;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        findSameLeague() {
            this.isBusy1 = true;
            let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
            let _this = this;
            Mapping.findSameLeague(_this.$root.url, params)
                .then((response) => {
                    _this.isBusy1 = false;
                    _this.valueFilter1 = "";
                    _this.dataTable1 = response.data.map((i) => {
                        i.diffLeague = i.diff.league;
                        i.diffLeagueId = i.diff.leagueId;
                        i.rootLeague = i.root.league;
                        i.rootLeagueId = i.root.leagueId;
                        return i;
                    });
                    _this.totalTable1 = response.data.length;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getLeagueNeedMapping() {
            this.isBusy2 = true;
            let params = this.setParamAPI(this.pageTable2, this.pageSizeTable2);
            let _this = this;
            Mapping.getLeagueNeedMapping(_this.$root.url, params)
                .then((response) => {
                    _this.isBusy2 = false;
                    _this.valueFilter2 = "";
                    _this.dataTable2 = response.data.listMapping;
                    _this.totalTable2 = response.data.total;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        mapSame() {
            let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
            let data = this.dataTable1.filter((i) => i.status === true);
            let _this = this;
            Mapping[this.mode.key === 0 ? "mappingMatch" : "mappingLeague"](_this.$root.url, data, params)
                .then((response) => {
                    console.log(response);
                    this.getData();
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        hideListMatch() {
            let params = this.setParamAPI(this.pageTable1, this.pageSizeTable1);
            let data = this.dataTable2.filter((i) => i.hidden === true);
            let _this = this;
            Mapping.hideListMatch(_this.$root.url, data, params)
                .then((response) => {
                    console.log(response);
                    this.getData();
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        setParamAPI(pageTable, pageSizeTable) {
            let data = {
                virtualSport: this.virtualSport,
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                page: pageTable,
                limit: pageSizeTable,
            };
            return data;
        },
        onRowClick(item, index, event) {
            console.log(item, index, event);
            this.valueFilter1 = this.mode.key === 0 ? item.match : item.league;
        },
        changePageTable() {
            switch (this.mode.key) {
                case 0:
                    this.getMatchNeedMapping();
                    break;
                case 1:
                    this.getLeagueNeedMapping();
                    break;
            }
        },
        onFilteredTable1(filteredItems) {
            this.totalTable1 = filteredItems.length;
            this.pageTable1 = 1;
        },
        onFilteredTable2(filteredItems) {
            this.totalTable2 = filteredItems.length;
            this.pageTable2 = 1;
        },
    },
};
</script>

<style lang="scss">
.style-for-web .mapping-match-league {
    .b-table-sticky-header {
        min-height: 70vh;
        background-color: var(--common-background);
    }

    .b-table {
        >thead {
            >tr {
                >th {
                    &:last-child {
                        width: 150px;
                    }
                }
            }
        }
    }
}
</style>
