<template>
    <div id="credit-balance" class="credit-balance">
        <pc-header :title="title" :backTo="backTo" />
        <div class="credit-balance-content">
            <b-row>
                <b-col offset="1" cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Tài Khoản</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="userName"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Trạng Thái</span>
                        <multi-select
                            class="multi-select"
                            v-model="valueStatus"
                            deselect-label="Không thể bỏ chọn"
                            track-by="codeKey"
                            label="codeName"
                            placeholder=""
                            :options="optionsStatus"
                            :searchable="false"
                            :allow-empty="false"
                        >
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="getData()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <div class="table-data">
                        <b-table
                            sticky-header
                            fixed
                            hover
                            small
                            :items="dataTable"
                            :fields="fields"
                            :head-variant="$root.theme"
                            :dark="$root.theme === 'dark'"
                            show-empty
                            empty-text="Không có dữ liệu"
                            :busy="isBusy"
                            :per-page="perPage"
                            :current-page="currentPage"
                        >
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(status)="scope">
                                <span>{{ scope.item.status === 0 ? "Open" : "Close" }}</span>
                            </template>
                            <template #cell(username)="scope">
                                <div class="btn-link" @click="getData(scope.item)">
                                    {{ scope.item.username }}
                                </div>
                            </template>
                        </b-table>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="dataTable.length"
                            :per-page="perPage"
                            first-number
                            last-number
                            align="center"
                        ></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "CreditBalance",
    data() {
        return {
            title: "CREDIT / BALANCE",
            backTo: "menu",
            userName: "",
            valueStatus: {},
            optionsStatus: [],
            dataTable: [],
            fields: [
                { key: "index", label: "STT", sortable: false },
                { key: "username", label: "Tên Tài Khoản", sortable: true },
                { key: "status", label: "Trạng Thái", sortable: true },
                { key: "credit", label: "Credit", sortable: true },
                { key: "availableCredit", label: "Available Credit", sortable: true },
            ],
            perPage: 25,
            currentPage: 1,
            isBusy: false,
        };
    },
    computed: {
        ...mapState("CreditBalance", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("CreditBalance", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.getData();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getData() {},
    },
};
</script>

<style lang="scss">
.style-for-web .credit-balance {
}
</style>
