<template>
    <div id="bet-top-member" class="bet-top-member">
        <pc-header :title="title" :backTo="backTo" />
        <div class="bet-top-member-content">
            <div class="player-content">
                <b-row>
                    <b-col offset="1" cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Lọc Theo</span>
                            <multi-select class="multi-select" v-model="orderBy" deselect-label="Không thể bỏ chọn"
                                track-by="codeKey" label="codeName" placeholder="" :options="orderByOptions"
                                :searchable="false" :allow-empty="false" @input="getTopMember()">
                            </multi-select>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Thời Gian</span>
                            <date-range-picker v-model="dateFromTo" :locale-data="$root.localeData" :show-dropdowns="true"
                                :auto-apply="true" :ranges="ranges" :linked-calendars="false"
                                opens="center"></date-range-picker>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="getTopMember()">
                                <svg class="icon icon-search-normal-15">
                                    <use xlink:href="#icon-search-normal-15"></use>
                                </svg>
                                <span class="ml-2">Tìm Kiếm</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <div class="table-data">
                            <b-table sticky-header fixed hover small :items="dataTable" :fields="fields"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu" :busy="isBusy">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle mr-2"></b-spinner>
                                        <strong>Đang tải dữ liệu...</strong>
                                    </div>
                                </template>
                                <template #cell(turnOver)="scope">
                                    <div>{{ getFormatNumber(scope.item.turnOver) }}</div>
                                </template>
                                <template #cell(totalWinLoss)="scope">
                                    <div>{{ getFormatNumber(scope.item.totalWinLoss) }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number
                                last-number align="center" @input="getTopMember()"></b-pagination>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Betscan from "../../../service/betscan.service";

export default {
    name: "BetTopMember",
    data() {
        return {
            title: "TOP THÀNH VIÊN THẮNG",
            backTo: "menu",
            ranges: {
                "Hôm Nay": this.getRangeDate("homNay"),
                "Hôm Qua": this.getRangeDate("homQua"),
                "Tuần Này": this.getRangeDate("tuanNay"),
                "Tuần Trước": this.getRangeDate("tuanTruoc"),
                "Tháng Này": this.getRangeDate("thangNay"),
                "Tháng Trước": this.getRangeDate("thangTruoc"),
            },
            dateFromTo: {
                startDate: this.getRangeDate("tuanNay")[0],
                endDate: this.getRangeDate("tuanNay")[1],
            },
            orderByOptions: [
                {
                    codeKey: "DESC",
                    codeName: "Top Thành Viên Thắng",
                },
                {
                    codeKey: "ASC",
                    codeName: "Top Thành Viên Thua",
                },
            ],
            orderBy: {
                codeKey: "DESC",
                codeName: "Top Thành Viên Thắng",
            },
            fields: [
                { key: "memberId", label: "ID Thành Viên", sortable: true },
                { key: "domainName", label: "Nhà Cái", sortable: true },
                { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                { key: "turnOver", label: "Tổng Điểm Cược", sortable: true },
                { key: "totalWinLoss", label: "WinLoss", sortable: true },
            ],
            dataTable: [],
            isBusy: false,
            perPage: 1000,
            currentPage: 1,
            totalRows: 0,
        };
    },
    computed: {
        ...mapState("BetTopMember", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getTopMember();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("BetTopMember", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.getTopMember();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getTopMember() {
            this.isBusy = true;
            let params = {
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                orderBy: this.orderBy.codeKey,
                page: this.currentPage,
                limit: this.perPage,
            };
            let _this = this;
            Betscan.getTopMember(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data.data;
                    _this.totalRows = response.data.total;
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy = false;
                });
        },
    },
};
</script>

<style lang="scss">
.style-for-web .bet-top-member {}
</style>
