import ApiService from "./api.service";

const Master = {
    getListMasterCodeByClass: async (url, data) => {
        url = url + "/master/getListMasterCodeByClass";
        return ApiService.get(url, data);
    },
};

export default Master;
