<template>
    <div id="thong-ke-lo-de" class="thong-ke-lo-de">
        <pc-header :title="title" :backTo="backTo" />
        <div class="thong-ke-lo-de-content">
            <div class="btn-filter">
                <b-button size="sm" variant="primary" @click="onClickBtnFilter()">
                    {{ isShowFilter ? "Đóng" : "Mở" }} Tìm Kiếm
                </b-button>
            </div>
            <div>
                <b-row v-if="isShowFilter">
                    <b-col offset="1" cols="2">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Miền:</span>
                            <b-form-radio v-model="valueVung1" name="vung-loc-du-lieu" value="MIEN_BAC"
                                @change="onChangeVung('1', 'MIEN_BAC')">
                                Bắc
                            </b-form-radio>
                            <b-form-radio v-model="valueVung1" name="vung-loc-du-lieu" value="MIEN_NAM"
                                @change="onChangeVung('1', 'MIEN_NAM')">
                                Nam
                            </b-form-radio>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Thời Gian:</span>
                            <date-range-picker v-model="dateFromTo1" :locale-data="$root.localeData" :show-dropdowns="true"
                                :auto-apply="true" :ranges="ranges1" :max-date="maxDate" :linked-calendars="false" opens="center"
                                @update="getListDate()"></date-range-picker>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Loại hiển thị:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="loaiHienThi" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="loaiHienThiOptions"
                                    :searchable="false" :allow-empty="false" @input="changeLoaiHienThi()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="isShowFilter" class="mt-2">
                    <b-col offset="1" cols="3" v-if="[loaiHienThiOptions[0].codeKey, loaiHienThiOptions[1].codeKey].includes(
                        loaiHienThi.codeKey
                    )
                        ">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Loại thống kê:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="loaiThongKe" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="loaiThongKeOptions"
                                    :searchable="false" :allow-empty="false" @input="changeLoaiThongKe()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2" v-if="loaiHienThi.codeKey === loaiHienThiOptions[0].codeKey">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Loại biểu đồ:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="loaiBieuDo" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="loaiBieuDoOptions"
                                    :searchable="false" :allow-empty="false" @input="changeLoaiBieuDo()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <!-- chart -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[0].codeKey" class="mt-1">
                    <b-col cols="12">
                        <div class="group-chart">
                            <chart-line v-if="loaiBieuDo.codeKey === 'line' && !isLoading" :options="chartOptions"
                                :data="chartData" />
                            <chart-bar v-if="loaiBieuDo.codeKey === 'bar' && !isLoading" :options="chartOptions"
                                :data="chartData" />
                        </div>
                    </b-col>
                </b-row>
                <!-- table -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[1].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="group-table">
                            <div class="group-header">
                                <div></div>
                                <div v-for="(item, index) in listDate" :key="index">
                                    {{ item }}
                                </div>
                            </div>
                            <div class="group-body">
                                <div v-for="(item, index) in dataTable" :key="index">
                                    {{ item.codeName }}
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <!-- table-100-so -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[2].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="group-table-100">
                            <div>
                                <div v-for="(item, index) in dataTable100So" :key="index"
                                    :class="`item item-${item.class}`">
                                    <label>{{ item.codeKey }}</label>
                                    <br />
                                    <label>{{ item.count }} ({{ item.percent }})</label>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <!-- table-chuoi-so -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[3].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="table-data group-chuoi-so">
                            <b-table sticky-header fixed hover small :items="dataTableChuoiSo" :fields="fieldsTableChuoiSo"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #cell(date)="scope">
                                    <span class="chuoi">{{ scope.item.date.join(", ") }}</span>
                                </template>
                                <template #cell(chuoiChan)="scope">
                                    <span class="chuoi">{{ getText(scope.item.chuoiChan) }}</span>
                                </template>
                                <template #cell(chuoiLe)="scope">
                                    <span class="chuoi">{{ getText(scope.item.chuoiLe) }}</span>
                                </template>
                            </b-table>
                            <b-pagination v-model="currentPageTableChuoiSo" :total-rows="totalRowsTableChuoiSo"
                                :per-page="perPageTableChuoiSo" first-number last-number align="center"
                                @input="getDataTableChuoiSo()"></b-pagination>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Bar, Line } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
} from "chart.js";
import Loto from "../../../service/loto.service";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
);

export default {
    name: "ThongKeLoDe",
    components: {
        "chart-bar": Bar,
        "chart-line": Line,
    },
    data() {
        return {
            app: this,
            title: "TẠO SỐ",
            backTo: "menu",
            isShowFilter: true,
            valueVung1: "MIEN_NAM",
            soNgayView: 0,
            ranges1: {
                "Tuần Trước": this.getRangeDate("tuanTruoc", 1, null, true),
                "Tháng Trước": this.getRangeDate("thangTruoc", 1, null, true),
                "3 Tháng Trước": this.getRangeDate("thangTruoc", 3, null, true),
                "6 Tháng Trước": this.getRangeDate("thangTruoc", 6, null, true),
                "9 Tháng Trước": this.getRangeDate("thangTruoc", 9, null, true),
                "Năm Nay": this.getRangeDate("namNay", 1, null, true),
            },
            dateFromTo1: {
                startDate: this.getRangeDate("thangNay")[0],
                endDate: this.getRangeDate("thangNay")[1],
            },
            thang: {},
            thangOptions: [],
            nam: {},
            namOptions: [],
            maxDate: new Date(),
            loaiHienThi: {
                codeKey: "chart",
                codeName: "Biểu đồ",
            },
            loaiHienThiOptions: [
                {
                    codeKey: "chart",
                    codeName: "Biểu đồ",
                },
                {
                    codeKey: "table",
                    codeName: "Bảng tính",
                },
                {
                    codeKey: "table-100-so",
                    codeName: "Bảng 100 số",
                },
                {
                    codeKey: "table-chuoi-so",
                    codeName: "Chuỗi chẵn lẻ",
                },
            ],
            loaiBieuDo: {
                codeKey: "bar",
                codeName: "Biểu đồ cột",
            },
            loaiBieuDoOptions: [
                {
                    codeKey: "line",
                    codeName: "Biểu đồ đường",
                },
                {
                    codeKey: "bar",
                    codeName: "Biểu đồ cột",
                },
            ],
            loaiThongKe: {
                codeKey: "1",
                codeName: "1. Chẳn Lẻ 6 Con Đầu Đuôi",
            },
            loaiThongKeOptions: [
                {
                    codeKey: "1",
                    codeName: "Chẳn Lẻ 6 Con Đầu Đuôi",
                },
                {
                    codeKey: "2",
                    codeName: "Chẳn Lẻ Bao Lô 18 Con",
                },
                {
                    codeKey: "3",
                    codeName: "Bộ 19 Số",
                },
            ],
            listDate: [],
            isLoading: false,
            dataTinh: [],
            dataChanLe: [],
            chartData: {
                labels: [],
                datasets: [],
            },
            chartOptions: {
                responsive: true,
                scales: {},
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                plugins: {
                    tooltip: {
                        // position: "average",
                        enabled: false,
                        external: this.tooltipCustom,
                    },
                    legend: {
                        position: "right",
                        useBorderRadius: true,
                        borderRadius: 5,
                        labels: {
                            color: this.getColor(0)[0],
                            font: {
                                size: 14,
                            },
                        },
                    },
                },
            },
            dataTable: [],
            dataTable100So: [],
            dataTableChuoiSo: [],
            fieldsTableChuoiSo: [
                { key: "date", label: "Ngày Ra Số", sortable: true },
                { key: "dai", label: "Đài", sortable: true },
                { key: "chuoiChan", label: "Chuỗi chẵn", sortable: true },
                { key: "chuoiLe", label: "Chuỗi lẻ", sortable: true },
            ],
            currentPageTableChuoiSo: 1,
            totalRowsTableChuoiSo: 0,
            perPageTableChuoiSo: 25,
        };
    },
    computed: {
        ...mapState("ThongKeLoDe", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListTinh();
        this.dateFromTo1 = {
            startDate: this.$moment(new Date()).startOf("month"),
            endDate: this.$moment(new Date()).endOf("month"),
        };
        this.getListDate();

        for (let i = 0; i < 100; i++) {
            let obj = {
                codeKey: this.padTwo(i),
                count: 0,
                percent: 0,
                class: this.checkChanLe(this.padTwo(i)) ? "even" : "odd",
            };
            this.dataTable100So.push(obj);
        }
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    watch: {},
    methods: {
        ...mapActions("ThongKeLoDe", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        this.getListDate();
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getListTinh() {
            let _this = this;
            Loto.getListTinh(_this.$root.url)
                .then((response) => {
                    this.dataTinh = response.data;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getData() {
            this.isLoading = true;

            let fromDate = this.getFormatDate(this.dateFromTo1.startDate, "formatDateAPI");
            let toDate = this.getFormatDate(this.dateFromTo1.endDate, "formatDateAPI");
            let mien = this.valueVung1;
            let _this = this;
            Loto.getListChanLe(_this.$root.url, { fromDate, toDate, mien })
                .then((response) => {
                    _this.dataChanLe = response.data.map((i) => {
                        let indexLA = i.infos.findIndex((j) => j.tinhId === "LA");
                        if (indexLA !== -1) {
                            i.infos.splice(indexLA, 1);
                        }
                        return i;
                    });
                    switch (_this.loaiHienThi.codeKey) {
                        case _this.loaiHienThiOptions[0].codeKey:
                            _this.getChartOptions();
                            _this.getChartDatasets();
                            break;
                        case _this.loaiHienThiOptions[3].codeKey:
                            _this.getDataTableChuoiSo();
                            break;
                    }
                    _this.isLoading = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getChartOptions() {
            let suggestedMax = 0;
            let gridColor = [];
            let gridTickColor = [];
            let gridLineWidth = [];
            this.chartOptions.scales = {
                x: {
                    grid: {
                        color: this.getColor(6)[0],
                        borderColor: this.getColor(6)[0],
                        tickColor: this.getColor(6)[0],
                        lineWidth: 1,
                    },
                    ticks: {
                        color: this.getColor(0)[0],
                        font: {
                            size: 14,
                        },
                    },
                },
                y: {
                    min: 0,
                    grid: {
                        color: gridColor,
                        borderColor: this.getColor(6)[0],
                        tickColor: gridTickColor,
                        lineWidth: gridLineWidth,
                    },
                    ticks: {
                        color: this.getColor(0)[0],
                        font: {
                            size: 18,
                        },
                        stepSize: 1,
                        callback: function (value, index, ticks) {
                            return value;
                        },
                    },
                },
            };
            switch (this.loaiThongKe.codeKey) {
                case this.loaiThongKeOptions[0].codeKey:
                    suggestedMax = 6;
                    break;
                case this.loaiThongKeOptions[1].codeKey:
                    suggestedMax = this.valueVung1 === "MIEN_BAC" ? 28 : 18;
                    if (this.loaiBieuDo.codeKey === this.loaiBieuDoOptions[1].codeKey) {
                        this.chartOptions.scales.x.stacked = true;
                        this.chartOptions.scales.y.stacked = true;
                    }
                    break;
                case this.loaiThongKeOptions[2].codeKey:
                    break;
            }
            for (let i = 0; i <= suggestedMax; i++) {
                if (i === suggestedMax / 2) {
                    gridColor.push(this.getColor(0)[0]);
                    gridTickColor.push(this.getColor(0)[0]);
                    gridLineWidth.push(2);
                } else {
                    gridColor.push(this.getColor(6)[0]);
                    gridTickColor.push(this.getColor(6)[0]);
                    gridLineWidth.push(1);
                }
            }
            if (suggestedMax) {
                this.chartOptions.scales.y.suggestedMax = suggestedMax;
                this.chartOptions.scales.y.grid.color = gridColor;
                this.chartOptions.scales.y.grid.tickColor = gridTickColor;
                this.chartOptions.scales.y.grid.lineWidth = gridLineWidth;
                this.chartOptions.scales.y.ticks.count = suggestedMax + 1;
            }
        },
        getChartDatasets() {
            switch (this.loaiThongKe.codeKey) {
                case this.loaiThongKeOptions[0].codeKey:
                    let totalChan = 0;
                    let totalLe = 0;
                    let chan = 0;
                    let le = 0;
                    this.chartData.datasets = [
                        {
                            label: "Chẵn",
                            data: [],
                            borderColor: this.getColor(0)[0],
                            backgroundColor: this.getColor(0)[0],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        },
                        {
                            label: "Lẻ",
                            data: [],
                            borderColor: this.getColor(2)[0],
                            backgroundColor: this.getColor(2)[0],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        },
                    ];
                    for (const item of this.dataChanLe) {
                        chan = 0;
                        le = 0;
                        for (const info of item.infos) {
                            totalChan += info.totalChan;
                            totalLe += info.totalLe;
                            chan += info.totalChan;
                            le += info.totalLe;
                        }
                        this.chartData.datasets[0].data.push(chan);
                        this.chartData.datasets[0].label = `Chẵn (${totalChan} số)`;
                        this.chartData.datasets[1].data.push(le);
                        this.chartData.datasets[1].label = `Lẻ (${totalLe} số)`;
                    }
                    break;
                case this.loaiThongKeOptions[1].codeKey:
                    let dataDai = [];
                    let number = 0;
                    this.chartData.datasets = [];
                    while (this.dataChanLe.every((i) => i.infos.length > number)) {
                        if (!dataDai[number]) {
                            dataDai[number] = {
                                dataChan: [],
                                dataLe: [],
                            };
                        }
                        for (const [index, item] of this.dataChanLe.entries()) {
                            if (item.infos[number]) {
                                dataDai[number].dataChan.push(item.infos[number].totalChanAll);
                                dataDai[number].dataLe.push(item.infos[number].totalLeAll);
                            }
                        }
                        let objChan = {
                            label: "Chẵn ",
                            data: dataDai[number].dataChan,
                            borderColor: this.getColor(this.chartData.datasets.length)[0],
                            backgroundColor: this.getColor(this.chartData.datasets.length)[1],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        };
                        if (this.loaiBieuDo.codeKey === this.loaiBieuDoOptions[1].codeKey) {
                            objChan.stack = "dai-" + number;
                        }
                        this.chartData.datasets.push(objChan);

                        let objLe = {
                            label: "Lẻ ",
                            data: dataDai[number].dataLe,
                            borderColor: this.getColor(this.chartData.datasets.length)[0],
                            backgroundColor: this.getColor(this.chartData.datasets.length)[1],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        };
                        if (this.loaiBieuDo.codeKey === this.loaiBieuDoOptions[1].codeKey) {
                            objLe.stack = "dai-" + number;
                        }
                        this.chartData.datasets.push(objLe);
                        number++;
                    }
                    break;
                case this.loaiThongKeOptions[2].codeKey:
                    break;
            }
        },
        getColor(number) {
            switch (number) {
                case 0:
                    return ["#39A7FF", "#39A7FF"];
                case 1:
                    return ["#87C4FF", "#87C4FF"];
                case 2:
                    return ["#FF5B22", "#FF5B22"];
                case 3:
                    return ["#FF9130", "#FF9130"];
                case 4:
                    return ["#557C55", "#557C55"];
                case 5:
                    return ["#A6CF98", "#A6CF98"];
                case 6:
                    return ["#74747678", "#74747678"];
                case 7:
                    return ["#747476", "#747476"];
            }
        },
        getListDate() {
            this.listDate = [];

            let endDate = this.$moment(this.dateFromTo1.endDate);
            let startDate = this.$moment(this.dateFromTo1.startDate);
            this.soNgayView = endDate.diff(startDate, "days") + 1;

            let previousDay = this.$moment(this.getFormatDate(endDate, "formatDateAPI"));
            let textDay = this.getFormatDate(previousDay, "formatDateNoYearVN");
            this.listDate.push(textDay);
            for (let i = 1; i < this.soNgayView; i++) {
                previousDay = previousDay.subtract(1, "days");
                textDay = this.getFormatDate(previousDay, "formatDateNoYearVN");
                this.listDate.unshift(textDay);
            }
            this.chartData.labels = this.listDate;

            this.getData();
        },
        getDataTableChuoiSo() {
            this.dataTableChuoiSo = [];
            let dataChanLe = this.cloneDeep(this.dataChanLe);
            let giaiCheck =
                this.valueVung1 === "MIEN_BAC" ? ["giai0", "giai1"] : ["giai0", "giai8"];
            for (const item of dataChanLe) {
                for (const info of item.infos) {
                    let obj = this.dataTableChuoiSo.filter((i) => i.daiId === info.tinhId)[0];
                    if (!obj) {
                        obj = {
                            date: [],
                            inputDate: [],
                            daiId: info.tinhId,
                            dai: info.tinhName,
                            ketQua: info.ketQua,
                            ketQua2So: {},
                            chuoiChan: {},
                            chuoiLe: {},
                        };
                        this.dataTableChuoiSo.push(obj);
                    }
                    obj.date.push(this.getFormatDate(item.inputDate, "formatDateShortYearVN"));
                    obj.inputDate.push(item.inputDate);
                    let keys = Object.keys(info.ketQua);
                    for (const key of keys) {
                        info.ketQua[key] = info.ketQua[key].split("\t");
                        if (giaiCheck.includes(key)) {
                            let haiSoCuoi = this.getHaiSoCuoi(info.ketQua[key]);
                            obj.ketQua2So[key] = obj.ketQua2So[key]
                                ? obj.ketQua2So[key].concat(haiSoCuoi)
                                : [].concat(haiSoCuoi);
                        }
                    }
                    this.getChuoiChanLe(obj);
                }
            }
        },
        getHaiSoCuoi(numberArr) {
            let dataReturn = [];
            for (const item of numberArr) {
                dataReturn.push(item.slice(-2));
            }
            return dataReturn;
        },
        getChuoiChanLe(obj) {
            let keys = Object.keys(obj.ketQua2So);
            for (const key of keys) {
                obj.chuoiChan[key] = [];
                obj.chuoiLe[key] = [];
                for (const item of obj.ketQua2So[key]) {
                    if (this.checkChanLe(item)) {
                        obj.chuoiChan[key].push(item);
                        obj.chuoiLe[key] = [];
                    } else {
                        obj.chuoiChan[key] = [];
                        obj.chuoiLe[key].push(item);
                    }
                }
            }
        },
        onClickBtnFilter() {
            this.isShowFilter = !this.isShowFilter;
            let ele = document.getElementsByClassName("group-chart")[0];
            if (this.isShowFilter) {
                ele.style.height = "calc(100vh - 190px)";
                ele.style.maxHeight = "calc(100vh - 190px)";
            } else {
                ele.style.height = "calc(100vh - 100px)";
                ele.style.maxHeight = "calc(100vh - 100px)";
            }
        },
        onChangeVung(number, mien) {
            switch (number) {
                case "1":
                    this.valueVung2 = mien;
                    this.getData();
                    break;
                case "2":
                    this.valueVung1 = mien;
                    this.initDataThemDuLieu();
                    break;
            }
            this.loaiThongKeOptions[1].codeName =
                this.valueVung1 === "MIEN_BAC" ? "Chẳn Lẻ Bao Lô 27 Con" : "Chẳn Lẻ Bao Lô 18 Con";
        },
        changeLoaiHienThi() {
            this.getData();
        },
        changeLoaiBieuDo() {
            this.getData();
        },
        changeLoaiThongKe() {
            this.getListDate();
        },
        checkChanLe(number) {
            let str1 = Number(number[0]);
            let str2 = Number(number[1]);
            if ((str1 + str2) % 2 == 0) {
                return true;
            } else {
                return false;
            }
        },
        tooltipCustom(context) {
            let _this = this;
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = "<thead>";

                titleLines.forEach(function (title) {
                    innerHtml += "<tr><th>" + title + "</th></tr>";
                });
                innerHtml += "</thead><tbody>";

                bodyLines.forEach(function (body, i) {
                    const colors = tooltipModel.labelColors[i];
                    let style = "background:" + colors.backgroundColor;
                    style += "; border-color:" + colors.borderColor;
                    style += "; border-width: 2px";
                    style += "; border-radius: 3px";
                    style += "; padding: 0 3px";
                    style += "; color:" + colors.borderColor;
                    let div = '<div style="' + style + '">a</div>';
                    const span = "<span>" + _this.getTextTootip(i, body, titleLines) + "</span>";
                    innerHtml += "<tr><td>" + div + "</td>";
                    innerHtml += "<td>" + span + "</td></tr>";
                });
                innerHtml += "</tbody>";

                let tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();
            const bodyFont =
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.backgroundColor = "#000";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.borderRadius = "8px";
        },
        getTextTootip(index, body, titleLines) {
            let _this = this;
            let text = "";
            switch (_this.loaiThongKe.codeKey) {
                case _this.loaiThongKeOptions[0].codeKey:
                    text = body[0].split(":")[0].trim().split("(")[0].trim() + ": ";
                    break;
                case _this.loaiThongKeOptions[1].codeKey:
                    text = body[0].split(":")[0].trim();
                    if (titleLines[0].includes("T2")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(2)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(2)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(2)
                                )[2].name +
                                ": ";
                        }
                    }
                    if (titleLines[0].includes("T3")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(3)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(3)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(3)
                                )[2].name +
                                ": ";
                        }
                    }
                    if (titleLines[0].includes("T4")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(4)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(4)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(4)
                                )[2].name +
                                ": ";
                        }
                    }
                    if (titleLines[0].includes("T5")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(5)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(5)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(5)
                                )[2].name +
                                ": ";
                        }
                    }
                    if (titleLines[0].includes("T6")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(6)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(6)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(6)
                                )[2].name +
                                ": ";
                        }
                    }
                    if (titleLines[0].includes("T7")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(7)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(7)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(7)
                                )[2].name +
                                ": ";
                        }
                    }
                    if (titleLines[0].includes("CN")) {
                        if ([0, 1].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(8)
                                )[0].name +
                                ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(8)
                                )[1].name +
                                ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text +=
                                "-" +
                                _this.dataTinh.filter(
                                    (i) => i.mien === _this.valueVung1 && i.inWeek.includes(8)
                                )[2].name +
                                ": ";
                        }
                    }
                    break;
                case _this.loaiThongKeOptions[2].codeKey:
                    break;
            }
            text += body[0].split(":")[1];
            return text;
        },
        getText(arr) {
            let text = "";
            let keys = Object.keys(arr);
            for (const key of keys) {
                if (key === "giai0") {
                    text += "Giải ĐB: ";
                }
                if (key === "giai1") {
                    text += "Giải Nhất: ";
                }
                if (key === "giai8") {
                    text += "Giải Tám: ";
                }
                text += arr[key].length + "\t";
                text += arr[key].length ? "[" + arr[key].join(", ") + "]\n" : "\n";
            }
            return text;
        },
    },
};
</script>

<style lang="scss">
.style-for-mobile .thong-ke-lo-de {
    .thong-ke-lo-de-content {
        position: relative;
    }

    .btn-filter {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
    }

    .group-chart {
        max-height: calc(100vh - 190px);
        width: 100%;
        display: flex;
        justify-content: center;
        transition: all 0.3s;

        canvas {
            background: var(--chart-background);
            padding: 5px;
            border-radius: 8px;
        }
    }

    .fieldset-nhap-du-lieu {
        width: calc((100% - 15px) / 3);
        margin-right: 5px;

        &:first-child {
            border-width: 3px;

            legend {
                font-weight: bold;
            }
        }
    }

    .group-dai {
        display: flex;

        >div {
            width: 50%;
            padding: 5px;
        }

        input {
            padding: 0.375rem 0.375rem;
        }
    }

    .group-table {
        overflow: auto;
        width: 100%;
        height: calc(100vh - 190px);
        max-height: calc(100vh - 190px);

        .group-header {
            display: flex;

            >div {
                border: 1px solid var(--border-color);
                border-right: none;
                padding: 5px;
                min-width: 55px;

                &:last-child {
                    border-right: 1px solid var(--border-color);
                }
            }
        }
    }

    .group-table-100 {
        display: flex;
        flex-wrap: wrap;

        >div {
            width: 700px;
            height: 700px;
        }

        .item {
            display: inline-block;
            text-align: center;
            width: calc((100% / 10) - 4px);
            height: calc((100% / 10) - 4px);
            border: 3px solid var(--header-bao-cao-phan-tich-background);
            padding: 5px;
            margin: 2px;
            border-radius: 5px;

            label {
                margin: 0;

                &:first-child {
                    font-weight: bold;
                }
            }

            &-even {
                border-color: #1177c0;
                background-color: #1177c078;
            }

            &-odd {
                border-color: #ff0000;
                background-color: #ff000078;
            }
        }
    }

    .group-chuoi-so {
        .chuoi {
            white-space: pre-wrap;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}
</style>
