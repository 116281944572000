<template>
    <div id="bao-cao" class="bao-cao">
        <pc-header :title="title" :backTo="backTo" />
        <div class="xem-nhanh-content">
            <div class="group-top-content">
                <div class="group-filter">
                    <b-row>
                        <b-col offset="1" cols="8">
                            <div class="group-item-filter">
                                <b-button variant="success" id="btn-chuc-nang">
                                    <svg class="icon icon-menu-15">
                                        <use xlink:href="#icon-menu-15"></use>
                                    </svg>
                                    <span class="ml-2">Chức Năng</span>
                                </b-button>
                                <b-popover id="popover-chuc-nang" target="btn-chuc-nang" triggers="focus" placement="bottom"
                                    :custom-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
                                    <div class="group-chuc-nang">
                                        <div>
                                            <b-form-checkbox v-for="(item, index) in dataChucNang" :key="index"
                                                v-model="item.value" @change="changeChucNang(item)">
                                                {{ item.name }}
                                            </b-form-checkbox>
                                        </div>
                                        <div>
                                            <b-form-checkbox v-for="(item, index) in dataFormatTable" :key="index"
                                                v-model="item.value" @change="changeFormatTable()">
                                                {{ item.name }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </b-popover>
                                <span class="span-nowrap mr-2 ml-3">Thời gian khác từ</span>
                                <b-input-group class="mr-2 input-milis">
                                    <b-form-input v-model="milisFrom" @change="changeTimeRange('0')"></b-form-input>
                                </b-input-group>
                                <span class="span-nowrap mr-2">tới</span>
                                <b-input-group class="mr-2 input-milis">
                                    <b-form-input v-model="milisTo" @change="changeTimeRange('0')"></b-form-input>
                                </b-input-group>
                                <multi-select class="multi-select" v-model="timeRange" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="timeRangeOptions"
                                    :searchable="false" :allow-empty="false" @input="changeTimeRange('1')">
                                </multi-select>
                            </div>
                        </b-col>
                        <b-col cols="3">
                            <div class="group-item-filter">
                                <b-input-group v-if="!isShowBuonCom">
                                    <b-form-checkbox v-model="normalSport">Bóng Chính</b-form-checkbox>
                                </b-input-group>
                                <b-input-group v-if="!isShowBuonCom">
                                    <b-form-checkbox v-model="virtualSport">Bóng Ảo</b-form-checkbox>
                                </b-input-group>
                                <b-input-group v-if="isShowBuonCom">
                                    <b-form-checkbox v-model="beforeMatch">Trước Trận</b-form-checkbox>
                                </b-input-group>
                                <b-input-group v-if="isShowBuonCom">
                                    <b-form-checkbox v-model="inMatch">Trong Trận</b-form-checkbox>
                                </b-input-group>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col offset="1" cols="4">
                            <div class="group-item-filter">
                                <date-range-picker v-model="dateFromTo" :locale-data="$root.localeData"
                                    :show-dropdowns="true" :auto-apply="true" :ranges="ranges" :linked-calendars="false"
                                    opens="center"></date-range-picker>
                            </div>
                        </b-col>
                        <b-col cols="3">
                            <div class="button-action text-left">
                                <b-button variant="success" @click="getData()">
                                    <svg class="icon icon-search-normal-15">
                                        <use xlink:href="#icon-search-normal-15"></use>
                                    </svg>
                                    <span class="ml-2">Tìm Kiếm</span>
                                </b-button>
                                <b-button variant="success" @click="print()">
                                    <svg class="icon icon-note5">
                                        <use xlink:href="#icon-note5"></use>
                                    </svg>
                                    <span class="ml-2">Xuất Hình</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col offset="1" cols="8">
                            <div class="group-item-filter">
                                <span class="span-nowrap mr-2">Ẩn Tổng Mã Cược Dưới</span>
                                <b-input-group class="mr-2 input-an-bet-count">
                                    <b-form-input v-model="anBetCount" @change="removeBelowCount()"
                                        :number="true"></b-form-input>
                                    <b-input-group-append>
                                        <div>
                                            <b-button variant="success" class="btn-cong" @click="removeBelowCount('cong')">
                                                <svg class="icon icon-add4">
                                                    <use xlink:href="#icon-add4"></use>
                                                </svg>
                                            </b-button>
                                            <b-button variant="success" class="btn-tru" @click="removeBelowCount('tru')">
                                                <svg class="icon icon-minus4">
                                                    <use xlink:href="#icon-minus4"></use>
                                                </svg>
                                            </b-button>
                                        </div>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="group-thong-ke">
                    <div>
                        <div class="w-50 tai">
                            <label>Tài</label>
                            <label>{{ dataMember.over }}</label>
                            <label>{{ dataMember.tai }}</label>
                        </div>
                        <div class="w-50 xiu">
                            <label>Xỉu</label>
                            <label>{{ dataMember.under }}</label>
                            <label>{{ dataMember.xiu }}</label>
                        </div>
                    </div>
                    <div>
                        <div class="w-50 tren">
                            <label>Trên</label>
                            <label>{{ dataMember.overTeam }}</label>
                            <label>{{ dataMember.tren }}</label>
                        </div>
                        <div class="w-50 duoi">
                            <label>Dưới</label>
                            <label>{{ dataMember.underTeam }}</label>
                            <label>{{ dataMember.duoi }}</label>
                        </div>
                    </div>
                    <div>
                        <div class="w-50 goc">
                            <label>Góc</label>
                            <label>{{ dataMember.corner }}</label>
                            <label>{{ dataMember.goc }}</label>
                        </div>
                        <div class="w-50 tong-ma-cuoc">
                            <label>Tổng Mã Cược</label>
                            <label>{{ dataMember.betCount }}</label>
                        </div>
                    </div>
                    <div>
                        <div class="w-33 bong-ao">
                            <label>Bóng Ảo</label>
                            <label>{{ dataMember.virtual }}</label>
                        </div>
                        <div class="w-33 casino">
                            <label>Casino</label>
                            <label>{{ dataMember.casino }}</label>
                        </div>
                        <div class="w-33 khac">
                            <label>Khác</label>
                            <label>{{ dataMember.other }}</label>
                        </div>
                    </div>
                    <div>
                        <div class="w-50 ty-le-player">
                            <label>Tỷ lệ Player</label>
                            <label>{{ dataMember.tyLePlayer }}</label>
                        </div>
                        <div class="w-50 ty-le-banker">
                            <label>Tỷ lệ Banker</label>
                            <label>{{ dataMember.tyLeBanker }}</label>
                        </div>
                    </div>
                    <div>
                        <div class="w-50 ty-le-win">
                            <label>Tỷ lệ Win</label>
                            <label>{{ dataMember.tyLeWin }}</label>
                        </div>
                        <div class="w-50 ty-le-loss">
                            <label>Tỷ lệ Loss</label>
                            <label>{{ dataMember.tyLeLoss }}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div ref="report-body" class="report-body">
                <b-row>
                    <b-col class="mt-4">
                        <div class="group-bao-cao-phan-tich">
                            <div class="header-bao-cao-phan-tich">BÁO CÁO VI PHẠM</div>
                            <div class="body-bao-cao-phan-tich">
                                <div class="group-item">
                                    <div>
                                        <label>Mã Quản Lý</label>
                                        <b-input-group v-if="!isPrint">
                                            <b-form-input v-model="dataMember.maQuanLy"
                                                placeholder="Nhấn để chỉnh sửa..."></b-form-input>
                                        </b-input-group>
                                        <label v-else>{{ dataMember.maQuanLy }}</label>
                                    </div>
                                    <div>
                                        <label>Tài Khoản</label>
                                        <label>{{ dataMember.memberId }}</label>
                                    </div>
                                    <div>
                                        <label>Ngày</label>
                                        <label>{{ dataMember.dateReportFormat }}</label>
                                    </div>
                                </div>
                                <div class="group-item">
                                    <div>
                                        <label>Tiền cược</label>
                                        <label>{{ getFormatNumber(dataMember.turnOver) }}</label>
                                    </div>
                                    <div>
                                        <label>Lời / Lỗ</label>
                                        <label>{{ getFormatNumber(dataMember.winLoss) }}</label>
                                    </div>
                                    <div>
                                        <label>Com</label>
                                        <label>{{ getFormatNumber(dataMember.com) }}</label>
                                    </div>
                                </div>
                                <div class="group-item-textarea">
                                    <div>
                                        <label>Phân Tích</label>
                                        <b-input-group v-if="!isPrint">
                                            <b-form-textarea v-model="dataMember.phanTich" no-resize
                                                placeholder="Nhấn để chỉnh sửa..."></b-form-textarea>
                                        </b-input-group>
                                        <label v-else>{{ dataMember.phanTich }}</label>
                                    </div>
                                    <div>
                                        <label>Kết Luận</label>
                                        <b-input-group v-if="!isPrint">
                                            <b-form-textarea v-model="dataMember.ketLuan" no-resize
                                                placeholder="Nhấn để chỉnh sửa..."></b-form-textarea>
                                        </b-input-group>
                                        <label v-else>{{ dataMember.ketLuan }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <template v-for="(item, index) in dataChucNang">
                    <div class="mt-3 group-ket-qua-tim-kiem" v-if="item.value" :key="index">
                        <b-row v-if="item.value">
                            <b-col cols="4">
                                <label class="chuc-nang-item" @click="boChonChucNang(item)">
                                    {{ item.name }}
                                    <svg v-if="dataChucNang.filter((i) => i.value).length > 1"
                                        class="icon icon-close-circle4 ml-2">
                                        <use xlink:href="#icon-close-circle4"></use>
                                    </svg>
                                    <b-spinner v-if="item.isBusy" class="align-middle ml-2"></b-spinner>
                                </label>
                                <label v-if="!isPrint" class="chuc-nang-phuc-hoi" @click="
                                    item.dataTableFilter = cloneDeep(item.dataTable);
                                anBetCount = 1;
                                ">
                                    <svg class="icon icon-recovery-convert5 ml-2">
                                        <use xlink:href="#icon-recovery-convert5"></use>
                                    </svg>
                                    Phục Hồi
                                </label>
                            </b-col>
                        </b-row>
                        <template v-if="item.key === 'trungIp'">
                            <b-row class="mt-2" v-for="(item1, index) in item.dataTableFilter" :key="index">
                                <b-col v-if="item1.dataTableFilter.length > 0">
                                    <div class="mb-2 title-trung-ip">
                                        <strong>IP: {{ item1.ip }}</strong>
                                    </div>
                                    <b-table hover small bordered :items="item1.dataTableFilter" :fields="item.fields"
                                        :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                        empty-text="Không có dữ liệu" :busy="item.isBusy" :tbody-tr-class="getClassRow">
                                        <template #empty="scope">
                                            <strong>{{ scope.emptyText }}</strong>
                                        </template>
                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle mr-2"></b-spinner>
                                                <strong>Đang tải dữ liệu...</strong>
                                            </div>
                                        </template>
                                        <!-- body -->
                                        <template #cell(action)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="removeRow(item1, scope)">
                                                    <svg class="icon icon-red icon-close-circle5">
                                                        <use xlink:href="#icon-close-circle5"></use>
                                                    </svg>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(refNo)="scope">
                                            <div class="cell-style">
                                                <span class="ref-no"
                                                    :class="scope.item.children && scope.item.children.length > 0 ? 'link' : ''"
                                                    @click="toggleDongCon(item1, scope.item)">
                                                    <strong> {{ scope.item.refNo }} </strong>
                                                    <span v-if="scope.item.children && scope.item.children.length > 0"
                                                        class="icon-arrow">
                                                        <svg class="icon icon-arrow-right-25"
                                                            :class="scope.item.isShowDongCon && !anDong ? 'show-dong-con' : ''">
                                                            <use xlink:href="#icon-arrow-right-25"></use>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(ip)="scope">
                                            <div class="cell-style nowrap">
                                                <span>{{ getIp(scope.item) }}</span>
                                            </div>
                                        </template>
                                        <template #cell(memberId)="scope">
                                            <div class="cell-style nowrap">
                                                <strong>
                                                    <span v-html="getUsername(scope.item)"></span>
                                                    <br />
                                                    <span v-if="!anSoDem" class="red-text"> ({{ scope.item.betCount }})
                                                    </span>
                                                </strong>
                                            </div>
                                        </template>
                                        <template #cell(match)="scope">
                                            <div class="cell-style">
                                                <span>
                                                    {{ scope.item.matchLabel }}
                                                    {{ scope.item.match }}
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(choiceDisplay)="scope">
                                            <div class="cell-style no-warp">
                                                <div :class="getClassChoiceDisplay(scope.item)"
                                                    v-html="getChoiceDisplay(scope.item)"></div>
                                            </div>
                                        </template>
                                        <template #cell(odds)="scope">
                                            <div class="cell-style no-warp" v-if="scope.item.refNo">
                                                <span>{{ scope.item.odds }}</span>
                                            </div>
                                        </template>
                                        <template #cell(oddsMy)="scope">
                                            <div class="cell-style no-warp" v-if="scope.item.refNo">
                                                <div>
                                                    <span>Giá: {{ scope.item.oddsMy || "-" }}</span>
                                                    <br />
                                                    <span>Điểm: {{ scope.item.stakeMy || "-" }}</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template #cell(priceDiff)="scope">
                                            <div class="cell-style">
                                                <strong>
                                                    <span>{{ scope.item.priceDiff }}</span>
                                                </strong>
                                            </div>
                                        </template>
                                        <template #cell(stake)="scope">
                                            <div class="cell-style">
                                                <span>{{ getStake(scope.item) }}</span>
                                            </div>
                                        </template>
                                        <template #cell(winLoss)="scope">
                                            <div v-if="anStake && !scope.item.children" class="cell-style">
                                                <span>******</span>
                                            </div>
                                            <div v-else class="cell-style"
                                                :class="scope.item.status === 'lose' ? 'red-text' : 'green-text'">
                                                {{ scope.item.status }}
                                                <br />
                                                {{ getFormatNumber(scope.item.winLoss) }}
                                            </div>
                                        </template>
                                        <template #cell(thoiGianCuoc)="scope">
                                            <div class="cell-style no-warp" v-if="scope.item.refNo">
                                                <div v-html="getThoiGianCuoc(scope.item)"></div>
                                            </div>
                                        </template>
                                        <template #cell(timeDiff)="scope">
                                            <div class="cell-style nowrap">
                                                <strong>
                                                    <span>{{ scope.item.timeDiff }}</span>
                                                </strong>
                                            </div>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </template>
                        <b-row class="mt-2" v-if="item.key !== 'trungIp'">
                            <b-col>
                                <b-table hover small bordered :items="item.dataTableFilter" :fields="item.fields"
                                    :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                    empty-text="Không có dữ liệu" :busy="item.isBusy" :tbody-tr-class="getClassRow">
                                    <template #empty="scope">
                                        <strong>{{ scope.emptyText }}</strong>
                                    </template>
                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle mr-2"></b-spinner>
                                            <strong>Đang tải dữ liệu...</strong>
                                        </div>
                                    </template>
                                    <!-- body -->
                                    <template #cell(action)="scope">
                                        <div class="cell-style" v-if="scope.item.refNo">
                                            <span class="link" @click="removeRow(item, scope)">
                                                <svg class="icon icon-red icon-close-circle5">
                                                    <use xlink:href="#icon-close-circle5"></use>
                                                </svg>
                                            </span>
                                        </div>
                                    </template>
                                    <template #cell(refNo)="scope">
                                        <div class="cell-style" v-if="scope.item.refNo">
                                            <span class="ref-no"
                                                :class="scope.item.children && scope.item.children.length > 0 ? 'link' : ''"
                                                @click="toggleDongCon(item, scope.item)">
                                                <strong> {{ scope.item.refNo }} </strong>
                                                <span v-if="scope.item.children && scope.item.children.length > 0"
                                                    class="icon-arrow">
                                                    <svg class="icon icon-arrow-right-25"
                                                        :class="scope.item.isShowDongCon && !anDong ? 'show-dong-con' : ''">
                                                        <use xlink:href="#icon-arrow-right-25"></use>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </template>
                                    <template #cell(ip)="scope">
                                        <div class="cell-style nowrap" v-if="scope.item.refNo">
                                            <span>{{ getIp(scope.item) }}</span>
                                        </div>
                                    </template>
                                    <template #cell(memberId)="scope">
                                        <div class="cell-style nowrap" v-if="scope.item.refNo">
                                            <strong>
                                                <span v-html="getUsername(scope.item)"></span>
                                                <br />
                                                <span v-if="!anSoDem" class="red-text"> ({{ scope.item.betCount }}) </span>
                                            </strong>
                                        </div>
                                    </template>
                                    <template #cell(match)="scope">
                                        <div class="cell-style" v-if="scope.item.refNo">
                                            <span> {{ scope.item.matchLabel }} {{ scope.item.match }} </span>
                                        </div>
                                    </template>
                                    <template #cell(choiceDisplay)="scope">
                                        <div class="cell-style no-warp" v-if="scope.item.refNo">
                                            <div :class="getClassChoiceDisplay(scope.item)"
                                                v-html="getChoiceDisplay(scope.item)"></div>
                                        </div>
                                    </template>
                                    <template #cell(odds)="scope">
                                        <div class="cell-style no-warp" v-if="scope.item.refNo">
                                            <div>
                                                <span>{{ scope.item.odds }}</span>
                                                <br />
                                                <span>{{ scope.item.oddsMy }}</span>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(oddsMy)="scope">
                                        <div class="cell-style no-warp" v-if="scope.item.refNo">
                                            <div>
                                                <span>Giá: {{ scope.item.oddsMy || "-" }}</span>
                                                <br />
                                                <span>Điểm: {{ scope.item.stakeMy || "-" }}</span>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(priceDiff)="scope">
                                        <div class="cell-style" v-if="scope.item.refNo">
                                            <strong>
                                                <span>{{ scope.item.priceDiff }}</span>
                                            </strong>
                                        </div>
                                    </template>
                                    <template #cell(stake)="scope">
                                        <div class="cell-style" v-if="scope.item.refNo">
                                            <span>{{ getStake(scope.item) }}</span>
                                        </div>
                                    </template>
                                    <template #cell(winLoss)="scope">
                                        <div v-if="anStake && !scope.item.children && scope.item.refNo" class="cell-style">
                                            <span>******</span>
                                        </div>
                                        <div v-else-if="scope.item.refNo" class="cell-style"
                                            :class="scope.item.status === 'lose' ? 'red-text' : 'green-text'">
                                            {{ scope.item.status }}
                                            <br />
                                            {{ getFormatNumber(scope.item.winLoss) }}
                                        </div>
                                    </template>
                                    <template #cell(thoiGianCuoc)="scope">
                                        <div class="cell-style no-warp" v-if="scope.item.refNo">
                                            <div v-html="getThoiGianCuoc(scope.item)"></div>
                                        </div>
                                    </template>
                                    <template #cell(timeDiff)="scope">
                                        <div class="cell-style nowrap">
                                            <strong>
                                                <span>{{ scope.item.timeDiff }}</span>
                                            </strong>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </div>
                </template>
                <hr />
                <div class="text-right red-text">Hình ảnh độc quyền bởi F1</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Betscan from "../../../service/betscan.service";
import Master from "../../../service/master.service";

export default {
    name: "BaoCao",
    data() {
        return {
            title: "THỐNG KÊ GIAN LẬN",
            titleDacBiet: "CƯỢC ĐẶC BIỆT",
            backTo: "thong-ke-theo-member",
            domain: "",
            domainName: "",
            memberId: "",
            milisFrom: 0,
            milisTo: 4,
            timeRange: {
                codeKey: "",
                codeName: "Other",
                codeValue: "",
                codeValue2: "",
            },
            timeRangeOptions: [
                {
                    codeKey: "",
                    codeName: "Other",
                    codeValue: "",
                    codeValue2: "",
                },
            ],
            ranges: {
                "Hôm Nay": this.getRangeDate("homNay"),
                "Hôm Qua": this.getRangeDate("homQua"),
                "Tuần Này": this.getRangeDate("tuanNay"),
                "Tuần Trước": this.getRangeDate("tuanTruoc"),
                "Tháng Này": this.getRangeDate("thangNay"),
                "Tháng Trước": this.getRangeDate("thangTruoc"),
            },
            dateFromTo: {
                startDate: this.getRangeDate("tuanNay")[0],
                endDate: this.getRangeDate("tuanNay")[1],
            },
            normalSport: true,
            virtualSport: false,
            beforeMatch: true,
            inMatch: true,
            dataMember: {
                maQuanLy: "",
                memberId: "",
                dateReportFormat: "",
                turnOver: "",
                winLoss: "",
                com: "",
                phanTich: "",
                ketLuan: "",
            },
            dataChucNangGoc: [],
            dataChucNang: [
                {
                    key: "trungIp",
                    value: false,
                    api: "getMemberSameIp",
                    name: "Trùng IP",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "cuocTheo",
                    value: false,
                    api: "getMemberSameBet",
                    name: "Cược Theo",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "oddsMy", label: "Đổi Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                        { key: "timeDiff", label: "Lệch Giây", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "buonCom",
                    value: true,
                    api: "getMemberDirtyCom",
                    name: "Buôn Com",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "oddsMy", label: "Đổi Giá", sortable: false },
                        { key: "priceDiff", label: "Lệnh Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                        { key: "timeDiff", label: "Lệch Giây", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "sapGia",
                    value: false,
                    api: ["getMemberCrashPriceBefore", "getMemberCrashPriceAfter", "getMemberBeforeCrashPrice"],
                    name: "Sập Giá",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "cuocXau",
                    value: false,
                    api: "getMemberUnderDirty",
                    name: "Cược Xấu",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "ruiRo",
                    value: false,
                    api: "getMemberBetRisk",
                    name: "Rủi Ro",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "dacBiet",
                    value: false,
                    api: "getMemberSpecial",
                    name: "Đặc Biệt",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                    ],
                    isBusy: false,
                },
                {
                    key: "buonBong",
                    value: false,
                    api: "getMemberDirtySameMatch",
                    name: "Buôn Bóng",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "rowNo", label: "STT", sortable: false },
                        { key: "action", label: "", sortable: false },
                        { key: "refNo", label: "Mã Cược", sortable: false },
                        { key: "domainName", label: "Nhà Cái", sortable: false },
                        { key: "ip", label: "IP", sortable: false },
                        { key: "memberId", label: "Tài Khoản", sortable: false },
                        { key: "match", label: "Trận Đấu", sortable: false },
                        { key: "league", label: "Giải Đấu", sortable: false },
                        { key: "choiceDisplay", label: "Cược/Live", sortable: false },
                        { key: "odds", label: "Giá", sortable: false },
                        { key: "stake", label: "Điểm", sortable: false },
                        { key: "winLoss", label: "Kết Quả", sortable: false },
                        { key: "thoiGianCuoc", label: "Thời Gian Cược", sortable: false },
                    ],
                    isBusy: false,
                },
            ],
            dataFormatTable: [
                {
                    key: "anDong",
                    value: false,
                    name: "Ẩn Dòng",
                },
                {
                    key: "anIp",
                    value: false,
                    name: "Ẩn IP",
                },
                {
                    key: "anUsername",
                    value: false,
                    name: "Ẩn Tài Khoản",
                },
                {
                    key: "anStake",
                    value: false,
                    name: "Ẩn điểm cược",
                },
                {
                    key: "anDoiKeo",
                    value: false,
                    name: "Ẩn Đổi Kèo/Giá",
                },
                {
                    key: "anSoDem",
                    value: false,
                    name: "Ẩn số đếm",
                },
                {
                    key: "thayDoiGio",
                    value: false,
                    name: "Thay đổi giờ",
                },
            ],
            anDong: false,
            anIp: false,
            anUsername: false,
            anStake: false,
            anDoiKeo: false,
            anSoDem: false,
            thayDoiGio: false,
            anBetCount: 1,
            isShowBuonCom: false,
            isSearchInit: false,
            isFromScreenXemNhanh: false,
            isPrint: false,
        };
    },
    computed: {
        ...mapState("BaoCao", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    watch: {
        isFromScreenXemNhanh(val) {
            if (val) {
                this.backTo = "xem-nhanh";
            } else {
                this.backTo = "thong-ke-theo-member";
            }
        },
        timeRange(val) {
            if ("B6" === val) {
                this.beforeMatch = true;
                this.inMatch = false;
            } else if ("B8" === val || "B5" === val) {
                this.beforeMatch = false;
                this.inMatch = true;
            } else {
                this.beforeMatch = true;
                this.inMatch = true;
            }
        },
        anDong(val) {
            this.anBetCount = 1;
            if (val) {
                for (const item of this.dataChucNang) {
                    if (item.value) {
                        if (item.key === "trungIp") {
                            for (const item1 of item.dataTableFilter) {
                                for (const item2 of item1.dataTableFilter) {
                                    if (item2.children) {
                                        this.toggleDongCon(item1, item2, true);
                                    }
                                }
                            }
                        } else {
                            for (const item1 of item.dataTableFilter) {
                                if (item1.children) {
                                    this.toggleDongCon(item, item1, true);
                                }
                            }
                        }
                    }
                }
            } else {
                for (const item of this.dataChucNang) {
                    if (item.value) {
                        item.dataTableFilter = this.cloneDeep(item.dataTable);
                    }
                }
            }
        },
        anDoiKeo(val) {
            for (const item of this.dataChucNang) {
                if (item.value) {
                    if (val) {
                        let index = item.fields.findIndex((i) => i.key === "oddsMy");
                        item.fields.splice(index, 1);
                    } else {
                        let index = item.fields.findIndex((i) => i.key === "odds");
                        item.fields.splice(index + 1, 0, {
                            key: "oddsMy",
                            label: "Đổi Giá",
                            sortable: false,
                        });
                    }
                }
            }
        },
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        let param = JSON.parse(localStorage.getItem("PARAM_FOR_LEVEL_3"));
        if (param) {
            this.memberId = param.memberId;
            this.domain = param.domain;
            this.domainName = param.domainName;
            this.dateFromTo = param.dateFromTo;
            this.normalSport = param.normalSport || true;
            this.virtualSport = param.virtualSport || false;
            this.milisFrom = param.milisFrom || 0;
            this.milisTo = param.milisTo || 4;
            this.timeRange = param.masterCode ? param.masterCode : this.timeRange;
            // chuc nang
            for (const item of this.dataChucNang) {
                if (param[item.key]) {
                    item.value = true;

                    if (item.key === "buonCom") {
                        this.isShowBuonCom = true;
                    }
                } else {
                    item.value = false;
                }
            }
            this.isSearchInit = param.isSearchInit || false;
            this.isFromScreenXemNhanh = param.isFromScreenXemNhanh || false;
            this.count = param.filterCount || 1;
            // sap gia
            this.sapGiaTruocTran = param.sapGiaTruocTran || false;
            this.sapGiaBatDau = param.sapGiaBatDau || false;
            this.sapGiaTrongTran = param.sapGiaTrongTran || false;
            // rui ro
            this.typeRuiRo = param.typeRuiRo || 0;
            // dac biet
            this.typeDacBiet = param.typeDacBiet || 0;
            this.titleDacBiet = this.setTitleDacBiet(this.typeDacBiet);
            // Buon bong
            this.buonBong = param.buonBong || false;
        }
        this.getListMasterCodeByClass("00004");
        this.getMemberInfo();
        this.getData();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("BaoCao", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        this.getData();
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    this.print();
                    e.preventDefault();
                    break;
            }
        },
        getListMasterCodeByClass(codeClass) {
            let _this = this;
            Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    response.data.forEach((item) => {
                        _this.timeRangeOptions.push({
                            codeKey: item.codeKey,
                            codeName: item.codeName,
                            codeValue: item.codeValue,
                            codeValue2: item.codeValue2,
                        });
                    });
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getMemberInfo() {
            let params = this.setParamAPI("memberInfo");
            let _this = this;
            Betscan.getMemberInfo(_this.$root.url, params)
                .then((response) => {
                    if (response.data) {
                        _this.dataMember = response.data;
                        let dateFrom = _this.getFormatDate(_this.dateFromTo.startDate, "formatDate");
                        let dateTo = _this.getFormatDate(_this.dateFromTo.endDate, "formatDate");
                        _this.dataMember.dateReportFormat = dateFrom + " tới " + dateTo;
                        _this.dataMember.maQuanLy = "";
                        _this.dataMember.phanTich = "";
                        _this.dataMember.ketLuan = "";
                        let betCount = _this.dataMember.betCount;
                        let data = _this.dataMember;
                        _this.dataMember.tai = Math.round((data.over / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.xiu = Math.round((data.under / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.tren = Math.round((data.overTeam / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.duoi = Math.round((data.underTeam / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.goc = Math.round((data.corner / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.casino = Math.round((data.casino / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.virtual = Math.round((data.virtualSport / betCount + Number.EPSILON) * 100) + "%";
                        _this.dataMember.other = Math.round(((data.typeOther + data.loto) / betCount + Number.EPSILON) * 100) + "%";

                        let total1 = data.player + data.banker;
                        _this.dataMember.tyLePlayer = (total1 !== 0 ? Math.round((data.player / total1 + Number.EPSILON) * 100) : 0) + "%";
                        _this.dataMember.tyLeBanker = (total1 !== 0 ? Math.round((data.banker / total1 + Number.EPSILON) * 100) : 0) + "%";

                        let total2 = data.win + data.loss;
                        _this.dataMember.tyLeWin = (total2 !== 0 ? Math.round((data.win / total2 + Number.EPSILON) * 100) : 0) + "%";
                        _this.dataMember.tyLeLoss = (total2 !== 0 ? Math.round((data.loss / total2 + Number.EPSILON) * 100) : 0) + "%";
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getData() {
            let _this = this;
            for (const item of _this.dataChucNang) {
                if (item.value) {
                    item.isBusy = true;
                    let params = _this.setParamAPI(item.key);
                    let api = "";
                    if (typeof item.api === "string") {
                        api = item.api;
                    } else {
                        if (this.sapGiaBatDau) {
                            api = item.api[0];
                        } else if (this.sapGiaTrongTran) {
                            api = item.api[1];
                        } else if (this.sapGiaTruocTran) {
                            api = item.api[2];
                        }
                    }
                    if (api) {
                        Betscan[api](_this.$root.url, params)
                            .then((response) => {
                                let data = [];
                                if (item.key === "trungIp" && response) {
                                    for (const i of response.data) {
                                        let data1 = [];
                                        i.dataSameIp.children = i.listDataSameIp;
                                        i.dataSameIp.rowNo = i.rowNo;
                                        i.dataSameIp.isShowDongCon = true;
                                        data1.push(i.dataSameIp);
                                        for (const j of i.listDataSameIp) {
                                            j.rowNo = "";
                                            j.idDongCha = i.rowNo;
                                            data1.push(j);
                                        }
                                        data.push({
                                            ip: data1[0].ip,
                                            dataTableFilter: data1,
                                        });
                                    }
                                } else if (response) {
                                    for (const i of response.data) {
                                        if (i.dataRoot) {
                                            if (data.length !== 0) {
                                                data.push({
                                                    idDongCha: i.rowNo - 1,
                                                });
                                            }
                                            i.dataRoot.children = i.listDataSameRoot;
                                            i.dataRoot.rowNo = i.rowNo;
                                            i.dataRoot.isShowDongCon = true;
                                            data.push(i.dataRoot);
                                            for (const j of i.listDataSameRoot) {
                                                j.rowNo = "";
                                                j.idDongCha = i.rowNo;
                                                data.push(j);
                                            }
                                        } else {
                                            i.children = [];
                                            data.push(i);
                                        }
                                    }
                                }
                                item.dataTable = _this.cloneDeep(data);
                                item.dataTableFilter = _this.cloneDeep(data);
                                item.isBusy = false;
                            })
                            .catch((error) => {
                                _this.errorApi(error, _this);
                                item.isBusy = false;
                            });
                    } else {
                        item.isBusy = false;
                    }
                }
            }
        },
        setParamAPI(type) {
            let params = {
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                typeDomain: this.domain,
                memberId: this.memberId,
            };
            if (type === "ruiRo") {
                params.normalSport = this.normalSport;
                params.virtualSport = this.virtualSport;
                params.type = this.typeRuiRo;
            }
            if (type === "dacBiet") {
                params.normalSport = this.normalSport;
                params.virtualSport = this.virtualSport;
                params.type = this.typeDacBiet;
            }
            if (type === "cuocTheo" || type === "buonCom" || type === "sapGia" || type === "trungIp") {
                params.milisFrom = this.milisFrom;
                params.milisTo = this.milisTo;
                if (this.isShowBuonCom) {
                    params.normalSport = this.beforeMatch;
                    params.virtualSport = this.inMatch;
                } else {
                    params.normalSport = this.normalSport;
                    params.virtualSport = this.virtualSport;
                }
                params.filterCount = this.count;
                params.timeRange = this.timeRange.codeKey;
            }
            return params;
        },
        setTitleDacBiet(titleDacBiet) {
            let titlesA = "CƯỢC ĐẶC BIỆT";
            if (titleDacBiet) {
                if (titleDacBiet === 1) {
                    titlesA = "CƯỢC XẤU";
                }
                if (titleDacBiet === 2) {
                    titlesA = "CHUYÊN ĐÁNH KÈO AN TOÀN";
                }
                if (titleDacBiet === 3) {
                    titlesA = "CHUYÊN ĐÁNH XỈU VÀ DƯỚI";
                }
            }
            return titlesA;
        },
        changeChucNang(item) {
            for (const i of this.dataChucNang) {
                if (item.key === i.key) {
                    i.value = true;
                } else {
                    i.value = false;
                }
                i.dataTable = [];
            }
            if (item.key === "buonCom") {
                this.isShowBuonCom = item.value;
            } else {
                this.isShowBuonCom = false;
            }
            this.getData();
        },
        changeFormatTable() {
            for (const i of this.dataFormatTable) {
                this[i.key] = i.value;
            }
        },
        changeTimeRange(mode) {
            let timeRange;
            switch (mode) {
                case "0":
                    this.milisFrom = this.checkOnlyNumber(this.milisFrom, 0);
                    this.milisTo = this.checkOnlyNumber(this.milisTo, 4);
                    if (this.milisFrom > this.milisTo) {
                        this.milisFrom = 0;
                    }
                    timeRange = this.timeRangeOptions.filter((i) => i.codeValue === this.milisFrom && i.codeValue2 === this.milisTo)[0];
                    if (timeRange) {
                        this.timeRange = timeRange;
                    } else {
                        this.timeRange = this.timeRangeOptions[0];
                    }
                    break;
                case "1":
                    this.milisFrom = this.timeRange.codeValue;
                    this.milisTo = this.timeRange.codeValue2;
                    break;
            }
        },
        getIp(row) {
            if (this.anIp && !row.children) {
                let str = row.ip.split(".").pop();
                return `********${str}`;
            } else {
                return row.ip;
            }
        },
        getUsername(row) {
            if (this.anUsername && !row.children) {
                let str = row.memberId.substr(row.memberId.length - 3);
                return `********${str}`;
            } else {
                return row.memberId;
            }
        },
        getStake(row) {
            if (this.anStake && !row.children) {
                return `******`;
            } else {
                return row.stake;
            }
        },
        getChoiceDisplay(row) {
            if (row.choiceDisplay) {
                let arr = [];
                for (const item of row.choiceDisplay.split("||")) {
                    arr.push("<span>" + item + "</span>");
                }
                return arr.join("<br />");
            } else {
                return "";
            }
        },
        getClassChoiceDisplay(row) {
            let classStr = "";
            if (row.children) {
                classStr += "cuoc-goc ";
            }
            if (row.choice === 1 || row.choice === 3) {
                classStr += "tai-tren";
            } else {
                classStr += "xiu-duoi";
            }
            return classStr;
        },
        getThoiGianCuoc(row) {
            if (this.thayDoiGio) {
                return this.getTimeDomain(row.domainName, row.timeChoice);
            } else {
                return this.getTimeVN(row.timeChoiceVst);
            }
        },
        getTimeVN(time) {
            if (time) {
                let str = "<span>Giờ VN: " + this.getThu(time) + "</span>";
                str += "<br />";
                str += "<span>" + time + "</span>";
                return str;
            } else {
                return "";
            }
        },
        getTimeDomain(domain, time) {
            if (time) {
                let str = "<span>Giờ theo " + domain + ": " + this.getThu(time) + "</span>";
                str += "<br />";
                str += "<span>" + time + "</span>";
                return str;
            } else {
                return "";
            }
        },
        getThu(time) {
            let dayName = "";
            switch (this.$moment(time).day()) {
                case 0:
                    dayName = "Chủ Nhật";
                    break;
                case 1:
                    dayName = "Thứ Hai";
                    break;
                case 2:
                    dayName = "Thứ Ba";
                    break;
                case 3:
                    dayName = "Thứ Tư";
                    break;
                case 4:
                    dayName = "Thứ Năm";
                    break;
                case 5:
                    dayName = "Thứ Sáu";
                    break;
                case 6:
                    dayName = "Thứ Bảy";
            }
            return dayName;
        },
        toggleDongCon(data, row, isForceClose = false, isRemoveRow = false) {
            row.isShowDongCon = isForceClose ? !isForceClose : !row.isShowDongCon;
            let dataReturn = this.cloneDeep(data.dataTableFilter);
            let indexRow = dataReturn.findIndex((i) => i.rowNo === row.rowNo);
            if (row.isShowDongCon) {
                for (const item of row.children) {
                    dataReturn.splice(indexRow + 1, 0, item);
                    indexRow++;
                }
            } else {
                for (var i = dataReturn.length - 1; i >= indexRow; i--) {
                    if (dataReturn[i] && dataReturn[i].idDongCha && dataReturn[i].idDongCha === row.rowNo) {
                        dataReturn.splice(i, 1);
                    }
                }
                if (isRemoveRow) {
                    dataReturn.splice(indexRow, 1);
                }
            }
            data.dataTableFilter = this.cloneDeep(dataReturn);
        },
        removeRow(data, row) {
            if (row.item.children) {
                this.toggleDongCon(data, row.item, true, true);
                let index = 1;
                for (const item of data.dataTableFilter) {
                    if (item.children) {
                        item.rowNo = index;
                        index++;
                    } else {
                        item.idDongCha = index - 1;
                    }
                }
            } else {
                data.dataTableFilter.splice(row.index, 1);
            }
        },
        removeBelowCount(type) {
            if (type) {
                switch (type) {
                    case "cong":
                        this.anBetCount += 1;
                        break;
                    case "tru":
                        this.anBetCount -= 1;
                        break;
                }
            }
            if (!this.anBetCount || !this.checkOnlyNumber(this.anBetCount)) {
                this.anBetCount = 1;
            }
            for (const data of this.dataChucNang) {
                if (["cuocTheo", "buonCom", "sapGia", "buonBong"].includes(data.key)) {
                    let listParent = [];
                    let index = 1;
                    for (const item of data.dataTable) {
                        if (item.children && item.children.length > 0) {
                            let listChild = [];
                            let parent = this.cloneDeep(item);
                            for (const child of item.children) {
                                if (child.betCount >= this.anBetCount) {
                                    child.idDongCha = index;
                                    listChild.push(child);
                                }
                            }
                            if (listChild.length > 0) {
                                parent.children = this.cloneDeep(listChild);
                                parent.rowNo = index;
                                index++;
                                listParent.push(parent);
                            }
                        }
                    }
                    let dataReturn = [];
                    for (const item of listParent) {
                        dataReturn.push(item);
                        for (const item1 of item.children) {
                            dataReturn.push(item1);
                        }
                        dataReturn.push({
                            idDongCha: item.rowNo - 1,
                        });
                    }
                    data.dataTableFilter = this.cloneDeep(dataReturn);
                }
            }
        },
        boChonChucNang(item) {
            item.value = !item.value;
            let isUncheckAll = this.dataChucNang.every((i) => i.value === false);
            if (isUncheckAll) {
                item.value = true;
            }
        },
        getClassRow(item, type) {
            if (!item || type !== "row") {
                return "";
            }
            if (!item.refNo) {
                return "dong-rong";
            }
            if (item.children) {
                return "dong-cha";
            }
        },
        print() {
            localStorage.setItem("loading", 1);
            this.isPrint = true;
            this.switchHtmlTheme("light", false);
            this.removeColAction();
            let _this = this;
            setTimeout(async () => {
                const el = _this.$refs["report-body"];
                const options = {
                    type: "dataURL",
                };
                const output = await _this.$html2canvas(el, options);
                // download img
                var link = document.createElement("a");
                link.href = output;
                link.download = `${_this.memberId}_${_this.$moment().format(_this.$root.formatDateTimeFileName)}.jpg`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                _this.switchHtmlTheme(localStorage.getItem("themeColor"));
                _this.dataChucNang = this.cloneDeep(_this.dataChucNangGoc);
                _this.isPrint = false;
                localStorage.setItem("loading", 0);
            }, 500);
        },
        removeColAction() {
            this.dataChucNangGoc = this.cloneDeep(this.dataChucNang);
            for (const item of this.dataChucNang) {
                item.fields.splice(1, 1);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.style-for-web .bao-cao {
    .group-item-filter {
        justify-content: flex-start;
        height: 38px;

        .input-an-bet-count {
            flex-wrap: nowrap;
            height: 100%;
            min-width: 125px;
            max-width: 125px;

            .btn-cong {
                height: 50%;
                width: 100%;
                border-radius: 0 8px 0 0;
                margin: 0;

                .icon {
                    stroke-width: 1px;
                }
            }

            .btn-tru {
                height: 50%;
                width: 100%;
                border-radius: 0 0 8px 0;
                margin: 0;

                .icon {
                    stroke-width: 1px;
                }
            }
        }
    }

    .group-top-content {
        display: flex;

        .group-filter {
            width: 70vw;
        }

        .group-thong-ke {
            width: calc(30vw - 30px);

            >div {
                display: flex;
                align-items: center;

                >div {
                    display: flex;
                    margin: 2px;
                    padding: 0.25rem 0.75rem;
                    border-radius: 8px;
                    box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
                    border: 1px solid var(--text-color-dark);

                    label {
                        margin: 0;
                        border-left: 1px solid var(--text-color-dark);
                        padding: 0.25rem 0 0.25rem 0.75rem;
                        font-weight: bold;
                        color: var(--text-color-dark);

                        &:first-child {
                            border-left: none;
                            padding-left: 0;
                        }
                    }
                }
            }

            .w-50 {
                width: 50%;
            }

            .w-33 {
                width: calc(100% / 3);
            }

            .tai {
                background-color: var(--tai-background);

                label {
                    width: calc(100% / 3);
                }
            }

            .xiu {
                background-color: var(--xiu-background);

                label {
                    width: calc(100% / 3);
                }
            }

            .tren {
                background-color: var(--tren-background);

                label {
                    width: calc(100% / 3);
                }
            }

            .duoi {
                background-color: var(--duoi-background);

                label {
                    width: calc(100% / 3);
                }
            }

            .goc {
                background-color: var(--goc-background);

                label {
                    width: calc(100% / 3);
                }
            }

            .tong-ma-cuoc {
                background-color: var(--tong-ma-cuoc-background);

                label {
                    width: calc(100% / 3);

                    &:first-child {
                        width: calc((100% / 3) * 2);
                    }
                }
            }

            .bong-ao {
                background-color: var(--bong-ao-background);

                label {
                    width: calc(100% / 2);
                }
            }

            .casino {
                background-color: var(--casino-background);

                label {
                    width: calc(100% / 2);
                }
            }

            .khac {
                background-color: var(--khac-background);

                label {
                    width: calc(100% / 2);
                }
            }

            .ty-le-player {
                background-color: var(--ty-le-player-background);

                label {
                    width: calc(100% / 3);

                    &:first-child {
                        width: calc((100% / 3) * 2);
                    }
                }
            }

            .ty-le-banker {
                background-color: var(--ty-le-banker-background);

                label {
                    width: calc(100% / 3);

                    &:first-child {
                        width: calc((100% / 3) * 2);
                    }
                }
            }

            .ty-le-win {
                background-color: var(--ty-le-win-background);

                label {
                    width: calc(100% / 3);

                    &:first-child {
                        width: calc((100% / 3) * 2);
                    }
                }
            }

            .ty-le-loss {
                background-color: var(--ty-le-loss-background);

                label {
                    width: calc(100% / 3);

                    &:first-child {
                        width: calc((100% / 3) * 2);
                    }
                }
            }
        }
    }

    &::v-deep table {
        tr {
            th {
                white-space: nowrap;
                text-align: center;

                >div {
                    white-space: nowrap;
                    text-align: center;
                }
            }
        }

        tr {
            td {
                text-align: center;
                word-break: break-all;
                word-break: break-word;

                >div {
                    text-align: center;

                    >strong {
                        text-align: center;
                    }
                }
            }
        }
    }

    .input-milis {
        min-width: 50px;
        max-width: 100px;

        input {
            width: 100%;
        }
    }

    .group-bao-cao-phan-tich {
        background-color: var(--header-bao-cao-phan-tich-background);
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        border-radius: 8px;

        .header-bao-cao-phan-tich {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100px;
            font-size: 32px;
            font-weight: bold;
            color: var(--text-color-light);
            background-color: var(--header-bao-cao-phan-tich-background);
            border-radius: 8px 8px 0 0;
        }

        .body-bao-cao-phan-tich {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            padding: 10px;
            background-color: var(--body-bao-cao-phan-tich-background);
            border: 3px solid var(--header-bao-cao-phan-tich-background);
            border-radius: 8px;

            .group-item {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% / 2);
                height: 125px;
                border-radius: 8px;
                margin: 5px;

                >div {
                    display: flex;
                    width: 100%;
                    height: calc(100% / 3);
                    margin-bottom: 3px;

                    label {
                        display: flex;
                        align-items: flex-start;
                        padding: 10px 15px;
                        margin: 0;
                        height: 100%;

                        &:first-child {
                            justify-content: flex-end;
                            width: 150px;
                            background-color: var(--title-bao-cao-phan-tich-background);
                            border-radius: 8px 0 0 8px;
                            color: var(--text-color-light);
                        }

                        &:last-child {
                            justify-content: flex-start;
                            width: calc(100% - 150px);
                            background-color: var(--value-bao-cao-phan-tich-background);
                            border-radius: 0 8px 8px 0;
                            color: var(--text-color);
                        }
                    }

                    .input-group {
                        width: calc(100% - 150px);
                        height: 100%;
                        background-color: var(--value-bao-cao-phan-tich-background);
                        border-radius: 0 8px 8px 0;
                        padding: 3px;

                        input {
                            height: 100%;
                            max-height: 100%;
                            min-height: 100%;
                            background-color: var(--value-bao-cao-phan-tich-background);
                            border-color: var(--value-bao-cao-phan-tich-background);
                            color: var(--text-color);
                        }
                    }
                }
            }

            .group-item-textarea {
                display: flex;
                flex-wrap: wrap;
                width: calc((100% / 2) * 2);
                height: 125px;
                border-radius: 8px;
                margin: 5px;

                >div {
                    display: flex;
                    width: 100%;
                    margin-bottom: 3px;

                    label {
                        display: flex;
                        align-items: flex-start;
                        padding: 10px 15px;
                        margin: 0;
                        height: 38px;

                        &:first-child {
                            justify-content: flex-end;
                            align-items: flex-start;
                            width: 150px;
                            height: 100%;
                            background-color: var(--title-bao-cao-phan-tich-background);
                            border-radius: 8px 0 0 8px;
                            color: var(--text-color-light);
                        }

                        &:last-child {
                            justify-content: flex-start;
                            width: calc(100% - 150px);
                            height: inherit;
                            background-color: var(--value-bao-cao-phan-tich-background);
                            border-radius: 0 8px 8px 0;
                            color: var(--text-color);
                            white-space: pre-wrap;
                        }
                    }

                    .input-group {
                        width: calc(100% - 150px);
                        background-color: var(--value-bao-cao-phan-tich-background);
                        border-radius: 0 8px 8px 0;
                        padding: 3px;

                        textarea {
                            height: 100%;
                            max-height: 100%;
                            min-height: 100%;
                            background-color: var(--value-bao-cao-phan-tich-background) !important;
                            border-color: var(--value-bao-cao-phan-tich-background) !important;
                            color: var(--text-color) !important;
                        }
                    }
                }
            }
        }
    }

    .chuc-nang-item {
        color: var(--text-btn-secondary-color);
        padding: 0.375rem 0.75rem;
        margin-right: 5px;
        background-color: var(--header-bao-cao-phan-tich-background);
        border-radius: 8px;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        cursor: pointer;

        &:hover {
            background-color: var(--btn-primary-color-hover);

            .icon {
                color: var(--btn-danger-color);
            }
        }

        .spinner-border {
            width: 1rem;
            height: 1rem;
        }
    }

    .chuc-nang-phuc-hoi {
        color: var(--text-btn-secondary-color);
        padding: 0.375rem 0.75rem;
        margin-right: 5px;
        background-color: var(--btn-success-color);
        border-radius: 8px;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        cursor: pointer;

        &:hover {
            background-color: var(--btn-success-color-hover);
        }
    }

    .group-ket-qua-tim-kiem {
        border: 3px solid var(--header-bao-cao-phan-tich-background);
        border-radius: 10px;
        padding: 5px;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        background-image: var(--background-image);

        .title-trung-ip {
            font-size: 16px;
            padding: 5px;
            border-top: 1px solid var(--border-color);
        }
    }

    .report-body {
        margin: -15px;
        padding: 15px;
    }

    .cell-style {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        &.no-warp {
            span {
                white-space: nowrap;
            }
        }

        .ref-no {
            position: relative;
            padding-right: 15px;

            .icon-arrow {
                position: absolute;
                right: -3px;
            }
        }

        .icon {
            transition: all 0.3s;
        }

        .show-dong-con {
            transform: rotate(90deg);
        }

        .link {
            display: flex;

            .icon-red {
                color: var(--btn-danger-color);
            }

            &:hover {
                .icon-red {
                    color: var(--btn-danger-color-hover);
                }
            }
        }

        &.nowrap {
            white-space: nowrap;
        }
    }

    &::v-deep .dong-rong {
        background-image: var(--background-image);

        td {
            border: none;
            height: 20px;
        }
    }

    &::v-deep .dong-cha {
        background-color: var(--cap-bat-1-back-color);

        &:hover {
            background-color: var(--cap-bat-1-color) !important;
        }
    }

    .tai-tren {
        color: var(--text-tai-tren-color);
        font-weight: bold;
    }

    .xiu-duoi {
        color: var(--text-xiu-duoi-color);
        font-weight: bold;
    }

    .cuoc-goc {
        &.tai-tren {
            color: var(--text-tai-tren-1-color);
            font-weight: bold;
        }

        &.xiu-duoi {
            color: var(--text-xiu-duoi-1-color);
            font-weight: bold;
        }
    }
}

#popover-chuc-nang {
    width: 30vw;
    min-width: 390px;

    .popover-body {
        width: 100%;
    }

    .group-chuc-nang {
        display: flex;
        width: 100%;

        >div {
            width: 50%;
            padding: 5px;
            white-space: nowrap;
        }
    }
}
</style>
