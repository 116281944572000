<template>
    <div id="report-by-product" class="report-by-product">
        <pc-header :title="title" :backTo="backTo" />
        <div class="report-by-product-content">
            <b-row>
                <b-col offset="1" cols="3">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Loại Thống Kê</span>
                        <multi-select class="multi-select" v-model="loaiThongKe" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="dataLoaiThongKe"
                            :searchable="false" :allow-empty="false" @input="changeLoaiThongKe()">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="3">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Nhà Cái</span>
                        <multi-select class="multi-select" v-model="valueMaster2" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="dataMaster2" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="3" v-if="loaiThongKe.codeKey === 1">
                    <div class="group-item-filter">
                        <b-input-group>
                            <b-form-checkbox v-model="normalSport">Bóng Thường</b-form-checkbox>
                        </b-input-group>
                        <b-input-group>
                            <b-form-checkbox v-model="virtualSport">Bóng Ảo</b-form-checkbox>
                        </b-input-group>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col offset="1" cols="3">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Tài Khoản</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="superId"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="3">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Thời Gian</span>
                        <date-range-picker v-model="dateFromTo" :locale-data="$root.localeData" :show-dropdowns="true"
                            :auto-apply="true" :ranges="ranges" :linked-calendars="false"
                            opens="center"></date-range-picker>
                    </div>
                </b-col>
                <b-col cols="3">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="getData()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col offset="1" cols="10">
                    <div class="group-thoi-diem">
                        <label class="w-50">
                            Tài khoản trong - <strong>{{ valueMaster2.codeName }}</strong>
                        </label>
                        <label class="w-50 text-right">
                            Kết quả được tính tới thời điểm: <strong>{{ timeUpdate }}</strong>
                        </label>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12">
                    <TableReportByProducts v-if="loaiThongKe.codeKey === 0" :data="dataTable" :super-id="superId"
                        :date-from-to="dateFromTo" :level="1" :is-busy="isBusy" />
                    <TableReportByChoice v-if="loaiThongKe.codeKey === 1" :data="dataTable" :super-id="superId"
                        :date-from-to="dateFromTo" :level="1" :is-busy="isBusy" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Master from "../../../service/master.service";
import Betscan from "../../../service/betscan.service";
import TableReportByProducts from "../Common/TableReportByProducts.vue";
import TableReportByChoice from "../Common/TableReportByChoice.vue";

export default {
    name: "ReportByProduct",
    components: {
        TableReportByProducts,
        TableReportByChoice,
    },
    data() {
        return {
            title: "THỐNG KÊ GIAN LẬN",
            backTo: "menu",
            timeUpdate: "",
            ranges: {
                "Hôm Nay": this.getRangeDate("homNay"),
                "Hôm Qua": this.getRangeDate("homQua"),
                "Tuần Này": this.getRangeDate("tuanNay"),
                "Tuần Trước": this.getRangeDate("tuanTruoc"),
                "Tháng Này": this.getRangeDate("thangNay"),
                "Tháng Trước": this.getRangeDate("thangTruoc"),
            },
            dataMaster2: [
                {
                    codeKey: "00",
                    codeName: "Tất cả",
                },
            ],
            valueMaster2: {
                codeKey: "00",
                codeName: "Tất cả",
            },
            dataLoaiThongKe: [
                {
                    codeKey: 0,
                    codeName: "Report By Products",
                },
                {
                    codeKey: 1,
                    codeName: "Report By Choice",
                },
            ],
            loaiThongKe: {
                codeKey: 0,
                codeName: "Report By Products",
            },
            normalSport: false,
            virtualSport: false,
            superId: "",
            dateFromTo: {
                startDate: this.getRangeDate("tuanNay")[0],
                endDate: this.getRangeDate("tuanNay")[1],
            },
            dataTable: [],
            isBusy: false,
        };
    },
    computed: {
        ...mapState("ReportByProduct", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    async mounted() {
        console.log("I am mounted to the DOM!");
        await this.getListMasterCodeByClass("00002");
        let param = JSON.parse(localStorage.getItem("PARAM_FOR_LEVEL_2"));
        if (param) {
            this.superId = param.inputSuperId;
            this.valueMaster2 = this.dataMaster2.filter((i) => i.codeKey === param.domain)[0];
            this.loaiThongKe = this.dataLoaiThongKe.filter((i) => i.codeKey === param.report)[0];
            this.dateFromTo = param.dateFromTo;

            localStorage.removeItem("PARAM_FOR_LEVEL_2");
        }
        this.getTimeUpdate();
        this.getData();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("ReportByProduct", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.getData();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        async getListMasterCodeByClass(codeClass) {
            let _this = this;
            await Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    response.data.forEach((item) => {
                        _this.dataMaster2.push({
                            codeKey: item.codeKey,
                            codeName: item.codeName,
                        });
                    });
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getTimeUpdate() {
            let _this = this;
            Betscan.getTimeUpdate(_this.$root.url)
                .then((response) => {
                    _this.timeUpdate = _this.getFormatDate(response.data, "formatDateTime");
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getData() {
            switch (this.loaiThongKe.codeKey) {
                case 0:
                    this.getReportByProduct();
                    break;
                case 1:
                    this.getReportByChoice();
                    break;
            }
        },
        getReportByProduct() {
            this.isBusy = true;
            let params = {
                page: this.pageTable,
                pageSize: this.pageSizeTable,
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                typeDomain: this.valueMaster2.codeKey,
                superId: this.superId,
            };
            let _this = this;
            Betscan.getReportByProduct(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data.list;
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy = false;
                });
        },
        getReportByChoice() {
            this.isBusy = true;
            let params = {
                page: this.pageTable,
                pageSize: this.pageSizeTable,
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                typeDomain: this.valueMaster2.codeKey,
                superId: this.superId,
                normalSport: this.normalSport,
                virtualSport: this.virtualSport,
            };
            let _this = this;
            Betscan.getReportByChoice(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data.list;
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy = false;
                });
        },
        changeLoaiThongKe() {
            this.dataTable = [];
            this.getData();
        },
    },
};
</script>

<style lang="scss">
.style-for-web .report-by-product {}
</style>
