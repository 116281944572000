import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import VueMomentJS from "vue-momentjs";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import VueHtml2Canvas from "vue-html2canvas";
import regeneratorRuntime from "regenerator-runtime";
import lightVueTree from "light-vue-tree";
import Treeselect from "@riophae/vue-treeselect";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "light-vue-tree/lib/style/index.css";

Vue.config.productionTip = false;

moment.locale("vi");

Vue.use(VueMomentJS, moment);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueHtml2Canvas);
Vue.use(regeneratorRuntime);

// register globally
Vue.component('multi-select', Multiselect)
Vue.component('date-range-picker', DateRangePicker)
Vue.component("light-vue-tree", lightVueTree);
Vue.component("tree-select", Treeselect);

import PcHeader from "./components/Web/Common/Header.vue";
import MbHeader from "./components/Mobile/Common/Header.vue";

Vue.component('pc-header', PcHeader);
Vue.component('mb-header', MbHeader);

Vue.mixin({
    methods: {
        detectMob() {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        },
        movePage(path, method = "push") {
            let isMob = this.detectMob();
            path = isMob ? `mb-${path}` : path.replace('mb-', '');
            switch (method) {
                case "replace":
                    if (this.$route.path !== ('/' + path)) {
                        this.$router.replace(path);
                    }
                    break;
                default:
                    if (this.$route.path !== ('/' + path)) {
                        this.$router.push(path);
                    }
                    break;
            }
        },
        forceMovePage() {
            let routePath = this.$route.path;
            if ((this.$root.isMobile && !routePath.includes("mb-")) ||
                (!this.$root.isMobile && routePath.includes("mb-"))) {
                this.movePage(routePath.substring(1), "replace");
            }
        },
        switchHtmlTheme(theme = 'light', isSave = true) {
            if (isSave) {
                localStorage.setItem('themeColor', theme);
            }
            let html = document.querySelector("html");
            html.dataset.theme = theme;
            this.$root.theme = theme
        },
        getImg(icon, type) {
            return require(`./assets/img/${icon}.${type}`);
        },
        getLogoNhaCai(name) {
            let type = 'png';
            let nameNhaCai = name;
            switch (nameNhaCai) {
                case 'SBOBET HK':
                    nameNhaCai = 'SBOBET';
                    break;
            }
            return require(`./assets/img/logo-nha-cai/${nameNhaCai.toUpperCase()}.${type}`);
        },
        errorApi: (error, app) => {
            localStorage.setItem("loading", 0);
            let message = "";
            let code = "";

            if (error.response && error.response.status === 401) {
                setTimeout(() => {
                    if (app.$root.isLogin) {
                        app.$root.isLogin = false;
                        app.showToast(
                            "Xin đăng nhập lại",
                            "Hết giờ!",
                            app,
                            "danger"
                        );
                    }
                }, 100);
                app.clearLocalStorage();
                app.movePage("login", "replace");
                return
            } else if (error.response) {
                message = error.response.data.message;
                code = error.response.data.code;
            } else {
                message = error.message;
                code = error.code;
            }
            if (code === 'ERR_NETWORK') {
                message = 'Lỗi kết nối do mất mạng hoặc server dừng hoạt động';
            }
            let title = `Lỗi ${code}`
            console.error(error);
            app.showToast(message, title, app, "danger", error.time);
        },
        clearLocalStorage() {
            let theme = localStorage.getItem("themeColor");
            let isMiniMenu = localStorage.getItem("isMiniMenu");
            localStorage.clear();
            localStorage.setItem("themeColor", theme);
            localStorage.setItem('isMiniMenu', isMiniMenu);
        },
        showToast: (message, title, app, variant, time = 3000) => {
            app.$bvToast.toast(message, {
                title: title,
                autoHideDelay: time,
                variant: variant,
                solid: true
            });
        },
        cloneDeep(value) {
            return value ? JSON.parse(JSON.stringify(value)) : value;
        },
        getRangeDate(range, number = 1, isNotToDate = false, isFromCurrent = false) {
            let dateFrom = new Date().setHours(0, 0, 0, 0);
            let dateTo = new Date().setHours(23, 59, 59, 999);
            switch (range) {
                case 'homNay':
                    dateFrom = this.$moment(dateFrom);
                    dateTo = this.$moment(dateTo);
                    break;
                case 'homQua':
                    dateFrom = this.$moment(dateFrom).subtract(1, 'days');
                    dateTo = isFromCurrent ? this.$moment(dateTo) : this.$moment(dateTo).subtract(1, 'days');
                    break;
                case 'homTruoc':
                    dateFrom = this.$moment(dateFrom).subtract(number, 'days');
                    dateTo = isFromCurrent ? this.$moment(dateTo) : this.$moment(dateTo);
                    break;
                case 'tuanNay':
                    dateFrom = this.$moment(dateFrom).startOf('week');
                    dateTo = this.$moment(dateTo);
                    break;
                case 'tuanTruoc':
                    dateFrom = this.$moment(dateFrom).subtract(number, 'weeks').startOf('week');
                    dateTo = isFromCurrent ? this.$moment(dateTo) : this.$moment(dateTo).subtract(number, 'weeks').endOf('week');
                    break;
                case 'thangNay':
                    dateFrom = this.$moment(dateFrom).startOf('month');
                    dateTo = this.$moment(dateTo);
                    break;
                case 'thangTruoc':
                    dateFrom = this.$moment(dateFrom).subtract(number, 'months').startOf('month');
                    dateTo = isFromCurrent ? this.$moment(dateTo) : this.$moment(dateTo).subtract(number, 'months').endOf('month');
                    break;
                case 'namNay':
                    dateFrom = this.$moment(dateFrom).startOf('years');
                    dateTo = this.$moment(dateTo);
                    break;
                case 'namTruoc':
                    dateFrom = this.$moment(dateFrom).subtract(number, 'years').startOf('year');
                    dateTo = isFromCurrent ? this.$moment(dateTo) : this.$moment(dateTo).subtract(number, 'years').endOf('year');
                    break;
            }
            if (isNotToDate) {
                return [dateFrom, dateTo];
            } else {
                return [dateFrom.toDate(), dateTo.toDate()];
            }
        },
        getDateAdd(type, date, number, isNoTime) {
            let returnDate = isNoTime ? date.setHours(0, 0, 0, 0) : date;
            switch (type) {
                case 'day':
                    returnDate = this.$moment(returnDate).add(number, 'days');
                    break;
                case 'week':
                    returnDate = this.$moment(returnDate).add(number, 'weeks');
                    break;
                case 'month':
                    returnDate = this.$moment(returnDate).add(number, 'months');
                    break;
                case 'year':
                    returnDate = this.$moment(returnDate).add(number, 'years');
                    break;
            }
            return returnDate.toDate();
        },
        getDateSubtract(type, date, number, isNoTime) {
            let returnDate = isNoTime ? date.setHours(0, 0, 0, 0) : date;
            switch (type) {
                case 'day':
                    returnDate = this.$moment(returnDate).subtract(number, 'days');
                    break;
                case 'week':
                    returnDate = this.$moment(returnDate).subtract(number, 'weeks');
                    break;
                case 'month':
                    returnDate = this.$moment(returnDate).subtract(number, 'months');
                    break;
                case 'year':
                    returnDate = this.$moment(returnDate).subtract(number, 'years');
                    break;
            }
            return returnDate.toDate();
        },
        getFormatDate(date, format, currentFormat) {
            if (currentFormat) {
                return date ? this.$moment(date, currentFormat).format(this.$root[format]) : '';
            } else {
                return date ? this.$moment(date).format(this.$root[format]) : '';
            }
        },
        getFormatNumber(value) {
            if (value || value === 0) {
                let val = value.toString();
                let arrVal = value.toString().split('.');
                if (arrVal.length === 2) {
                    val = Number(arrVal[0].replace(',', '') + '.' + arrVal[1]);
                } else {
                    val = Number(val.replace(',', ''));
                }
                let returnData = Math.round((val + Number.EPSILON) * 100) / 100;
                return returnData.toLocaleString(['en-US']); // en-US, vi-VN
            } else {
                return '-';
            }
        },
        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        randomPass() {
            const charUpStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const charLowStr = 'abcdefghijklmnopqrstuvwxyz';
            const numberStr = '0123456789';
            const specStr = '!@#$%';
            const randomCharUp = charUpStr.charAt(Math.floor(Math.random() * charUpStr.length));
            const randomCharLow = charLowStr.charAt(Math.floor(Math.random() * charLowStr.length));
            const randomNumber = numberStr.charAt(Math.floor(Math.random() * numberStr.length));
            const randomSpec = specStr.charAt(Math.floor(Math.random() * specStr.length));
            let counter = 0;
            let result = '';
            while (counter < 1) {
                result += randomCharUp;
                counter += 1;
            }
            while (counter < 4) {
                result += randomCharLow;
                counter += 1;
            }
            while (counter < 8) {
                result += randomNumber;
                counter += 1;
            }
            while (counter < 9) {
                result += randomSpec;
                counter += 1;
            }
            return result;
        },
        createCookie() {
            let userId = localStorage.getItem("userId");
            let token = localStorage.getItem("jwt");

            let today = new Date();
            let expire = new Date();
            expire.setTime(today.getTime() + 3600000 * 24 * 15);

            document.cookie = "userId=" + userId + ";path=/" + ";expires=" + expire.toUTCString();
            document.cookie = "token=" + encodeURI(token) + ";path=/" + ";expires=" + expire.toUTCString();
            //can only write one entity at a time (name, pass)
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            } else {
                return '';
            }
        },
        deleteCookie(name) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        },
        demNguocThoiGian(date) {
            var countDownDate = new Date(date).getTime();

            let _this = this;
            var x = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                _this.$root.demNguocNgay = (days).toString().length === 1 ? '0' + days : days;
                _this.$root.demNguocGio = (hours).toString().length === 1 ? '0' + hours : hours;
                _this.$root.demNguocPhut = (minutes).toString().length === 1 ? '0' + minutes : minutes;
                _this.$root.demNguocGiay = (seconds).toString().length === 1 ? '0' + seconds : seconds;

                if (distance < 0) {
                    _this.$root.demNguocNgay = '00';
                    _this.$root.demNguocGio = '00';
                    _this.$root.demNguocPhut = '00';
                    _this.$root.demNguocGiay = '00';
                    clearInterval(x);
                }
            }, 1000);
        },
        checkOnlyNumber(value, defaultValue) {
            var reg = /^\d+$/;
            if (typeof defaultValue !== 'undefined') {
                return reg.test(value) ? value : defaultValue;
            } else {
                return reg.test(value);
            }
        },
        padTwo(d) {
            return (d < 10) ? '0' + d.toString() : d.toString();
        },
        optionsTinhTheoVung(valueVung) {
            return this.$root.optionsTinh.filter(i => i.codeVung === valueVung.codeKey);
        },
        getRandomNumber(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
    data: {
        url: "https://be.test.zf1.us",
        theme: "light",
        isLogin: false,
        isMobile: false,
        isShowLoading: true,
        isAdmin: false,
        formatDate: "YYYY/MM/DD",
        formatDateTimeNoSecond: "YYYY/MM/DD HH:mm",
        formatDateTime: "YYYY/MM/DD HH:mm:ss",
        formatDateAPI: "YYYY-MM-DD",
        formatDateTimeNoSecondAPI: "YYYY-MM-DD HH:mm",
        formatDateTimeAPI: "YYYY-MM-DD HH:mm:ss",
        formatDateTimeFileName: "YYYY-MM-DD_HH-mm-ss",
        formatDateTimeNoSecondVN: "DD/MM/YYYY HH:mm",
        formatThoiGianDau: "HH:mm DD/MM/YYYY",
        formatDateShortYearVN: "DD/MM/YY",
        formatDateNoYearNoDateVN: "DD/MM",
        formatDateNoYearVN: "ddd DD/MM",
        formatDayVN: "ddd DD/MM/YYYY",
        formatDay: "ddd",
        localeData: {
            direction: 'ltr',
            format: 'dd/mm/yyyy',
            separator: ' tới ',
            applyLabel: 'Áp dụng',
            cancelLabel: 'Hủy',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['CN', 'Hai', 'Ba', 'Tư', 'Năm', 'Sáu', 'Bảy'],
            monthNames: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
            firstDay: 1,
        },
        isConnectedSocket: false,
        isChangeRateUSD: false,
        rateUsd: 0,
        isSystemMaintenance: false,
        dataMaintenance: {
            description: "",
            endDate: "",
            isEnable: false,
            startDate: "",
            title: "",
        },
        isLoginLanDau: false,
        demNguocNgay: "",
        demNguocGio: "",
        demNguocPhut: "",
        demNguocGiay: "",
        optionsVung: [
            {
                codeKey: 'MIEN_BAC',
                codeName: 'Miền Bắc',
            },
            {
                codeKey: 'MIEN_TRUNG',
                codeName: 'Miền Trung',
            },
            {
                codeKey: 'MIEN_NAM',
                codeName: 'Miền Nam',
            },
        ],
        optionsTinh: [
            {
                codeKey: '01',
                codeName: 'Tp Hà Nội',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '02',
                codeName: 'Tp Hồ Chí Minh',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '03',
                codeName: 'Tp Hải Phòng',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '04',
                codeName: 'Tp Đà Đẵng',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '05',
                codeName: 'Hà Giang',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '06',
                codeName: 'Cao Bằng',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '07',
                codeName: 'Lai Châu',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '08',
                codeName: 'Lào Cai',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '09',
                codeName: 'Tuyên Quang',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '10',
                codeName: 'Lạng Sơn',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '11',
                codeName: 'Bắc Kạn',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '12',
                codeName: 'Thái Nguyên',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '13',
                codeName: 'Yên Bái',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '14',
                codeName: 'Sơn La',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '15',
                codeName: 'Phú Thọ',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '16',
                codeName: 'Vĩnh Phúc',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '17',
                codeName: 'Quảng Ninh',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '18',
                codeName: 'Bắc Giang',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '19',
                codeName: 'Bắc Ninh',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '20',
                codeName: 'Bắc Ninh',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '21',
                codeName: 'Hải Dương',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '22',
                codeName: 'Hưng Yên',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '23',
                codeName: 'Hoà Bình',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '24',
                codeName: 'Hà Nam',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '25',
                codeName: 'Nam Định',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '26',
                codeName: 'Thái Bình',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '27',
                codeName: 'Ninh Bình',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '28',
                codeName: 'Thanh Hoá',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '28',
                codeName: 'Khánh Hoà',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '29',
                codeName: 'Nghệ An',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '30',
                codeName: 'Hà Tĩnh',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '31',
                codeName: 'Quảng Bình',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '32',
                codeName: 'Quảng Trị',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '33',
                codeName: 'Thừa Thiên-Huế',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '34',
                codeName: 'Quảng Nam',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '35',
                codeName: 'Quảng Ngãi',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '36',
                codeName: 'Kon Tum',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '37',
                codeName: 'Bình Định',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '38',
                codeName: 'Gia Lai',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '39',
                codeName: 'Phú Yên',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '40',
                codeName: 'Đắk Lắk',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '42',
                codeName: 'Lâm Đồng',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '43',
                codeName: 'Bình Phước',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '44',
                codeName: 'Bình Dương',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '45',
                codeName: 'Ninh Thuận',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '46',
                codeName: 'Tây Ninh',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '47',
                codeName: 'Bình Thuận',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '48',
                codeName: 'Đồng Nai',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '49',
                codeName: 'Long An',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '50',
                codeName: 'Đồng Tháp',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '51',
                codeName: 'An Giang',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '52',
                codeName: 'Bà Rịa-Vũng Tàu',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '53',
                codeName: 'Tiền Giang',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '54',
                codeName: 'Kiên Giang',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '55',
                codeName: 'Tp Cần Thơ',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '56',
                codeName: 'Bến Tre',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '57',
                codeName: 'Vĩnh Long',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '58',
                codeName: 'Trà Vinh',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '59',
                codeName: 'Sóc Trăng',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '60',
                codeName: 'Bạc Liêu',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '61',
                codeName: 'Cà Mau',
                codeVung: 'MIEN_NAM',
            },
            {
                codeKey: '62',
                codeName: 'Điện Biên',
                codeVung: 'MIEN_BAC',
            },
            {
                codeKey: '63',
                codeName: 'Đắk Nông',
                codeVung: 'MIEN_TRUNG',
            },
            {
                codeKey: '64',
                codeName: 'Hậu Giang',
                codeVung: 'MIEN_NAM',
            }
        ],
    },
}).$mount("#app");
