<template>
    <div id="tao-so" class="tao-so">
        <pc-header :title="title" :backTo="backTo" />
        <div class="tao-so-content">
            <b-row>
                <b-col cols="12" class="tab-btn mb-3">
                    <div>
                        <b-button size="sm" :variant="showTab === '1' ? 'primary' : 'light'" @click="changeTab('1')"
                            class="ml-1" :class="{ 'active-tab': showTab === '1' }">
                            Tạo Số
                        </b-button>
                        <b-button size="sm" :variant="showTab === '2' ? 'primary' : 'light'" @click="changeTab('2')"
                            class="ml-1" :class="{ 'active-tab': showTab === '2' }">
                            Tạo Bộ Số
                        </b-button>
                        <b-button size="sm" :variant="showTab === '3' ? 'primary' : 'light'" @click="changeTab('3')"
                            class="ml-1" :class="{ 'active-tab': showTab === '3' }">
                            Tạo Tin Nhắn
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <template v-if="showTab === '1'">
                <b-row class="mt-2">
                    <b-col offset="1" cols="4">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Dãy Số</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="numbersTaoSo"
                                    @change="onChangeNumbersTaoSo()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Giá Tiền</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="giaTien"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Số Phần</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="soPhan" :disabled="isShowVong"
                                    @change="onChangeSoPhan()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col offset="1" cols="4">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Dãy Lọc</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="filterNumbers" :disabled="isShowVong"
                                    @change="onChangeFilterNumbers()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col offset="1" cols="8">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="taoSo(0)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Xiên 2</span>
                            </b-button>
                            <b-button variant="success" @click="taoSo(1)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Xiên 3</span>
                            </b-button>
                            <b-button variant="success" @click="taoSo(2)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Xiên 4</span>
                            </b-button>
                            <b-button variant="success" @click="taoSo(3)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Xáo Vị Trí</span>
                            </b-button>
                            <b-button variant="success" @click="taoVong('4')" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Vòng 2, 3, 4</span>
                            </b-button>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="button-action text-right">
                            <b-button :variant="isDisableBtn ? 'danger' : 'success'" @click="khoaNut()">
                                <svg v-if="isDisableBtn" class="icon icon-lock-slash5">
                                    <use xlink:href="#icon-lock-slash5"></use>
                                </svg>
                                <svg v-if="!isDisableBtn" class="icon icon-lock5">
                                    <use xlink:href="#icon-lock5"></use>
                                </svg>
                                <span class="ml-2">{{ isDisableBtn ? "Mở Khóa" : "Khóa" }} Nút</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2" v-if="isShowVong">
                    <b-col offset="1" cols="10">
                        <div class="group-item-filter text-left">
                            <b-form-radio v-model="loaiTaoVong" name="loai-tao-vong" value="4" @change="taoVong()">
                                Ưu tiên tạo vòng 4
                            </b-form-radio>
                            <b-form-radio v-model="loaiTaoVong" name="loai-tao-vong" value="3" @change="taoVong()">
                                Ưu tiên tạo vòng 3
                            </b-form-radio>
                        </div>
                    </b-col>
                </b-row>
                <template v-if="!isShowVong">
                    <div v-for="(item, index) in dataTaoSo" :key="index" class="mode-tao-so">
                        <b-row v-if="item.visible" class="mt-3">
                            <b-col class="group-ket-qua" offset="1" cols="10">
                                <div class="group-title-ket-qua mb-2 ml-5">
                                    <span class="span-nowrap mr-2">
                                        <strong>Kết Quả {{ item.name }}:</strong>
                                        <span v-if="item.listNumberTheoThuTu.length === 0" class="ml-2 small-text">
                                            Không có số phù hợp với điệu kiện lọc
                                        </span>
                                    </span>
                                    <span v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap small-text mr-2">
                                        Tổng
                                        {{ getFormatNumber(item.listNumberTheoThuTu.length) }} số |
                                        Mỗi phần có {{ getFormatNumber(item.soLuongMoiPhan) }} số
                                    </span>
                                    <div v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap title-icon mr-2"
                                        title="Copy" @click="copySo(item, index)">
                                        <svg class="icon icon-copy5">
                                            <use xlink:href="#icon-copy5"></use>
                                        </svg>
                                    </div>
                                    <div v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap title-icon mr-2"
                                        title="Xáo vị trí" :class="isDisableBtn ? 'disable-btn' : ''"
                                        @click="shuffleDaySo(item, index)">
                                        <svg class="icon icon-shuffle5">
                                            <use xlink:href="#icon-shuffle5"></use>
                                        </svg>
                                    </div>
                                    <div v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap title-icon mr-2"
                                        title="Phục hồi vị trí" :class="isDisableBtn ? 'disable-btn' : ''"
                                        @click="sapXepTheoThuTu(item, index)">
                                        <svg class="icon icon-repeat5">
                                            <use xlink:href="#icon-repeat5"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div class="group-body-ket-qua">
                                    <div v-for="(number, indexNumber) of item.listNumber" :key="indexNumber">
                                        <b-form-checkbox v-model="number.selected"></b-form-checkbox>
                                        <div class="group-item-number">
                                            <template v-for="(itemArr, indexArr) of number.arr">
                                                <div :key="indexArr" class="item-number p-2">
                                                    <span>{{ itemArr }}</span>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <b-pagination v-if="item.listNumberTheoThuTu.length > 0" v-model="item.page"
                                    :total-rows="item.listNumberGoc.length" :per-page="item.pageSize" first-number
                                    last-number align="center" @input="onChangePage(item)"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </template>
                <template v-if="isShowVong">
                    <div class="mode-tao-so">
                        <b-row v-for="(item, index) in dataVong" :key="index" class="mt-3">
                            <b-col class="group-ket-qua" offset="1" cols="10">
                                <div class="group-title-ket-qua mb-2 ml-5">
                                    <span class="span-nowrap mr-2">
                                        <strong>Kết Quả Vòng {{ index + 2 }}:</strong>
                                    </span>
                                    <span class="span-nowrap small-text mr-2">
                                        Tổng {{ getFormatNumber(item.listNumber.length) }} số (Tạo
                                        xiên 2:
                                        {{ tinhXien2(item.listNumber.length, index + 2) }} số)
                                    </span>
                                    <div class="span-nowrap title-icon mr-2" title="Copy" @click="copySo(item, index)">
                                        <svg class="icon icon-copy5">
                                            <use xlink:href="#icon-copy5"></use>
                                        </svg>
                                    </div>
                                    <div class="span-nowrap title-icon mr-2" title="Xáo vị trí"
                                        :class="isDisableBtn ? 'disable-btn' : ''" @click="shuffleDaySo(item, index)">
                                        <svg class="icon icon-shuffle5">
                                            <use xlink:href="#icon-shuffle5"></use>
                                        </svg>
                                    </div>
                                    <div class="span-nowrap title-icon mr-2" title="Phục hồi vị trí"
                                        :class="isDisableBtn ? 'disable-btn' : ''" @click="sapXepTheoThuTu(item, index)">
                                        <svg class="icon icon-repeat5">
                                            <use xlink:href="#icon-repeat5"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div class="group-body-ket-qua">
                                    <div>
                                        <div class="group-item-number">
                                            <div v-for="(number, index1) in item.listNumber" :key="index1"
                                                class="item-number p-2">
                                                <span>{{ number }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </template>
            <template v-if="showTab === '2'">
                <b-row class="mt-2">
                    <b-col offset="1" cols="4">
                        <div class="group-item-filter baseline">
                            <span class="span-nowrap mr-2">Dãy Số</span>
                            <b-input-group size="lg">
                                <b-form-textarea v-model="daySo" rows="3" max-rows="6"
                                    @change="onChangeDaySo()"></b-form-textarea>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter baseline">
                            <span class="span-nowrap mr-2">Giá Tiền</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="giaTienCanXoa"
                                    @change="onChangeGiaTienCanXoa()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col offset="1" cols="8">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="layBoSoBanDau()" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Bộ Số Ban Đầu</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <div v-if="daySoBanDau" class="mode-phuc-hoi-bo-so">
                    <b-row class="mt-3">
                        <b-col class="group-ket-qua" offset="1" cols="10">
                            <div class="group-title-ket-qua mb-2 ml-5">
                                <span class="span-nowrap mr-2">
                                    <strong>Dãy Số Ban Đầu:</strong>
                                </span>
                                <span class="span-nowrap small-text mr-2">
                                    Tổng {{ getFormatNumber(daySoBanDau.length) }} số
                                </span>
                                <div class="span-nowrap title-icon mr-2" title="Copy" @click="copySo(daySoBanDau)">
                                    <svg class="icon icon-copy5">
                                        <use xlink:href="#icon-copy5"></use>
                                    </svg>
                                </div>
                            </div>
                            <div class="group-body-ket-qua">
                                <div>
                                    <div class="group-item-number">
                                        <div v-for="(number, index1) in daySoBanDau" :key="index1" class="item-number p-2">
                                            <span>{{ number }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-if="showTab === '3'">
                <b-row class="mt-2">
                    <b-col offset="1" cols="2">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Miền:</span>
                            <b-form-radio v-model="valueVung" name="vung-loc-du-lieu" value="MIEN_BAC"
                                @change="onChangeVung('MIEN_BAC')">
                                Bắc
                            </b-form-radio>
                            <b-form-radio v-model="valueVung" name="vung-loc-du-lieu" value="MIEN_NAM"
                                @change="onChangeVung('MIEN_NAM')">
                                Nam
                            </b-form-radio>
                        </div>
                    </b-col>
                    <b-col offset="2" cols="2">
                        <div class="group-item-filter">
                            <h4 class="span-nowrap mr-2">
                                Ngày {{ getFormatDate(getRangeDate('homNay')[0], 'formatDayVN') }}
                            </h4>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2 group-btn-tao-tin-nhan">
                    <b-col offset="1" cols="6">
                        <div class="group-input w-100 mb-2">
                            <div class="group-item-filter">
                                <span class="span-nowrap mr-2">Cách Tạo TN</span>
                                <multi-select class="multi-select" v-model="modeTaoTN" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="taoTinNhanOptions"
                                    :searchable="false" :allow-empty="false" @input="onChangeDaySoMau()()">
                                </multi-select>
                            </div>
                            <div class="group-item-filter">
                                <span class="span-nowrap mr-2">Dãy Số Mẫu</span>
                                <b-input-group size="lg" class="mr-2">
                                    <b-form-input type="text" v-model="daySoMau"
                                        @change="onChangeDaySoMau()"></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="group-item-filter">
                                <span class="span-nowrap mr-2">Số Tiền Mẫu</span>
                                <b-input-group size="lg">
                                    <b-form-input type="number" v-model="soTienMau"
                                        @change="onChangeSoTienMau()"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                        <template v-for="(loaiChoi, index) in listLoaiChoi">
                            <div :key="index" class="row-loai-choi mb-2" v-if="loaiChoi.visible">
                                <div class="button-action button-loai-choi text-left mr-2">
                                    <b-button :variant="loaiChoi.isSelected ? 'info' : 'secondary'"
                                        @click="changeLoaiChoi(loaiChoi)">
                                        <svg class="icon"
                                            :class="loaiChoi.isSelected ? 'icon-tick-circle5' : 'icon-record4'">
                                            <use :xlink:href="loaiChoi.isSelected ? '#icon-tick-circle5' : '#icon-record4'">
                                            </use>
                                        </svg>
                                        <span class="ml-2">{{ loaiChoi.label }}</span>
                                    </b-button>
                                </div>
                                <template v-if="loaiChoi.isSelected">
                                    <div v-for="(tinh, index1) in loaiChoi.listTinh" :key="index1" class="mr-1">
                                        <b-button :variant="tinh.isSelected ? 'info' : 'secondary'"
                                            @click="changeLoaiChoiTinh(tinh)">
                                            <svg class="icon"
                                                :class="tinh.isSelected ? 'icon-tick-circle5' : 'icon-record4'">
                                                <use :xlink:href="tinh.isSelected ? '#icon-tick-circle5' : '#icon-record4'">
                                                </use>
                                            </svg>
                                            <span class="ml-2">{{ tinh.name }}</span>
                                        </b-button>
                                    </div>
                                    <div v-if="loaiChoi.type === 'XIU_CHU'" class="ml-1">
                                        <b-button :variant="loaiChoi.isDao ? 'info' : 'secondary'"
                                            @click="loaiChoi.isDao = !loaiChoi.isDao">
                                            <svg class="icon"
                                                :class="loaiChoi.isDao ? 'icon-tick-circle5' : 'icon-record4'">
                                                <use :xlink:href="loaiChoi.isDao ? '#icon-tick-circle5' : '#icon-record4'">
                                                </use>
                                            </svg>
                                            <span class="ml-2">Đảo</span>
                                        </b-button>
                                    </div>
                                    <div class="group-input w-100 mt-1">
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Dãy Số</span>
                                            <b-input-group size="lg" class="mr-2">
                                                <b-form-input type="text" v-model="loaiChoi.numbers"
                                                    @change="onChangeNumbersTaoTinNhan(loaiChoi)"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Số Tiền</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="loaiChoi.soTien"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </b-col>
                    <b-col cols="4">
                        <div>
                            <div v-if="!isAddOrEditDaiLy" class="title-dai-ly">
                                <label class="title">
                                    <h5>Danh Sách Đại Lý</h5>
                                </label>
                                <div class="group-item-filter">
                                    <b-button variant="primary" @click="isAddOrEditDaiLy = true">
                                        <svg class="icon icon-add-square5">
                                            <use xlink:href="#icon-add-square5"></use>
                                        </svg>
                                        <span class="ml-2">Thêm Đại Lý</span>
                                    </b-button>
                                </div>
                            </div>
                            <template v-if="!isAddOrEditDaiLy">
                                <!-- danh sach dai ly da chon -->
                                <template v-if="listDaiLy.length > 0">
                                    <div class="group-items-dai-ly">
                                        <div v-for="(item, index) in listDaiLy" :key="'item-dai-ly-' + index"
                                            @click="chonDaiLy(item)">
                                            <div class="group-item-dai-ly">
                                                <div class="item-dai-ly-title">
                                                    <label>
                                                        <svg v-if="item.isSelected" class="icon icon-tick-circle5 mr-1">
                                                            <use xlink:href="#icon-tick-circle5"></use>
                                                        </svg>
                                                        {{ item.tenDaiLy }}
                                                    </label>
                                                    <div class="group-item-filter">
                                                        <b-button variant="info mr-1" size="sm"
                                                            @click.stop="item.isShowDetail = !item.isShowDetail">
                                                            <svg class="icon"
                                                                :class="!item.isShowDetail ? 'icon-eye4' : 'icon-eye-slash5'">
                                                                <use
                                                                    :xlink:href="!item.isShowDetail ? '#icon-eye4' : '#icon-eye-slash5'">
                                                                </use>
                                                            </svg>
                                                        </b-button>
                                                        <b-button variant="primary mr-1" size="sm" @click="suaDaiLy(item)">
                                                            <svg class="icon icon-edit-25">
                                                                <use xlink:href="#icon-edit-25"></use>
                                                            </svg>
                                                        </b-button>
                                                        <b-button variant="danger" size="sm" @click="xoaDaiLy(item)">
                                                            <svg class="icon icon-trash5">
                                                                <use xlink:href="#icon-trash5"></use>
                                                            </svg>
                                                        </b-button>
                                                    </div>
                                                </div>
                                                <div v-if="item.isShowDetail" class="item-dai-ly-body">
                                                    <div>
                                                        <label>Bao Lô:</label>
                                                        <label>
                                                            {{ `Tiền TĐ: ${item.soTienBaoToiDa}` }}
                                                        </label>
                                                        <label>
                                                            {{ `Con TĐ: ${item.soConToiDa}` }}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>Đá Thẳng:</label>
                                                        <label>
                                                            {{ `Tiền TĐ: ${item.soTienDaToiDa}` }}
                                                        </label>
                                                        <label>
                                                            {{ `Cặp TĐ: ${item.soCapToiDa}` }}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>Đá Xiên</label>
                                                        <label>
                                                            {{ `Tiền TĐ: ${item.soTienXienToiDa}` }}
                                                        </label>
                                                        <label>
                                                            {{ `Cặp TĐ: ${item.soXienToiDa}` }}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>Xỉu Chủ</label>
                                                        <label>
                                                            {{ `Tiền TĐ: ${item.soTienXiuChuToiDa}` }}
                                                        </label>
                                                        <label>
                                                            {{ `Cặp TĐ: ${item.soXiuChuToiDa}` }}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>Đầu</label>
                                                        <label>
                                                            {{ `Tiền TĐ: ${item.soTienDauToiDa}` }}
                                                        </label>
                                                        <label>
                                                            {{ `Cặp TĐ: ${item.soDauToiDa}` }}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>Đuôi</label>
                                                        <label>
                                                            {{ `Tiền TĐ: ${item.soTienDuoiToiDa}` }}
                                                        </label>
                                                        <label>
                                                            {{ `Cặp TĐ: ${item.soDuoiToiDa}` }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <!-- them hoac sua dai ly -->
                            <template v-if="isAddOrEditDaiLy">
                                <div class="title-dai-ly">
                                    <h5>Thêm Đại Lý</h5>
                                </div>
                                <div class="group-input-dai-ly w-100 mt-1">
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Tên</div>
                                        <b-input-group size="lg">
                                            <b-form-input type="text" v-model="daiLy.tenDaiLy"></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Bao Lô</div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Tiền Tối Đa</span>
                                            <b-input-group size="lg" class=" mr-2">
                                                <b-form-input type="number" v-model="daiLy.soTienBaoToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Con Tối Đa</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="daiLy.soConToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Đá Thẳng</div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Tiền Tối Đa</span>
                                            <b-input-group size="lg" class=" mr-2">
                                                <b-form-input type="number" v-model="daiLy.soTienDaToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Cặp Tối Đa</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="daiLy.soCapToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Đá Xiên</div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Tiền Tối Đa</span>
                                            <b-input-group size="lg" class=" mr-2">
                                                <b-form-input type="number" v-model="daiLy.soTienXienToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Cặp Tối Đa</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="daiLy.soXienToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Xỉu Chủ</div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Tiền Tối Đa</span>
                                            <b-input-group size="lg" class=" mr-2">
                                                <b-form-input type="number"
                                                    v-model="daiLy.soTienXiuChuToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <!-- <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Cặp Tối Đa</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="daiLy.soXiuChuToiDa"></b-form-input>
                                            </b-input-group>
                                        </div> -->
                                    </div>
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Đầu</div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Tiền Tối Đa</span>
                                            <b-input-group size="lg" class=" mr-2">
                                                <b-form-input type="number" v-model="daiLy.soTienDauToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Cặp Tối Đa</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="daiLy.soDauToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="group-item-filter">
                                        <div class="span-nowrap mr-2">Đuôi</div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Tiền Tối Đa</span>
                                            <b-input-group size="lg" class=" mr-2">
                                                <b-form-input type="number" v-model="daiLy.soTienDuoiToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div class="group-item-filter">
                                            <span class="span-nowrap mr-2">Cặp Tối Đa</span>
                                            <b-input-group size="lg">
                                                <b-form-input type="number" v-model="daiLy.soDuoiToiDa"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <!-- action dai ly -->
                        <div v-if="!isAddOrEditDaiLy" class="button-action text-right mt-2">
                            <b-button variant="success" @click="taoTinNhan()">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Tin Nhắn</span>
                            </b-button>
                        </div>
                        <div v-if="isAddOrEditDaiLy" class="button-action text-right mt-2">
                            <b-button variant="danger" @click="huyDaiLy()">
                                <svg class="icon icon-close-square5">
                                    <use xlink:href="#icon-close-square5"></use>
                                </svg>
                                <span class="ml-2">Hủy</span>
                            </b-button>
                            <b-button variant="primary" @click="taoOrSuaDaiLy()">
                                <svg class="icon icon-add-square5">
                                    <use xlink:href="#icon-add-square5"></use>
                                </svg>
                                <span class="ml-2">{{ daiLy.id ? 'Lưu' : 'Thêm' }}</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="dataTaoTinNhan.length > 0">
                    <b-col offset="1" cols="10">
                        <h5 class="mb-2">Tin Nhắn Đã Tạo</h5>
                        <div class="group-tin-nhan">
                            <div v-for="(item, index) in dataTaoTinNhan" :key="'tn-' + index" class="item-tin-nhan">
                                <b-button class="btn-copy" variant="primary" @click="copyTinNhan(getTextTinNhan(item))">
                                    <svg class="icon icon-copy4">
                                        <use xlink:href="#icon-copy4"></use>
                                    </svg>
                                </b-button>
                                <div class="text-tin-nhan">
                                    {{ getTextTinNhan(item) }}
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loto from "../../../service/loto.service";

export default {
    name: "TaoSo",
    data() {
        return {
            title: "TẠO SỐ",
            backTo: "menu",
            showTab: "3",
            numbersTaoSo: "",
            giaTien: "",
            filterNumbers: "",
            soPhan: 1,
            dataTaoSo: [
                {
                    name: "Xiên 2",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: "Xiên 3",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: "Xiên 4",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: "Xáo Vị Trí",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
            ],
            dataVong: [
                {
                    name: "Vòng 2",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 3",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 4",
                    listNumber: [],
                    listNumberGoc: [],
                },
            ],
            isDisableBtn: false,
            isShowVong: false,
            dauCach: ",",
            daySo: "",
            daySoBanDau: "",
            giaTienCanXoa: "",
            loaiTaoVong: "4",
            // mode tao tn
            valueVung: "MIEN_NAM",
            dataTaoTinNhan: [],
            modeTaoTN: {
                codeKey: 1,
                codeName: "Theo Dãy Số",
            },
            taoTinNhanOptions: [
                {
                    codeKey: 1,
                    codeName: "Theo Dãy Số",
                },
                {
                    codeKey: 2,
                    codeName: "Thêm 0 - 9",
                }
            ],
            daySoMau: "",
            soTienMau: "",
            listLoaiChoi: [],
            listDaiLy: [],
            listDaiLyDaChon: [],
            daiLy: {
                isSelected: false,
                tenDaiLy: "",
                soTienBaoToiDa: "",
                soConToiDa: "",
                soTienDaToiDa: "",
                soCapToiDa: "",
                soTienXienToiDa: "",
                soXienToiDa: "",
                soTienXiuChuToiDa: "",
                soXiuChuToiDa: "",
                soTienDauToiDa: "",
                soDauToiDa: "",
                soTienDuoiToiDa: "",
                soDuoiToiDa: "",
            },
            isAddOrEditDaiLy: false,
            soTienXac: "",
        };
    },
    computed: {
        ...mapState("TaoSo", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.changeTab(this.showTab);
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    watch: {},
    methods: {
        ...mapActions("TaoSo", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getListTinh() {
            this.listLoaiChoi = [
                {
                    type: "BAO_LO",
                    code: "bao",
                    label: "Bao lô",
                    numbers: "",
                    soTien: "",
                    listTinh: [],
                    isSelected: false,
                    visible: true,
                },
                {
                    type: "DA_THANG",
                    code: "da",
                    label: "Đá thẳng",
                    numbers: "",
                    soTien: "",
                    listTinh: [],
                    isSelected: false,
                    visible: true,
                },
                {
                    type: "DA_XIEN",
                    code: "dx",
                    label: "Đá xiên",
                    numbers: "",
                    soTien: "",
                    listTinh: [],
                    isSelected: false,
                    visible: this.valueVung === "MIEN_NAM",
                },
                {
                    type: "XIU_CHU",
                    code: "xc",
                    label: "Xỉu chủ",
                    numbers: "",
                    soTien: "",
                    listTinh: [],
                    isSelected: false,
                    isDao: false,
                    visible: true,
                },
                {
                    type: "DAU",
                    code: "dau",
                    label: "Đầu",
                    numbers: "",
                    soTien: "",
                    listTinh: [],
                    isSelected: false,
                    visible: true,
                },
                {
                    type: "DUOI",
                    code: "duoi",
                    label: "Đuôi",
                    numbers: "",
                    soTien: "",
                    listTinh: [],
                    isSelected: false,
                    visible: true,
                },
            ];
            this.dataVong = [
                {
                    name: "Vòng 2",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 3",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 4",
                    listNumber: [],
                    listNumberGoc: [],
                },
            ];
            let thuTrongTuan = this.getFormatDate(this.getRangeDate('homNay')[0], 'formatDay').replace('T', '');
            let _this = this;
            Loto.getListTinh(_this.$root.url)
                .then((response) => {
                    for (const loaiChoi of _this.listLoaiChoi) {
                        for (const item of response.data) {
                            if (item.mien === _this.valueVung &&
                                (item.inWeek.includes(thuTrongTuan) || item.inWeek === "8" && thuTrongTuan === "CN")) {
                                loaiChoi.listTinh.push({
                                    id: item.id,
                                    name: item.name,
                                    isSelected: false,
                                })
                            }
                        }
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        listAgent() {
            let _this = this;
            Loto.listAgent(_this.$root.url)
                .then((response) => {
                    _this.listDaiLy = response.data.map((item) => {
                        item.isSelected = false;
                        item.isShowDetail = false;
                        return item;
                    });
                    _this.listDaiLyDaChon = _this.listDaiLy.filter((item) => item.isSelected);
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        saveAgent(data) {
            let _this = this;
            Loto.saveAgent(_this.$root.url, data)
                .then((response) => {
                    console.log(response.data);
                    _this.listAgent();
                    if (data.isSelected) {
                        _this.dataTaoTinNhan = [];
                        _this.isAddOrEditDaiLy = false;
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        deleteAgent(data) {
            let _this = this;
            Loto.deleteAgent(_this.$root.url, data)
                .then((response) => {
                    console.log(response.data);
                    _this.listAgent();
                    if (data.isSelected) {
                        _this.dataTaoTinNhan = [];
                        _this.isAddOrEditDaiLy = false;
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        changeTab(tab) {
            this.showTab = tab;
            switch (tab) {
                case "1":
                    this.title = "TẠO SỐ";
                    this.numbersTaoSo = "";
                    this.onChangeNumbersTaoSo();
                    break;
                case "2":
                    this.title = "TẠO BỘ SỐ";
                    this.daySo = "";
                    this.onChangeDaySo();
                    break;
                case "3":
                    this.title = "TẠO TIN NHẮN";
                    this.numbers = "";
                    this.getListTinh();
                    this.listAgent();
                    break;
            }
        },
        onChangeNumbersTaoSo() {
            if (this.numbersTaoSo) {
                let numbersTaoSo = this.numbersTaoSo
                    .replaceAll(", ", ",")
                    .replaceAll(" ", ",")
                    .replaceAll(".", ",")
                    .replace(/[^\d,]/g, "");
                this.numbersTaoSo = Array.from(new Set(numbersTaoSo.split(",")))
                    .filter(Boolean)
                    .join(",");
            }
            this.dataTaoSo = [
                {
                    name: "Xiên 2",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: "Xiên 3",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: "Xiên 4",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: "Xáo Vị Trí",
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
            ];
            this.dataVong = [
                {
                    name: "Vòng 2",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 3",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 4",
                    listNumber: [],
                    listNumberGoc: [],
                },
            ];
        },
        onChangeFilterNumbers() {
            let filterNumbers = this.filterNumbers
                .replaceAll(".", this.dauCach)
                .replaceAll(" ", ";")
                .replace(/[^\d,.;]/g, "");
            this.filterNumbers = Array.from(new Set(filterNumbers.split(";")))
                .filter(Boolean)
                .join(";");

            for (const [index, item] of this.dataTaoSo.entries()) {
                if (item.listNumberTheoThuTu.length && item.visible) {
                    this.taoSo(index);
                }
            }
        },
        onChangeSoPhan() {
            if (this.soPhan) {
                for (const item of this.dataTaoSo) {
                    if (item.listNumberTheoThuTu.length) {
                        item.page = 1;
                        item.soLuongMoiPhan = Math.ceil(
                            item.listNumberTheoThuTu.length / this.soPhan
                        );
                        item.listNumberGoc = [];
                        let arr = [];
                        for (const [idx1, it] of item.listNumberTheoThuTu.entries()) {
                            arr.push(it);
                            if (arr.length === item.soLuongMoiPhan) {
                                item.listNumberGoc.push({
                                    selected: false,
                                    arr: this.cloneDeep(arr),
                                });
                                arr = [];
                            } else if (idx1 === item.listNumberTheoThuTu.length - 1) {
                                item.listNumberGoc.push({
                                    selected: false,
                                    arr: this.cloneDeep(arr),
                                });
                            }
                        }
                        this.onChangePage(item);
                    } else {
                        item.listNumberGoc = [];
                        item.listNumber = [];
                    }
                }
            }
        },
        onChangeDaySo() {
            console.log(this.daySo);
        },
        onChangeGiaTienCanXoa() {
            let giaTienCanXoa = this.giaTienCanXoa.replaceAll(" ", ";");
            this.giaTienCanXoa = Array.from(new Set(giaTienCanXoa.split(";")))
                .filter(Boolean)
                .join(";");
        },
        onChangeVung() {
            this.getListTinh();
        },
        onChangeNumbersTaoTinNhan(item) {
            if (item.numbers) {
                let numbers = item.numbers
                    .replaceAll(", ", ",")
                    .replaceAll(" ", ",")
                    .replaceAll(".", ",")
                    .replace(/[^\d,]/g, "");
                numbers = Array.from(new Set(numbers.split(",")))
                    .filter(Boolean)
                    .join(",");
                item.numbers = numbers;
            }
            this.dataTaoTinNhan = [];
        },
        onChangePage(item) {
            item.listNumber = [];
            for (
                var i = (item.page - 1) * item.pageSize;
                i < item.page * item.pageSize && i < item.listNumberGoc.length;
                i++
            ) {
                item.listNumber.push(item.listNumberGoc[i]);
            }
        },
        onChangeDaySoMau() {
            let numbers = this.daySoMau
                .replaceAll(", ", ",")
                .replaceAll(" ", ",")
                .replaceAll(".", ",")
                .replace(/[^\d,]/g, "");
            this.daySoMau = Array.from(new Set(numbers.split(",")))
                .filter(Boolean)
                .join(",");
            for (const item of this.listLoaiChoi) {
                if (this.modeTaoTN.codeKey === this.taoTinNhanOptions[0].codeKey) {
                    item.numbers = this.daySoMau;
                } else {
                    let numbers = [];
                    for (const number of this.daySoMau.split(",")) {
                        for (let index = 0; index < 10; index++) {
                            numbers.push(index.toString() + number.toString());
                        }
                    }
                    item.numbers = numbers.join(",");
                    console.log(numbers);
                }
            }
            this.dataTaoTinNhan = [];
        },
        onChangeSoTienMau() {
            for (const item of this.listLoaiChoi) {
                item.soTien = this.soTienMau;
            }
            this.dataTaoTinNhan = [];
        },
        changeLoaiChoi(loaiChoi) {
            loaiChoi.isSelected = !loaiChoi.isSelected;
            for (const [index, item] of loaiChoi.listTinh.entries()) {
                if (index) {
                    item.isSelected = false;
                } else {
                    item.isSelected = true;
                }
            }
            this.dataTaoTinNhan = [];
        },
        changeLoaiChoiTinh(tinh) {
            tinh.isSelected = !tinh.isSelected;
            this.dataTaoTinNhan = [];
        },
        taoSo(index) {
            if (!this.numbersTaoSo || this.isDisableBtn) {
                return;
            }
            this.isShowVong = false;
            this.loaiTaoVong = "4";
            for (const [idx, item] of this.dataTaoSo.entries()) {
                item.visible = idx === index;
            }
            let arrNumbers = this.numbersTaoSo.split(",");
            let length = arrNumbers.length;

            // tạo xien 2
            let itemXien2 = this.dataTaoSo[0];
            if (itemXien2.visible) {
                itemXien2.listNumberTheoThuTu = [];
                let arrNumbersXien2 = this.k_combinations(arrNumbers, 2);
                for (const item of arrNumbersXien2) {
                    if (!this.filterNumbers.includes(item.join(this.dauCach))) {
                        itemXien2.listNumberTheoThuTu.push(item.join(this.dauCach));
                    }
                }
                itemXien2.listNumberPhucHoi = this.cloneDeep(itemXien2.listNumberTheoThuTu);
            }

            // tạo xien 3
            let itemXien3 = this.dataTaoSo[1];
            if (itemXien3.visible) {
                itemXien3.listNumberTheoThuTu = [];
                let arrNumbersXien3 = this.k_combinations(arrNumbers, 3);
                for (const item of arrNumbersXien3) {
                    if (!this.filterNumbers.includes(item.join(this.dauCach))) {
                        itemXien3.listNumberTheoThuTu.push(item.join(this.dauCach));
                    }
                }
                itemXien3.listNumberPhucHoi = this.cloneDeep(itemXien3.listNumberTheoThuTu);
            }

            // tạo xien 4
            let itemXien4 = this.dataTaoSo[2];
            if (itemXien4.visible) {
                itemXien4.listNumberTheoThuTu = [];
                let arrNumbersXien4 = this.k_combinations(arrNumbers, 4);
                for (const item of arrNumbersXien4) {
                    if (!this.filterNumbers.includes(item.join(this.dauCach))) {
                        itemXien4.listNumberTheoThuTu.push(item.join(this.dauCach));
                    }
                }
                itemXien4.listNumberPhucHoi = this.cloneDeep(itemXien4.listNumberTheoThuTu);
            }

            // tạo xáo vị trí
            let itemXaoViTri = this.dataTaoSo[3];
            if (itemXaoViTri.visible) {
                itemXaoViTri.listNumberTheoThuTu = [];
                itemXaoViTri.listNumberPhucHoi = [];
                while (itemXaoViTri.listNumberTheoThuTu.length < length) {
                    let randomItem = this.cloneDeep(arrNumbers[Math.floor(Math.random() * length)]);
                    if (!itemXaoViTri.listNumberTheoThuTu.includes(randomItem)) {
                        itemXaoViTri.listNumberTheoThuTu.push(randomItem);
                    }
                }
                let listSorted = this.cloneDeep(arrNumbers);
                listSorted.sort((a, b) => {
                    let numberA = Number(a);
                    let numberB = Number(b);
                    if (numberA < numberB) {
                        return -1;
                    }
                    if (numberA > numberB) {
                        return 1;
                    }
                    return 0;
                });
                for (const item of listSorted) {
                    itemXaoViTri.listNumberPhucHoi.push(item);
                }
            }

            this.onChangeSoPhan();
        },
        k_combinations(set, k) {
            let i, j, combs, head, tailcombs;
            if (k > set.length || k <= 0) {
                return [];
            }
            if (k == set.length) {
                return [set];
            }
            if (k == 1) {
                combs = [];
                for (i = 0; i < set.length; i++) {
                    combs.push([set[i]]);
                }
                return combs;
            }
            combs = [];
            for (i = 0; i < set.length - k + 1; i++) {
                head = set.slice(i, i + 1);
                tailcombs = this.k_combinations(set.slice(i + 1), k - 1);
                for (j = 0; j < tailcombs.length; j++) {
                    combs.push(head.concat(tailcombs[j]));
                }
            }
            return combs;
        },
        taoVong(loaiTaoVong) {
            if (!this.numbersTaoSo || this.isDisableBtn) {
                return;
            }
            this.isShowVong = true;
            this.dataVong = [
                {
                    name: "Vòng 2",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 3",
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: "Vòng 4",
                    listNumber: [],
                    listNumberGoc: [],
                },
            ];
            if (loaiTaoVong) {
                this.loaiTaoVong = loaiTaoVong;
            }
            if (this.loaiTaoVong === "3") {
                this.taoVong3();
            }
            if (this.loaiTaoVong === "4") {
                this.taoVong4();
            }
        },
        taoVong3() {
            let arrNumbers = this.numbersTaoSo.split(",");
            let arrXien2 = this.k_combinations(arrNumbers, 2);
            let arrXien3 = this.k_combinations(arrNumbers, 3);
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            for (const item of arrXien3) {
                if (
                    this.checkSo(item[0], item[1], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[2], arr2, arr3, arr4)
                ) {
                    arr3.push(item);
                }
            }
            for (const item of arrXien2) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4)) {
                    arr2.push(item);
                }
            }

            this.dataVong[0].listNumber = this.cloneDeep(arr2).map((i) => i.join(this.dauCach));
            this.dataVong[1].listNumber = this.cloneDeep(arr3).map((i) => i.join(this.dauCach));
            this.dataVong[2].listNumber = this.cloneDeep(arr4).map((i) => i.join(this.dauCach));
            this.dataVong[0].listNumberGoc = this.cloneDeep(this.dataVong[0].listNumber);
            this.dataVong[1].listNumberGoc = this.cloneDeep(this.dataVong[1].listNumber);
            this.dataVong[2].listNumberGoc = this.cloneDeep(this.dataVong[2].listNumber);
        },
        taoVong4() {
            let arrNumbers = this.numbersTaoSo.split(",");
            let arrXien2 = this.k_combinations(arrNumbers, 2);
            let arrXien3 = this.k_combinations(arrNumbers, 3);
            let arrXien4 = this.k_combinations(arrNumbers, 4);
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            for (const item of arrXien4) {
                if (
                    this.checkSo(item[0], item[1], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[3], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[3], arr2, arr3, arr4) &&
                    this.checkSo(item[2], item[3], arr2, arr3, arr4)
                ) {
                    arr4.push(item);
                }
            }
            for (const item of arrXien3) {
                if (
                    this.checkSo(item[0], item[1], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[2], arr2, arr3, arr4)
                ) {
                    arr3.push(item);
                }
            }
            for (const item of arrXien2) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4)) {
                    arr2.push(item);
                }
            }

            this.dataVong[0].listNumber = this.cloneDeep(arr2).map((i) => i.join(this.dauCach));
            this.dataVong[1].listNumber = this.cloneDeep(arr3).map((i) => i.join(this.dauCach));
            this.dataVong[2].listNumber = this.cloneDeep(arr4).map((i) => i.join(this.dauCach));
            this.dataVong[0].listNumberGoc = this.cloneDeep(this.dataVong[0].listNumber);
            this.dataVong[1].listNumberGoc = this.cloneDeep(this.dataVong[1].listNumber);
            this.dataVong[2].listNumberGoc = this.cloneDeep(this.dataVong[2].listNumber);
        },
        checkSo(so1, so2, arr2, arr3, arr4) {
            if (!so1 || !so2) {
                return false;
            }
            for (const item of arr2) {
                if (item.includes(so1) && item.includes(so2)) {
                    return false;
                }
            }
            for (const item of arr3) {
                if (item.includes(so1) && item.includes(so2)) {
                    return false;
                }
            }
            for (const item of arr4) {
                if (item.includes(so1) && item.includes(so2)) {
                    return false;
                }
            }
            return true;
        },
        layBoSoBanDau() {
            let arrGiaTienCanXoa = this.giaTienCanXoa.split(";");
            let daySo = this.daySo;
            for (const item of arrGiaTienCanXoa) {
                daySo = daySo.replaceAll(item, "");
            }
            daySo = daySo
                .replaceAll("\n", ",")
                .replaceAll(", ", ",")
                .replaceAll(" ", ",")
                .replaceAll(".", ",")
                .replace(/[^\d,]/g, "");
            this.daySoBanDau = Array.from(new Set(daySo.split(",")))
                .filter(Boolean)
                .sort((a, b) => {
                    let numberA = Number(a);
                    let numberB = Number(b);
                    if (numberA < numberB) {
                        return -1;
                    }
                    if (numberA > numberB) {
                        return 1;
                    }
                    return 0;
                });
        },
        shuffleDaySo(item) {
            if (this.isDisableBtn) {
                return;
            }
            let returnData = [];
            if (this.isShowVong) {
                let arrNumbers = this.cloneDeep(item.listNumber);
                while (returnData.length < arrNumbers.length) {
                    let randomItem = arrNumbers[Math.floor(Math.random() * arrNumbers.length)];
                    if (!returnData.includes(randomItem)) {
                        returnData.push(randomItem);
                    }
                }
                item.listNumber = this.cloneDeep(returnData);
            } else {
                let arrNumbers = this.cloneDeep(item.listNumberPhucHoi);
                while (returnData.length < arrNumbers.length) {
                    let randomItem = arrNumbers[Math.floor(Math.random() * arrNumbers.length)];
                    if (!returnData.includes(randomItem)) {
                        returnData.push(randomItem);
                    }
                }
                item.listNumberTheoThuTu = this.cloneDeep(returnData);
                this.onChangeSoPhan();
            }
        },
        copySo(data, index) {
            let textToCopy = "";
            if (this.showTab === "2") {
                textToCopy = this.daySoBanDau.join(",");
            } else if (this.isShowVong) {
                for (const item of data.listNumber) {
                    textToCopy += this.giaTien ? `${item} ${this.giaTien}.\n` : `${item}\n`;
                }
                this.showToast(`Đã copy số vòng ${index + 2}`, "Thông Báo", this, "success");
            } else {
                let isCoChonItem = false;
                for (const item of data.listNumber) {
                    if (item.selected && index === 3) {
                        textToCopy += this.giaTien
                            ? `${item.arr.join(",")} ${this.giaTien}.\n`
                            : `${item.arr.join(",")}\n`;
                        isCoChonItem = true;
                    } else if (item.selected) {
                        for (const it of item.arr) {
                            textToCopy += this.giaTien ? `${it} ${this.giaTien}.\n` : `${it}\n`;
                        }
                        isCoChonItem = true;
                    }
                }
                if (!isCoChonItem) {
                    if (index === 3) {
                        for (const it of data.listNumberTheoThuTu) {
                            textToCopy += this.giaTien ? `${it} ${this.giaTien}.\n` : `${it}\n`;
                        }
                    } else {
                        for (const item of data.listNumber) {
                            for (const it of item.arr) {
                                textToCopy += this.giaTien ? `${it} ${this.giaTien}.\n` : `${it}\n`;
                            }
                        }
                    }
                }
                if (isCoChonItem) {
                    this.showToast(`Đã copy số ${data.name} đã chọn`, "Thông Báo", this, "success");
                } else {
                    this.showToast(`Đã copy tất cả số ${data.name}`, "Thông Báo", this, "success");
                }
            }
            navigator.clipboard.writeText(textToCopy);
        },
        sapXepTheoThuTu(item) {
            if (this.isDisableBtn) {
                return;
            }
            if (this.isShowVong) {
                item.listNumber = this.cloneDeep(item.listNumberGoc);
            } else {
                item.listNumberTheoThuTu = this.cloneDeep(item.listNumberPhucHoi);
                item.page = 1;
                this.onChangeSoPhan();
            }
        },
        khoaNut() {
            this.isDisableBtn = !this.isDisableBtn;
        },
        tinhXien2(number, length) {
            let ketQua = 0;
            switch (length) {
                case 2:
                    ketQua = number;
                    break;
                case 3:
                    ketQua = number * 3;
                    break;
                case 4:
                    ketQua = number * 6;
                    break;
            }
            return ketQua;
        },
        chonDaiLy(daiLy) {
            daiLy.isSelected = !daiLy.isSelected
            this.listDaiLyDaChon = this.listDaiLy.filter((item) => item.isSelected);
        },
        taoOrSuaDaiLy() {
            this.saveAgent(this.daiLy);
            this.resetObjDaiLy();
            this.isAddOrEditDaiLy = false;
        },
        huyDaiLy() {
            this.resetObjDaiLy();
            this.isAddOrEditDaiLy = false;
        },
        suaDaiLy(daiLy) {
            this.daiLy = this.cloneDeep(daiLy);
            this.isAddOrEditDaiLy = true;
        },
        xoaDaiLy(daiLy) {
            this.deleteAgent(daiLy);
        },
        resetObjDaiLy() {
            this.daiLy = {
                isSelected: false,
                tenDaiLy: "",
                soTienBaoToiDa: "",
                soConToiDa: "",
                soTienDaToiDa: "",
                soCapToiDa: "",
                soTienXienToiDa: "",
                soXienToiDa: "",
                soTienXiuChuToiDa: "",
                soXiuChuToiDa: "",
                soTienDauToiDa: "",
                soDauToiDa: "",
                soTienDuoiToiDa: "",
                soDuoiToiDa: "",
            }
        },
        taoTinNhan() {
            // cú pháp là xx ( ss ) yy zz
            // xx : đài
            // ss : số muốn chơi
            // yy: loại chơi
            // zz : tiền
            if (this.listLoaiChoi.filter((i) => i.isSelected).length === 0) {
                this.showToast("Vui lòng chọn cách đánh", "Thông Báo", this, "warning");
                return;
            }
            if (this.listDaiLyDaChon.length === 0) {
                this.showToast("Vui lòng chọn Đại Lý", "Thông Báo", this, "warning");
                return;
            }
            this.dataTaoTinNhan = [];
            let tienDu = 0;
            let tienDuBao = 0;
            let tienDuDa = 0;
            let tienDuXien = 0;
            let tienDuXiuChu = 0;
            let tienDuDau = 0;
            let tienDuDuoi = 0;
            let from = 0;
            let to = 0;
            let listDaAdd = [];
            for (const [indexDaiLy, daiLy] of this.listDaiLyDaChon.entries()) {
                let arr = [];
                for (const loaiChoi of this.listLoaiChoi) {
                    if (loaiChoi.isSelected) {
                        let daiDaChon = loaiChoi.listTinh.filter((tinh) => tinh.isSelected);
                        if (daiDaChon.length === 0) {
                            this.showToast(`Vui lòng chọn Đài cho ${loaiChoi.label}`, "Thông Báo", this, "warning");
                            continue;
                        }

                        // get tien
                        let soTien = loaiChoi.soTien ? Number(loaiChoi.soTien) : 0;
                        let soTienToiDa = daiLy.soTienToiDa ? Number(daiLy.soTienToiDa) : 0;
                        switch (loaiChoi.type) {
                            case 'BAO_LO':
                                tienDu = tienDuBao;
                                soTienToiDa = Number(daiLy.soTienBaoToiDa);
                                break;
                            case 'DA_THANG':
                                tienDu = tienDuDa;
                                soTienToiDa = Number(daiLy.soTienDaToiDa);
                                break;
                            case 'DA_XIEN':
                                tienDu = tienDuXien;
                                tienDuXien = Number(daiLy.soTienXienToiDa);
                                break;
                            case 'XIU_CHU':
                                tienDu = tienDuXiuChu;
                                tienDuXiuChu = Number(daiLy.soTienXiuChuToiDa);
                                break;
                            case 'DAU':
                                tienDu = tienDuDau;
                                tienDuDau = Number(daiLy.soTienDauToiDa);
                                break;
                            case 'DUOI':
                                tienDu = tienDuDuoi;
                                tienDuDuoi = Number(daiLy.soTienDuoiToiDa);
                                break;
                        }
                        if (soTien > soTienToiDa && soTienToiDa) {
                            daiLy.tien = soTienToiDa;
                            tienDu += soTien - soTienToiDa;
                        } else if (soTien < soTienToiDa && soTienToiDa) {
                            if (soTien + tienDu > soTienToiDa) {
                                daiLy.tien = soTienToiDa;
                                tienDu = (tienDu + soTien) - soTienToiDa;
                            } else {
                                daiLy.tien = soTien + tienDu;
                                tienDu = 0;
                            }
                        } else {
                            daiLy.tien = soTien;
                        }
                        // cap nhat tien du
                        switch (loaiChoi.type) {
                            case 'BAO_LO':
                                tienDuBao = tienDu;
                                break;
                            case 'DA_THANG':
                                tienDuDa = tienDu;
                                break;
                            case 'DA_XIEN':
                                tienDuXien = tienDu;
                                break;
                            case 'XIU_CHU':
                                tienDuXiuChu = tienDu;
                                break;
                            case 'DAU':
                                tienDuDau = tienDu;
                                break;
                            case 'DUOI':
                                tienDuDuoi = tienDu;
                                break;
                        }

                        // get dai
                        let dai = "";
                        for (const [indexDai, tinhDai] of daiDaChon.entries()) {
                            dai += tinhDai.id.toLowerCase();
                            if (indexDai < daiDaChon.length - 1) {
                                dai += "-";
                            } else {
                                dai += " " + daiDaChon.length + "d";
                            }
                        }

                        // get numbers
                        let numbersOrigin;
                        let numbers;
                        let max;
                        switch (loaiChoi.type) {
                            case 'BAO_LO':
                                numbersOrigin = loaiChoi.numbers.split(',');
                                max = daiLy.soConToiDa ? daiLy.soConToiDa : 4;
                                break;
                            case 'DA_THANG':
                                this.numbersTaoSo = loaiChoi.numbers;
                                this.onChangeNumbersTaoSo();
                                this.taoVong('3');
                                numbersOrigin = [].concat(this.dataVong[1].listNumber, this.dataVong[0].listNumber);
                                max = daiLy.soCapToiDa ? daiLy.soCapToiDa : 4;
                                break;
                            case 'DA_XIEN':
                                numbersOrigin = loaiChoi.numbers.split(',');
                                max = daiLy.soXienToiDa ? daiLy.soXienToiDa : 4;
                                break;
                            case 'XIU_CHU':
                                if (loaiChoi.isDao) {
                                    numbers = this.daoSo(loaiChoi.numbers);
                                } else {
                                    numbers = loaiChoi.numbers.split(',');
                                }
                                break;
                            case 'DAU':
                                numbersOrigin = loaiChoi.numbers.split(',');
                                max = daiLy.soDauToiDa ? daiLy.soDauToiDa : 4;
                                break;
                            case 'DUOI':
                                numbersOrigin = loaiChoi.numbers.split(',');
                                max = daiLy.soDuoiToiDa ? daiLy.soDuoiToiDa : 4;
                                break;
                        }
                        if (numbersOrigin && numbersOrigin.length === 0) {
                            this.dataTaoTinNhan = [];
                            return;
                        } else if (loaiChoi.type === 'DA_THANG') {
                            let count = 0;
                            numbers = [];
                            for (const itemNumber of numbersOrigin) {
                                if (listDaAdd.includes(itemNumber)) {
                                    continue;
                                }
                                count += itemNumber.split(",").length === 2 ? 1 : 3;
                                if (count < Number(max)) {
                                    numbers.push(itemNumber);
                                    listDaAdd.push(itemNumber);
                                } else if (count === Number(max)) {
                                    numbers.push(itemNumber);
                                    listDaAdd.push(itemNumber);
                                    break;
                                } else {
                                    count -= itemNumber.split(",").length === 2 ? 1 : 3;
                                    continue;
                                }
                            }
                        } else if (typeof max !== "undefined") {
                            from = to ? Number(to) + 1 : indexDaiLy;
                            to = (Number(from) + Number(max)) - 1;
                            numbers = this.getElementsInRange(numbersOrigin, from, to);
                        }
                        for (const [indexDai, tinhDai] of daiDaChon.entries()) {
                            switch (loaiChoi.type) {
                                case 'BAO_LO':
                                    arr.push({
                                        type: loaiChoi.type,
                                        dai: tinhDai.id.toLowerCase(),
                                        numbers: numbers,
                                        code: loaiChoi.code,
                                        money: daiLy.tien,
                                        tenDaiLy: daiLy.tenDaiLy,
                                    })
                                    break;
                                case 'DA_THANG':
                                    arr.push({
                                        type: loaiChoi.type,
                                        dai: tinhDai.id.toLowerCase(),
                                        numbers: numbers,
                                        code: loaiChoi.code,
                                        money: daiLy.tien,
                                        tenDaiLy: daiLy.tenDaiLy,
                                    })
                                    break;
                                case 'DA_XIEN':
                                    if (indexDai === daiDaChon.length - 1) {
                                        arr.push({
                                            type: loaiChoi.type,
                                            dai: dai,
                                            numbers: numbers,
                                            code: loaiChoi.code,
                                            money: daiLy.tien,
                                            tenDaiLy: daiLy.tenDaiLy,
                                        })
                                    }
                                    break;
                                case 'XIU_CHU':
                                    arr.push({
                                        type: loaiChoi.type,
                                        dai: tinhDai.id.toLowerCase(),
                                        numbers: numbers,
                                        code: loaiChoi.code,
                                        money: daiLy.tien,
                                        tenDaiLy: daiLy.tenDaiLy,
                                    })
                                    break;
                                case 'DAU':
                                    arr.push({
                                        type: loaiChoi.type,
                                        dai: tinhDai.id.toLowerCase(),
                                        numbers: numbers,
                                        code: loaiChoi.code,
                                        money: daiLy.tien,
                                        tenDaiLy: daiLy.tenDaiLy,
                                    })
                                    break;
                                case 'DUOI':
                                    arr.push({
                                        type: loaiChoi.type,
                                        dai: tinhDai.id.toLowerCase(),
                                        numbers: numbers,
                                        code: loaiChoi.code,
                                        money: daiLy.tien,
                                        tenDaiLy: daiLy.tenDaiLy,
                                    })
                                    break;
                            }
                        }
                    }
                }
                if (arr.length > 0) {
                    this.dataTaoTinNhan.push(this.cloneDeep(arr));
                }
            }
        },
        daoSo(number) {
            const digits = number.split(''); // Convert number to an array of digits
            const combinations = [];

            // Recursive function to generate combinations
            function generateCombinations(currentCombination, remainingDigits) {
                if (currentCombination.length === 3) {
                    if (!combinations.includes(currentCombination.join(""))) {
                        combinations.push(currentCombination.join(""));
                        return;
                    }
                }

                for (let i = 0; i < remainingDigits.length; i++) {
                    const newCombination = [...currentCombination, remainingDigits[i]];
                    const newRemainingDigits = remainingDigits.filter((_, index) => index !== i);
                    generateCombinations(newCombination, newRemainingDigits);
                }
            }

            generateCombinations([], digits);
            return combinations;
        },
        shuffleArray(array) {
            // Hàm ngẫu nhiên để sử dụng trong sort
            function randomSort() {
                return Math.random() - 0.5;
            }

            // Sử dụng sort với hàm ngẫu nhiên để xáo trộn mảng
            const shuffledArray = array.slice().sort(randomSort);

            return shuffledArray;
        },
        chiaMang(numbers, n, min) {
            // Hàm để tạo mảng con không trùng lặp
            function getRandomSubarray(arr, size) {
                const shuffled = arr.slice(0);
                let i = arr.length;
                while (i--) {
                    const index = Math.floor((i + 1) * Math.random());
                    [shuffled[i], shuffled[index]] = [shuffled[index], shuffled[i]];
                }
                return shuffled.slice(0, size);
            }

            // Tạo n mảng
            const resultArrays = [];
            for (let i = 0; i < n; i++) {
                const randomArray = getRandomSubarray(numbers, min);
                resultArrays.push(randomArray);
            }

            return resultArrays;
        },
        getElementsInRange(numbers, from, to) {
            const arrayLength = numbers.length;

            // Xử lý trường hợp from và to nằm ngoài phạm vi của mảng
            from = Math.max(0, from);
            to = Math.min(to, arrayLength - 1);

            // Lấy phần tử từ mảng theo khoảng from và to
            const result = [];
            for (let i = from; i <= to; i++) {
                result.push(numbers[i % arrayLength]);
            }

            return result;
        },
        getTextTinNhan(data) {
            let text = [];
            for (const tinNhan of data) {
                let dai = this.valueVung === "MIEN_NAM" ? tinNhan.dai : "mb";
                let money = tinNhan.money;
                let numbers;
                switch (tinNhan.type) {
                    case 'BAO_LO':
                    case 'DA_XIEN':
                    case 'XIU_CHU':
                    case 'DAU':
                    case 'DUOI':
                        numbers = tinNhan.numbers;
                        text.push(`${dai} ${numbers.join(".")} ${tinNhan.code}${money}n`);
                        break;
                    case 'DA_THANG':
                        for (const number of tinNhan.numbers) {
                            numbers = this.cloneDeep(number.split(","));
                            numbers = this.cloneDeep(this.shuffleArray(numbers));
                            text.push(`${dai} ${numbers.join(".")} ${tinNhan.code}${money}n`);
                        }
                        break;
                }
            }
            text = this.shuffleArray(text);
            text.unshift(`${data[0].tenDaiLy}`);
            return text.join("\n");
        },
        copyTinNhan(textToCopy) {
            this.showToast(`Đã copy tin nhắn`, "Thông Báo", this, "success");
            navigator.clipboard.writeText(textToCopy);
        },
    },
};
</script>

<style lang="scss">
.style-for-web .tao-so {
    .baseline {
        align-items: baseline;
    }

    .group-ket-qua {
        border: 3px solid var(--header-bao-cao-phan-tich-background);
        border-radius: 8px;
        padding: 10px 3px 3px 3px;
    }

    .group-title-ket-qua {
        display: flex;
        align-items: center;

        .title-icon {
            cursor: pointer;
            transition: all 0.3s;
            color: var(--text-btn-success-color);
            background-color: var(--btn-success-color);
            border: 1px solid var(--btn-success-color);
            border-radius: 6px;
            padding: 5px 7px;

            &:hover {
                color: var(--text-btn-hover);
                background-color: var(--btn-success-color-hover);
                border-color: var(--btn-success-color-hover);
            }

            &.disable-btn {
                opacity: 0.65;
            }
        }
    }

    .group-body-ket-qua {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        >div {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            border-top: 1px solid var(--header-bao-cao-phan-tich-background);
            padding: 5px 0 5px 5px;

            .group-item-number {
                display: flex;
                flex-wrap: wrap;
                overflow: auto;
            }

            .item-number {
                min-width: 100px;
                margin-right: 2px;
                margin-bottom: 2px;
                background-color: var(--modal-input-background);
                border: 1px solid var(--modal-input-border);
                border-radius: 8px;
                color: var(--text-color);
                text-align: center;
            }

            .custom-checkbox {

                input,
                label {
                    cursor: pointer;
                }
            }
        }
    }

    .group-items-dai-ly {
        max-height: 300px;
        overflow: auto;
        border: 3px solid var(--header-bao-cao-phan-tich-background);
        border-radius: 8px;
        margin-top: 2px;

        .icon-tick-circle5 {
            color: var(--btn-success-color);
        }

        >div {
            border-bottom: 1px solid var(--header-bao-cao-phan-tich-background);

            &:last-child {
                border-bottom: none;
            }
        }

        .group-item-dai-ly {
            display: flex;
            flex-wrap: wrap;
            padding: 2px 10px;
            transition: all 0.3s;
            cursor: pointer;

            .group-item-filter {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;

                .btn-sm {
                    padding: 0.25rem;

                    svg {
                        height: 14px;
                        width: 14px;
                    }
                }
            }

            .item-dai-ly-title {
                label {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    height: 24px;
                }
            }

            .item-dai-ly-title,
            .item-dai-ly-body>div {
                position: relative;
                width: 100%;
                font-weight: bold;

                label {
                    margin: 0;
                    font-size: 12px;
                    font-weight: normal;
                    cursor: pointer;

                    &:nth-child(1) {
                        width: 100px;
                    }

                    &:nth-child(2) {
                        width: 150px;
                    }

                    &:nth-child(3) {
                        width: 150px;
                    }
                }
            }

            &:hover {
                background-color: var(--row-table-color-hover);
            }

            label {
                margin: 0;
            }
        }
    }

    .title-dai-ly {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35.5px;

        .group-item-filter {
            padding: 0;
        }
    }

    .group-btn-tao-tin-nhan {
        .row-loai-choi {
            display: flex;
            flex-wrap: wrap;
        }

        .group-input {
            display: flex;

            >div {
                &:nth-child(1) {
                    width: 250px;
                }

                &:nth-child(2) {
                    width: calc(100% - 450px);
                }

                &:nth-child(3) {
                    width: 200px;
                }
            }
        }

        .row-loai-choi {
            .group-input {
                display: flex;

                >div {
                    &:nth-child(1) {
                        width: calc(100% - 200px);
                    }

                    &:nth-child(2) {
                        width: 200px;
                    }
                }
            }
        }

        .group-input-dai-ly {
            display: flex;
            flex-wrap: wrap;
            border: 3px solid var(--header-bao-cao-phan-tich-background);
            border-radius: 8px;
            padding: 5px 10px;

            >div {
                margin-bottom: 0.25rem;
                width: 100%;
                justify-content: left;

                >.span-nowrap {
                    min-width: 115px;
                }

                &:nth-child(1) {
                    width: 100%;
                }

                .group-item-filter {
                    width: calc((100% - 125px) / 2);
                    padding: 0;
                }
            }
        }

        .button-action {
            height: auto;
            width: auto;

            &.button-loai-choi {
                button {
                    min-width: 200px;
                    justify-content: flex-start;
                }
            }

            button {
                height: 35.5px;
            }
        }

        >div {
            &:last-child {
                border-left: 1px solid var(--modal-input-border);
            }
        }
    }

    .group-tin-nhan {
        display: flex;
        flex-wrap: wrap;

        .item-tin-nhan {
            position: relative;
            white-space: pre-line;
            width: calc((100% / 4) - 0.5rem);
            height: 200px;
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
            background-color: var(--modal-input-background);
            border: 3px solid var(--header-bao-cao-phan-tich-background);
            border-radius: 8px;
            color: var(--text-color);
            text-align: left;
            overflow: auto;

            .btn-copy {
                position: sticky;
                left: 85%;
                top: 0;
                padding: 3px 10px;
                border-radius: 0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            .text-tin-nhan {
                margin-top: -30px;
                padding: 5px;
            }
        }
    }

    .mode-tao-so {
        .pagination {
            width: 90%;
            margin: auto;
            background-color: transparent;
        }
    }

    .mode-phuc-hoi-bo-so {
        .pagination {
            width: 90%;
            margin: auto;
            background-color: transparent;
        }
    }
}
</style>
