<template>
    <div id="login" class="login">
        
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AuthRepository from "../../../service/auth.service";

export default {
    name: "LoginPage",
    data() {
        return {
            userName: "",
            password: "",
            isShowPass: false,
            isGhiNhoDangNhap: false,
        };
    },
    computed: {
        ...mapState("LoginPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("LoginPage", []),
        showPass() {
            this.isShowPass = !this.isShowPass;
        },
        submit() {
            let _this = this;
            AuthRepository.login(_this.$root.url, {
                userName: _this.userName,
                password: _this.password,
            })
                .then((response) => {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    localStorage.setItem("userName", response.data.userName);
                    localStorage.setItem("jwt", response.data.token);
                    _this.$root.isLogin = true;
                    _this.movePage("menu");
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
    },
};
</script>

<style lang="scss">
.style-for-mobile .login {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 10px;
    color: var(--text-color);
    background-image: var(--background-image);
    text-align: center;

    .f1-invoice {
        padding: 10px;
        margin: auto;

        >div {
            width: 100%;
            margin-bottom: 15px;
            text-align: center;
            font-weight: bold;

            &:last-child {
                font-size: 16px;
                font-weight: normal;
            }
        }

        img {
            height: 50px;
            width: 50px;
            background: var(--white-color);
            border-radius: 12px;
        }

        .back-right-login {
            margin-top: 15px;
            margin-bottom: 15px;

            img {
                background: transparent;
                width: auto;
                height: 155px;
            }
        }
    }

    .body-box {
        text-align: left;
    }

    .input-login {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        .vs-input-parent {
            width: 80%;

            input {
                width: 100%;
            }
        }
    }

    .con-switch {
        display: flex;
        align-items: center;
        justify-content: center;

        .vs-switch {
            margin-right: 10px;
        }
    }

    .footer-box {

        button {
            width: 60%;
        }

        .footer-label {
            width: 100%;
            justify-content: center;
        }

        .ngay-ket-thuc {
            font-weight: bold;
            font-size: 20px;
        }

        .dem-nguoc {
            border-radius: 18px;
            width: 75%;
            margin: auto;
            justify-content: center;
            align-items: center;
            background-color: var(--dem-nguoc-background);
            padding: 10px 20px;
        }

        .dem-nguoc-ngay,
        .dem-nguoc-gio,
        .dem-nguoc-phut,
        .dem-nguoc-giay {
            div {
                font-weight: bold;
                color: var(--text-nguoc-color);

                &:first-child {
                    font-size: 35px;
                    height: 40px;
                }
            }
        }
    }

    .text-maintenance {
        text-align: center;
    }
}
</style>
