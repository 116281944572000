<template>
    <div id="danh-sach-tai-khoan" class="danh-sach-tai-khoan">
        <pc-header :title="title" :backTo="backTo" />
        <div class="danh-sach-tai-khoan-content">
            <b-row class="mt-3">
                <b-col offset="1" cols="3">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Tài Khoản</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="userName"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="onClickSearchBtn()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                        <b-button variant="primary" @click="onClickAddBtn()">
                            <svg class="icon icon-add-square5">
                                <use xlink:href="#icon-add-square5"></use>
                            </svg>
                            <span class="ml-2">Thêm Tài Khoản</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <div class="table-data">
                        <b-table
                            sticky-header
                            fixed
                            hover
                            small
                            :items="dataTable"
                            :fields="fields"
                            :head-variant="$root.theme"
                            :dark="$root.theme === 'dark'"
                            show-empty
                            empty-text="Không có dữ liệu"
                            :busy="isBusy"
                            :per-page="perPage"
                            :current-page="currentPage"
                        >
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(delFlg)="scope">
                                <span>{{ scope.item.delFlg === 0 ? "Open" : "Close" }}</span>
                            </template>
                            <template #cell(roles)="scope">
                                <div v-for="role in scope.item.roles" :key="role">
                                    <span> {{ role }}</span>
                                </div>
                            </template>
                            <template #cell(action)="scope">
                                <div class="button-action text-left">
                                    <b-button
                                        variant="primary"
                                        @click="onClickEditItemBtn(scope.item)"
                                    >
                                        <svg class="icon icon-edit-25">
                                            <use xlink:href="#icon-edit-25"></use>
                                        </svg>
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        @click="onClickDeleteItemBtn(scope.item)"
                                    >
                                        <svg class="icon icon-trash5">
                                            <use xlink:href="#icon-trash5"></use>
                                        </svg>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="dataTable.length"
                            :per-page="perPage"
                            first-number
                            last-number
                            align="center"
                        ></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>

        <!-- modal -->
        <b-modal
            centered
            id="modal-tai-khoan"
            :title="seletedRow ? 'CHỈNH SỬA NGƯỜI CHƠI' : 'THÊM NGƯỜI CHƠI'"
            scrollable
            size="lg"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'"
        >
            <div class="d-block">
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">
                            Tên Tài Khoản<span class="red-text ml-2">*</span>
                        </div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.name"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">
                            ID Đăng Nhập<span class="red-text ml-2">*</span>
                        </div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.userName"></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    variant="success"
                                    title="Tạo tự động"
                                    @click="onGenerate('userName')"
                                >
                                    <svg class="icon icon-repeat5">
                                        <use xlink:href="#icon-repeat5"></use>
                                    </svg>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Mật Khẩu<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.password"></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    variant="success"
                                    title="Tạo tự động"
                                    @click="onGenerate('password')"
                                >
                                    <svg class="icon icon-repeat5">
                                        <use xlink:href="#icon-repeat5"></use>
                                    </svg>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <div class="text-left">Chú Thích</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Phone 1</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.phone"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Phone 2</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.phone2"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Email</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.email"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Phân Quyền</div>
                        <b-input-group class="mb-3">
                            <multi-select
                                class="multi-select"
                                v-model="rolesSelected"
                                deselect-label="Không thể bỏ chọn"
                                track-by="value"
                                label="name"
                                placeholder=""
                                :options="rolesOptions"
                                :searchable="false"
                                :allow-empty="false"
                                :multiple="true"
                                :close-on-select="false"
                                @input="onChangeRole()"
                            >
                            </multi-select>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button block @click="closeModal('modal-tai-khoan')"> Hủy </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button
                            variant="primary"
                            block
                            @click="submit(seletedRow ? 'edit' : 'add')"
                        >
                            {{ seletedRow ? "Lưu" : "Thêm" }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <b-modal
            centered
            id="modal-xac-nhan-xoa"
            title="XÁC NHẬN XÓA"
            scrollable
            size="sm"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'"
        >
            <div class="msg-xac-nhan-xoa">
                Bạn Chắc Chắn Muốn Xóa Người Dùng
                <b>{{ seletedRow ? `${seletedRow.name} (${seletedRow.userName})` : "" }}</b
                >?
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col offset="4" cols="4">
                        <b-button
                            id="btn-xac-nhan-ko-xoa"
                            block
                            @click="closeModal('modal-xac-nhan-xoa')"
                        >
                            Không
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button
                            id="btn-xac-nhan-co-xoa"
                            variant="danger"
                            block
                            @click="submit('delete')"
                        >
                            Có
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import User from "../../../service/user.service";

export default {
    name: "ListUser",
    data() {
        return {
            title: "QUẢN LÝ TÀI KHOẢN",
            backTo: "menu",
            userName: "",
            fields: [
                { key: "id", label: "ID", sortable: false },
                { key: "name", label: "Tài Khoản", sortable: true },
                { key: "userName", label: "ID Đăng Nhập", sortable: true },
                { key: "password", label: "Mật Khẩu", sortable: true },
                { key: "delFlg", label: "Trạng Thái", sortable: true },
                { key: "note", label: "Ghi Chú", sortable: true },
                { key: "roles", label: "Phân Quyền", sortable: true },
                { key: "phone", label: "Phone 1", sortable: true },
                { key: "phone2", label: "Phone 2", sortable: true },
                { key: "email", label: "Email", sortable: true },
                { key: "action", label: "", sortable: false },
            ],
            dataTable: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 15,
            isBusy: false,
            seletedRow: null,
            dataForm: {
                name: "",
                userName: "",
                password: "",
                note: "",
                roles: "",
                phone: "",
                phone2: "",
                email: "",
            },
            rolesOptions: [
                {
                    name: "Admin",
                    value: "ROLE_ADMIN",
                },
                {
                    name: "Quản Lý",
                    value: "ROLE_MANAGER",
                },
                {
                    name: "Support",
                    value: "ROLE_SUPPORT",
                },
                {
                    name: "Kế Toán",
                    value: "ROLE_ACCOUNTANT",
                },
                {
                    name: "Member",
                    value: "ROLE_MEMBER",
                },
            ],
            rolesSelected: [
                {
                    name: "Member",
                    value: "ROLE_MEMBER",
                },
            ],
            characters: [
                {
                    name: "Lowercase",
                    value: "abcdefghijklmnopqrstuvwxyz",
                },
                {
                    name: "Uppercase",
                    value: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
                },
                {
                    name: "Numbers",
                    value: "0123456789",
                },
            ],
            gLength: 8,
        };
    },
    computed: {
        ...mapState("ListUser", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getUsers();
        window.addEventListener("keydown", this.keydown);
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("ListUser", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && document.getElementById("modal-tai-khoan")) {
                        this.submit(this.seletedRow ? "edit" : "add");
                    } else if (!isForcusDropdown && document.getElementById("modal-xac-nhan-xoa")) {
                        this.submit("delete");
                    } else if (!isForcusDropdown) {
                        this.onClickSearchBtn();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    if (document.getElementById("modal-tai-khoan")) {
                        this.closeModal("modal-tai-khoan");
                    } else if (document.getElementById("modal-xac-nhan-xoa")) {
                        this.closeModal("modal-xac-nhan-xoa");
                    }
                    e.preventDefault();
                    break;
                case 112: // f1
                    this.onClickAddBtn();
                    e.preventDefault();
                    break;
            }
        },
        getUsers() {
            this.isBusy = true;
            let params = {
                userName: this.userName,
            };
            let _this = this;
            User.getUsers(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data;
                    _this.totalRows = _this.dataTable.length;
                    _this.currentPage = 1;
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy = false;
                });
        },
        onClickSearchBtn() {
            this.getUsers();
        },
        onClickAddBtn() {
            this.seletedRow = null;
            this.dataForm = {
                name: "",
                userName: "",
                password: "",
                note: "",
                roles: "",
                phone: "",
                phone2: "",
                email: "",
            };
            this.$bvModal.show("modal-tai-khoan");
        },
        onChangeRole() {
            if (this.rolesSelected.findIndex((i) => i.value === "ROLE_MEMBER") === -1) {
                this.rolesSelected.unshift({
                    name: "Member",
                    value: "ROLE_MEMBER",
                });
            }
            this.dataForm.roles = [];
            for (const item of this.rolesSelected) {
                this.dataForm.roles.push(item.value);
            }
        },
        onClickEditItemBtn(item) {
            this.seletedRow = this.cloneDeep(item);
            this.rolesSelected = [
                {
                    name: "Member",
                    value: "ROLE_MEMBER",
                },
            ];
            for (const role of item.roles) {
                if (role !== "ROLE_MEMBER") {
                    let rolesSelected = this.rolesOptions.filter((i) => i.value === role)[0];
                    this.rolesSelected.push(rolesSelected);
                }
            }
            this.dataForm = {
                name: item.name,
                userName: item.userName,
                password: item.password,
                note: item.note,
                roles: item.roles,
                phone: item.phone,
                phone2: item.phone2,
                email: item.email,
            };
            this.$bvModal.show("modal-tai-khoan");
        },
        onClickDeleteItemBtn(item) {
            this.seletedRow = this.cloneDeep(item);
            this.$bvModal.show("modal-xac-nhan-xoa");
        },
        closeModal(id) {
            this.$bvModal.hide(id);
        },
        submit(mode) {
            let _this = this;
            switch (mode) {
                case "add":
                    User.insert(_this.$root.url, this.setData(this.dataForm))
                        .then((response) => {
                            console.log(response);
                            _this.getUsers();
                            _this.closeModal("modal-tai-khoan");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "edit":
                    this.dataForm.id = this.seletedRow.id;
                    User.update(_this.$root.url, this.setData(this.dataForm))
                        .then((response) => {
                            console.log(response);
                            _this.getUsers();
                            _this.closeModal("modal-tai-khoan");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "delete":
                    User.delete(_this.$root.url, this.setData(this.seletedRow))
                        .then((response) => {
                            console.log(response);
                            _this.getUsers();
                            _this.closeModal("modal-xac-nhan-xoa");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
            }
        },
        setData(val) {
            let dataReturn = {};
            dataReturn.id = val.id;
            dataReturn.userName = val.userName;
            dataReturn.name = val.name;
            dataReturn.password = val.password;
            dataReturn.note = val.note;
            dataReturn.delFlg = val.delFlg;
            dataReturn.roles = val.roles;
            dataReturn.phone = val.phone;
            dataReturn.phone2 = val.phone2;
            dataReturn.email = val.email;
            return dataReturn;
        },
        onGenerate(key) {
            let result = "";
            let charactersVal = "";
            for (var j = 0; j < this.characters.length; j++) {
                charactersVal += this.characters[j].value;
            }
            for (var i = 0; i < this.gLength; i++) {
                result += charactersVal.charAt(Math.floor(Math.random() * charactersVal.length));
            }
            this.dataForm[key] = result;
        },
    },
};
</script>

<style lang="scss">
.style-for-web .danh-sach-tai-khoan {
}
</style>
