<template>
    <div id="menu" class="menu">
        <pc-header :title="title" />
        <div class="menu-content">
            <b-row class="menu-item">
                <b-col v-for="(item, index) in dataMenu" :key="index">
                    <div class="btn-icon-menu" @click="onClickBtn(item)">
                        <label class="name-btn">{{ item.btn.text }}</label>
                        <svg class="icon" :class="item.btn.icon">
                            <use :xlink:href="'#' + item.btn.icon"></use>
                        </svg>
                    </div>
                    <b-collapse :id="'collapse-' + item.btn.page" v-model="item.visibleBtn" class="mt-2 collapse-list-item">
                        <b-button v-for="(item, index) in item.btn.subBtn" :key="index" :variant="$root.theme" block
                            @click="onClickSubBtn(item.page)">
                            {{ item.text }}
                        </b-button>
                    </b-collapse>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "MenuPage",
    data() {
        return {
            title: 'MENU',
            isAdmin: false,
            isManager: false,
            isSupport: false,
            isKeToan: false,
            isMember: false,
            dataMenu: [
                {
                    visibleBtn: false,
                    btn: {
                        text: "Cào dữ liệu",
                        icon: "icon-receipt-search5",
                        subBtn: [
                            { text: "Quản lý người chơi", page: "quan-ly-player" },
                            { text: "Lịch sử cào", page: "history-batch-job" },
                            { text: "Ghép Trận đấu & giải đấu", page: "mapping-match-league" },
                            { text: "Thống kê các tài khoản không còn chơi", page: "thong-ke-khong-choi" },
                        ]
                    },
                },
                {
                    visibleBtn: false,
                    btn: {
                        text: "Tài Khoản",
                        icon: "icon-people5",
                        subBtn: [
                            { text: "Quản lý đại lý và tài khoản", page: "manager-agent-player" }
                        ],
                    },
                },
                {
                    visibleBtn: false,
                    btn: {
                        text: "Quét hàng",
                        icon: "icon-box-search5",
                        subBtn: [
                            { text: "Xem theo win loss", page: "xem-theo-win-loss" },
                            { text: "Xem nhanh", page: "xem-nhanh" },
                            { text: "Top thắng thua member", page: "bet-top-member" },
                            { text: "Top thắng thua vi phạm", page: "top-thang-thua" },
                            { text: "Báo cáo theo sản phẩm (Bài, Gà, Banh)", page: "report-by-product" },
                            { text: "Credit / Balance", page: "credit-balance" },
                        ]
                    },
                },
                {
                    visibleBtn: false,
                    btn: {
                        text: "Thống kê và tạo số",
                        icon: "icon-chart-15",
                        subBtn: [
                            { text: "Thống kê chuỗi", page: "thong-ke-lo-de" },
                            { text: "Tạo tự động", page: "tao-so" },
                            { text: "Thống kê xác suất", page: "thong-ke-xac-suat" },
                        ]
                    },
                },
                {
                    visibleBtn: false,
                    btn: {
                        text: "Bot telegram",
                        page: "bot-telegram",
                        icon: "icon-text5",
                        subBtn: [
                            { text: "Quản lý Bot telegram", page: "quan-ly-bot" },
                        ]
                    },
                },
                {
                    visibleBtn: false,
                    btn: {
                        text: "Setting",
                        icon: "icon-cpu-setting5",
                        subBtn: [
                            { text: "Quản lý user", page: "list-user" },
                        ]
                    },
                },
                // {
                //     visibleBtn: false,
                //     btn: {
                //         text: "...",
                //         icon: "icon-more-25",
                //         subBtn: []
                //     },
                // },
            ],
        };
    },
    computed: {
        ...mapState("MenuPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        this.isDarkMode = this.$root.theme === 'dark';
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("MenuPage", []),
        getUserName() {
            this.userName = localStorage.getItem("userName");
            let user = {
                userName: "",
                name: "",
                roles: [""],
            };
            user = JSON.parse(localStorage.getItem("user"));
            if (user) {
                this.name = user.name;
                const roles = user.roles;
                if (roles.includes("ROLE_ADMIN")) {
                    this.nameHighestRole = "ADMIN";
                    this.isAdmin = true;
                } else if (roles.includes("ROLE_MANAGER")) {
                    this.nameHighestRole = "MANAGER";
                    this.isManager = true;
                } else if (roles.includes("ROLE_SUPPORT")) {
                    this.nameHighestRole = "SUPPORT";
                    this.isSupport = true;
                } else if (roles.includes("ROLE_ACCOUNTANT")) {
                    this.nameHighestRole = "ACCOUNTANT";
                    this.isKeToan = true;
                } else {
                    this.nameHighestRole = "MEMBER";
                    this.isMember = true;
                }
            }
        },
        onClickBtn(data) {
            for (const item of this.dataMenu) {
                item.visibleBtn = false;
            }
            data.visibleBtn = true;
        },
        onClickSubBtn(page) {
            this.movePage(page);
        },
    },
};
</script>

<style lang="scss">
.style-for-web .menu {
    height: 100%;

    .menu-content {
        display: flex;
        height: 100%;

        .menu-item {
            margin: 10vh auto auto auto;
            position: relative;

            .col {
                margin: auto 0.5vw;
            }
        }
    }

    .collapse-list-item {
        position: absolute;
        left: 0;
        width: 100%;
        border-radius: 8px;
        z-index: 2;

        button {
            text-transform: capitalize;
            background-color: var(--model-background);
            box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

            &:hover {
                background-color: var(--model-body-background);
            }
        }
    }

    .btn-icon-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: calc((100vw / 7) - 40px);
        width: calc((100vw / 7) - 40px);
        margin: auto;
        background-color: var(--model-background);
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background-color: var(--model-body-background);

            .icon {
                width: 60%;
                height: 60%;
            }
        }

        .icon {
            width: 50%;
            height: 50%;
            transition: all 0.3s;
        }

        .name-btn {
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            font-size: 100%;
            font-weight: bold;
        }
    }
}
</style>
