<template>
    <div id="login" class="login">
        <img class="back-login" :src="getImg('img-bong-da', 'jpg')" />
        <div class="login-left">
            <div v-if="$root.isSystemMaintenance" class="group-login bao-tri">
                <img :src="getImg('bao-tri', 'svg')" />
            </div>
            <div v-else class="group-login">
                <b-row>
                    <b-col>
                        <div class="logo">
                            <img :src="getImg('logo', 'jpg')" alt="">
                        </div>
                        <div class="title-logo">
                            <label>Vui Lòng Nhập Thông Tin Đăng Nhập</label>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="12">
                        <label>Tài Khoản</label>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="userName"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="12">
                        <label>Mật Khẩu</label>
                        <b-input-group size="lg">
                            <b-form-input :type="isShowPass ? 'text' : 'password'" v-model="password"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="12">
                        <b-form-checkbox v-model="isShowPass" switch>
                            Hiển Mật Khẩu
                        </b-form-checkbox>
                        <b-form-checkbox v-model="isDarkMode" switch @change="setThemePreference()">
                            Chế Độ Tối
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col cols="12">
                        <b-button variant="primary" block size="lg" @click="submit()">Đăng Nhập</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="login-right">
            <div class="back-img">
                <!-- <img v-if="!$root.isSystemMaintenance" :src="getImg('img-bong-da', 'jpg')" /> -->
                <!-- <img v-if="$root.isSystemMaintenance" :src="getImg('bao-tri', 'svg')" /> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AuthRepository from "../../../service/auth.service";

export default {
    name: "LoginPage",
    data() {
        return {
            userName: "",
            password: "",
            isShowPass: false,
            isDarkMode: false,
            isGhiNhoDangNhap: false,
        };
    },
    computed: {
        ...mapState("LoginPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        this.isDarkMode = this.$root.theme === 'dark';

        let token = localStorage.getItem("jwt");
        if (token) {
            localStorage.setItem("jwt", token)
            this.$router.push("menu");
        } else {
            this.clearLocalStorage();
        }
        window.addEventListener('keydown', this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener('keydown', this.keydown);
    },
    methods: {
        ...mapActions("LoginPage", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes('multiselect');
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.submit();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    break;
            }
        },
        setThemePreference() {
            let theme = this.isDarkMode ? 'dark' : 'light';
            this.switchHtmlTheme(theme);
        },
        submit() {
            let _this = this;
            AuthRepository.login(_this.$root.url, {
                userName: _this.userName,
                password: _this.password,
            })
                .then((response) => {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    localStorage.setItem("userName", response.data.userName);
                    localStorage.setItem("jwt", response.data.token);
                    _this.$root.isLogin = true;
                    _this.movePage("menu");
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
    },
};
</script>

<style lang="scss">
.style-for-web .login {
    display: flex;
    background-image: var(--background-image);

    .back-login {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .login-right {
        .back-img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                height: 100%;
            }
        }

        height: 100vh;
        width: 50vw;
        display: flex;
    }

    .login-left {
        height: 100vh;
        width: 50vw;
        background-size: cover;
        display: flex;
    }

    .group-login {
        background-color: var(--back-color-group-login);
        width: 400px;
        height: 60vh;
        min-height: 600px;
        border-radius: 16px;
        padding: 10px;
        margin: auto;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        font-weight: bold;
        z-index: 1;

        .title-logo {
            font-size: 16px;
            text-align: center;
            margin: 0 0 1vh 0;
        }

        .logo {
            background-color: var(--white-color);
            border: 2px solid var(--btn-primary-color);
            border-radius: 16px;
            width: 35%;
            margin: 4vh auto 1vh auto;

            img {
                width: 100%;
                border-radius: 16px;
            }
        }

        .input-group-lg>.form-control:not(textarea),
        .input-group-lg>.custom-select {
            height: 56px;
        }

        .btn-lg,
        .btn-group-lg>.btn {
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
        }

        &.bao-tri {
            display: flex;
            margin: auto;

            img {
                width: 90%;
            }
        }
    }
}
</style>
