<template>
    <div id="thong-ke-lo-de" class="thong-ke-lo-de">
        <pc-header :title="title" :backTo="backTo" />
        <div class="thong-ke-lo-de-content">
            <div>
                <b-row v-if="isShowFilter">
                    <b-col cols="2">
                        <div class="group-item-filter text-left" v-if="![2].includes(activeChuoi)">
                            <span class="span-nowrap mr-2">Miền:</span>
                            <b-form-radio v-model="valueVung" name="vung-loc-du-lieu" value="MIEN_BAC"
                                @change="onChangeVung('MIEN_BAC')">
                                Bắc
                            </b-form-radio>
                            <b-form-radio v-model="valueVung" name="vung-loc-du-lieu" value="MIEN_NAM"
                                @change="onChangeVung('MIEN_NAM')">
                                Nam
                            </b-form-radio>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Loại:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="loaiHienThi" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="loaiHienThiOptions"
                                    :searchable="false" :allow-empty="false" @input="changeLoaiHienThi()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2"
                        v-if="[loaiHienThiOptions[3].codeKey].includes(loaiHienThi.codeKey) && ![2].includes(activeChuoi)">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Thứ:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="thuTrongTuan" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="thuTrongTuanOptions"
                                    :searchable="false" :allow-empty="false" @input="changeThuTrongTuan()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Thời Gian:</span>
                            <date-range-picker v-model="dateFromTo1" :locale-data="$root.localeData" :show-dropdowns="true"
                                :auto-apply="true" :ranges="ranges1" :max-date="maxDate" :linked-calendars="false"
                                opens="center" @update="changeLoaiThongKe()"></date-range-picker>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Tuần:</span>
                            <b-input-group class="mr-2 input-number">
                                <b-form-input v-model="soTuanTruoc" @change="changeSoTuanTruoc()"
                                    :number="true"></b-form-input>
                                <b-input-group-append>
                                    <div>
                                        <b-button variant="success" class="btn-cong" @click="changeSoTuanTruoc('cong')">
                                            <svg class="icon icon-add4">
                                                <use xlink:href="#icon-add4"></use>
                                            </svg>
                                        </b-button>
                                        <b-button variant="success" class="btn-tru" @click="changeSoTuanTruoc('tru')">
                                            <svg class="icon icon-minus4">
                                                <use xlink:href="#icon-minus4"></use>
                                            </svg>
                                        </b-button>
                                    </div>
                                </b-input-group-append>
                            </b-input-group>
                            <span class="span-nowrap mr-2">Năm:</span>
                            <b-input-group class="mr-2 input-number">
                                <b-form-input v-model="soNam" @change="changeSoNam()" :number="true"></b-form-input>
                                <b-input-group-append>
                                    <div>
                                        <b-button variant="success" class="btn-cong" @click="changeSoNam('cong')">
                                            <svg class="icon icon-add4">
                                                <use xlink:href="#icon-add4"></use>
                                            </svg>
                                        </b-button>
                                        <b-button variant="success" class="btn-tru" @click="changeSoNam('tru')">
                                            <svg class="icon icon-minus4">
                                                <use xlink:href="#icon-minus4"></use>
                                            </svg>
                                        </b-button>
                                    </div>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="isShowFilter" class="mt-2">
                    <b-col offset="1" cols="3"
                        v-if="[loaiHienThiOptions[0].codeKey, loaiHienThiOptions[1].codeKey].includes(loaiHienThi.codeKey)">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Loại Thống Kê:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="loaiThongKe" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="loaiThongKeOptions"
                                    :searchable="false" :allow-empty="false" @input="changeLoaiThongKe()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col :offset="loaiHienThiOptions[2].codeKey === loaiHienThi.codeKey ? 1 : 0" cols="2" v-if="([loaiHienThiOptions[0].codeKey, loaiHienThiOptions[1].codeKey].includes(loaiHienThi.codeKey) &&
                        loaiThongKe.codeKey === loaiThongKeOptions[2].codeKey) ||
                        loaiHienThiOptions[2].codeKey === loaiHienThi.codeKey
                        ">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Tỉnh:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="valueTinh" deselect-label="Không thể bỏ chọn"
                                    track-by="tinhId" label="tinhName" placeholder="" :options="tinhOpations"
                                    :searchable="false" :allow-empty="false" @input="changeTinh()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2" v-if="loaiHienThi.codeKey === loaiHienThiOptions[0].codeKey">
                        <div class="group-item-filter text-left">
                            <span class="span-nowrap mr-2">Loại Biểu Đồ:</span>
                            <b-input-group>
                                <multi-select class="multi-select" v-model="loaiBieuDo" deselect-label="Không thể bỏ chọn"
                                    track-by="codeKey" label="codeName" placeholder="" :options="loaiBieuDoOptions"
                                    :searchable="false" :allow-empty="false" @input="changeLoaiBieuDo()">
                                </multi-select>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <!-- chart -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[0].codeKey" class="mt-1">
                    <b-col cols="12">
                        <div class="group-chart">
                            <chart-line v-if="loaiBieuDo.codeKey === 'line' && !isLoading" :options="chartOptions"
                                :data="chartData" />
                            <chart-bar v-if="loaiBieuDo.codeKey === 'bar' && !isLoading" :options="chartOptions"
                                :data="chartData" />
                        </div>
                    </b-col>
                </b-row>
                <!-- table -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[1].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="table-data group-table">
                            <b-table sticky-header fixed hover small :items="dataTable" :fields="fieldsTable"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #cell(date)="scope">
                                    <span class="chuoi">{{ getFormatDate(scope.item.date, "formatDateNoYearVN") }}</span>
                                </template>
                                <template #cell()="scope">
                                    <div class="chuoi" :class="{ 'no-data': getTextTable(scope.value) === '-' }"
                                        v-html="getTextTable(scope.value)"></div>
                                </template>
                            </b-table>
                        </div>
                    </b-col>
                </b-row>
                <!-- table-100-so -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[2].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="table-100-so">
                            <div>
                                <div v-for="(item, index) in dataTable100So" :key="index" class="item"
                                    :class="{ 'item-even': item.class === 'even', 'item-odd': item.class === 'odd', 'no-data': item.count === 0 }">
                                    <label>{{ item.codeKey }}</label>
                                    <br />
                                    <label class="mr-1">{{ item.count ? item.count : "-" }}</label>
                                    <!-- <label>({{ item.percent }})</label> -->
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="mr-2">THÔNG TIN TỔNG HỢP:</div>
                                    <div class="pl-2">
                                        <span class="mr-2">Tổng số chẵn:</span>
                                        <span>{{ totalChan100So }}</span>
                                    </div>
                                    <div class="pl-2">
                                        <span class="mr-2">Tổng số lẻ:</span>
                                        <span>{{ totalLe100So }}</span>
                                    </div>
                                    <div class="group-so-lan-ra">
                                        <div v-for="(item, index) in listSoDaRa100So" :key="index" class="pl-2">
                                            <div class="mr-2">Số ra {{ index }} lần:</div>
                                            <div>
                                                <span class="mr-2">- Tài chẵn:</span>
                                                <span v-for="(item1, index1) in item" :key="index1">
                                                    {{ checkChanLe(item1) && Number(item1) >= 50 ? item1 + (index1 <
                                                        item.length - 1 ? ", " : "") : "" }} </span>
                                            </div>
                                            <div>
                                                <span class="mr-2">- Tài lẻ:</span>
                                                <span v-for="(item1, index1) in item" :key="index1">
                                                    {{ !checkChanLe(item1) && Number(item1) >= 50 ? item1 + (index1 <
                                                        item.length - 1 ? ", " : "") : "" }} </span>
                                            </div>
                                            <div>
                                                <span class="mr-2">- Xỉu chẵn:</span>
                                                <span v-for="(item1, index1) in item" :key="index1">
                                                    {{ checkChanLe(item1) && Number(item1) < 50 ? item1 + (index1 <
                                                        item.length - 1 ? ", " : "") : "" }} </span>
                                            </div>
                                            <div>
                                                <span class="mr-2">- Xỉu lẻ:</span>
                                                <span v-for="(item1, index1) in item" :key="index1">
                                                    {{ !checkChanLe(item1) && Number(item1) < 50 ? item1 + (index1 <
                                                        item.length - 1 ? ", " : "") : "" }} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <!-- table-chuoi-so -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[3].codeKey && [1, 2].includes(activeChuoi)"
                    class="mt-1">
                    <b-col offset="2" cols="9">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Mốc Hiệu Quả:</span>
                            <b-input-group class="mr-2 input-number">
                                <b-form-input v-model="soMocHieuQua" :number="true"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[3].codeKey" class="mt-1">
                    <b-col offset="1" cols="1" class="group-button-chuoi">
                        <b-button :variant="activeChuoi === 0 ? 'success' : 'secondary'" @click="changeChuoi(0)">
                            Chuỗi Hiện Tại
                        </b-button>
                        <br />
                        <b-button :variant="activeChuoi === 1 ? 'success' : 'secondary'" @click="changeChuoi(1)">
                            Thống Kê Chuỗi
                        </b-button>
                        <br />
                        <b-button :variant="activeChuoi === 2 ? 'success' : 'secondary'" @click="changeChuoi(2)">
                            Thống Kê Chuỗi (Miền)
                        </b-button>
                    </b-col>
                    <b-col cols="9">
                        <div class="table-data table-chuoi-so" v-if="[0].includes(activeChuoi)">
                            <b-table sticky-header fixed hover small :items="dataTableChuoiSo" :fields="fieldsTableChuoiSo"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #cell(date)="scope">
                                    <span class="chuoi-ngay" :title="scope.item.date.join(', ')">
                                        {{ scope.item.date.join(", ") }}
                                    </span>
                                </template>
                                <template #cell(chuoiChan)="scope">
                                    <span class="chuoi" v-html="getTextChuoiSo(scope.item, 0, 'chan')"></span>
                                </template>
                                <template #cell(chuoiLe)="scope">
                                    <span class="chuoi" v-html="getTextChuoiSo(scope.item, 0, 'le')"></span>
                                </template>
                            </b-table>
                        </div>
                        <div class="table-data table-chuoi-so" v-if="[1].includes(activeChuoi)">
                            <b-table sticky-header fixed hover small :items="dataTableThongKeChuoiSo"
                                :fields="fieldsTableThongKeChuoiSo" :head-variant="$root.theme"
                                :dark="$root.theme === 'dark'" show-empty empty-text="Không có dữ liệu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #cell(count)="scope">
                                    <div class="count-chuoi-so">
                                        <div class="chuoi" v-html="getCountChuoiSo(scope.item, 'chan')"></div>
                                        <div class="chuoi" v-html="getCountChuoiSo(scope.item, 'le')"></div>
                                    </div>
                                </template>
                                <template #cell(chuoiChan)="scope">
                                    <span class="chuoi" v-html="getTextChuoiSo(scope.item, 1, 'chan')"></span>
                                </template>
                                <template #cell(chuoiLe)="scope">
                                    <span class="chuoi" v-html="getTextChuoiSo(scope.item, 1, 'le')"></span>
                                </template>
                            </b-table>
                        </div>
                        <div class="data-chuoi-hai-mien" v-if="[2].includes(activeChuoi) && !isLoading">
                            <div class="table-data table-chuoi-so">
                                <div>
                                    <strong>Miền Nam</strong>
                                </div>
                                <div class="group-data">
                                    <strong>Chuỗi Chẵn:</strong>
                                    <div v-html="getTextChuoiMien(dataMienNam.listPhanTram.chan)"></div>
                                </div>
                                <div class="group-data">
                                    <strong>Chuỗi Lẻ:</strong>
                                    <div v-html="getTextChuoiMien(dataMienNam.listPhanTram.le)"></div>
                                </div>
                            </div>
                            <div class="table-data table-chuoi-so">
                                <div>Miền Bắc</div>
                                <div class="group-data">
                                    <strong>Chuỗi Chẵn:</strong>
                                    <div v-html="getTextChuoiMien(dataMienBac.listPhanTram.chan)"></div>
                                </div>
                                <div class="group-data">
                                    <strong>Chuỗi Lẻ:</strong>
                                    <div v-html="getTextChuoiMien(dataMienBac.listPhanTram.le)"></div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <!-- table-chuoi-so-6-con -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[4].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="table-data table-chuoi-so-6-con">
                            <b-table sticky-header fixed hover small :items="dataTableChuoiSo6Con"
                                :fields="fieldsTableChuoiSo6Con" :head-variant="$root.theme" :dark="$root.theme === 'dark'"
                                show-empty empty-text="Không có dữ liệu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #cell(date)="scope">
                                    <span class="chuoi-ngay" :title="scope.item.date.join(', ')">
                                        {{ scope.item.date.join(", ") }}
                                    </span>
                                </template>
                                <template #cell(chuoiChan)="scope">
                                    <span class="chuoi" v-html="getTextChuoiSo6Con(scope.item.chuoiChan, 'chan')"></span>
                                </template>
                                <template #cell(chuoiLe)="scope">
                                    <span class="chuoi" v-html="getTextChuoiSo6Con(scope.item.chuoiLe, 'le')"></span>
                                </template>
                            </b-table>
                        </div>
                    </b-col>
                </b-row>
                <!-- table-chuoi-so-bao-lo -->
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[5].codeKey" class="mt-1">
                    <b-col offset="2" cols="9">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Lọc Chuỗi:</span>
                            <b-input-group class="mr-2 input-number">
                                <b-form-input v-model="soLocChuoi" :number="true"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="loaiHienThi.codeKey === loaiHienThiOptions[5].codeKey" class="mt-1">
                    <b-col offset="1" cols="10">
                        <div class="table-data table-chuoi-so-bao-lo">
                            <b-table sticky-header fixed hover small :items="dataTableChuoiSoBaoLo"
                                :fields="fieldsTableChuoiSoBaoLo" :head-variant="$root.theme" :dark="$root.theme === 'dark'"
                                show-empty empty-text="Không có dữ liệu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #cell(date)="scope">
                                    <span class="chuoi-ngay" v-for="(item, index) in scope.item.chuoi" :key="index">
                                        <label>{{ item.daiName }}:</label><br>
                                    </span>
                                </template>
                                <template #cell(chuoiChan)="scope">
                                    <span v-for="(item, index) in scope.item.chuoi" :key="index">
                                        <span class="chuoi" v-html="getTextChuoiSoBaoLo(item, 'chan')"></span>
                                    </span>
                                </template>
                                <template #cell(chuoiLe)="scope">
                                    <span v-for="(item, index) in scope.item.chuoi" :key="index">
                                        <span class="chuoi" v-html="getTextChuoiSoBaoLo(item, 'le')"></span>
                                    </span>
                                </template>
                            </b-table>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Bar, Line } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement } from "chart.js";
import Loto from "../../../service/loto.service";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement);

export default {
    name: "ThongKeLoDe",
    components: {
        "chart-bar": Bar,
        "chart-line": Line,
    },
    data() {
        return {
            app: this,
            title: "TẠO SỐ",
            backTo: "menu",
            isShowFilter: true,
            valueVung: "MIEN_NAM",
            soNgayView: 0,
            ranges1: {
                "1 Tuần Trước": this.getRangeDate("tuanTruoc", 1, null, true),
                "2 Tuần Trước": this.getRangeDate("tuanTruoc", 2, null, true),
                "3 Tuần Trước": this.getRangeDate("tuanTruoc", 3, null, true),
                "1 Tháng Trước": this.getRangeDate("thangTruoc", 1, null, true),
                "2 Tháng Trước": this.getRangeDate("thangTruoc", 2, null, true),
                "3 Tháng Trước": this.getRangeDate("thangTruoc", 3, null, true),
                "4 Tháng Trước": this.getRangeDate("thangTruoc", 4, null, true),
                "5 Tháng Trước": this.getRangeDate("thangTruoc", 5, null, true),
                "6 Tháng Trước": this.getRangeDate("thangTruoc", 6, null, true),
                "9 Tháng Trước": this.getRangeDate("thangTruoc", 9, null, true),
                "Năm Nay": this.getRangeDate("namNay", 1, null, true),
                "1 Năm Trước": this.getRangeDate("namTruoc", 1, null, true),
                "2 Năm Trước": this.getRangeDate("namTruoc", 2, null, true),
                "3 Năm Trước": this.getRangeDate("namTruoc", 3, null, true),
            },
            dateFromTo1: {
                startDate: this.getRangeDate("thangNay")[0],
                endDate: this.getRangeDate("thangNay")[1],
            },
            thang: {},
            thangOptions: [],
            nam: {},
            namOptions: [],
            maxDate: new Date(),
            loaiHienThi: {
                codeKey: "table-chuoi-so",
                codeName: "Chuỗi chẵn lẻ (theo đài)",
            },
            loaiHienThiOptions: [
                {
                    codeKey: "chart",
                    codeName: "Biểu đồ",
                },
                {
                    codeKey: "table",
                    codeName: "Bảng tính",
                },
                {
                    codeKey: "table-100-so",
                    codeName: "Bảng 100 số",
                },
                {
                    codeKey: "table-chuoi-so",
                    codeName: "Chuỗi chẵn lẻ (từng đài)",
                },
                {
                    codeKey: "table-chuoi-so-6-con",
                    codeName: "Chuỗi chẵn lẻ (6 con đầu đuôi)",
                },
                {
                    codeKey: "table-chuoi-so-bao-lo",
                    codeName: "Chuỗi chẵn lẻ (Bao lô 18 con)",
                },
            ],
            loaiBieuDo: {
                codeKey: "bar",
                codeName: "Biểu đồ cột",
            },
            loaiBieuDoOptions: [
                {
                    codeKey: "line",
                    codeName: "Biểu đồ đường",
                },
                {
                    codeKey: "bar",
                    codeName: "Biểu đồ cột",
                },
            ],
            loaiThongKe: {
                codeKey: "1",
                codeName: "Chẳn Lẻ 6 Con Đầu Đuôi",
            },
            loaiThongKeOptions: [
                {
                    codeKey: "1",
                    codeName: "Chẳn Lẻ 6 Con Đầu Đuôi",
                },
                {
                    codeKey: "2",
                    codeName: "Chẳn Lẻ Bao Lô 18 Con",
                },
                {
                    codeKey: "3",
                    codeName: "Bộ 19 Số",
                },
            ],
            valueTinh: {},
            tinhOpations: [],
            thuTrongTuan: {},
            thuTrongTuanOptions: [
                {
                    codeKey: "7",
                    codeKeyStr: "ALL",
                    codeName: "Cả Tuần",
                },
                {
                    codeKey: "1",
                    codeKeyStr: "T2",
                    codeName: "Thứ 2",
                },
                {
                    codeKey: "2",
                    codeKeyStr: "T3",
                    codeName: "Thứ 3",
                },
                {
                    codeKey: "3",
                    codeKeyStr: "T4",
                    codeName: "Thứ 4",
                },
                {
                    codeKey: "4",
                    codeKeyStr: "T5",
                    codeName: "Thứ 5",
                },
                {
                    codeKey: "5",
                    codeKeyStr: "T6",
                    codeName: "Thứ 6",
                },
                {
                    codeKey: "6",
                    codeKeyStr: "T7",
                    codeName: "Thứ 7",
                },
                {
                    codeKey: "0",
                    codeKeyStr: "CN",
                    codeName: "Chủ Nhật",
                },
            ],
            listDate: [],
            isLoading: false,
            dataTinh: [],
            dataChanLe: [],
            chartData: {
                labels: [],
                datasets: [],
            },
            chartOptions: {
                responsive: true,
                scales: {},
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                plugins: {
                    tooltip: {
                        enabled: false,
                        // position: "nearest",
                        external: this.tooltipCustom,
                    },
                    legend: {
                        position: "right",
                        useBorderRadius: true,
                        borderRadius: 5,
                        labels: {
                            color: this.getColor(0)[0],
                            font: {
                                size: 14,
                            },
                        },
                    },
                },
            },
            dataTable: [],
            dataTable100So: [],
            dataTableChuoiSo: [],
            dataTableThongKeChuoiSo: [],
            dataTableChuoiSo6Con: [],
            dataTableChuoiSoBaoLo: [],
            fieldsTable: [{ key: "date", label: "Ngày", sortable: false, stickyColumn: true }],
            fieldsTableChuoiSo: [
                { key: "date", label: "Ngày Ra Số", sortable: false },
                { key: "dai", label: "Đài", sortable: false },
                { key: "chuoiChan", label: "Chuỗi Chẵn", sortable: false },
                { key: "chuoiLe", label: "Chuỗi Lẻ", sortable: false },
            ],
            fieldsTableThongKeChuoiSo: [
                { key: "dai", label: "Đài", sortable: false },
                { key: "count", label: "Số Lần Chuỗi", sortable: false },
                { key: "chuoiChan", label: "Chuỗi Chẵn", sortable: false },
                { key: "chuoiLe", label: "Chuỗi Lẻ", sortable: false },
            ],
            fieldsTableChuoiSo6Con: [
                { key: "day", label: "Thứ", sortable: false },
                { key: "date", label: "Các ngày", sortable: false },
                { key: "chuoiChan", label: "Chuỗi Chẵn", sortable: false },
                { key: "chuoiLe", label: "Chuỗi Lẻ", sortable: false },
            ],
            fieldsTableChuoiSoBaoLo: [
                { key: "day", label: "Thứ", sortable: false },
                { key: "date", label: "Các ngày", sortable: false },
                // { key: "chuoi", label: "Chuỗi", sortable: false },
                { key: "chuoiChan", label: "Chuỗi Chẵn", sortable: false },
                { key: "chuoiLe", label: "Chuỗi Lẻ", sortable: false },
            ],
            totalChan100So: 0,
            totalLe100So: 0,
            listSoDaRa100So: [],
            soTuanTruoc: 0,
            soNam: 0,
            activeChuoi: 0,
            chuoiChan: {},
            chuoiLe: {},
            dataMienNam: null,
            dataMienBac: null,
            soMocHieuQua: 0,
            soLocChuoi: 0,
        };
    },
    computed: {
        ...mapState("ThongKeLoDe", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");

        this.dateFromTo1 = {
            startDate: this.getRangeDate("thangNay")[0],
            endDate: this.getRangeDate("thangNay")[1],
        };

        let day = this.$moment(new Date()).day().toString();
        this.thuTrongTuan = this.thuTrongTuanOptions.filter((i) => i.codeKey === day)[0];

        this.getListTinh();
        this.getData();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    watch: {},
    methods: {
        ...mapActions("ThongKeLoDe", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        this.getListDate();
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getListTinh() {
            let _this = this;
            Loto.getListTinh(_this.$root.url)
                .then((response) => {
                    _this.dataTinh = response.data;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getCau100(obj) {
            let data = {
                fromDate: this.getFormatDate(this.dateFromTo1.startDate, "formatDateAPI"),
                toDate: this.getFormatDate(this.dateFromTo1.endDate, "formatDateAPI"),
                mien: this.valueVung,
                tinhId: this.valueTinh.tinhId,
            };
            let _this = this;
            Loto.getCau100(_this.$root.url, data)
                .then((response) => {
                    _this.dataTable100So = [];
                    _this.totalChan100So = 0;
                    _this.totalLe100So = 0;
                    _this.listSoDaRa100So = [];
                    if (response.data[0]) {
                        for (const item of response.data[0].cau100) {
                            if (_this.checkChanLe(item.num) && item.count > 0) {
                                _this.totalChan100So += 1;
                            } else if (!_this.checkChanLe(item.num) && item.count > 0) {
                                _this.totalLe100So += 1;
                            }

                            if (!_this.listSoDaRa100So[item.count]) {
                                _this.listSoDaRa100So[item.count] = [];
                            }
                            _this.listSoDaRa100So[item.count].push(item.num);

                            let newObj = {
                                codeKey: item.num,
                                count: item.count,
                                percent: 0,
                                class: _this.checkChanLe(item.num) ? "even" : "odd",
                            };
                            _this.dataTable100So.push(newObj);
                        }
                    }
                    if (obj) {
                        obj.dataCau100 = _this.dataTable100So;
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getData(mien) {
            if ([2].includes(this.activeChuoi)) {
                this.getDataHaiMien();
                return;
            }
            this.isLoading = true;

            this.isAddEventChuoiSo = true;
            this.isAddEventCountChuoiSo = true;

            this.dataChanLe = [];
            this.dataTable = [];
            this.dataTable100So = [];
            this.dataTableChuoiSo = [];
            this.dataTableThongKeChuoiSo = [];
            this.dataTableChuoiSo6Con = [];

            this.tinhOpations = [];
            let data = {
                fromDate: this.getFormatDate(this.dateFromTo1.startDate, "formatDateAPI"),
                toDate: this.getFormatDate(this.dateFromTo1.endDate, "formatDateAPI"),
                mien: mien ? mien : this.valueVung,
            };
            if (this.loaiHienThi.codeKey === this.loaiHienThiOptions[3].codeKey && this.thuTrongTuan.codeKey !== "7") {
                data.dayOfWeeks = this.thuTrongTuan.codeKey;
            }
            let _this = this;
            Loto.getListChanLe(_this.$root.url, data)
                .then((response) => {
                    _this.dataChanLe = response.data.map((i) => {
                        let indexLA = i.infos.findIndex((j) => j.tinhId === "LA");
                        if (indexLA !== -1) {
                            i.infos.splice(indexLA, 1);
                        }
                        for (const info of i.infos) {
                            if (_this.tinhOpations.findIndex((j) => j.tinhId === info.tinhId) === -1) {
                                _this.tinhOpations.push({
                                    tinhId: info.tinhId,
                                    tinhName: info.tinhName,
                                });
                            }
                        }
                        return i;
                    });
                    _this.valueTinh = _this.valueTinh && _this.valueTinh.tinhId ? _this.valueTinh : _this.tinhOpations[0];
                    if (_this.valueTinh) {
                        _this.getDataTableChuoiSo();
                        _this.getDataTableThongKeChuoiSo();
                        _this.getDataTableChuoiSo6Con();
                        _this.getDataTableChuoiSoBaoLo();
                        _this.getDataTable();
                        _this.getCau100();
                        _this.getChartOptions();
                        _this.getChartDatasets();
                    }

                    _this.isLoading = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getDataHaiMien() {
            this.isLoading = true;
            this.dataMienNam = { dataTableChuoiSo: [] };
            this.dataMienBac = { dataTableChuoiSo: [] };

            let data = {
                fromDate: this.getFormatDate(this.dateFromTo1.startDate, "formatDateAPI"),
                toDate: this.getFormatDate(this.dateFromTo1.endDate, "formatDateAPI"),
            };
            let _this = this;
            Loto.getListChanLe(_this.$root.url, data)
                .then((response) => {
                    let responseData = response.data.map((i) => {
                        let indexLA = i.infos.findIndex((j) => j.tinhId === "LA");
                        if (indexLA !== -1) {
                            i.infos.splice(indexLA, 1);
                        }
                        return i;
                    });
                    let dataChanLe = _this.getDataTableChuoiSo(responseData);
                    for (const item of dataChanLe) {
                        if (item.mien === "MIEN_NAM") {
                            _this.dataMienNam.dataTableChuoiSo.push(item);
                        }
                        if (item.mien === "MIEN_BAC") {
                            _this.dataMienBac.dataTableChuoiSo.push(item);
                        }
                    }
                    _this.getDataChuoiMien(_this.dataMienNam);
                    _this.getDataChuoiMien(_this.dataMienBac);
                    _this.isLoading = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getDataChuoiMien(obj) {
            let listPhanTram = [];
            for (const item of obj.dataTableChuoiSo) {
                listPhanTram.push({
                    chan: this.getCountChuoiSo(item, 'chan', true),
                    le: this.getCountChuoiSo(item, 'le', true)
                });
            }
            obj.listPhanTram = {}
            for (const item of listPhanTram) {
                // chan/le
                let keys = Object.keys(item);
                for (const key of keys) {
                    obj.listPhanTram[key] = obj.listPhanTram[key] ? obj.listPhanTram[key] : {};

                    // giai0, giai1, giai8
                    let keys1 = Object.keys(item[key]);
                    for (const key1 of keys1) {
                        obj.listPhanTram[key][key1] = obj.listPhanTram[key][key1] ? obj.listPhanTram[key][key1] : {};

                        // 1,2,3,...
                        let keys2 = Object.keys(item[key][key1]);
                        for (const key2 of keys2) {
                            obj.listPhanTram[key][key1][key2] = obj.listPhanTram[key][key1][key2] ? obj.listPhanTram[key][key1][key2] : { numberUp: 0, numberDown: 0 };
                            obj.listPhanTram[key][key1][key2].numberUp += item[key][key1][key2].numberUp;
                            obj.listPhanTram[key][key1][key2].numberDown += item[key][key1][key2].numberDown;
                        }
                    }
                }
            }
        },
        getChartOptions() {
            let suggestedMax = 0;
            let gridColor = [];
            let gridTickColor = [];
            let gridLineWidth = [];
            this.chartOptions.scales = {
                x: {
                    grid: {
                        color: this.getColor(6)[0],
                        borderColor: this.getColor(6)[0],
                        tickColor: this.getColor(6)[0],
                        lineWidth: 1,
                    },
                    ticks: {
                        color: this.getColor(0)[0],
                        font: {
                            size: 14,
                        },
                    },
                },
                y: {
                    min: 0,
                    grid: {
                        color: gridColor,
                        borderColor: this.getColor(6)[0],
                        tickColor: gridTickColor,
                        lineWidth: gridLineWidth,
                    },
                    ticks: {
                        color: this.getColor(0)[0],
                        font: {
                            size: 18,
                        },
                        stepSize: 1,
                        callback: function (value, index, ticks) {
                            return value;
                        },
                    },
                },
            };
            switch (this.loaiThongKe.codeKey) {
                case this.loaiThongKeOptions[0].codeKey:
                    suggestedMax = 6;
                    break;
                case this.loaiThongKeOptions[1].codeKey:
                    suggestedMax = this.valueVung === "MIEN_BAC" ? 28 : 18;
                    if (this.loaiBieuDo.codeKey === this.loaiBieuDoOptions[1].codeKey) {
                        this.chartOptions.scales.x.stacked = true;
                        this.chartOptions.scales.y.stacked = true;
                    }
                    break;
                case this.loaiThongKeOptions[2].codeKey:
                    break;
            }
            for (let i = 0; i <= suggestedMax; i++) {
                if (i === suggestedMax / 2) {
                    gridColor.push(this.getColor(0)[0]);
                    gridTickColor.push(this.getColor(0)[0]);
                    gridLineWidth.push(2);
                } else {
                    gridColor.push(this.getColor(6)[0]);
                    gridTickColor.push(this.getColor(6)[0]);
                    gridLineWidth.push(1);
                }
            }
            if (suggestedMax) {
                this.chartOptions.scales.y.suggestedMax = suggestedMax;
                this.chartOptions.scales.y.grid.color = gridColor;
                this.chartOptions.scales.y.grid.tickColor = gridTickColor;
                this.chartOptions.scales.y.grid.lineWidth = gridLineWidth;
                this.chartOptions.scales.y.ticks.count = suggestedMax + 1;
            } else {
                this.chartOptions.scales.y.grid.color = this.getColor(6)[0];
                this.chartOptions.scales.y.grid.tickColor = this.getColor(6)[0];
                this.chartOptions.scales.y.grid.lineWidth = 1;
            }
        },
        getChartDatasets() {
            switch (this.loaiThongKe.codeKey) {
                case this.loaiThongKeOptions[0].codeKey:
                    let totalChan = 0;
                    let totalLe = 0;
                    let chan = 0;
                    let le = 0;
                    this.chartData.datasets = [
                        {
                            label: "Chẵn",
                            data: [],
                            borderColor: this.getColor(0)[0],
                            backgroundColor: this.getColor(0)[0],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        },
                        {
                            label: "Lẻ",
                            data: [],
                            borderColor: this.getColor(2)[0],
                            backgroundColor: this.getColor(2)[0],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        },
                    ];
                    for (const item of this.dataChanLe) {
                        chan = 0;
                        le = 0;
                        for (const info of item.infos) {
                            totalChan += info.totalChan;
                            totalLe += info.totalLe;
                            chan += info.totalChan;
                            le += info.totalLe;
                        }
                        this.chartData.datasets[0].data.push(chan);
                        this.chartData.datasets[0].label = `Chẵn (${totalChan} số)`;
                        this.chartData.datasets[1].data.push(le);
                        this.chartData.datasets[1].label = `Lẻ (${totalLe} số)`;
                    }
                    break;
                case this.loaiThongKeOptions[1].codeKey:
                    let dataDai = [];
                    let number = 0;
                    this.chartData.datasets = [];
                    while (this.dataChanLe.every((i) => i.infos.length > number)) {
                        if (!dataDai[number]) {
                            dataDai[number] = {
                                dataChan: [],
                                dataLe: [],
                            };
                        }
                        for (const [index, item] of this.dataChanLe.entries()) {
                            if (item.infos[number]) {
                                dataDai[number].dataChan.push(item.infos[number].totalChanAll);
                                dataDai[number].dataLe.push(item.infos[number].totalLeAll);
                            }
                        }
                        let objChan = {
                            label: "Chẵn",
                            data: dataDai[number].dataChan,
                            borderColor: this.getColor(this.chartData.datasets.length)[0],
                            backgroundColor: this.getColor(this.chartData.datasets.length)[1],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        };
                        if (this.loaiBieuDo.codeKey === this.loaiBieuDoOptions[1].codeKey) {
                            objChan.stack = "dai-" + number;
                        }
                        this.chartData.datasets.push(objChan);

                        let objLe = {
                            label: "Lẻ",
                            data: dataDai[number].dataLe,
                            borderColor: this.getColor(this.chartData.datasets.length)[0],
                            backgroundColor: this.getColor(this.chartData.datasets.length)[1],
                            borderWidth: 2,
                            borderSkipped: false,
                            pointStyle: "circle",
                            pointRadius: 5,
                            pointHoverRadius: 10,
                            tension: 0.3,
                        };
                        if (this.loaiBieuDo.codeKey === this.loaiBieuDoOptions[1].codeKey) {
                            objLe.stack = "dai-" + number;
                        }
                        this.chartData.datasets.push(objLe);
                        number++;
                    }
                    break;
                case this.loaiThongKeOptions[2].codeKey:
                    this.chartData.datasets = [];
                    let arrNumber = [];
                    let data = [];
                    for (const item of this.dataTableChuoiSo) {
                        if (item.daiId === this.valueTinh.tinhId) {
                            let keys = Object.keys(item.ketQua2So);
                            for (const key of keys) {
                                arrNumber = arrNumber.concat(item.ketQua2So[key]);
                            }
                        }
                    }
                    for (const item of arrNumber) {
                        for (const [index, number] of this.chartData.labels.entries()) {
                            if (!data[index]) {
                                data[index] = 0;
                            }
                            if (item === number) {
                                data[index] += 1;
                            }
                        }
                    }
                    let objLe = {
                        label: "Số",
                        data: data,
                        borderColor: this.getColor(0)[0],
                        backgroundColor: this.getColor(0)[1],
                        borderWidth: 2,
                        borderSkipped: false,
                        pointStyle: "circle",
                        pointRadius: 5,
                        pointHoverRadius: 10,
                        tension: 0.3,
                    };
                    this.chartData.datasets.push(objLe);
                    break;
            }
        },
        getColor(number) {
            switch (number) {
                case 0:
                    return ["#39A7FF", "#39A7FF"];
                case 1:
                    return ["#87C4FF", "#87C4FF"];
                case 2:
                    return ["#FF5B22", "#FF5B22"];
                case 3:
                    return ["#FF9130", "#FF9130"];
                case 4:
                    return ["#557C55", "#557C55"];
                case 5:
                    return ["#A6CF98", "#A6CF98"];
                case 6:
                    return ["#74747678", "#74747678"];
                case 7:
                    return ["#747476", "#747476"];
            }
        },
        getListDate() {
            this.listDate = [];

            let endDate = this.$moment(this.dateFromTo1.endDate);
            let startDate = this.$moment(this.dateFromTo1.startDate);
            this.soNgayView = endDate.diff(startDate, "days") + 1;

            let previousDay = this.$moment(this.getFormatDate(endDate, "formatDateAPI"));
            let textDay = this.getFormatDate(previousDay, "formatDateNoYearVN");
            this.listDate.push(textDay);
            for (let i = 1; i < this.soNgayView; i++) {
                previousDay = previousDay.subtract(1, "days");
                textDay = this.getFormatDate(previousDay, "formatDateNoYearVN");
                this.listDate.unshift(textDay);
            }
            this.chartData.labels = this.listDate;
            this.onChangeVung();
        },
        getDataTableChuoiSo(dataInput) {
            this.dataTableChuoiSo = [];
            this.fieldsTable = [{ key: "date", label: "Ngày", sortable: false, stickyColumn: true }];
            let dataChanLe = dataInput ? dataInput : this.cloneDeep(this.dataChanLe);
            for (const item of dataChanLe) {
                for (const info of item.infos) {
                    let giaiCheck = info.mien === "MIEN_BAC" ? ["giai0", "giai1"] : ["giai0", "giai8"];
                    let obj = this.dataTableChuoiSo.filter((i) => i.daiId === info.tinhId)[0];
                    this.fieldsTable.push({
                        key: info.tinhId,
                        label: info.tinhName,
                        sortable: false,
                    });
                    if (!obj) {
                        obj = {
                            date: [],
                            inputDate: [],
                            mien: info.mien,
                            daiId: info.tinhId,
                            dai: info.tinhName,
                            ketQua: [],
                            ketQua2So: {},
                            chuoiChan: [],
                            chuoiLe: [],
                            chuoiDateChan: [],
                            chuoiDateLe: [],
                        };
                        this.dataTableChuoiSo.push(obj);
                    }
                    obj.date.push(this.getFormatDate(item.inputDate, "formatDateShortYearVN"));
                    obj.inputDate.push(item.inputDate);
                    obj.ketQua.push(info.ketQua);
                    let keys = Object.keys(info.ketQua);
                    for (const key of keys) {
                        info.ketQua[key] = info.ketQua[key].split("\t");
                        if (giaiCheck.includes(key)) {
                            let haiSoCuoi = this.getHaiSoCuoi(info.ketQua[key]);
                            obj.ketQua2So[key] = obj.ketQua2So[key] ? obj.ketQua2So[key].concat(haiSoCuoi) : [].concat(haiSoCuoi);
                        }
                    }
                }
            }
            this.getChuoi(this.dataTableChuoiSo);
            return this.dataTableChuoiSo;
        },
        getDataTableThongKeChuoiSo() {
            this.dataTableThongKeChuoiSo = this.cloneDeep(this.dataTableChuoiSo);
            this.getChuoi(this.dataTableThongKeChuoiSo);
            return this.dataTableThongKeChuoiSo;
        },
        getChuoi(data) {
            for (const obj of data) {
                let keys = Object.keys(obj.ketQua2So);
                let chuoiChan = {};
                chuoiChan[keys[0]] = [];
                chuoiChan[keys[1]] = [];
                let chuoiLe = {};
                chuoiLe[keys[0]] = [];
                chuoiLe[keys[1]] = [];
                obj.listChuoiChan = [];
                obj.listChuoiChan = chuoiChan;
                obj.listChuoiLe = [];
                obj.listChuoiLe = chuoiLe;

                let chuoiDateChan = {};
                chuoiDateChan[keys[0]] = [];
                chuoiDateChan[keys[1]] = [];
                let chuoiDateLe = {};
                chuoiDateLe[keys[0]] = [];
                chuoiDateLe[keys[1]] = [];
                obj.listChuoiDateChan = [];
                obj.listChuoiDateChan = chuoiDateChan;
                obj.listChuoiDateLe = [];
                obj.listChuoiDateLe = chuoiDateLe;
                for (const key of keys) {
                    let numbersChan = "";
                    let numbersLe = "";
                    let dateChan = "";
                    let dateLe = "";
                    for (const [index, item] of obj.ketQua2So[key].entries()) {
                        if (this.checkChanLe(item)) {
                            numbersChan += item + ",";
                            numbersLe = numbersLe ? numbersLe + "|" : "";

                            dateChan += obj.inputDate[index] + ",";
                            dateLe = dateLe ? dateLe + "|" : "";
                        } else {
                            numbersChan = numbersChan ? numbersChan + "|" : "";
                            numbersLe += item + ",";

                            dateChan = dateChan ? dateChan + "|" : "";
                            dateLe += obj.inputDate[index] + ",";
                        }
                    }
                    chuoiChan[key] = numbersChan.split("|").filter((i) => i !== "");
                    chuoiLe[key] = numbersLe.split("|").filter((i) => i !== "");

                    chuoiDateChan[key] = dateChan.split("|").filter((i) => i !== "");
                    chuoiDateLe[key] = dateLe.split("|").filter((i) => i !== "");
                }
                obj.chuoiChan[keys[0]] =
                    chuoiChan[keys[0]].length > 0 ? chuoiChan[keys[0]][chuoiChan[keys[0]].length - 1].slice(0, -1).split(",") : [];
                obj.chuoiChan[keys[1]] =
                    chuoiChan[keys[1]].length > 0 ? chuoiChan[keys[1]][chuoiChan[keys[1]].length - 1].slice(0, -1).split(",") : [];
                obj.chuoiLe[keys[0]] = chuoiLe[keys[0]].length > 0 ? chuoiLe[keys[0]][chuoiLe[keys[0]].length - 1].slice(0, -1).split(",") : [];
                obj.chuoiLe[keys[1]] = chuoiLe[keys[1]].length > 0 ? chuoiLe[keys[1]][chuoiLe[keys[1]].length - 1].slice(0, -1).split(",") : [];

                obj.chuoiDateChan[keys[0]] =
                    chuoiDateChan[keys[0]].length > 0 ? chuoiDateChan[keys[0]][chuoiDateChan[keys[0]].length - 1].slice(0, -1).split(",") : [];
                obj.chuoiDateChan[keys[1]] =
                    chuoiDateChan[keys[1]].length > 0 ? chuoiDateChan[keys[1]][chuoiDateChan[keys[1]].length - 1].slice(0, -1).split(",") : [];
                obj.chuoiDateLe[keys[0]] =
                    chuoiDateLe[keys[0]].length > 0 ? chuoiDateLe[keys[0]][chuoiDateLe[keys[0]].length - 1].slice(0, -1).split(",") : [];
                obj.chuoiDateLe[keys[1]] =
                    chuoiDateLe[keys[1]].length > 0 ? chuoiDateLe[keys[1]][chuoiDateLe[keys[1]].length - 1].slice(0, -1).split(",") : [];
            }
        },
        getDataTableChuoiSo6Con() {
            this.dataTableChuoiSo6Con = [];
            let dataChanLe = this.cloneDeep(this.dataChanLe);
            for (const day of this.thuTrongTuanOptions) {
                if (day.codeKeyStr === "ALL") {
                    continue;
                }
                let obj = {
                    day: day.codeName,
                    date: [],
                    chuoiChan: [],
                    chuoiLe: [],
                };
                for (const item of dataChanLe) {
                    if (this.getFormatDate(item.inputDate, "formatDay") === day.codeKeyStr) {
                        let totalChan = 0;
                        let totalLe = 0;
                        for (const info of item.infos) {
                            totalChan += info.totalChan;
                            totalLe += info.totalLe;
                        }
                        if (totalChan > totalLe) {
                            obj.chuoiChan.push({
                                totalChan: totalChan,
                                totalLe: totalLe,
                            });
                            if (obj.chuoiLe.length > 0) {
                                obj.chuoiLe = [];
                                obj.date = [];
                                obj.isChuoiChan = true;
                            }
                        } else if (totalChan < totalLe) {
                            if (obj.chuoiChan.length > 0) {
                                obj.chuoiChan = [];
                                obj.date = [];
                                obj.isChuoiChan = false;
                            }
                            obj.chuoiLe.push({
                                totalChan: totalChan,
                                totalLe: totalLe,
                            });
                        }
                        obj.date.push(this.getFormatDate(item.inputDate, "formatDateShortYearVN"));
                    }
                }
                this.dataTableChuoiSo6Con.push(obj);
            }
            return this.dataTableChuoiSo6Con;
        },
        getDataTableChuoiSoBaoLo() {
            this.dataTableChuoiSoBaoLo = [];
            let dataChanLe = this.cloneDeep(this.dataChanLe);
            let mocSoSanh = this.valueVung === "MIEN_BAC" ? 15 : 11;
            for (const day of this.thuTrongTuanOptions) {
                if (day.codeKeyStr === "ALL") {
                    continue;
                }
                let obj = {
                    day: day.codeName,
                    chuoi: [],
                };
                for (const item of dataChanLe) {
                    if (this.getFormatDate(item.inputDate, "formatDay") === day.codeKeyStr) {
                        for (const info of item.infos) {
                            let objChuoi = obj.chuoi.filter((i) => i.daiId === info.tinhId)[0];
                            if (!objChuoi) {
                                objChuoi = {
                                    daiId: info.tinhId,
                                    daiName: info.tinhName,
                                    date: [],
                                    dateStrChan: "",
                                    dateStrLe: "",
                                    dateArrChan: [],
                                    dateArrLe: [],
                                    dateChan: [],
                                    dateLe: [],
                                    isChuoiChan: true,
                                    chuoiStrChan: "",
                                    chuoiStrLe: "",
                                    chuoiArrChan: [],
                                    chuoiArrLe: [],
                                    chuoiSoChan: [],
                                    chuoiSoLe: [],
                                }
                                obj.chuoi.push(objChuoi);
                            }
                            let date = this.getFormatDate(this.getDateSubtract('day', item.inputDate, 7), "formatDateShortYearVN");
                            let isTuanLienTiep = objChuoi.date[objChuoi.date.length - 1] ? date === objChuoi.date[objChuoi.date.length - 1] : true;
                            if (info.totalChanAll >= mocSoSanh && isTuanLienTiep) {
                                objChuoi.dateStrChan += this.getFormatDate(item.inputDate, "formatDateShortYearVN") + ",";
                                objChuoi.chuoiStrChan += info.totalChanAll + ",";
                            } else {
                                objChuoi.dateStrChan += objChuoi.dateStrChan ? "|" : "";
                                objChuoi.chuoiStrChan += objChuoi.chuoiStrChan ? "|" : "";
                            }

                            if (info.totalLeAll >= mocSoSanh && isTuanLienTiep) {
                                objChuoi.dateStrLe += this.getFormatDate(item.inputDate, "formatDateShortYearVN") + ",";
                                objChuoi.chuoiStrLe += info.totalLeAll + ",";
                            } else {
                                objChuoi.dateStrLe += objChuoi.dateStrLe ? "|" : "";
                                objChuoi.chuoiStrLe += objChuoi.chuoiStrLe ? "|" : "";
                            }

                            objChuoi.dateArrChan = objChuoi.dateStrChan.split("|").filter((i) => i !== "");
                            objChuoi.dateArrLe = objChuoi.dateStrLe.split("|").filter((i) => i !== "");

                            objChuoi.chuoiArrChan = objChuoi.chuoiStrChan.split("|").filter((i) => i !== "");
                            objChuoi.chuoiArrLe = objChuoi.chuoiStrLe.split("|").filter((i) => i !== "");

                            if (objChuoi.chuoiArrChan.length > 0) {
                                objChuoi.dateChan = objChuoi.dateArrChan[objChuoi.dateArrChan.length - 1].split(",").filter((i) => i !== "");
                                objChuoi.chuoiSoChan = objChuoi.chuoiArrChan[objChuoi.chuoiArrChan.length - 1].split(",").filter((i) => i !== "");
                            }
                            if (objChuoi.chuoiArrLe.length > 0) {
                                objChuoi.dateLe = objChuoi.dateArrLe[objChuoi.dateArrLe.length - 1].split(",").filter((i) => i !== "");
                                objChuoi.chuoiSoLe = objChuoi.chuoiArrLe[objChuoi.chuoiArrLe.length - 1].split(",").filter((i) => i !== "");
                            }

                            objChuoi.date.push(this.getFormatDate(item.inputDate, "formatDateShortYearVN"));
                        }
                    }
                }
                this.dataTableChuoiSoBaoLo.push(obj);
            }
            console.log(this.cloneDeep(this.dataTableChuoiSoBaoLo));
            return this.dataTableChuoiSoBaoLo;
        },
        getDataTable() {
            this.dataTable = [];
            if (this.loaiHienThi.codeKey === this.loaiHienThiOptions[1].codeKey && this.loaiThongKe.codeKey === this.loaiThongKeOptions[2].codeKey) {
                this.fieldsTable = [{ key: "number", label: "", sortable: false, stickyColumn: true }];
                let dataTinh = this.dataTableChuoiSo.filter((i) => i.daiId === this.valueTinh.tinhId)[0];
                for (const [index, item] of dataTinh.inputDate.entries()) {
                    this.fieldsTable.push({
                        key: index.toString(),
                        label: this.getFormatDate(item, "formatDateNoYearNoDateVN"),
                        sortable: false,
                    });
                }
                for (const item of this.chartData.labels) {
                    let obj = {
                        number: item,
                    };
                    let keys = Object.keys(dataTinh.ketQua2So);
                    for (const key of keys) {
                        for (const [index, item1] of dataTinh.ketQua2So[key].entries()) {
                            obj[index.toString()] = obj[index.toString()] ? obj[index.toString()] : 0;
                            if (item1 === item) {
                                obj[index.toString()] += 1;
                            }
                        }
                    }
                    this.dataTable.push(obj);
                }
            } else {
                for (const item of this.dataChanLe) {
                    let obj = {
                        date: item.inputDate,
                    };
                    for (const info of item.infos) {
                        obj[info.tinhId] = {
                            totalChan: info.totalChan,
                            totalChanAll: info.totalChanAll,
                            totalLe: info.totalLe,
                            totalLeAll: info.totalLeAll,
                        };
                    }
                    this.dataTable.push(this.cloneDeep(obj));
                }
            }
            return this.dataTable;
        },
        getHaiSoCuoi(numberArr) {
            let dataReturn = [];
            for (const item of numberArr) {
                dataReturn.push(item.slice(-2));
            }
            return dataReturn;
        },
        getChuoiChanLe(obj) {
            let keys = Object.keys(obj.ketQua2So);
            for (const key of keys) {
                obj.chuoiChan[key] = [];
                obj.chuoiLe[key] = [];
                for (const item of obj.ketQua2So[key]) {
                    if (this.checkChanLe(item)) {
                        obj.chuoiChan[key].push(item);
                        obj.chuoiLe[key] = [];
                    } else {
                        obj.chuoiChan[key] = [];
                        obj.chuoiLe[key].push(item);
                    }
                }
            }
        },
        onClickBtnFilter() {
            this.isShowFilter = !this.isShowFilter;
            let ele = document.getElementsByClassName("group-chart")[0];
            if (this.isShowFilter) {
                ele.style.height = "calc(100vh - 190px)";
                ele.style.maxHeight = "calc(100vh - 190px)";
            } else {
                ele.style.height = "calc(100vh - 100px)";
                ele.style.maxHeight = "calc(100vh - 100px)";
            }
        },
        onChangeVung(mien) {
            if (mien) {
                this.valueVung = mien;
                this.valueTinh = {};
            }
            this.getData();
            this.loaiThongKeOptions[1].codeName = this.valueVung === "MIEN_BAC" ? "Chẳn Lẻ Bao Lô 27 Con" : "Chẳn Lẻ Bao Lô 18 Con";
            this.loaiHienThiOptions[5].codeName = this.valueVung === "MIEN_BAC" ? "Chuỗi chẵn lẻ (Bao lô 27 con)" : "Chuỗi chẵn lẻ (Bao lô 18 con)";
        },
        changeLoaiHienThi() {
            this.activeChuoi = 0;
            this.getListDate();
        },
        changeThuTrongTuan() {
            this.onChangeVung();
        },
        changeLoaiBieuDo() {
            this.onChangeVung();
        },
        changeLoaiThongKe() {
            switch (this.loaiThongKe.codeKey) {
                case this.loaiThongKeOptions[0].codeKey:
                case this.loaiThongKeOptions[1].codeKey:
                    this.getListDate();
                    break;
                case this.loaiThongKeOptions[2].codeKey:
                    this.chartData.labels = [
                        "00",
                        "10",
                        "11",
                        "20",
                        "22",
                        "30",
                        "33",
                        "40",
                        "44",
                        "50",
                        "55",
                        "60",
                        "66",
                        "70",
                        "77",
                        "80",
                        "88",
                        "90",
                        "99",
                    ];
                    this.onChangeVung();
                    break;
            }
        },
        changeSoTuanTruoc(type) {
            this.soNam = 0;
            switch (type) {
                case "cong":
                    this.soTuanTruoc = this.soTuanTruoc + 1;
                    break;
                case "tru":
                    this.soTuanTruoc = this.soTuanTruoc - 1;
                    break;
            }
            this.soTuanTruoc = this.soTuanTruoc < 1 ? 1 : this.soTuanTruoc;
            this.dateFromTo1.startDate = this.getRangeDate("tuanTruoc", this.soTuanTruoc, null)[0];
            this.getListDate();
        },
        changeSoNam(type) {
            this.soTuanTruoc = 0;
            switch (type) {
                case "cong":
                    this.soNam = this.soNam + 1;
                    break;
                case "tru":
                    this.soNam = this.soNam - 1;
                    break;
            }
            this.soNam = this.soNam < 1 ? 1 : this.soNam;
            this.dateFromTo1.startDate = this.getRangeDate("namTruoc", this.soNam, null)[0];
            this.getListDate();
        },
        changeTinh() {
            this.getData();
        },
        changeChuoi(number) {
            this.activeChuoi = number;
            this.getData();
        },
        changeMocHieuQua() {
            this.getData();
        },
        checkChanLe(number) {
            let str1 = Number(number[0]);
            let str2 = Number(number[1]);
            if ((str1 + str2) % 2 == 0) {
                return true;
            } else {
                return false;
            }
        },
        tooltipCustom(context) {
            let _this = this;
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip");

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform");
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add("no-transform");
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = "<thead>";

                titleLines.forEach(function (title) {
                    innerHtml += "<tr><th>" + title + "</th></tr>";
                });
                innerHtml += "</thead><tbody>";

                bodyLines.forEach(function (body, i) {
                    const colors = tooltipModel.labelColors[i];
                    let style = "background:" + colors.backgroundColor;
                    style += "; border-color:" + colors.borderColor;
                    style += "; border-width: 2px";
                    style += "; border-radius: 3px";
                    style += "; padding: 0 3px";
                    style += "; color:" + colors.borderColor;
                    let div = '<div style="' + style + '">a</div>';
                    const span = "<span>" + _this.getTextTootip(i, body, titleLines) + "</span>";
                    innerHtml += "<tr><td>" + div + "</td>";
                    innerHtml += "<td>" + span + "</td></tr>";
                });
                innerHtml += "</tbody>";

                let tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();
            const bodyFont =
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.padding = "5px";
            tooltipEl.style.backgroundColor = "#000";
            tooltipEl.style.color = "#fff";
            tooltipEl.style.borderRadius = "8px";
            tooltipEl.style.transform = "translate(10%, -50%)";
            tooltipEl.style.transition = "all .1s ease";
        },
        getTextTootip(index, body, titleLines) {
            let _this = this;
            let text = "";
            switch (_this.loaiThongKe.codeKey) {
                case _this.loaiThongKeOptions[0].codeKey:
                    text = body[0].split(":")[0].trim().split("(")[0].trim() + ": ";
                    break;
                case _this.loaiThongKeOptions[1].codeKey:
                    text = body[0].split(":")[0].trim();
                    if (titleLines[0].includes("T2")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(2))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(2))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(2))[2].name + ": ";
                        }
                    }
                    if (titleLines[0].includes("T3")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(3))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(3))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(3))[2].name + ": ";
                        }
                    }
                    if (titleLines[0].includes("T4")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(4))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(4))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(4))[2].name + ": ";
                        }
                    }
                    if (titleLines[0].includes("T5")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(5))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(5))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(5))[2].name + ": ";
                        }
                    }
                    if (titleLines[0].includes("T6")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(6))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(6))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(6))[2].name + ": ";
                        }
                    }
                    if (titleLines[0].includes("T7")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(7))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(7))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(7))[2].name + ": ";
                        }
                    }
                    if (titleLines[0].includes("CN")) {
                        if ([0, 1].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(8))[0].name + ": ";
                        }
                        if ([2, 3].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(8))[1].name + ": ";
                        }
                        if ([4, 5].includes(index)) {
                            text += "-" + _this.dataTinh.filter((i) => i.mien === _this.valueVung && i.inWeek.includes(8))[2].name + ": ";
                        }
                    }
                    break;
                case _this.loaiThongKeOptions[2].codeKey:
                    break;
            }
            text += body[0].split(":")[1];
            return text;
        },
        getTextChuoiSo(row, mode, type) {
            let arr = [];
            let text = "";
            let keys = "";
            let number = 0;
            let listPhanTram = [];
            let phanTram = 0;
            let listChuoiDate = type === "chan" ? row.listChuoiDateChan : row.listChuoiDateLe;
            let chuoiDate = type === "chan" ? row.chuoiDateChan : row.chuoiDateLe;
            let date = "";
            switch (mode) {
                case 0:
                    listPhanTram = this.getCountChuoiSo(row, type, true);
                    arr = type === "chan" ? row.chuoiChan : row.chuoiLe;
                    keys = Object.keys(arr);
                    for (const key of keys) {
                        phanTram = listPhanTram[key][arr[key].length];
                        if (key === "giai0") {
                            text += "Đuôi: ";
                        }
                        if (key === "giai1") {
                            text += "Đuôi giải nhất: ";
                        }
                        if (key === "giai8") {
                            text += "Đầu: ";
                        }
                        for (const [index, item] of chuoiDate[key].entries()) {
                            if (!date.includes(this.getFormatDate(item, "formatDateShortYearVN"))) {
                                date += this.getFormatDate(item, "formatDateShortYearVN") + (index === chuoiDate[key].length - 1 ? "" : ", ");
                            }
                        }
                        text += arr[key].length + "\t";
                        text += arr[key].length
                            ? `<label data-title="${date}" ` +
                            `class='${key} tooltip-block color-${arr[key].length > 10 ? arr[key].length - 9 : arr[key].length} text-chuoi'>` +
                            `[${arr[key].join(", ")}] ` +
                            `(Chuỗi ${arr[key].length}: lên ${phanTram.numberUp}, gãy ${phanTram.numberDown}) (${phanTram.phanTram}%)` +
                            `</label>\n`
                            : "\n";
                        date = "";
                    }
                    break;
                case 1:
                    arr = type === "chan" ? row.listChuoiChan : row.listChuoiLe;
                    keys = Object.keys(arr);
                    date = [];
                    for (const key of keys) {
                        if (key === "giai0") {
                            text += "Đuôi: ";
                        }
                        if (key === "giai1") {
                            text += "Đuôi giải nhất: ";
                        }
                        if (key === "giai8") {
                            text += "Đầu: ";
                        }
                        text += "\t";
                        for (const [index, item] of listChuoiDate[key].entries()) {
                            for (const [index1, item1] of item.slice(0, -1).split(",").entries()) {
                                if (!date.includes(this.getFormatDate(item1, "formatDateShortYearVN"))) {
                                    date[index] = date[index]
                                        ? date[index] +
                                        this.getFormatDate(item1, "formatDateShortYearVN") +
                                        (index1 === item.slice(0, -1).split(",").length - 1 ? "" : ", ")
                                        : this.getFormatDate(item1, "formatDateShortYearVN") +
                                        (index1 === item.slice(0, -1).split(",").length - 1 ? "" : ", ");
                                }
                            }
                        }
                        for (const [index, item] of arr[key].entries()) {
                            number =
                                item.slice(0, -1).split(",").length > 10
                                    ? item.slice(0, -1).split(",").length - 9
                                    : item.slice(0, -1).split(",").length;
                            text +=
                                `<label data-title="${date[index]}"` +
                                `class='${key + "_" + row.daiId + "_" + type} tooltip-block color-${number} text-chuoi'>` +
                                `[${item.slice(0, -1)}]` +
                                `</label>`;
                        }
                        text += "\n\n";
                        date = [];
                    }
                    break;
            }
            if (this.isAddEventChuoiSo) {
                setTimeout(() => {
                    let listDomChuoiSo = document.getElementsByClassName("tooltip-block");
                    for (const item of listDomChuoiSo) {
                        item.addEventListener("click", () => {
                            this.copyDate(item.getAttribute("data-title"));
                        });
                    }
                }, 300);
                this.isAddEventChuoiSo = false;
            }
            return text;
        },
        getCountChuoiSo(row, type, isGetPhanTram) {
            let item = type === "chan" ? row.listChuoiChan : row.listChuoiLe;
            let text = `Chuỗi ${type === "chan" ? "Chẵn" : "Lẻ"}\n`;
            let listNumber = {};
            let listPhanTram = {};
            let now = row.inputDate[row.inputDate.length - 1];
            let keys = Object.keys(item);
            for (const key of keys) {
                listNumber[key] = listNumber[key] ? listNumber[key] : {};
                listPhanTram[key] = listPhanTram[key] ? listPhanTram[key] : {};
                if (key === "giai0") {
                    text += "Đuôi: ";
                }
                if (key === "giai1") {
                    text += "Đuôi giải nhất: ";
                }
                if (key === "giai8") {
                    text += "Đầu: ";
                }
                text += "\n";
                for (const [index, item1] of item[key].entries()) {
                    let number = item1.slice(0, -1).split(",").length;
                    for (let i = 1; i <= number; i++) {
                        listNumber[key][i] = listNumber[key][i] ? listNumber[key][i] + 1 : 1;
                    }
                }
                let keys1 = Object.keys(listNumber[key]);
                for (const key1 of keys1) {
                    let chuoiDate = type === "chan" ? row.chuoiDateChan[key][key1 - 1] : row.chuoiDateLe[key][key1 - 1];
                    let numberDown = listNumber[key][Number(key1) + 1] ? listNumber[key][key1] - listNumber[key][Number(key1) + 1] : 0;
                    let isChuoiHienTai = chuoiDate && numberDown > 0 ? chuoiDate.includes(now) : false;
                    if (isChuoiHienTai) {
                        numberDown -= 1;
                    }
                    let numberUp = listNumber[key][Number(key1) + 1] ? listNumber[key][Number(key1) + 1] : 0;
                    let phanTram = numberUp + numberDown > 0 ? Math.round((numberUp / (numberUp + numberDown)) * 100) : 0;
                    listPhanTram[key][key1] = {
                        phanTram: phanTram,
                        numberDown: numberDown,
                        numberUp: numberUp,
                    };
                    let classStr = `color-1 text-chuoi w-100 ${key + "_" + row.daiId + "_" + type}_count ${(this.soMocHieuQua > 0 && phanTram < this.soMocHieuQua) ? "hidden" : ""}`
                    text +=
                        `<label data-key='${key1}'` +
                        `class='${classStr}'>` +
                        `Chuỗi ${key1}: ${listNumber[key][key1]} lần \n` +
                        `[Lên ${numberUp}, Gãy ${numberDown} ${isChuoiHienTai ? ", Hiện tại 1" : ""}] [${phanTram}%]` +
                        `</label>`;
                    setTimeout(() => {
                        let listDomChuoiSo = document.getElementsByClassName(key + "_" + row.daiId + "_" + type);
                        for (const item of listDomChuoiSo) {
                            let lengthNumber = item.innerText.split(",").length;
                            let phanTram = listPhanTram[key][lengthNumber - 1] ? listPhanTram[key][lengthNumber - 1].phanTram : listPhanTram[key][lengthNumber].phanTram;
                            if (this.soMocHieuQua > 0 && phanTram < this.soMocHieuQua) {
                                item.classList.add("hidden");
                            } else {
                                item.classList.remove("hidden");
                            }
                        }
                    }, 300);
                    if (this.activeChuoi === 1) {
                        setTimeout(() => {
                            let listDomCountChuoiSo = document.getElementsByClassName(key + "_" + row.daiId + "_" + type + "_count");
                            for (const item of listDomCountChuoiSo) {
                                let isLocChuoiSo = true;
                                if (item.getAttribute("da-add-event")) {
                                    return;
                                }
                                item.setAttribute("da-add-event", "true");
                                item.addEventListener("click", () => {
                                    if (item.classList.contains("active-loc-chuoi-so")) {
                                        item.classList.remove("active-loc-chuoi-so");
                                        isLocChuoiSo = false;
                                    } else {
                                        for (const item2 of listDomCountChuoiSo) {
                                            item2.classList.remove("active-loc-chuoi-so");
                                        }
                                        item.classList.add("active-loc-chuoi-so");
                                        isLocChuoiSo = true;
                                    }
                                    let numberChuoi = Number(item.getAttribute("data-key"));
                                    let listDomChuoiSo = document.getElementsByClassName(key + "_" + row.daiId + "_" + type);
                                    for (const item1 of listDomChuoiSo) {
                                        if (!isLocChuoiSo) {
                                            item1.classList.remove("hidden");
                                        } else if (item1.innerHTML.split(",").length === numberChuoi) {
                                            item1.classList.remove("hidden");
                                        } else {
                                            item1.classList.add("hidden");
                                        }
                                    }
                                });
                            }
                        }, 300);
                    }
                }
                text += "\n\n";
            }
            return isGetPhanTram ? listPhanTram : text;
        },
        getTextChuoiSo6Con(arr, type) {
            let text = "-"
            if (arr.length) {
                text = `Chuỗi ${arr.length} ${type === "chan" ? "chẵn" : "lẻ"}\t`;
                text += `<span class='color-${arr.length > 10 ? arr.length - 9 : arr.length} text-chuoi'>`;
                for (const [index, item] of arr.entries()) {
                    text += "(" + item.totalChan + " Chẵn, " + item.totalLe + " Lẻ" + ")";
                    text += index === arr.length - 1 ? "" : "; ";
                }
                text += "</span>";
            }
            return text;
        },
        getTextChuoiSoBaoLo(data, type) {
            let chuoiSo = type === "chan" ? data.chuoiSoChan : data.chuoiSoLe;
            let date = type === "chan" ? data.dateChan : data.dateLe;
            let text = "";
            if (chuoiSo.length && chuoiSo.length >= this.soLocChuoi) {
                text = `Chuỗi ${chuoiSo.length} ${type === "chan" ? "chẵn" : "lẻ"}\t`;
                if (chuoiSo.length) {
                    text += `<label data-title="${date.join(", ")}" class='tooltip-block color-${chuoiSo.length > 10 ? chuoiSo.length - 9 : chuoiSo.length} text-chuoi'>[`;
                    for (const [index, item] of chuoiSo.entries()) {
                        text += `${item}${index === chuoiSo.length - 1 ? "" : ", "}`;
                    }
                    text += "]</label>\n";
                }
            } else {
                text = "-\n";
            }
            if (this.isAddEventChuoiSo) {
                setTimeout(() => {
                    let listDomChuoiSo = document.getElementsByClassName("tooltip-block");
                    for (const item of listDomChuoiSo) {
                        item.addEventListener("click", () => {
                            this.copyDate(item.getAttribute("data-title"));
                        });
                    }
                }, 300);
                this.isAddEventChuoiSo = false;
            }
            return text;
        },
        getTextTable(obj) {
            let text = "";
            let chan = "";
            let le = "";
            switch (this.loaiThongKe.codeKey) {
                case this.loaiThongKeOptions[0].codeKey:
                    chan = obj.totalChan;
                    le = obj.totalLe;
                    break;
                case this.loaiThongKeOptions[1].codeKey:
                    chan = obj.totalChanAll;
                    le = obj.totalLeAll;
                    break;
                case this.loaiThongKeOptions[2].codeKey:
                    text = obj;
                    break;
            }
            if (chan || le) {
                text += `<div class='text-chan'>${chan} Chẵn</div>`;
                text += `<div class='text-le'>${le} Lẻ</div>`;
            } else if (!text) {
                text = "-";
            }
            return text;
        },
        getTextChuoiMien(data) {
            let text = "";
            // giai0, giai1, giai8
            let keys = Object.keys(data);
            for (const key of keys) {
                text += `<div>`;
                if (key === "giai0") {
                    text += "Đuôi: ";
                }
                if (key === "giai1") {
                    text += "Đuôi giải nhất: ";
                }
                if (key === "giai8") {
                    text += "Đầu: ";
                }
                text += "\t";
                // 1,2,3,...
                let keys1 = Object.keys(data[key]);
                for (const key1 of keys1) {
                    let phanTram = (data[key][key1].numberUp + data[key][key1].numberDown) > 0 ? Math.round((data[key][key1].numberUp / (data[key][key1].numberUp + data[key][key1].numberDown)) * 100) : 0;
                    if (phanTram > this.soMocHieuQua) {
                        text +=
                            `<label data-key='${key1}' class='color-1 text-chuoi'>` +
                            `Chuỗi ${key1}: ${data[key][key1].numberUp + data[key][key1].numberDown} lần \n` +
                            `[Lên ${data[key][key1].numberUp}, Gãy ${data[key][key1].numberDown}] [${phanTram}%]` +
                            `</label>`;
                    }
                }
                text += "</div>\n";
            }
            return text;
        },
        copyDate(date) {
            navigator.clipboard.writeText(date);
            this.showToast(`Đã copy thành công`, "Thông Báo", this, "success");
        },
    },
};
</script>

<style lang="scss">
.style-for-web .thong-ke-lo-de {
    .thong-ke-lo-de-content {
        position: relative;
    }

    .group-item-filter {
        justify-content: flex-start;
        height: 38px;

        .input-number {
            flex-wrap: nowrap;
            height: 100%;
            min-width: 90px;
            max-width: 90px;

            .form-control {
                padding: 0.375rem 0.35rem;
            }

            .btn-cong {
                height: 50%;
                width: 100%;
                border-radius: 0 8px 0 0;
                margin: 0;

                .icon {
                    stroke-width: 1px;
                }
            }

            .btn-tru {
                height: 50%;
                width: 100%;
                border-radius: 0 0 8px 0;
                margin: 0;

                .icon {
                    stroke-width: 1px;
                }
            }
        }
    }

    .group-chart {
        max-height: calc(100vh - 190px);
        width: 100%;
        display: flex;
        justify-content: center;
        transition: all 0.3s;

        canvas {
            background: var(--chart-background);
            padding: 5px;
            border-radius: 8px;
        }
    }

    .fieldset-nhap-du-lieu {
        width: calc((100% - 15px) / 3);
        margin-right: 5px;

        &:first-child {
            border-width: 3px;

            legend {
                font-weight: bold;
            }
        }
    }

    .group-dai {
        display: flex;

        >div {
            width: 50%;
            padding: 5px;
        }

        input {
            padding: 0.375rem 0.375rem;
        }
    }

    .group-table {
        .chuoi {
            display: flex;
            white-space: nowrap;
            justify-content: start;
            align-items: center;

            >div {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .text-chan {
                border: 3px solid var(--btn-primary-color);
                border-radius: 8px;
                background-color: var(--btn-primary-color-hover);
                width: 70px;
                height: 30px;
                font-weight: bold;
                color: var(--text-btn-primary-color);
            }

            .text-le {
                border: 3px solid var(--btn-danger-color);
                border-radius: 8px;
                background-color: var(--btn-danger-color-hover);
                width: 70px;
                height: 30px;
                font-weight: bold;
                color: var(--text-btn-danger-color);
            }
        }

        .b-table-sticky-header {
            min-width: 80vh;
            margin-bottom: 0;

            >.table.b-table>thead>tr>th {
                min-width: 150px;
                width: 150px;
            }

            >.table.b-table>tbody>tr {
                >td {
                    padding: 0;
                    vertical-align: middle;

                    >div {
                        width: 100%;
                        height: 100%;
                        padding: 0.3rem;
                    }

                    .no-data {
                        background-color: var(--input-disabled-color);
                    }
                }

                &:hover {
                    .no-data {
                        background-color: var(--btn-primary-color-hover);
                        color: var(--white-color);
                    }
                }
            }
        }
    }

    .table-100-so {
        display: flex;
        flex-wrap: wrap;
        height: 75vh;

        >div {
            &:first-child {
                position: relative;
                width: 700px;

                .item {
                    display: inline-block;
                    text-align: center;
                    width: calc((100% / 10) - 4px);
                    border: 1px solid;
                    padding: 3px;
                    margin: 2px;
                    border-radius: 3px;
                    transition: all 0.5s;

                    label {
                        margin: 0;
                        font-weight: bold;

                        &:first-child {
                            font-size: 18px;
                            color: var(--small-text-2-color);
                        }

                        &:last-child {
                            font-size: 14px;
                            color: var(--text-color-dark);
                        }
                    }

                    &.no-data {
                        border-color: var(--btn-secondary-color);
                        background-color: var(--btn-secondary-color);

                        label {
                            color: var(--small-text-color);
                        }
                    }

                    &-even {
                        border-color: var(--btn-secondary-color);
                        background-color: var(--even-background);
                    }

                    &-odd {
                        border-color: var(--btn-secondary-color);
                        background-color: var(--odd-background);
                    }
                }
            }

            &:last-child {
                width: calc(100% - 700px);
                height: 100%;
                display: flex;

                >div {
                    width: 100%;
                    height: 100%;
                    border: 3px solid var(--header-bao-cao-phan-tich-background);
                    padding: 10px;
                    margin: 0 10px;
                    border-radius: 8px;

                    .mr-2 {
                        font-weight: bold;
                    }

                    >div:nth-child(3) {
                        border-bottom: 1px solid var(--header-bao-cao-phan-tich-background);
                        margin-bottom: 2px;
                    }
                }

                .group-so-lan-ra {
                    overflow: auto;
                    height: calc(100% - 65px);

                    >div {
                        border-bottom: 1px solid var(--header-bao-cao-phan-tich-background);
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }

    .data-chuoi-hai-mien {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        white-space: pre-wrap;

        >div {
            width: 49.5%;

            .group-data {
                padding-left: 5px;

                >div {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 5px;

                    >div {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                    }
                }
            }
        }
    }

    .table-chuoi-so {
        .chuoi {
            white-space: pre-wrap;
        }

        .chuoi-ngay {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 99%;
        }

        .b-table-sticky-header {
            min-width: 80vh;
            margin-bottom: 0;

            >.table.b-table>thead>tr>th {
                position: sticky !important;

                &:first-child {
                    width: 200px;
                    max-width: 200px;
                }
            }
        }
    }

    .table-chuoi-so-6-con {
        .chuoi {
            white-space: pre-wrap;
        }

        .chuoi-ngay {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 99%;
        }

        .b-table-sticky-header {
            min-width: 80vh;
            margin-bottom: 0;

            >.table.b-table>thead>tr>th {
                position: sticky !important;

                &:first-child {
                    width: 100px;
                }
            }
        }
    }

    .table-chuoi-so-bao-lo {
        .chuoi {
            white-space: pre-wrap;
        }

        .chuoi-ngay {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 99%;

            label {
                margin: 0;

                &:first-child {
                    width: 100px;
                    font-weight: bold;
                }
            }
        }

        .b-table-sticky-header {
            min-width: 80vh;
            margin-bottom: 0;

            >.table.b-table>thead>tr>th {
                position: sticky !important;

                &:first-child {
                    width: 100px;
                }
            }
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .group-button-chuoi {
        button {
            width: 100%;
        }
    }

    .count-chuoi-so {
        display: flex;

        >div {
            font-size: 12px;

            &:first-child {
                width: 50%;
                padding: 1px 3px;
                border-right: 1px solid var(--modal-input-border);
            }

            &:last-child {
                width: 50%;
                padding: 1px 3px;
            }
        }
    }

    .text-chuoi {
        border-radius: 3px;
        font-size: 12px;
        padding: 1px 2px;
        margin: 2px 0 0px 2px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        }
    }

    .active-loc-chuoi-so {
        color: var(--text-8-color) !important;
        background-color: var(--text-8-background) !important;
        font-weight: bold;
    }

    .color-1 {
        color: var(--text-1-color);
        background-color: var(--text-1-background);
    }

    .color-2 {
        color: var(--text-2-color);
        background-color: var(--text-2-background);
    }

    .color-3 {
        color: var(--text-3-color);
        background-color: var(--text-3-background);
    }

    .color-4 {
        color: var(--text-4-color);
        background-color: var(--text-4-background);
    }

    .color-5 {
        color: var(--text-5-color);
        background-color: var(--text-5-background);
    }

    .color-6 {
        color: var(--text-6-color);
        background-color: var(--text-6-background);
    }

    .color-7 {
        color: var(--text-7-color);
        background-color: var(--text-7-background);
    }

    .color-8 {
        color: var(--text-8-color);
        background-color: var(--text-8-background);
    }

    .color-9 {
        color: var(--text-9-color);
        background-color: var(--text-9-background);
    }

    .color-10 {
        color: var(--text-10-color);
        background-color: var(--text-10-background);
    }

    .hidden {
        display: none;
    }

    .tooltip-block {
        position: relative;

        &:hover:before {
            white-space: nowrap;
            color: var(--white-color);
            border: 1px solid var(--black-color);
            border-radius: 8px;
            padding: 1px 26px;
            background: var(--black-color);
            content: attr(data-title);
            position: absolute;
            pointer-events: none;
            right: 0;
            top: -22px;
            z-index: 999;
        }
    }
}

.chuoi-tooltip-class {
    .tooltip-inner {
        max-width: 30vw;
        max-height: 30vh;
        overflow: auto;
        text-align: left;
    }
}
</style>
