<template>
    <div id="player" class="player">
        <pc-header :title="title" :backTo="backTo" />
        <div class="player-content">
            <b-row>
                <b-col offset="1" cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Mode</span>
                        <multi-select class="multi-select" v-model="valueMaster3" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="dataMaster3" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Nhà Cái</span>
                        <multi-select class="multi-select" v-model="valueMaster2" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="dataMaster2" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Tên Người Chơi</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="userName"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="onClickSearchBtn()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                        <b-button variant="primary" @click="onClickAddBtn()">
                            <svg class="icon icon-add-square5">
                                <use xlink:href="#icon-add-square5"></use>
                            </svg>
                            <span class="ml-2">Thêm Người Chơi</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <div class="table-data">
                        <b-table sticky-header hover small :items="dataTable" :fields="fields" :head-variant="$root.theme"
                            :dark="$root.theme === 'dark'" show-empty empty-text="Không có dữ liệu" :busy="isBusy"
                            :per-page="perPage" :current-page="currentPage">
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(status)="scope">
                                <span>{{ scope.item.status === 0 ? "Open" : "Close" }}</span>
                            </template>
                            <template #cell(action)="scope">
                                <div class="button-action text-left">
                                    <b-button variant="primary" @click="onClickEditItemBtn(scope.item)">
                                        <svg class="icon icon-edit-25">
                                            <use xlink:href="#icon-edit-25"></use>
                                        </svg>
                                    </b-button>
                                    <b-button variant="danger" @click="onClickDeleteItemBtn(scope.item)">
                                        <svg class="icon icon-trash5">
                                            <use xlink:href="#icon-trash5"></use>
                                        </svg>
                                    </b-button>
                                    <b-button v-if="scope.item.status === 0" variant="success"
                                        @click="onClickRunCrawlDataMember(scope.item)">
                                        <svg class="icon icon-play-cricle5">
                                            <use xlink:href="#icon-play-cricle5"></use>
                                        </svg>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination v-model="currentPage" :total-rows="dataTable.length" :per-page="perPage" first-number
                            last-number align="center"></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>

        <!-- modal -->
        <b-modal centered id="modal-nguoi-choi" :title="seletedRow ? 'CHỈNH SỬA NGƯỜI CHƠI' : 'THÊM NGƯỜI CHƠI'" scrollable
            size="lg" :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div class="d-block">
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Down Line<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.downLine"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">NCC<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.supplier"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Nhà Cái<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <multi-select class="multi-select" v-model="dataForm.domain" deselect-label="Không thể bỏ chọn"
                                track-by="codeKey" label="codeName" placeholder="" :options="dataMaster2"
                                :searchable="false" :allow-empty="false">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">TK Chính<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.userName"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Login ID<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Mật Khẩu<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Mật Khẩu Code</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Trạng Thái<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <multi-select class="multi-select" v-model="dataForm.status" deselect-label="Không thể bỏ chọn"
                                track-by="key" label="label" placeholder="" :options="dataStatus" :searchable="false"
                                :allow-empty="false">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Ngày Bắt Đầu</div>
                        <b-input-group class="mb-3">
                            <date-range-picker v-model="dataForm.crDateFrom" :single-date-picker="true"
                                :locale-data="$root.localeData" :show-dropdowns="true" :auto-apply="true" :ranges="false"
                                :linked-calendars="false" @toggle="toggleDatePicker($event)"
                                opens="center"></date-range-picker>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Ngày Kết Thúc</div>
                        <b-input-group class="mb-3">
                            <date-range-picker v-model="dataForm.crDateTo" :single-date-picker="true"
                                :locale-data="$root.localeData" :show-dropdowns="true" :auto-apply="true" :ranges="false"
                                :linked-calendars="false" @toggle="toggleDatePicker($event)"
                                opens="center"></date-range-picker>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Chú Thích</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Scan</div>
                        <b-input-group class="mb-3">
                            <b-form-checkbox v-model="dataForm.scan"></b-form-checkbox>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button block @click="closeModal('modal-nguoi-choi')"> Hủy </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button variant="primary" block @click="submit(seletedRow ? 'edit' : 'add')">
                            {{ seletedRow ? "Lưu" : "Thêm" }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <b-modal centered id="modal-xac-nhan-xoa" title="XÁC NHẬN XÓA" scrollable size="sm"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div class="msg-xac-nhan-xoa">
                Bạn Chắc Chắn Muốn Xóa Người Chơi <b>{{ seletedRow ? seletedRow.userName : "" }}</b>?
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col offset="4" cols="4">
                        <b-button id="btn-xac-nhan-ko-xoa" block @click="closeModal('modal-xac-nhan-xoa')"> Không
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button id="btn-xac-nhan-co-xoa" variant="danger" block @click="submit('delete')"> Có </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Master from "../../../service/master.service";
import Crawldata from "../../../service/crawldata.service";

export default {
    name: "PlayerPage",
    data() {
        return {
            title: "QUẢN LÝ NGƯỜI CHƠI",
            backTo: "menu",
            dataStatus: [
                {
                    key: 0,
                    label: "Open",
                },
                {
                    key: 1,
                    label: "Close",
                },
            ],
            dataMaster2: [],
            dataMaster3: [],
            valueMaster2: {},
            valueMaster3: {},
            userName: "",
            fields: [
                { key: "index", label: "STT", sortable: false },
                { key: "downLine", label: "Down Line", sortable: true },
                { key: "supplier", label: "NCC", sortable: true },
                { key: "typeDomainNm", label: "Nhà Cái", sortable: true },
                { key: "userName", label: "TK Chính", sortable: true },
                { key: "loginId", label: "Login Id", sortable: true },
                { key: "passWord", label: "Mật Khẩu", sortable: true },
                { key: "passCode", label: "Mật Khẩu Code", sortable: true },
                { key: "status", label: "Trạng Thái", sortable: true },
                { key: "note", label: "Ghi Chú", sortable: true },
                { key: "crDateFromFormat", label: "Ngày Bắt Đầu", sortable: true },
                { key: "crDateToFormat", label: "Ngày Kết Thúc", sortable: true },
                { key: "crDatetimeRunFormat", label: "Ngày Chạy", sortable: true },
                { key: "action", label: "", sortable: false },
            ],
            dataTable: [],
            isBusy: false,
            seletedRow: null,
            dataForm: {
                crDateFrom: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                crDateTo: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                crDatetimeRun: "",
                delivered: "",
                downLine: "",
                loginId: "",
                note: "",
                passCode: "",
                passWord: "",
                status: "",
                supplier: "",
                domain: {},
                userName: "",
                scan: false,
            },
            perPage: 25,
            currentPage: 1,
        };
    },
    computed: {
        ...mapState("PlayerPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListMasterCodeByClass("00002");
        this.getListMasterCodeByClass("00003");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("PlayerPage", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && document.getElementById("modal-nguoi-choi")) {
                        this.submit(this.seletedRow ? "edit" : "add");
                    } else if (!isForcusDropdown && document.getElementById("modal-xac-nhan-xoa")) {
                        this.submit("delete");
                    } else if (!isForcusDropdown) {
                        this.onClickSearchBtn();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    if (document.getElementById("modal-nguoi-choi")) {
                        this.closeModal("modal-nguoi-choi");
                    } else if (document.getElementById("modal-xac-nhan-xoa")) {
                        this.closeModal("modal-xac-nhan-xoa");
                    }
                    e.preventDefault();
                    break;
                case 112: // f1
                    this.onClickAddBtn();
                    e.preventDefault();
                    break;
            }
        },
        getListMasterCodeByClass(codeClass) {
            let _this = this;
            Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    switch (codeClass) {
                        case "00002":
                            _this.dataMaster2 = response.data;
                            break;
                        case "00003":
                            _this.dataMaster3 = response.data;
                            _this.valueMaster3 = _this.dataMaster3.filter((item) => item.codeKey === "4")[0];
                            _this.getDataListPlayer();
                            break;
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getDataListPlayer() {
            this.isBusy = true;
            let _this = this;
            let params = {
                mode: this.valueMaster3.codeKey,
                typeDomain: this.valueMaster2.codeKey || "",
                userName: this.userName,
            };
            Crawldata.getPlayers(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data.map((item, index) => {
                        item.index = index + 1;
                        item.crDateFromFormat = _this.getFormatDate(item.crDateFrom, "formatDate");
                        item.crDateToFormat = _this.getFormatDate(item.crDateTo, "formatDate");
                        item.crDatetimeRunFormat = _this.getFormatDate(item.crDatetimeRun, "formatDateTimeNoSecond");
                        return item;
                    });
                    if (_this.valueMaster3.codeKey === "4" || _this.valueMaster3.codeKey === "5") {
                        _this.fields = [
                            { key: "index", label: "STT", sortable: false },
                            { key: "downLine", label: "Down Line", sortable: true },
                            { key: "supplier", label: "NCC", sortable: true },
                            { key: "typeDomainNm", label: "Nhà Cái", sortable: true },
                            { key: "userName", label: "TK Chính", sortable: true },
                            { key: "loginId", label: "Login Id", sortable: true },
                            { key: "passWord", label: "Mật Khẩu", sortable: true },
                            { key: "passCode", label: "Mật Khẩu Code", sortable: true },
                            { key: "status", label: "Trạng Thái", sortable: true },
                            { key: "note", label: "Ghi Chú", sortable: true },
                            { key: "crDateFromFormat", label: "Ngày Bắt Đầu", sortable: true },
                            { key: "crDateToFormat", label: "Ngày Kết Thúc", sortable: true },
                            { key: "crDatetimeRunFormat", label: "Ngày Chạy", sortable: true },
                            { key: "action", label: "", sortable: false },
                        ];
                    } else {
                        _this.fields = [
                            { key: "index", label: "STT", sortable: false },
                            { key: "downLine", label: "Down Line", sortable: true },
                            { key: "supplier", label: "NCC", sortable: true },
                            { key: "typeDomainNm", label: "Nhà Cái", sortable: true },
                            { key: "userName", label: "TK Chính", sortable: true },
                            { key: "loginId", label: "Login Id", sortable: true },
                            { key: "passWord", label: "Mật Khẩu", sortable: true },
                            { key: "passCode", label: "Mật Khẩu Code", sortable: true },
                            { key: "status", label: "Trạng Thái", sortable: true },
                            { key: "note", label: "Ghi Chú", sortable: true },
                            { key: "action", label: "", sortable: false },
                        ];
                    }
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.dataTable = [];
                    _this.isBusy = false;
                    _this.errorApi(error, _this);
                });
        },
        runCrawlDataMemberByDomain(item) {
            localStorage.setItem("loading", 1);
            this.showToast("Bắt đầu chạy thu thập dữ liệu thành viên. Xin chờ trong ít phút.", "Thông báo", this, "warning", 5000);
            let _this = this;
            let params = {
                userName: item.userName,
                typeDomain: item.typeDomain,
            };
            Crawldata.runCrawlDataMemberByDomain(_this.$root.url, params)
                .then((response) => {
                    if (response) {
                        _this.showToast("Đã chạy thu thập dữ liệu thành viên xong.", "Thông báo", this, "success", 5000);
                    }
                    localStorage.setItem("loading", 0);
                })
                .catch((error) => {
                    _this.dataTable = [];
                    _this.isBusy = false;
                    _this.errorApi(error, _this);
                });
        },
        onClickSearchBtn() {
            this.getDataListPlayer();
        },
        onClickAddBtn() {
            this.seletedRow = null;
            this.dataForm = {
                crDateFrom: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                crDateTo: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                crDatetimeRun: "",
                delivered: "",
                downLine: "",
                loginId: "",
                note: "",
                passCode: "",
                passWord: "",
                status: "",
                supplier: "",
                domain: {},
                userName: "",
                scan: false,
            };
            this.$bvModal.show("modal-nguoi-choi");
        },
        onClickRunCrawlDataMember(item) {
            this.runCrawlDataMemberByDomain(item);
        },
        onClickEditItemBtn(item) {
            this.seletedRow = this.cloneDeep(item);
            this.dataForm = {
                crDateFrom: {
                    startDate: item.crDateFrom,
                    endDate: item.crDateFrom,
                },
                crDateTo: {
                    startDate: item.crDateTo,
                    endDate: item.crDateTo,
                },
                crDatetimeRun: item.crDatetimeRun,
                delivered: item.delivered,
                downLine: item.downLine,
                loginId: item.loginId,
                note: item.note,
                passCode: item.passCode,
                passWord: item.passWord,
                status: this.dataStatus.filter((i) => i.key === item.status)[0],
                supplier: item.supplier,
                domain: this.dataMaster2.filter((i) => i.codeKey === item.typeDomain)[0],
                userName: item.userName,
                scan: item.scan,
            };
            this.$bvModal.show("modal-nguoi-choi");
        },
        onClickDeleteItemBtn(item) {
            this.seletedRow = this.cloneDeep(item);
            this.$bvModal.show("modal-xac-nhan-xoa");
        },
        closeModal(id) {
            this.$bvModal.hide(id);
        },
        submit(mode) {
            let data = {
                crDateFrom: this.getFormatDate(this.dataForm.crDateFrom.startDate, "formatDateAPI"),
                crDateTo: this.getFormatDate(this.dataForm.crDateTo.startDate, "formatDateAPI"),
                crDatetimeRun: this.seletedRow ? this.seletedRow.crDatetimeRun : "",
                delivered: this.dataForm.delivered,
                downLine: this.dataForm.downLine,
                loginId: this.dataForm.loginId,
                mode: this.valueMaster3.codeKey,
                modeNm: this.valueMaster3.codeName,
                note: this.dataForm.note,
                passCode: this.dataForm.passCode,
                passWord: this.dataForm.passWord,
                status: this.dataForm.status.key,
                supplier: this.dataForm.supplier,
                typeDomain: this.dataForm.domain.codeKey,
                typeDomainNm: this.dataForm.domain.codeName,
                typeManager: this.seletedRow ? this.seletedRow.typeManager : "",
                typeManagerNm: this.seletedRow ? this.seletedRow.typeManagerNm : "",
                updDate: this.dataForm.updDate,
                userName: this.dataForm.userName,
            };
            let _this = this;
            switch (mode) {
                case "add":
                    Crawldata.addPlayer(_this.$root.url, data)
                        .then((response) => {
                            console.log(response);
                            _this.getDataListPlayer();
                            _this.closeModal("modal-nguoi-choi");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "edit":
                    Crawldata.updatePlayer(_this.$root.url, data)
                        .then((response) => {
                            console.log(response);
                            _this.getDataListPlayer();
                            _this.closeModal("modal-nguoi-choi");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "delete":
                    Crawldata.deletePlayer(_this.$root.url, this.seletedRow)
                        .then((response) => {
                            console.log(response);
                            _this.getDataListPlayer();
                            _this.closeModal("modal-xac-nhan-xoa");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
            }
        },
        toggleDatePicker(isOpen) {
            setTimeout(() => {
                if (isOpen) {
                    let objDiv = document.getElementById("modal-nguoi-choi___BV_modal_body_");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }, 100);
        },
    },
};
</script>

<style lang="scss">
.style-for-web .player {
    .player-content {
        padding: 20px;
    }

    .b-table {
        >thead {
            >tr {
                >th {
                    &:first-child {
                        width: 50px;
                    }
                }
            }
        }
    }
}
</style>
