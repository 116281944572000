<template>
    <div id="header" class="header">
        <b-navbar toggleable="lg" :type="$root.theme" :variant="$root.theme">
            <b-navbar-brand href="#/menu">
                <img class="logo" :src="getImg('logo', 'jpg')" alt="">
            </b-navbar-brand>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-link @click="goBack()" class="btn-back">
                        <svg v-if="!$route.path.includes('menu')" class="icon icon-arrow-left-24">
                            <use xlink:href="#icon-arrow-left-24"></use>
                        </svg>
                    </b-link>
                </b-navbar-nav>
                <b-navbar-nav>
                    <strong class="ml-4 title">{{ title }}</strong>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-form class="mr-4">
                        <div class="group-theme-switch">
                            <svg class="icon icon-sun-15">
                                <use xlink:href="#icon-sun-15"></use>
                            </svg>
                            <b-form-checkbox v-model="isDarkMode" switch @change="setThemePreference()"> </b-form-checkbox>
                            <svg class="icon icon-moon5">
                                <use xlink:href="#icon-moon5"></use>
                            </svg>
                        </div>
                    </b-nav-form>
                    <b-nav-item-dropdown right>
                        <template #button-content>
                            <b-icon icon="person-circle"></b-icon>
                            Xin chào: <strong class="mr-1">{{ name }}</strong>(<em>{{ highestRole }}</em>)
                        </template>
                        <b-dropdown-item @click="logout()">
                            Đăng Xuất
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "HeaderPage",
    props: {
        title: {
            default: "",
        },
        backTo: {
            default: "",
        },
    },
    data() {
        return {
            userName: "",
            name: "",
            highestRole: "",
            isDarkMode: false,
        };
    },
    computed: {
        ...mapState("HeaderPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();

        this.isDarkMode = this.$root.theme === 'dark';
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getUserName();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("HeaderPage", []),
        setThemePreference() {
            let theme = this.isDarkMode ? 'dark' : 'light';
            this.switchHtmlTheme(theme);
        },
        getUserName() {
            this.userName = localStorage.getItem("userName");
            let user = JSON.parse(localStorage.getItem("user"));
            if (user) {
                this.name = user.name;
                const roles = user.roles;
                if (roles.includes("ROLE_ADMIN")) {
                    this.highestRole = "ADMIN";
                } else if (roles.includes("ROLE_MANAGER")) {
                    this.highestRole = "MANAGER";
                } else if (roles.includes("ROLE_ACCOUNTANT")) {
                    this.highestRole = "ACCOUNTANT";
                } else if (roles.includes("ROLE_SUPPORT")) {
                    this.highestRole = "SUPPORT";
                } else {
                    this.highestRole = "MEMBER";
                }
            }
        },
        logout() {
            this.clearLocalStorage();
            this.$router.push("/");
        },
        goBack() {
            if (this.backTo !== "") {
                this.$router.push(this.backTo);
            } else {
                if (window.history.state) {
                    this.$router.back();
                } else {
                    this.$router.push("menu");
                }
            }
        },
    },
};
</script>

<style lang="scss">
.style-for-web .header {
    .title {
        font-size: 20px;
    }

    .group-theme-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .custom-switch {
            padding-left: 2.6rem;
        }
    }

    .btn-back {
        width: 24px;

        .icon {
            height: 20px;
            width: 20px;
            color: var(--text-color);
            transition: all 0.3s;
            stroke-width: 1px;
        }

        &:hover {
            .icon {
                height: 24px;
                width: 24px;
                color: var(--btn-primary-color);
                stroke-width: 2px;
            }
        }
    }
}
</style>
