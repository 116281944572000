<template>
    <div id="report-by-product" class="report-by-product">
        <pc-header :title="title" :backTo="backTo" />
        <div class="report-by-product-content">
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "ReportByProduct",
    data() {
        return {
            title: "BÁO CÁO THEO SẢN PHẨM",
            backTo: "menu",
        };
    },
    computed: {
        ...mapState("ReportByProduct", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    async mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("ReportByProduct", []),
    },
};
</script>

<style lang="scss">
.style-for-web .report-by-product {}
</style>
