import ApiService from "./api.service";

const Crawldata = {
    // player
    getPlayers: async (url, data) => {
        url = url + "/crawldata/player/getPlayers";
        return ApiService.get(url, data);
    },
    addPlayer: async (url, data) => {
        url = url + "/crawldata/player/insert";
        return ApiService.put(url, data);
    },
    updatePlayer: async (url, data) => {
        url = url + "/crawldata/player/update";
        return ApiService.put(url, data);
    },
    deletePlayer: async (url, data) => {
        url = url + "/crawldata/player/delete";
        return ApiService.delete(url, data);
    },
    checkPlayerNotPlay: async (url, data) => {
        url = url + "/crawldata/player/checkPlayerNotPlay";
        return ApiService.get(url, data);
    },
    runCrawlDataMemberByDomain: async (url, data) => {
        url = url + "/crawldata/runCrawlDataMemberByDomain";
        return ApiService.get(url, data);
    },
    // job
    getJobHistory: async (url, data) => {
        url = url + "/crawldata/job/getJobHistory";
        return ApiService.get(url, data);
    },
    getJobHistoryDetail: async (url, data) => {
        url = url + "/crawldata/job/getJobHistoryDetail";
        return ApiService.get(url, data);
    },
    // ghi chú vi phạm
    getNoteHistory: async (url, data) => {
        url = url + "/crawldata/note/getNoteHistory";
        return ApiService.get(url, data);
    },
    insert: async (url, data) => {
        url = url + "/crawldata/note/insert";
        return ApiService.put(url, data);
    },
    update: async (url, data) => {
        url = url + "/crawldata/note/update";
        return ApiService.put(url, data);
    },
    delete: async (url, data) => {
        url = url + "/crawldata/note/delete";
        return ApiService.delete(url, data);
    },
};

export default Crawldata;
