<template>
    <div id="menu" class="menu">
        
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "MenuPage",
    data() {
        return {
        };
    },
    computed: {
        ...mapState("MenuPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.forceMovePage();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("MenuPage", []),
    },
};
</script>

<style lang="scss">
.style-for-mobile .menu {
}
</style>
