<template>
    <div id="thong-ke-xac-suat" class="thong-ke-xac-suat">
        <pc-header :title="title" :backTo="backTo" />
        <div class="thong-ke-xac-suat-content">

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "ThongKeXacSuat",
    data() {
        return {
            title: "THỐNG KÊ XÁC SUẤT",
            backTo: "menu",
        };
    },
    computed: {
        ...mapState("ThongKeXacSuat", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    watch: {},
    methods: {
        ...mapActions("ThongKeXacSuat", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        copyTinNhan(textToCopy) {
            this.showToast(`Đã copy dãy số`, "Thông Báo", this, "success");
            navigator.clipboard.writeText(textToCopy);
        },
    },
};
</script>

<style lang="scss">
.style-for-web .thong-ke-xac-suat {
}
</style>
