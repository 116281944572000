<template>
    <div id="tao-so" class="tao-so">
        <pc-header :title="title" :backTo="backTo" />
        <div class="tao-so-content">
            <b-row>
                <b-col cols="12" class="tab-btn mb-3">
                    <div>
                        <b-button size="sm" :variant="showTab === '1' ? 'primary' : 'light'" @click="changeTab('1')"
                            class="ml-1" :class="{ 'active-tab': showTab === '1' }">
                            Tạo Số
                        </b-button>
                        <b-button size="sm" :variant="showTab === '2' ? 'primary' : 'light'" @click="changeTab('2')"
                            class="ml-1" :class="{ 'active-tab': showTab === '2' }">
                            Lấy Bộ Số
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <template v-if="showTab === '1'">
                <b-row class="mt-2">
                    <b-col offset="1" cols="4">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Dãy Số</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="numbers" @change="onChangeNumbers()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Giá Tiền</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="giaTien"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Số Phần</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="soPhan" :disabled="isShowVong"
                                    @change="onChangeSoPhan()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col offset="1" cols="4">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Dãy Lọc</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="filterNumbers" :disabled="isShowVong"
                                    @change="onChangeFilterNumbers()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col offset="1" cols="8">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="taoSo(0)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Xiên 2</span>
                            </b-button>
                            <b-button variant="success" @click="taoSo(1)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Xiên 3</span>
                            </b-button>
                            <b-button variant="success" @click="taoSo(2)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Xiên 4</span>
                            </b-button>
                            <b-button variant="success" @click="taoSo(3)" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Xáo Vị Trí</span>
                            </b-button>
                            <b-button variant="success" @click="taoVong('4')" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Tạo Số Vòng 2, 3, 4</span>
                            </b-button>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="button-action text-right">
                            <b-button :variant="isDisableBtn ? 'danger' : 'success'" @click="khoaNut()">
                                <svg v-if="isDisableBtn" class="icon icon-lock-slash5">
                                    <use xlink:href="#icon-lock-slash5"></use>
                                </svg>
                                <svg v-if="!isDisableBtn" class="icon icon-lock5">
                                    <use xlink:href="#icon-lock5"></use>
                                </svg>
                                <span class="ml-2">{{ isDisableBtn ? 'Mở Khóa' : 'Khóa' }} Nút</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2" v-if="isShowVong">
                    <b-col offset="1" cols="10">
                        <div class="group-item-filter text-left">
                            <b-form-radio v-model="loaiTaoVong" name="loai-tao-vong" value="4" @change="taoVong()">
                                Ưu tiên tạo vòng 4
                            </b-form-radio>
                            <b-form-radio v-model="loaiTaoVong" name="loai-tao-vong" value="3" @change="taoVong()">
                                Ưu tiên tạo vòng 3
                            </b-form-radio>
                        </div>
                    </b-col>
                </b-row>
                <template v-if="!isShowVong">
                    <div v-for="(item, index) in data" :key="index" class="mode-tao-so">
                        <b-row v-if="item.visible" class="mt-3">
                            <b-col class="group-ket-qua" offset="1" cols="10">
                                <div class="group-title-ket-qua mb-2 ml-5">
                                    <span class="span-nowrap mr-2">
                                        <strong>Kết Quả {{ item.name }}:</strong>
                                        <span v-if="item.listNumberTheoThuTu.length === 0" class="ml-2 small-text">
                                            Không có số phù hợp với điệu kiện lọc
                                        </span>
                                    </span>
                                    <span v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap small-text mr-2">
                                        Tổng {{ getFormatNumber(item.listNumberTheoThuTu.length) }} số | Mỗi phần có {{
                                            getFormatNumber(item.soLuongMoiPhan) }} số
                                    </span>
                                    <div v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap title-icon mr-2"
                                        title="Copy" @click="copySo(item, index)">
                                        <svg class="icon icon-copy5">
                                            <use xlink:href="#icon-copy5"></use>
                                        </svg>
                                    </div>
                                    <div v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap title-icon mr-2"
                                        title="Xáo vị trí" :class="isDisableBtn ? 'disable-btn' : ''"
                                        @click="shuffleDaySo(item, index)">
                                        <svg class="icon icon-shuffle5">
                                            <use xlink:href="#icon-shuffle5"></use>
                                        </svg>
                                    </div>
                                    <div v-if="item.listNumberTheoThuTu.length > 0" class="span-nowrap title-icon mr-2"
                                        title="Phục hồi vị trí" :class="isDisableBtn ? 'disable-btn' : ''"
                                        @click="sapXepTheoThuTu(item, index)">
                                        <svg class="icon icon-repeat5">
                                            <use xlink:href="#icon-repeat5"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div class="group-body-ket-qua">
                                    <div v-for="(number, indexNumber) of item.listNumber" :key="indexNumber">
                                        <b-form-checkbox v-model="number.selected"></b-form-checkbox>
                                        <div class="group-item-number">
                                            <template v-for="(itemArr, indexArr) of number.arr">
                                                <div :key="indexArr" class="item-number p-2">
                                                    <span>{{ itemArr }}</span>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <b-pagination v-if="item.listNumberTheoThuTu.length > 0" v-model="item.page"
                                    :total-rows="item.listNumberGoc.length" :per-page="item.pageSize" first-number
                                    last-number align="center" @input="onChangePage(item)"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </template>
                <template v-if="isShowVong">
                    <div class="mode-tao-so">
                        <b-row v-for="(item, index) in dataVong" :key="index" class="mt-3">
                            <b-col class="group-ket-qua" offset="1" cols="10">
                                <div class="group-title-ket-qua mb-2 ml-5">
                                    <span class="span-nowrap mr-2">
                                        <strong>Kết Quả Vòng {{ index + 2 }}:</strong>
                                    </span>
                                    <span class="span-nowrap small-text mr-2">
                                        Tổng {{ getFormatNumber(item.listNumber.length) }} số 
                                        (Tạo xiên 2: {{ tinhXien2(item.listNumber.length, index + 2) }} số)
                                    </span>
                                    <div class="span-nowrap title-icon mr-2" title="Copy" @click="copySo(item, index)">
                                        <svg class="icon icon-copy5">
                                            <use xlink:href="#icon-copy5"></use>
                                        </svg>
                                    </div>
                                    <div class="span-nowrap title-icon mr-2" title="Xáo vị trí"
                                        :class="isDisableBtn ? 'disable-btn' : ''" @click="shuffleDaySo(item, index)">
                                        <svg class="icon icon-shuffle5">
                                            <use xlink:href="#icon-shuffle5"></use>
                                        </svg>
                                    </div>
                                    <div class="span-nowrap title-icon mr-2" title="Phục hồi vị trí"
                                        :class="isDisableBtn ? 'disable-btn' : ''" @click="sapXepTheoThuTu(item, index)">
                                        <svg class="icon icon-repeat5">
                                            <use xlink:href="#icon-repeat5"></use>
                                        </svg>
                                    </div>
                                </div>
                                <div class="group-body-ket-qua">
                                    <div>
                                        <div class="group-item-number">
                                            <div v-for="(number, index1) in item.listNumber" :key="index1"
                                                class="item-number p-2">
                                                <span>{{ number }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </template>
            <template v-if="showTab === '2'">
                <b-row class="mt-2">
                    <b-col offset="1" cols="4">
                        <div class="group-item-filter baseline">
                            <span class="span-nowrap mr-2">Dãy Số</span>
                            <b-input-group size="lg">
                                <b-form-textarea v-model="daySo" rows="3" max-rows="6"
                                    @change="onChangeDaySo()"></b-form-textarea>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <div class="group-item-filter baseline">
                            <span class="span-nowrap mr-2">Giá Tiền</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="giaTienCanXoa"
                                    @change="onChangeGiaTienCanXoa()"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col offset="1" cols="8">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="layBoSoBanDau()" :disabled="isDisableBtn">
                                <svg class="icon icon-play-cricle5">
                                    <use xlink:href="#icon-play-cricle5"></use>
                                </svg>
                                <span class="ml-2">Lấy Bộ Số Ban Đầu</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <div v-if="daySoBanDau" class="mode-phuc-hoi-bo-so">
                    <b-row class="mt-3">
                        <b-col class="group-ket-qua" offset="1" cols="10">
                            <div class="group-title-ket-qua mb-2 ml-5">
                                <span class="span-nowrap mr-2">
                                    <strong>Dãy Số Ban Đầu:</strong>
                                </span>
                                <span class="span-nowrap small-text mr-2">
                                    Tổng {{ getFormatNumber(daySoBanDau.length) }} số
                                </span>
                                <div class="span-nowrap title-icon mr-2" title="Copy" @click="copySo(daySoBanDau)">
                                    <svg class="icon icon-copy5">
                                        <use xlink:href="#icon-copy5"></use>
                                    </svg>
                                </div>
                            </div>
                            <div class="group-body-ket-qua">
                                <div>
                                    <div class="group-item-number">
                                        <div v-for="(number, index1) in daySoBanDau" :key="index1" class="item-number p-2">
                                            <span>{{ number }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "TaoSo",
    data() {
        return {
            title: "TẠO SỐ",
            backTo: "menu",
            showTab: '1',
            valueVung: {},
            valueTinh: {},
            valueKieuChoi: {},
            numbers: '',
            giaTien: '',
            filterNumbers: '',
            soPhan: 1,
            data: [
                {
                    name: 'Xiên 2',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: 'Xiên 3',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: 'Xiên 4',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: 'Xáo Vị Trí',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
            ],
            dataVong: [
                {
                    name: 'Vòng 2',
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: 'Vòng 3',
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: 'Vòng 4',
                    listNumber: [],
                    listNumberGoc: [],
                },
            ],
            isDisableBtn: false,
            isShowVong: false,
            dauCach: ',',
            daySo: '',
            daySoBanDau: '',
            giaTienCanXoa: '',
            loaiTaoVong: '4',
        };
    },
    computed: {
        ...mapState("TaoSo", []),
        optionsTinhTheoVung() {
            return this.optionsTinh.filter(i => i.codeVung === this.valueVung.codeKey);
        }
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener('keydown', this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener('keydown', this.keydown);
    },
    watch: {
    },
    methods: {
        ...mapActions("TaoSo", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes('multiselect');
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        this.getData();
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getData() {

        },
        changeTab(tab) {
            this.showTab = tab;
            switch (tab) {
                case '1':
                    this.title = 'TẠO SỐ';
                    this.numbers = '';
                    this.onChangeNumbers();
                    break;
                case '2':
                    this.title = 'LẤY BỘ SỐ';
                    this.daySo = '';
                    this.onChangeDaySo();
                    break;
            }
        },
        onChangeNumbers() {
            if (this.numbers) {
                let numbers = this.numbers.replaceAll(', ', ',').replaceAll(' ', ',').replaceAll('.', ',').replace(/[^\d,]/g, '');
                this.numbers = Array.from(new Set(numbers.split(','))).filter(Boolean).join(',');
            }
            this.data = [
                {
                    name: 'Xiên 2',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: 'Xiên 3',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: 'Xiên 4',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
                {
                    name: 'Xáo Vị Trí',
                    listNumberPhucHoi: [],
                    listNumberTheoThuTu: [],
                    listNumberGoc: [],
                    listNumber: [],
                    page: 1,
                    pageSize: 5,
                    visible: false,
                    soLuongMoiPhan: 0,
                },
            ]
        },
        onChangeFilterNumbers() {
            let filterNumbers = this.filterNumbers.replaceAll('.', this.dauCach).replaceAll(' ', ';').replace(/[^\d,.;]/g, '');
            this.filterNumbers = Array.from(new Set(filterNumbers.split(';'))).filter(Boolean).join(';');

            for (const [index, item] of this.data.entries()) {
                if (item.listNumberTheoThuTu.length && item.visible) {
                    this.taoSo(index);
                }
            }
        },
        onChangeSoPhan() {
            if (this.soPhan) {
                for (const item of this.data) {
                    if (item.listNumberTheoThuTu.length) {
                        item.page = 1;
                        item.soLuongMoiPhan = Math.ceil(item.listNumberTheoThuTu.length / this.soPhan);
                        item.listNumberGoc = [];
                        let arr = [];
                        for (const [idx1, it] of item.listNumberTheoThuTu.entries()) {
                            arr.push(it);
                            if (arr.length === item.soLuongMoiPhan) {
                                item.listNumberGoc.push({
                                    selected: false,
                                    arr: this.cloneDeep(arr),
                                });
                                arr = [];
                            } else if (idx1 === item.listNumberTheoThuTu.length - 1) {
                                item.listNumberGoc.push({
                                    selected: false,
                                    arr: this.cloneDeep(arr),
                                });
                            }
                        }
                        this.onChangePage(item);
                    } else {
                        item.listNumberGoc = [];
                        item.listNumber = [];
                    }
                }
            }
        },
        onChangeDaySo() {
            console.log(this.daySo);
        },
        onChangeGiaTienCanXoa() {
            let giaTienCanXoa = this.giaTienCanXoa.replaceAll(' ', ';');
            this.giaTienCanXoa = Array.from(new Set(giaTienCanXoa.split(';'))).filter(Boolean).join(';');
        },
        onChangePage(item) {
            item.listNumber = [];
            for (var i = (item.page - 1) * item.pageSize; i < (item.page * item.pageSize) && i < item.listNumberGoc.length; i++) {
                item.listNumber.push(item.listNumberGoc[i]);
            }
        },
        taoSo(index) {
            if (!this.numbers || this.isDisableBtn) {
                return;
            }
            this.isShowVong = false;
            this.loaiTaoVong = '4';
            for (const [idx, item] of this.data.entries()) {
                item.visible = idx === index;
            }
            let arrNumbers = this.numbers.split(',');
            let length = arrNumbers.length;

            // tạo xien 2
            let itemXien2 = this.data[0];
            if (itemXien2.visible) {
                itemXien2.listNumberTheoThuTu = [];
                let arrNumbersXien2 = this.k_combinations(arrNumbers, 2);
                for (const item of arrNumbersXien2) {
                    if (!this.filterNumbers.includes(item.join(this.dauCach))) {
                        itemXien2.listNumberTheoThuTu.push(item.join(this.dauCach))
                    }
                }
                itemXien2.listNumberPhucHoi = this.cloneDeep(itemXien2.listNumberTheoThuTu);
            }

            // tạo xien 3
            let itemXien3 = this.data[1];
            if (itemXien3.visible) {
                itemXien3.listNumberTheoThuTu = [];
                let arrNumbersXien3 = this.k_combinations(arrNumbers, 3);
                for (const item of arrNumbersXien3) {
                    if (!this.filterNumbers.includes(item.join(this.dauCach))) {
                        itemXien3.listNumberTheoThuTu.push(item.join(this.dauCach))
                    }
                }
                itemXien3.listNumberPhucHoi = this.cloneDeep(itemXien3.listNumberTheoThuTu);
            }

            // tạo xien 4
            let itemXien4 = this.data[2];
            if (itemXien4.visible) {
                itemXien4.listNumberTheoThuTu = [];
                let arrNumbersXien4 = this.k_combinations(arrNumbers, 4);
                for (const item of arrNumbersXien4) {
                    if (!this.filterNumbers.includes(item.join(this.dauCach))) {
                        itemXien4.listNumberTheoThuTu.push(item.join(this.dauCach))
                    }
                }
                itemXien4.listNumberPhucHoi = this.cloneDeep(itemXien4.listNumberTheoThuTu);
            }

            // tạo xáo vị trí
            let itemXaoViTri = this.data[3];
            if (itemXaoViTri.visible) {
                itemXaoViTri.listNumberTheoThuTu = [];
                itemXaoViTri.listNumberPhucHoi = [];
                while (itemXaoViTri.listNumberTheoThuTu.length < length) {
                    let randomItem = this.cloneDeep(arrNumbers[(Math.floor(Math.random() * length))]);
                    if (!itemXaoViTri.listNumberTheoThuTu.includes(randomItem)) {
                        itemXaoViTri.listNumberTheoThuTu.push(randomItem);
                    }
                }
                let listSorted = this.cloneDeep(arrNumbers);
                listSorted.sort((a, b) => {
                    let numberA = Number(a);
                    let numberB = Number(b);
                    if (numberA < numberB) {
                        return -1;
                    }
                    if (numberA > numberB) {
                        return 1;
                    }
                    return 0;
                });
                for (const item of listSorted) {
                    itemXaoViTri.listNumberPhucHoi.push(item)
                }
            }

            this.onChangeSoPhan();
        },
        k_combinations(set, k) {
            let i, j, combs, head, tailcombs;
            if (k > set.length || k <= 0) {
                return [];
            }
            if (k == set.length) {
                return [set];
            }
            if (k == 1) {
                combs = [];
                for (i = 0; i < set.length; i++) {
                    combs.push([set[i]]);
                }
                return combs;
            }
            combs = [];
            for (i = 0; i < set.length - k + 1; i++) {
                head = set.slice(i, i + 1);
                tailcombs = this.k_combinations(set.slice(i + 1), k - 1);
                for (j = 0; j < tailcombs.length; j++) {
                    combs.push(head.concat(tailcombs[j]));
                }
            }
            return combs;
        },
        taoVong(loaiTaoVong) {
            if (!this.numbers || this.isDisableBtn) {
                return;
            }
            this.isShowVong = true;
            this.dataVong = [
                {
                    name: 'Vòng 2',
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: 'Vòng 3',
                    listNumber: [],
                    listNumberGoc: [],
                },
                {
                    name: 'Vòng 4',
                    listNumber: [],
                    listNumberGoc: [],
                },
            ];
            if (loaiTaoVong) {
                this.loaiTaoVong = loaiTaoVong;
            }
            if (this.loaiTaoVong === '3') {
                this.taoVong3();
            }
            if (this.loaiTaoVong === '4') {
                this.taoVong4();
            }
        },
        taoVong3() {
            let arrNumbers = this.numbers.split(',');
            let arrXien2 = this.k_combinations(arrNumbers, 2);
            let arrXien3 = this.k_combinations(arrNumbers, 3);
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            for (const item of arrXien3) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[2], arr2, arr3, arr4)) {
                    arr3.push(item);
                }
            }
            for (const item of arrXien2) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4)) {
                    arr2.push(item);
                }
            }

            this.dataVong[0].listNumber = this.cloneDeep(arr2).map(i => i.join(this.dauCach));
            this.dataVong[1].listNumber = this.cloneDeep(arr3).map(i => i.join(this.dauCach));
            this.dataVong[2].listNumber = this.cloneDeep(arr4).map(i => i.join(this.dauCach));
            this.dataVong[0].listNumberGoc = this.cloneDeep(this.dataVong[0].listNumber);
            this.dataVong[1].listNumberGoc = this.cloneDeep(this.dataVong[1].listNumber);
            this.dataVong[2].listNumberGoc = this.cloneDeep(this.dataVong[2].listNumber);
        },
        taoVong4() {
            let arrNumbers = this.numbers.split(',');
            let arrXien2 = this.k_combinations(arrNumbers, 2);
            let arrXien3 = this.k_combinations(arrNumbers, 3);
            let arrXien4 = this.k_combinations(arrNumbers, 4);
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            for (const item of arrXien4) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[3], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[3], arr2, arr3, arr4) &&
                    this.checkSo(item[2], item[3], arr2, arr3, arr4)) {
                    arr4.push(item);
                }
            }
            for (const item of arrXien3) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4) &&
                    this.checkSo(item[0], item[2], arr2, arr3, arr4) &&
                    this.checkSo(item[1], item[2], arr2, arr3, arr4)) {
                    arr3.push(item);
                }
            }
            for (const item of arrXien2) {
                if (this.checkSo(item[0], item[1], arr2, arr3, arr4)) {
                    arr2.push(item);
                }
            }

            this.dataVong[0].listNumber = this.cloneDeep(arr2).map(i => i.join(this.dauCach));
            this.dataVong[1].listNumber = this.cloneDeep(arr3).map(i => i.join(this.dauCach));
            this.dataVong[2].listNumber = this.cloneDeep(arr4).map(i => i.join(this.dauCach));
            this.dataVong[0].listNumberGoc = this.cloneDeep(this.dataVong[0].listNumber);
            this.dataVong[1].listNumberGoc = this.cloneDeep(this.dataVong[1].listNumber);
            this.dataVong[2].listNumberGoc = this.cloneDeep(this.dataVong[2].listNumber);
        },
        checkSo(so1, so2, arr2, arr3, arr4) {
            if (!so1 || !so2) {
                return false;
            }
            for (const item of arr2) {
                if (item.includes(so1) && item.includes(so2)) {
                    return false;
                }
            }
            for (const item of arr3) {
                if (item.includes(so1) && item.includes(so2)) {
                    return false;
                }
            }
            for (const item of arr4) {
                if (item.includes(so1) && item.includes(so2)) {
                    return false;
                }
            }
            return true;
        },
        layBoSoBanDau() {
            let arrGiaTienCanXoa = this.giaTienCanXoa.split(';');
            let daySo = this.daySo;
            for (const item of arrGiaTienCanXoa) {
                daySo = daySo.replaceAll(item, '');
            }
            daySo = daySo.replaceAll('\n', ',').replaceAll(', ', ',').replaceAll(' ', ',').replaceAll('.', ',').replace(/[^\d,]/g, '');
            this.daySoBanDau = Array.from(new Set(daySo.split(','))).filter(Boolean).sort((a, b) => {
                let numberA = Number(a);
                let numberB = Number(b);
                if (numberA < numberB) {
                    return -1;
                }
                if (numberA > numberB) {
                    return 1;
                }
                return 0;
            });
        },
        shuffleDaySo(item) {
            if (this.isDisableBtn) {
                return;
            }
            let returnData = [];
            if (this.isShowVong) {
                let arrNumbers = this.cloneDeep(item.listNumber);
                while (returnData.length < arrNumbers.length) {
                    let randomItem = arrNumbers[(Math.floor(Math.random() * arrNumbers.length))];
                    if (!returnData.includes(randomItem)) {
                        returnData.push(randomItem);
                    }
                }
                item.listNumber = this.cloneDeep(returnData);
            } else {
                let arrNumbers = this.cloneDeep(item.listNumberPhucHoi);
                while (returnData.length < arrNumbers.length) {
                    let randomItem = arrNumbers[(Math.floor(Math.random() * arrNumbers.length))];
                    if (!returnData.includes(randomItem)) {
                        returnData.push(randomItem);
                    }
                }
                item.listNumberTheoThuTu = this.cloneDeep(returnData);
                this.onChangeSoPhan();
            }
        },
        copySo(data, index) {
            let textToCopy = '';
            if (this.showTab === '3') {
                textToCopy = this.daySoBanDau.join(',');
            } else if (this.isShowVong) {
                for (const item of data.listNumber) {
                    textToCopy += this.giaTien ? `${item} ${this.giaTien}.\n` : `${item}\n`
                }
                this.showToast(`Đã copy số vòng ${index + 2}`, "Thông Báo", this, "success");
            } else {
                let isCoChonItem = false;
                for (const item of data.listNumber) {
                    if (item.selected && index === 3) {
                        textToCopy += this.giaTien ? `${item.arr.join(',')} ${this.giaTien}.\n` : `${item.arr.join(',')}\n`
                        isCoChonItem = true;
                    } else if (item.selected) {
                        for (const it of item.arr) {
                            textToCopy += this.giaTien ? `${it} ${this.giaTien}.\n` : `${it}\n`;
                        }
                        isCoChonItem = true;
                    }
                }
                if (!isCoChonItem) {
                    if (index === 3) {
                        for (const it of data.listNumberTheoThuTu) {
                            textToCopy += this.giaTien ? `${it} ${this.giaTien}.\n` : `${it}\n`;
                        }
                    } else {
                        for (const item of data.listNumber) {
                            for (const it of item.arr) {
                                textToCopy += this.giaTien ? `${it} ${this.giaTien}.\n` : `${it}\n`;
                            }
                        }
                    }
                }
                if (isCoChonItem) {
                    this.showToast(`Đã copy số ${data.name} đã chọn`, "Thông Báo", this, "success");
                } else {
                    this.showToast(`Đã copy tất cả số ${data.name}`, "Thông Báo", this, "success");
                }
            }
            navigator.clipboard.writeText(textToCopy);
        },
        sapXepTheoThuTu(item) {
            if (this.isDisableBtn) {
                return;
            }
            if (this.isShowVong) {
                item.listNumber = this.cloneDeep(item.listNumberGoc);
            } else {
                item.listNumberTheoThuTu = this.cloneDeep(item.listNumberPhucHoi);
                item.page = 1;
                this.onChangeSoPhan();
            }
        },
        khoaNut() {
            this.isDisableBtn = !this.isDisableBtn;
        },
        tinhXien2(number, length) {
            let ketQua = 0;
            switch (length) {
                case 2:
                    ketQua = number;
                    break;
                case 3:
                    ketQua = number * 3;
                    break;
                case 4:
                    ketQua = number * 6;
                    break;
            }
            return ketQua;
        }
    },
};
</script>

<style lang="scss">
.style-for-mobile .tao-so {
    .baseline {
        align-items: baseline;
    }

    .group-ket-qua {
        border: 3px solid var(--header-bao-cao-phan-tich-background);
        border-radius: 8px;
        padding: 10px 3px 3px 3px;
    }

    .group-title-ket-qua {
        display: flex;
        align-items: center;

        .title-icon {
            cursor: pointer;
            transition: all 0.3s;
            color: var(--text-btn-success-color);
            background-color: var(--btn-success-color);
            border: 1px solid var(--btn-success-color);
            border-radius: 6px;
            padding: 5px 7px;

            &:hover {
                color: var(--text-btn-hover);
                background-color: var(--btn-success-color-hover);
                border-color: var(--btn-success-color-hover);
            }

            &.disable-btn {
                opacity: 0.65;
            }
        }
    }

    .group-body-ket-qua {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        >div {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            border-top: 1px solid var(--header-bao-cao-phan-tich-background);
            padding: 5px 0 5px 5px;

            .group-item-number {
                display: flex;
                flex-wrap: wrap;
                overflow: auto;
            }

            .item-number {
                min-width: 100px;
                margin-right: 2px;
                margin-bottom: 2px;
                background-color: var(--modal-input-background);
                border: 1px solid var(--modal-input-border);
                border-radius: 8px;
                color: var(--text-color);
                text-align: center;
            }

            .custom-checkbox {

                input,
                label {
                    cursor: pointer;
                }
            }
        }
    }

    .mode-tao-so {
        .pagination {
            width: 90%;
            margin: auto;
            background-color: transparent;
        }
    }

    .mode-phuc-hoi-bo-so {
        .pagination {
            width: 90%;
            margin: auto;
            background-color: transparent;
        }
    }
}
</style>
