<template>
    <div id="top-thang-thua" class="top-thang-thua">
        <pc-header :title="title" :backTo="backTo" />
        <div class="top-thang-thua-content">
            <b-row>
                <b-col cols="12" class="tab-btn mb-3">
                    <div>
                        <b-button
                            size="sm"
                            :variant="showTab === '1' ? 'primary' : 'light'"
                            @click="changeTab('1')"
                            class="mr-1"
                            :class="{ 'active-tab': showTab === '1' }"
                        >
                            Outstanding
                        </b-button>
                        <b-button
                            size="sm"
                            :variant="showTab === '2' ? 'primary' : 'light'"
                            @click="changeTab('2')"
                            class="ml-1"
                            :class="{ 'active-tab': showTab === '2' }"
                        >
                            Top Master Thắng / Thua
                        </b-button>
                        <b-button
                            size="sm"
                            :variant="showTab === '3' ? 'primary' : 'light'"
                            @click="changeTab('3')"
                            class="ml-1"
                            :class="{ 'active-tab': showTab === '3' }"
                        >
                            Top Agent Thắng / Thua
                        </b-button>
                        <b-button
                            size="sm"
                            :variant="showTab === '4' ? 'primary' : 'light'"
                            @click="changeTab('4')"
                            class="ml-1"
                            :class="{ 'active-tab': showTab === '4' }"
                        >
                            Top Thành Viên Thắng / Thua
                        </b-button>
                        <b-button
                            size="sm"
                            :variant="showTab === '5' ? 'primary' : 'light'"
                            @click="changeTab('5')"
                            class="ml-1"
                            :class="{ 'active-tab': showTab === '5' }"
                        >
                            <svg class="icon icon-warning-25 red-text mr-1">
                                <use xlink:href="#icon-warning-25"></use>
                            </svg>
                            Phân vùng ưu tiên
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">ID Thành Viên</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="valueSeacrh"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="getData()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <div class="table-data">
                        <b-table
                            sticky-header
                            fixed
                            hover
                            small
                            :items="dataTable"
                            :fields="fields"
                            :head-variant="$root.theme"
                            :dark="$root.theme === 'dark'"
                            show-empty
                            empty-text="Không có dữ liệu"
                            :busy="isBusy"
                            :per-page="perPage"
                            :current-page="currentPage"
                        >
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <!-- body -->
                            <template #cell(status)="scope">
                                <span>{{ scope.item.status === 0 ? "Open" : "Close" }}</span>
                            </template>
                            <template #cell(username)="scope">
                                <div class="btn-link" @click="getData(scope.item)">
                                    {{ scope.item.username }}
                                </div>
                            </template>
                            <template #cell(winLoss)="scope">
                                <div>{{ getFormatNumber(scope.item.winLoss) }}</div>
                            </template>
                        </b-table>
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="dataTable.length"
                            :per-page="perPage"
                            first-number
                            last-number
                            align="center"
                        ></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "TopThangThua",
    data() {
        return {
            title: "THỐNG KÊ GIAN LẬN",
            backTo: "menu",
            showTab: "4",
            valueSeacrh: "",
            dataTable: [],
            fields: [
                { key: "index", label: "STT", sortable: false },
                { key: "id", label: "ID Thành Viên", sortable: true },
                { key: "domain", label: "Nhà Cái", sortable: true },
                { key: "winLoss", label: "Lời / Lỗ", sortable: true },
                { key: "note", label: "Ghi Chú", sortable: true },
            ],
            perPage: 25,
            currentPage: 1,
            isBusy: false,
        };
    },
    computed: {
        ...mapState("TopThangThua", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("TopThangThua", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.getData();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    this.changeTab("1");
                    e.preventDefault();
                    break;
                case 113: // f2
                    this.changeTab("2");
                    e.preventDefault();
                    break;
                case 114: // f3
                    this.changeTab("3");
                    e.preventDefault();
                    break;
                case 115: // f4
                    this.changeTab("4");
                    e.preventDefault();
                    break;
                case 116: // f5
                    this.changeTab("5");
                    e.preventDefault();
                    break;
            }
        },
        changeTab(tab) {
            this.showTab = tab;
            switch (tab) {
                case "1":
                    this.fields = [
                        { key: "index", label: "STT", sortable: false },
                        { key: "id", label: "ID Thành Viên", sortable: true },
                        { key: "domain", label: "Nhà Cái", sortable: true },
                        { key: "winLoss", label: "Lời / Lỗ", sortable: true },
                        { key: "note", label: "Ghi Chú", sortable: true },
                    ];
                    break;
                case "2":
                    this.fields = [
                        { key: "index", label: "STT", sortable: false },
                        { key: "id", label: "ID Thành Viên", sortable: true },
                        { key: "domain", label: "Nhà Cái", sortable: true },
                        { key: "winLoss", label: "Lời / Lỗ", sortable: true },
                        { key: "note", label: "Ghi Chú", sortable: true },
                    ];
                    break;
                case "3":
                    this.fields = [
                        { key: "index", label: "STT", sortable: false },
                        { key: "id", label: "ID Thành Viên", sortable: true },
                        { key: "domain", label: "Nhà Cái", sortable: true },
                        { key: "winLoss", label: "Lời / Lỗ", sortable: true },
                        { key: "note", label: "Ghi Chú", sortable: true },
                    ];
                    break;
                case "4":
                    this.fields = [
                        { key: "index", label: "STT", sortable: false },
                        { key: "id", label: "ID Thành Viên", sortable: true },
                        { key: "domain", label: "Nhà Cái", sortable: true },
                        { key: "winLoss", label: "Lời / Lỗ", sortable: true },
                        { key: "note", label: "Ghi Chú", sortable: true },
                        { key: "action", label: "", sortable: true },
                    ];
                    break;
                case "5":
                    this.fields = [
                        { key: "index", label: "STT", sortable: false },
                        { key: "id", label: "Tài Khoản", sortable: true },
                        { key: "domain", label: "Số Mã Cược", sortable: true },
                        { key: "turnover", label: "Turnover", sortable: true },
                    ];
                    break;
            }
        },
        getData() {},
    },
};
</script>

<style lang="scss">
.style-for-web .top-thang-thua {
}
</style>
