<template>
    <div id="xem-nhanh" class="xem-nhanh">
        <pc-header :title="title" :backTo="backTo" />
        <div class="xem-nhanh-content">
            <b-row>
                <b-col cols="12" class="tab-btn mb-3">
                    <div>
                        <b-button size="sm" :variant="showTab === '1' ? 'primary' : 'light'" @click="changeTab('1')"
                            class="mr-1" :class="{ 'active-tab': showTab === '1' }">
                            Xem Nhanh
                        </b-button>
                        <b-button size="sm" :variant="showTab === '2' ? 'primary' : 'light'" @click="changeTab('2')"
                            class="ml-1" :class="{ 'active-tab': showTab === '2' }">
                            Xem Ghi Chú
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <template v-if="showTab === '1'">
                <b-row>
                    <b-col offset="1" cols="3">
                        <div class="group-item-filter">
                            <b-button variant="success" id="btn-chuc-nang">
                                <svg class="icon icon-menu-15">
                                    <use xlink:href="#icon-menu-15"></use>
                                </svg>
                                <span class="ml-2">Chức Năng</span>
                            </b-button>
                            <b-popover id="popover-chuc-nang" target="btn-chuc-nang" triggers="focus" placement="bottom"
                                :custom-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
                                <b-form-checkbox v-for="(item, index) in dataChucNang" :key="index" v-model="item.value"
                                    @change="changeChucNang(item)">
                                    {{ item.name }}
                                </b-form-checkbox>
                            </b-popover>
                            <span class="span-nowrap mr-2 ml-3">Nhà Cái</span>
                            <multi-select class="multi-select" v-model="valueMaster2" deselect-label="Không thể bỏ chọn"
                                track-by="codeKey" label="codeName" placeholder="" :options="dataMaster2"
                                :searchable="false" :allow-empty="false">
                            </multi-select>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Thời Gian</span>
                            <date-range-picker v-model="dateFromTo" :locale-data="$root.localeData" :show-dropdowns="true"
                                :auto-apply="true" :ranges="ranges" :linked-calendars="false"
                                opens="center"></date-range-picker>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="getData()">
                                <svg class="icon icon-search-normal-15">
                                    <use xlink:href="#icon-search-normal-15"></use>
                                </svg>
                                <span class="ml-2">Tìm Kiếm</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col offset="1" cols="3">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Tài Khoản Trong / Ngoài Hệ Thống</span>
                            <multi-select class="multi-select" v-model="loaiScan" deselect-label="Không thể bỏ chọn"
                                track-by="codeKey" label="codeName" placeholder="" :options="dataLoaiScan"
                                :searchable="false" :allow-empty="false" @input="locTaiKhoan()">
                            </multi-select>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col offset="1" cols="10">
                        <div class="group-thoi-diem">
                            <label class="w-50">
                                Tài khoản trong: <strong>{{ valueMaster2.codeName }}</strong>
                            </label>
                            <label class="w-50 text-right">
                                Kết quả được tính tới thời điểm: <strong>{{ timeUpdate }}</strong>
                            </label>
                        </div>
                    </b-col>
                </b-row>
                <template v-for="(item, index) in dataChucNang">
                    <div class="mt-3 group-chuc-nang" v-if="item.value" :key="index">
                        <b-row v-if="item.value">
                            <b-col cols="2">
                                <label class="chuc-nang-item" @click="boChonChucNang(item)">
                                    {{ item.name }}
                                    <svg v-if="dataChucNang.filter((i) => i.value).length > 1"
                                        class="icon icon-close-circle4 ml-2">
                                        <use xlink:href="#icon-close-circle4"></use>
                                    </svg>
                                </label>
                            </b-col>
                            <b-col cols="2">
                                <div class="group-item-filter">
                                    <span class="span-nowrap mr-2">Tài Khoản</span>
                                    <b-input-group size="lg">
                                        <b-form-input type="search" v-model="item.valueFilter"
                                            @input="filterUserName(item)"></b-form-input>
                                    </b-input-group>
                                </div>
                            </b-col>
                            <b-col cols="3">
                                <div class="group-item-filter">
                                    <span class="span-nowrap mr-2">Sort cột</span>
                                    <multi-select class="multi-select mr-1" v-model="item.valueSort1"
                                        deselect-label="Không thể bỏ chọn" track-by="key" label="label" placeholder=""
                                        :options="item.fields" :searchable="false" :allow-empty="false"
                                        @input="changeSort(item)">
                                    </multi-select>
                                    <multi-select class="multi-select" v-model="item.orderSort1"
                                        deselect-label="Không thể bỏ chọn" track-by="key" label="label" placeholder=""
                                        :options="dataOrderSort" :searchable="false" :allow-empty="false"
                                        @input="changeSort(item)">
                                    </multi-select>
                                </div>
                            </b-col>
                            <b-col cols="3">
                                <div class="group-item-filter">
                                    <span class="span-nowrap mr-2">và cột</span>
                                    <multi-select class="multi-select mr-1" v-model="item.valueSort2"
                                        deselect-label="Không thể bỏ chọn" track-by="key" label="label" placeholder=""
                                        :options="item.fields" :searchable="false" :allow-empty="false"
                                        @input="changeSort(item)">
                                    </multi-select>
                                    <multi-select class="multi-select" v-model="item.orderSort2"
                                        deselect-label="Không thể bỏ chọn" track-by="key" label="label" placeholder=""
                                        :options="dataOrderSort" :searchable="false" :allow-empty="false"
                                        @input="changeSort(item)">
                                    </multi-select>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3" v-if="item.value">
                            <b-col>
                                <div class="table-data">
                                    <b-table sticky-header fixed hover small :items="item.dataTableFilter"
                                        :fields="item.fields" :head-variant="$root.theme" :dark="$root.theme === 'dark'"
                                        show-empty empty-text="Không có dữ liệu" :busy="item.isBusy"
                                        :per-page="item.perPage" :current-page="item.currentPage"
                                        :sort-by.sync="item.sortBy" :sort-desc.sync="item.sortDesc"
                                        @head-clicked="headClicked">
                                        <template #empty="scope">
                                            <strong>{{ scope.emptyText }}</strong>
                                        </template>
                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle mr-2"></b-spinner>
                                                <strong>Đang tải dữ liệu...</strong>
                                            </div>
                                        </template>
                                        <!-- body -->
                                        <template #cell(userName)="scope">
                                            <div class="cell-style" :class="scope.item.scan ? 'warning-row' : ''">
                                                <span class="link" @click="
                                                    clickMemberIdToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                    })
                                                    ">
                                                    <strong class="text-1 mr-1">
                                                        {{ scope.item.userName }}
                                                    </strong>
                                                </span>
                                                <span class="link" @click="
                                                    clickMemberIdToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        newTab: true,
                                                    })
                                                    ">
                                                    <svg class="icon icon-copy5">
                                                        <use xlink:href="#icon-copy5"></use>
                                                    </svg>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(winLoss)="scope">
                                            <div>{{ getFormatNumber(scope.item.winLoss) }}</div>
                                        </template>

                                        <!-- cuoc theo -->
                                        <template #cell(betA5)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 4,
                                                        masterCode: 'A5',
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betA5 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betA6)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 15,
                                                        masterCode: 'A6',
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betA6 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betA7)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReportCount({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 5,
                                                        masterCode: 'A1',
                                                        filterCount: 3,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betA7 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betA8)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReportCount({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 200,
                                                        masterCode: 'A0',
                                                        filterCount: 6,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betA8 }} </strong>
                                                </span>
                                            </div>
                                        </template>

                                        <!-- buon com -->
                                        <template #cell(betB8)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        masterCode: 'B6',
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betB8 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betB6)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        masterCode: 'B8',
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betB6 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betB5)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 300,
                                                        masterCode: 'B5',
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betB5 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betB7)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        masterCode: 'B7',
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betB7 }} </strong>
                                                </span>
                                            </div>
                                        </template>

                                        <!-- hang sap gia -->
                                        <template #cell(crashPrice1)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 10,
                                                        typeCrash: 0,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.crashPrice1 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(crashPrice2)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 10,
                                                        typeCrash: 1,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.crashPrice2 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(crashPrice3)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 10,
                                                        typeCrash: 2,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.crashPrice3 }} </strong>
                                                </span>
                                            </div>
                                        </template>

                                        <!-- rui ro -->
                                        <template #cell(betRisk1)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 5,
                                                        type: 1,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betRisk1 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betRisk2)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 5,
                                                        type: 2,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betRisk2 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(betRisk3)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 0,
                                                        milisTo: 5,
                                                        type: 3,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.betRisk3 }} </strong>
                                                </span>
                                            </div>
                                        </template>

                                        <!-- dac biet -->
                                        <template #cell(special1)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 1,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special1 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(special2)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 2,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special2 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(special3)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 3,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special3 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(special4)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 4,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special4 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(special5)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 5,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special5 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(special6)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 6,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special6 }} </strong>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(special7)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.item,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 7,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.special7 }} </strong>
                                                </span>
                                            </div>
                                        </template>

                                        <!-- buon bong -->
                                        <template #cell(dirtySoccer)="scope">
                                            <div class="cell-style">
                                                <span class="link" @click="
                                                    clickNumberToReport({
                                                        chucNang: item,
                                                        row: scope.row,
                                                        milisFrom: 5,
                                                        milisTo: 15,
                                                        type: 1,
                                                    })
                                                    ">
                                                    <strong> {{ scope.item.dirtySoccer }} </strong>
                                                </span>
                                            </div>
                                        </template>

                                        <template #cell(status)="scope">
                                            <div class="button-action text-left" v-if="item.key === 'trungIp'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusSamIp"
                                                        @input="changeViewStatus(scope.item, 6, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusSameIp"
                                                        @input="changeReportStatus(scope.item, 6, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                            <div class="button-action text-left" v-if="item.key === 'cuocTheo'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusSameProduct"
                                                        @input="changeViewStatus(scope.item, 1, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusSameProduct"
                                                        @input="changeReportStatus(scope.item, 1, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                            <div class="button-action text-left" v-if="item.key === 'buonCom'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusDirtyCom"
                                                        @input="changeViewStatus(scope.item, 2, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusDirtyCom"
                                                        @input="changeReportStatus(scope.item, 2, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                            <div class="button-action text-left" v-if="item.key === 'hangSapGia'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusCrashPrice"
                                                        @input="changeViewStatus(scope.item, 3, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusCrashPrice"
                                                        @input="changeReportStatus(scope.item, 3, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                            <div class="button-action text-left" v-if="item.key === 'cuocRuiRo'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusBetRisk"
                                                        @input="changeViewStatus(scope.item, 4, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusBetRisk"
                                                        @input="changeReportStatus(scope.item, 4, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                            <div class="button-action text-left" v-if="item.key === 'cuocDacBiet'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusBetSpecial"
                                                        @input="changeViewStatus(scope.item, 5, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusBetSpecial"
                                                        @input="changeReportStatus(scope.item, 5, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                            <div class="button-action text-left" v-if="item.key === 'buonBong'">
                                                <span title="Đã Xem">
                                                    <b-form-checkbox v-model="scope.item.viewStatusDirySoccer"
                                                        @input="changeViewStatus(scope.item, 6, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                                <span title="Đã Report">
                                                    <b-form-checkbox v-model="scope.item.reportStatusDirySoccer"
                                                        @input="changeReportStatus(scope.item, 6, $event)">
                                                    </b-form-checkbox>
                                                </span>
                                            </div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-model="item.currentPage" :total-rows="item.totalRows"
                                        :per-page="item.perPage" first-number last-number align="center"></b-pagination>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </template>
            </template>
            <template v-if="showTab === '2'">
                <b-row>
                    <b-col offset="1" cols="3">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2 ml-3">Nhà Cái</span>
                            <multi-select class="multi-select" v-model="typeDomainGhiChu" track-by="codeKey"
                                label="codeName" placeholder="" :options="dataMaster2" :searchable="false">
                            </multi-select>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="group-item-filter">
                            <span class="span-nowrap mr-2">Tài Khoản</span>
                            <b-input-group size="lg">
                                <b-form-input type="text" v-model="userNameGhiChu"></b-form-input>
                            </b-input-group>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="button-action text-left">
                            <b-button variant="success" @click="xemGhiChu()">
                                <svg class="icon icon-search-normal-15">
                                    <use xlink:href="#icon-search-normal-15"></use>
                                </svg>
                                <span class="ml-2">Tìm Kiếm</span>
                            </b-button>
                            <b-button variant="primary" @click="addOrEditGhiChu()">
                                <svg class="icon icon-add-square5">
                                    <use xlink:href="#icon-add-square5"></use>
                                </svg>
                                <span class="ml-2">Thêm Ghi Chú</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <div class="table-data">
                            <b-table sticky-header fixed hover small :items="dataTableGhiChu" :fields="fieldsGhiChu"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu" :busy="isBusyGhiChu" :per-page="perPageGhiChu"
                                :current-page="currentPageGhiChu">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle mr-2"></b-spinner>
                                        <strong>Đang tải dữ liệu...</strong>
                                    </div>
                                </template>
                                <!-- body -->
                                <template #cell(typeDomain)="scope">
                                    <span>
                                        {{ dataMaster2.filter((i) => i.codeKey === scope.item.typeDomain)[0].codeName }}
                                    </span>
                                </template>
                                <template #cell(cheatMode)="scope">
                                    <span>
                                        {{ cheatModeOptions.filter((i) => i.codeKey === scope.item.cheatMode)[0].codeName }}
                                    </span>
                                </template>
                                <template #cell(inputDate)="scope">
                                    <span>{{ getFormatDate(scope.item.inputDate, "formatDate") }}</span>
                                </template>
                                <template #cell(action)="scope">
                                    <div class="button-action text-left">
                                        <b-button variant="primary" @click="onClickEditItemBtn(scope.item)">
                                            <svg class="icon icon-edit-25">
                                                <use xlink:href="#icon-edit-25"></use>
                                            </svg>
                                        </b-button>
                                        <b-button variant="danger" @click="onClickDeleteItemBtn(scope.item)">
                                            <svg class="icon icon-trash5">
                                                <use xlink:href="#icon-trash5"></use>
                                            </svg>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                            <b-pagination v-model="currentPageGhiChu" :total-rows="dataTableGhiChu.length"
                                :per-page="perPageGhiChu" first-number last-number align="center"></b-pagination>
                        </div>
                    </b-col>
                </b-row>
            </template>
        </div>

        <b-modal centered id="modal-them-ghi-chu" :title="ghiChuSelected ? 'CHỈNH SỬA GHI CHÚ' : 'THÊM GHI CHÚ'" scrollable
            size="lg" :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div class="d-block">
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Nhà Cái<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <multi-select class="multi-select" v-model="dataForm.typeDomainObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="dataMaster2" :searchable="false" :allow-empty="false"
                                @input="dataForm.typeDomain = dataForm.typeDomainObj.codeKey">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Đại Lý (Super)<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.superId"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Tài Khoản<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.memberId"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Loại Gian Lận<span class="red-text ml-2">*</span></div>
                        <b-input-group class="mb-3">
                            <multi-select class="multi-select" v-model="dataForm.cheatModeObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="cheatModeOptions" :searchable="false" :allow-empty="false"
                                @input="dataForm.cheatMode = dataForm.cheatModeObj.codeKey">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <div class="text-left">Ghi Chú</div>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="dataForm.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="6">
                        <div class="text-left">Ngày Bắt</div>
                        <b-input-group class="mb-3">
                            <date-range-picker v-model="dataForm.inputDateObj" :single-date-picker="true"
                                :locale-data="$root.localeData" :show-dropdowns="true" :auto-apply="true" :ranges="false"
                                :linked-calendars="false" @toggle="toggleDatePicker($event)"
                                opens="center"></date-range-picker>
                        </b-input-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <b-button block @click="closeModal('modal-them-ghi-chu')"> Hủy </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button variant="primary" block @click="submit(ghiChuSelected ? 'edit' : 'add')">
                            {{ ghiChuSelected ? "Lưu" : "Thêm" }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <b-modal centered id="modal-xac-nhan-xoa" title="XÁC NHẬN XÓA" scrollable size="sm"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div class="msg-xac-nhan-xoa">Bạn Chắc Chắn Muốn Xóa Ghi Chú Này?</div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col offset="4" cols="4">
                        <b-button id="btn-xac-nhan-ko-xoa" block @click="closeModal('modal-xac-nhan-xoa')"> Không
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button id="btn-xac-nhan-co-xoa" variant="danger" block @click="submit('delete')"> Có </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Master from "../../../service/master.service";
import Betscan from "../../../service/betscan.service";
import Betscanv2 from "../../../service/betscanv2.service";
import Crawldata from "../../../service/crawldata.service";

export default {
    name: "XemNhanh",
    data() {
        return {
            title: "XEM NHANH",
            backTo: "menu",
            showTab: "1",
            timeUpdate: "",
            ranges: {
                "Hôm Nay": this.getRangeDate("homNay"),
                "Hôm Qua": this.getRangeDate("homQua"),
                "Tuần Này": this.getRangeDate("tuanNay"),
                "Tuần Trước": this.getRangeDate("tuanTruoc"),
                "Tháng Này": this.getRangeDate("thangNay"),
                "Tháng Trước": this.getRangeDate("thangTruoc"),
            },
            dataMaster2: [
                {
                    codeKey: "00",
                    codeName: "Tất cả",
                },
            ],
            valueMaster2: {
                codeKey: "00",
                codeName: "Tất cả",
            },
            dateFromTo: {
                startDate: this.getRangeDate("tuanNay")[0],
                endDate: this.getRangeDate("tuanNay")[1],
            },
            dataOrderSort: [
                {
                    key: 0,
                    label: "Giảm Dần",
                    value: true,
                },
                {
                    key: 1,
                    label: "Tăng Dần",
                    value: false,
                },
            ],
            dataLoaiScan: [
                {
                    codeKey: 0,
                    codeName: "Tất Cả",
                },
                {
                    codeKey: 1,
                    codeName: "Trong Hệ Thống",
                },
                {
                    codeKey: 2,
                    codeName: "Ngoài Hệ Thống",
                },
            ],
            loaiScan: {
                codeKey: 0,
                codeName: "Tất Cả",
            },
            dataChucNang: [
                {
                    key: "trungIp",
                    value: false,
                    api: "listMemberRateSameIp",
                    name: "Trùng IP",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "superId", label: "Ip", sortable: true },
                        { key: "rangeRateSamIp", label: "Số Ip Trùng Tuyến Trên", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 500,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
                {
                    key: "cuocTheo",
                    value: false,
                    api: "listMemberRateSameProduct",
                    name: "Cược Theo",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "ipCount", label: "Ip Count", sortable: true },
                        { key: "betA5", label: "Cược Theo\nBằng Máy", sortable: true },
                        { key: "betA6", label: "Cược Theo\nTrước Trận", sortable: true },
                        { key: "betA7", label: "Cược Phần Mềm\nTrùng Từ 3 Mã", sortable: true },
                        {
                            key: "betA8",
                            label: "Cược Theo\nTrùng Từ 6 Lần Trở Lên",
                            sortable: true,
                        },
                        { key: "rangeRateSameProduct", label: "Tỷ Lệ Vi Phạm", sortable: true },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 1000,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
                {
                    key: "buonCom",
                    value: true,
                    api: "listMemberRateDirtyCom",
                    name: "Buôn Com",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "ipCount", label: "Ip Count", sortable: true },
                        { key: "betB6", label: "Trước Trận\n(+ Giá)", sortable: true },
                        { key: "betB8", label: "Trong Trận\n(+ Giá)", sortable: true },
                        { key: "betB5", label: "Trong Trận\n(5p)", sortable: true },
                        { key: "betB7", label: "Toàn Trận", sortable: true },
                        {
                            key: "rangeRateDirtyCom",
                            label: "Tỷ Lệ Vi Phạm\n(Toàn Trận / Tổng)",
                            sortable: true,
                        },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 1000,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
                {
                    key: "hangSapGia",
                    value: false,
                    api: "listMemberRateCrashPrice",
                    name: "Hàng Sập Giá",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "crashPrice1", label: "Lệch Giá\nTrước Trận", sortable: true },
                        {
                            key: "crashPrice2",
                            label: "Lệch Giá\nSo Với Thời Điểm Bắt Đầu",
                            sortable: true,
                        },
                        { key: "crashPrice3", label: "Lệch Giá\nTrong Trận", sortable: true },
                        { key: "rangeRateCrashPrice", label: "Tỷ Lệ Vi Phạm", sortable: true },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 1000,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
                {
                    key: "cuocRuiRo",
                    value: false,
                    api: "listMemberBetRisk",
                    name: "Cược Rủi Ro",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "betRisk1", label: "Rủi Ro Cao", sortable: true },
                        { key: "betRisk2", label: "Rủi Ro Trung Bình", sortable: true },
                        { key: "betRisk3", label: "Rủi Ro Thấp", sortable: true },
                        { key: "rangeRateBetRisk", label: "Tỷ Lệ Vi Phạm", sortable: true },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 1000,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
                {
                    key: "cuocDacBiet",
                    value: false,
                    api: "listMemberBetSpecial",
                    name: "Cách Chơi Đặc Biệt",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "special1", label: "Chuyên\nXỉu Bẩn", sortable: true },
                        { key: "special2", label: "Chuyên\nĐánh Kèo An Toàn", sortable: true },
                        { key: "special3", label: "Chuyên\nĐánh Xỉu Và Dưới", sortable: true },
                        { key: "rangeRateBetSpecial", label: "Tỷ Lệ Vi Phạm", sortable: true },
                        { key: "special4", label: "Xỉu\nTrước Trận", sortable: true },
                        { key: "special5", label: "Xỉu\nHiệp 1", sortable: true },
                        { key: "special6", label: "Toàn Trận", sortable: true },
                        { key: "special7", label: "Chuyên Góc (Corner)", sortable: true },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 1000,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
                {
                    key: "buonBong",
                    value: false,
                    api: "listMemberDirtySoccer",
                    name: "Buôn Bóng",
                    dataTable: [],
                    dataTableFilter: [],
                    fields: [
                        { key: "userName", label: "Tài Khoản", sortable: true },
                        { key: "betCount", label: "Tổng Mã Cược", sortable: true },
                        { key: "winLoss", label: "Win / Loss", sortable: true },
                        { key: "dirtySoccer", label: "Buôn Bóng", sortable: true },
                        { key: "rangeRateBetSpecial", label: "Tỷ Lệ Vi Phạm", sortable: true },
                        { key: "status", label: "Tình Trạng", sortable: false, $isDisabled: true },
                    ],
                    perPage: 1000,
                    currentPage: 1,
                    totalRows: 0,
                    isBusy: false,
                    valueFilter: "",
                    valueSort1: {},
                    orderSort1: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    valueSort2: {},
                    orderSort2: {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    },
                    sortBy: "",
                    sortDesc: "",
                },
            ],
            cheatModeOptions: [
                {
                    codeKey: 1,
                    codeName: "Buôn Com",
                },
                {
                    codeKey: 2,
                    codeName: "Cược Theo",
                },
                {
                    codeKey: 3,
                    codeName: "Sập Giá",
                },
                {
                    codeKey: 4,
                    codeName: "Cược Rủi Ro",
                },
                {
                    codeKey: 5,
                    codeName: "Cách Chơi Đặc Biệt",
                },
                {
                    codeKey: 6,
                    codeName: "Buôn Bóng",
                },
                {
                    codeKey: 7,
                    codeName: "Khác",
                },
            ],
            userNameGhiChu: "",
            typeDomainGhiChu: {
                codeKey: "00",
                codeName: "Tất cả",
            },
            dataTableGhiChu: [],
            fieldsGhiChu: [
                { key: "typeDomain", label: "Nhà Cái", sortable: true },
                { key: "memberId", label: "Tài Khoản", sortable: true },
                { key: "superId", label: "Đại Lý", sortable: true },
                { key: "cheatMode", label: "Gian Lận", sortable: true },
                { key: "inputDate", label: "Ngày Bắt", sortable: true },
                { key: "note", label: "Ghi Chú", sortable: true },
                { key: "action", label: "", sortable: false },
            ],
            currentPageGhiChu: 1,
            perPageGhiChu: 25,
            isBusyGhiChu: false,
            ghiChuSelected: null,
            dataForm: {
                cheatMode: "",
                cheatModeObj: {},
                id: "",
                inputDate: "",
                inputDateObj: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                memberId: "",
                note: "",
                superId: "",
                typeDomain: "",
                typeDomainObj: {},
            },
        };
    },
    computed: {
        ...mapState("XemNhanh", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListMasterCodeByClass("00002");
        this.getTimeUpdate();
        this.getData();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    methods: {
        ...mapActions("XemNhanh", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && document.getElementById("modal-them-ghi-chu")) {
                        this.submit(this.ghiChuSelected ? "edit" : "add");
                    } else if (!isForcusDropdown && document.getElementById("modal-xac-nhan-xoa")) {
                        this.submit("delete");
                    } else if (!isForcusDropdown) {
                        this.changeTab(this.showTab);
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    break;
            }
        },
        changeTab(tab) {
            this.showTab = tab;
            switch (tab) {
                case "1":
                    this.title = "XEM NHANH";
                    this.getData();
                    break;
                case "2":
                    this.title = "XEM GHI CHÚ";
                    this.xemGhiChu();
                    break;
            }
        },
        getListMasterCodeByClass(codeClass) {
            let _this = this;
            Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    response.data.forEach((item) => {
                        _this.dataMaster2.push({
                            codeKey: item.codeKey,
                            codeName: item.codeName,
                        });
                    });
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getTimeUpdate() {
            let _this = this;
            Betscan.getTimeUpdate(_this.$root.url)
                .then((response) => {
                    _this.timeUpdate = _this.getFormatDate(response.data, "formatDateTime");
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getData() {
            let _this = this;
            for (const item of this.dataChucNang) {
                if (item.value) {
                    item.isBusy = true;
                    let params = {
                        dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                        dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                        typeDomain: this.valueMaster2.codeKey,
                    };
                    Betscanv2[item.api](_this.$root.url, params)
                        .then((response) => {
                            item.userName = "";
                            item.dataTable = _this.cloneDeep(response.data);
                            item.dataTableFilter = _this.cloneDeep(response.data);
                            item.currentPage = 1;
                            item.totalRows = item.dataTableFilter.length;
                            item.isBusy = false;
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                            item.isBusy = false;
                        });
                }
            }
        },
        getNoteHistory(isNoBusy) {
            this.isBusyGhiChu = isNoBusy ? !isNoBusy : true;
            let _this = this;
            let params = {
                mode: 0,
                typeDomain: this.typeDomainGhiChu.codeKey,
                userName: this.userNameGhiChu,
            };
            Crawldata.getNoteHistory(_this.$root.url, params)
                .then((response) => {
                    _this.dataTableGhiChu = response.data;
                    _this.currentPageGhiChu = 1;
                    _this.isBusyGhiChu = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusyGhiChu = false;
                });
        },
        changeViewStatus(item, mode, event) {
            let _this = this;
            let params = {
                mode: mode,
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                typeDomain: item.typeDomain,
                memberId: item.userName,
                check: event,
            };
            Betscanv2.setHasViewData(_this.$root.url, params)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        changeReportStatus(item, mode, event) {
            let _this = this;
            let params = {
                mode: mode,
                dateFrom: this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI"),
                dateTo: this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI"),
                typeDomain: item.typeDomain,
                memberId: item.userName,
                check: event,
            };
            Betscanv2.setHasReportData(_this.$root.url, params)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        changeChucNang(item) {
            for (const i of this.dataChucNang) {
                if (item.key === i.key) {
                    i.value = true;
                } else {
                    i.value = false;
                }
                this.getData();
            }
        },
        boChonChucNang(item) {
            item.value = !item.value;
            let isUncheckAll = this.dataChucNang.every((i) => i.value === false);
            if (isUncheckAll) {
                item.value = true;
            }
        },
        changeSort(item) {
            item.sortBy = "";
            item.sortDesc = "";
            item.dataTableFilter.sort((a, b) => {
                var aValueSort1 = a[item.valueSort1.key];
                var bValueSort1 = b[item.valueSort1.key];
                var orderSort1 = item.orderSort1.key;

                var aValueSort2 = a[item.valueSort2.key];
                var bValueSort2 = b[item.valueSort2.key];
                var orderSort2 = item.orderSort2.key;

                if (aValueSort1 == bValueSort1) {
                    return aValueSort2 < bValueSort2 ? (orderSort2 === 0 ? 1 : -1) : aValueSort2 > bValueSort2 ? (orderSort2 === 0 ? -1 : 1) : 0;
                } else {
                    return aValueSort1 < bValueSort1 ? (orderSort1 === 0 ? 1 : -1) : orderSort1 === 0 ? -1 : 1;
                }
            });
        },
        headClicked(key, field) {
            console.log(key, field);
            for (const item of this.dataChucNang) {
                if (item.value) {
                    item.valueSort1 = item.fields.filter((i) => i.key === key)[0];
                    item.orderSort1 = item.sortDesc || item.sortDesc === "" ? this.dataOrderSort[1] : this.dataOrderSort[0];

                    item.valueSort2 = {};
                    item.orderSort2 = { key: 0, label: "Giảm Dần", value: true };
                }
            }
        },
        filterUserName(item) {
            item.dataTableFilter = this.cloneDeep(item.dataTable.filter((i) => i.userName.toLowerCase().includes(item.valueFilter.toLowerCase())));
            item.totalRows = item.dataTableFilter.length;
            item.currentPage = 1;
        },
        clickMemberIdToReport(data) {
            this.settingParramToReport(data);
            if (data.newTab) {
                let routeData = this.$router.resolve("report");
                window.open(routeData.href, "_blank");
            } else {
                this.$router.push("report");
            }
        },
        clickNumberToReport(data) {
            this.settingParramToReport(data);
            let routeData = this.$router.resolve("report");
            window.open(routeData.href, "_blank");
        },
        clickNumberToReportCount(data) {
            this.settingParramToReport(data);
            let routeData = this.$router.resolve("report");
            window.open(routeData.href, "_blank");
        },
        settingParramToReport(data) {
            let sapGiaTruocTran = false;
            let sapGiaBatDau = false;
            let sapGiaTrongTran = false;
            if (data.typeCrash === 0) sapGiaTruocTran = true;
            else if (data.typeCrash === 1) sapGiaBatDau = true;
            else if (data.typeCrash === 2) sapGiaTrongTran = true;
            let dataLocalStorage = {
                memberId: data.row.userName,
                superId: data.row.superId,
                domain: data.row.typeDomain,
                //domainName: data.row.domainName,
                isFromScreenXemNhanh: true,
                dateFromTo: this.dateFromTo,
                milisFrom: data.milisFrom,
                milisTo: data.milisTo,
                isSearchInit: true,
                trungIp: false,
                buonCom: false,
                cuocTheo: false,
                trungGiaiCam: false,
                giaiCam: false,
                cuocXau: false,
                sapGia: false,
                ruiRo: false,
                dacBiet: false,
                buonBong: false,
            };
            switch (data.chucNang.key) {
                case "trungIp":
                    dataLocalStorage.trungIp = true;
                    dataLocalStorage.type = data.type;
                    break;
                case "cuocTheo":
                    dataLocalStorage.cuocTheo = true;
                    dataLocalStorage.masterCode = data.masterCode;
                    dataLocalStorage.filterCount = data.filterCount;
                    break;
                case "buonCom":
                    dataLocalStorage.buonCom = true;
                    dataLocalStorage.masterCode = data.masterCode;
                    break;
                case "hangSapGia":
                    dataLocalStorage.dacBiet = true;
                    dataLocalStorage.sapGiaTruocTran = sapGiaTruocTran;
                    dataLocalStorage.sapGiaBatDau = sapGiaBatDau;
                    dataLocalStorage.sapGiaTrongTran = sapGiaTrongTran;
                    break;
                case "cuocRuiRo":
                    dataLocalStorage.dacBiet = true;
                    dataLocalStorage.typeRuiRo = data.type;
                    break;
                case "cuocDacBiet":
                    dataLocalStorage.dacBiet = true;
                    dataLocalStorage.typeDacBiet = data.type;
                    break;
                case "buonBong":
                    dataLocalStorage.buonBong = true;
                    dataLocalStorage.type = data.type;
                    break;
            }
            localStorage.setItem("PARAM_FOR_LEVEL_3", JSON.stringify(dataLocalStorage));
        },
        closeModal(id) {
            this.$bvModal.hide(id);
        },
        onClickEditItemBtn(item) {
            this.ghiChuSelected = this.cloneDeep(item);
            this.addOrEditGhiChu();
        },
        onClickDeleteItemBtn(item) {
            this.ghiChuSelected = this.cloneDeep(item);
            this.$bvModal.show("modal-xac-nhan-xoa");
        },
        xemGhiChu() {
            this.getNoteHistory();
        },
        addOrEditGhiChu() {
            this.$bvModal.show("modal-them-ghi-chu");
            if (this.ghiChuSelected) {
                this.dataForm = {
                    cheatMode: this.ghiChuSelected.cheatMode,
                    cheatModeObj: this.cheatModeOptions.filter((i) => i.codeKey === this.ghiChuSelected.cheatMode)[0] || "",
                    id: this.ghiChuSelected.id,
                    inputDate: this.ghiChuSelected.inputDate,
                    inputDateObj: {
                        startDate: this.ghiChuSelected.inputDate,
                        endDate: this.ghiChuSelected.inputDate,
                    },
                    memberId: this.ghiChuSelected.memberId,
                    note: this.ghiChuSelected.note,
                    superId: this.ghiChuSelected.superId,
                    typeDomain: this.ghiChuSelected.typeDomain,
                    typeDomainObj: this.dataMaster2.filter((i) => i.codeKey === this.ghiChuSelected.typeDomain)[0] || "",
                };
            } else {
                this.dataForm = {
                    cheatMode: "",
                    cheatModeObj: {},
                    id: "",
                    inputDate: "",
                    inputDateObj: {
                        startDate: new Date(),
                        endDate: new Date(),
                    },
                    memberId: "",
                    note: "",
                    superId: "",
                    typeDomain: "",
                    typeDomainObj: {},
                };
            }
        },
        submit(mode) {
            if (!this.dataForm.typeDomain || !this.dataForm.superId || !this.dataForm.memberId || !this.dataForm.cheatMode) {
                this.showToast("Xin hãy điền đầy đủ thông tin", "Thông Báo", this, "danger");
                return;
            }
            let _this = this;
            let params = {
                cheatMode: this.dataForm.cheatMode,
                inputDate: this.dataForm.inputDateObj.startDate,
                memberId: this.dataForm.memberId,
                note: this.dataForm.note,
                superId: this.dataForm.superId,
                typeDomain: this.dataForm.typeDomain,
            };
            params.id = this.dataForm.id ? this.dataForm.id : "";
            switch (mode) {
                case "add":
                    Crawldata.insert(_this.$root.url, params)
                        .then(() => {
                            _this.getNoteHistory(true);
                            _this.closeModal("modal-them-ghi-chu");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "edit":
                    Crawldata.update(_this.$root.url, params)
                        .then(() => {
                            _this.getNoteHistory(true);
                            _this.ghiChuSelected = null;
                            _this.closeModal("modal-them-ghi-chu");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "delete":
                    Crawldata.delete(_this.$root.url, params)
                        .then(() => {
                            _this.getNoteHistory(true);
                            _this.ghiChuSelected = null;
                            _this.closeModal("modal-xac-nhan-xoa");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
            }
        },
        toggleDatePicker(isOpen) {
            setTimeout(() => {
                if (isOpen) {
                    let objDiv = document.getElementById("modal-them-ghi-chu___BV_modal_body_");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }
            }, 100);
        },
        locTaiKhoan() {
            for (const item of this.dataChucNang) {
                if (item.value) {
                    let dataTableFilter;
                    switch (this.loaiScan.codeKey) {
                        case 0:
                            dataTableFilter = item.dataTable;
                            break;
                        case 1:
                            dataTableFilter = item.dataTable.filter((i) => i.scan);
                            break;
                        case 2:
                            dataTableFilter = item.dataTable.filter((i) => !i.scan);
                            break;
                    }
                    item.dataTableFilter = dataTableFilter;
                    item.totalRows = item.dataTableFilter.length;
                    item.valueFilter = "";
                    item.valueSort1 = {};
                    item.orderSort1 = {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    };
                    item.valueSort2 = {};
                    item.orderSort2 = {
                        key: 0,
                        label: "Giảm Dần",
                        value: true,
                    };
                    item.sortBy = "";
                    item.sortDesc = "";
                }
            }
        },
    },
};
</script>

<style lang="scss">
.style-for-web .xem-nhanh {
    label {
        margin: 0;
    }

    .b-table-sticky-header {
        max-height: 50vh;
    }

    .chuc-nang-item {
        color: var(--text-btn-secondary-color);
        padding: 0.375rem 0.75rem;
        margin-right: 5px;
        background-color: var(--btn-primary-color);
        border-radius: 8px;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        cursor: pointer;

        &:hover {
            background-color: var(--btn-primary-color-hover);

            .icon {
                color: var(--btn-danger-color);
            }
        }
    }

    .group-chuc-nang {
        border: 2px solid var(--btn-primary-color);
        border-radius: 10px;
        padding: 5px;
        box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
    }

    .warning-row {
        background-color: var(--btn-warning-color);
        color: var(--text-color-dark);
        padding: 5px;
        border-radius: 8px;
    }

    .cell-style {
        padding: 5px;
    }
}

#modal-them-ghi-chu___BV_modal_body_.modal-body {
    min-height: 40vh;
}
</style>
