<template>
    <div id="table-report-by-products" class="table-report-by-products">
        <div class="mb-3">
            <b-table-simple hover small bordered :dark="$root.theme === 'dark'">
                <b-thead :head-variant="$root.theme">
                    <b-tr class="dong-dau-tien">
                        <b-th rowspan="3" colspan="1">
                            <div class="group-sort">
                                Tài Khoản
                                <span class="group-icon-sort" @click="changeSort('userName')">
                                    <b-icon icon="caret-up-fill" :class="getSort('userName', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('userName', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="3">Số Mã Cược</b-th>
                        <b-th colspan="3">TurnOver</b-th>
                        <b-th colspan="1">Lớn / Nhỏ</b-th>
                        <b-th colspan="1">Win Loss</b-th>
                        <b-th colspan="1">Cược Xấu</b-th>
                        <b-th colspan="1">Trùng Ip / DS Đen</b-th>
                        <b-th colspan="2">Cược Theo</b-th>
                        <b-th colspan="2">Buôn Com</b-th>
                    </b-tr>
                    <b-tr class="dong-thu-2">
                        <b-th colspan="1">
                            <div class="group-sort">
                                Bóng Chính
                                <span class="group-icon-sort" @click="changeSort('refPercentNSoccer')">
                                    <b-icon icon="caret-up-fill" :class="getSort('refPercentNSoccer', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('refPercentNSoccer', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Bóng Ảo
                                <span class="group-icon-sort" @click="changeSort('refPercentVSoccer')">
                                    <b-icon icon="caret-up-fill" :class="getSort('refPercentVSoccer', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('refPercentVSoccer', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th rowspan="2" colspan="1">
                            <div class="group-sort">
                                Tổng
                                <span class="group-icon-sort" @click="changeSort('refTotal')">
                                    <b-icon icon="caret-up-fill" :class="getSort('refTotal', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('refTotal', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Bóng Chính
                                <span class="group-icon-sort" @click="changeSort('turnOverPercentNSoccer')">
                                    <b-icon icon="caret-up-fill" :class="getSort('turnOverPercentNSoccer', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('turnOverPercentNSoccer', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Bóng Ảo
                                <span class="group-icon-sort" @click="changeSort('turnOverPercentVSoccer')">
                                    <b-icon icon="caret-up-fill" :class="getSort('turnOverPercentVSoccer', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('turnOverPercentVSoccer', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th rowspan="2" colspan="1">
                            <div class="group-sort">
                                Tổng
                                <span class="group-icon-sort" @click="changeSort('turnOverTotal')">
                                    <b-icon icon="caret-up-fill" :class="getSort('turnOverTotal', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('turnOverTotal', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Nhỏ
                                <span class="group-icon-sort" @click="changeSort('maxStake')">
                                    <b-icon icon="caret-up-fill" :class="getSort('maxStake', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('maxStake', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Win Loss
                                <span class="group-icon-sort" @click="changeSort('totalWinLoss')">
                                    <b-icon icon="caret-up-fill" :class="getSort('totalWinLoss', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('totalWinLoss', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Số Mã Cược
                                <span class="group-icon-sort" @click="changeSort('refPercentUnderDirty')">
                                    <b-icon icon="caret-up-fill" :class="getSort('refPercentUnderDirty', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('refPercentUnderDirty', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Trùng IP
                                <span class="group-icon-sort" @click="changeSort('sameIp')">
                                    <b-icon icon="caret-up-fill" :class="getSort('sameIp', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('sameIp', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                0-5s
                                <span class="group-icon-sort" @click="changeSort('betA1')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betA1', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betA1', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                6s-20s
                                <span class="group-icon-sort" @click="changeSort('betA2')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betA2', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betA2', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                0-5s
                                <span class="group-icon-sort" @click="changeSort('betB1')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betB1', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betB1', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                6s-20s
                                <span class="group-icon-sort" @click="changeSort('betB2')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betB2', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betB2', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                    </b-tr>
                    <b-tr class="dong-thu-3">
                        <b-th colspan="1">
                            <div class="group-sort">
                                Casino
                                <span class="group-icon-sort" @click="changeSort('refPercentCasino')">
                                    <b-icon icon="caret-up-fill" :class="getSort('refPercentCasino', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('refPercentCasino', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Game Khác
                                <span class="group-icon-sort" @click="changeSort('refPercentOther')">
                                    <b-icon icon="caret-up-fill" :class="getSort('refPercentOther', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('refPercentOther', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Casino
                                <span class="group-icon-sort" @click="changeSort('turnOverPercentCasino')">
                                    <b-icon icon="caret-up-fill" :class="getSort('turnOverPercentCasino', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('turnOverPercentCasino', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Game Khác
                                <span class="group-icon-sort" @click="changeSort('turnOverPercentOther')">
                                    <b-icon icon="caret-up-fill" :class="getSort('turnOverPercentOther', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('turnOverPercentOther', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Lớn
                                <span class="group-icon-sort" @click="changeSort('minStake')">
                                    <b-icon icon="caret-up-fill" :class="getSort('minStake', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('minStake', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                Com
                                <span class="group-icon-sort" @click="changeSort('totalCom')">
                                    <b-icon icon="caret-up-fill" :class="getSort('totalCom', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('totalCom', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                TurnOver
                                <span class="group-icon-sort" @click="changeSort('turnOverPercentUnderDirty')">
                                    <b-icon icon="caret-up-fill" :class="getSort('turnOverPercentUnderDirty', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('turnOverPercentUnderDirty', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                DS Đen
                                <span class="group-icon-sort" @click="changeSort('banIp')">
                                    <b-icon icon="caret-up-fill" :class="getSort('banIp', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('banIp', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                21s-100s
                                <span class="group-icon-sort" @click="changeSort('betA3')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betA3', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betA3', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                101s-200s
                                <span class="group-icon-sort" @click="changeSort('betA4')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betA4', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betA4', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                21s-100s
                                <span class="group-icon-sort" @click="changeSort('betB3')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betB3', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betB3', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                        <b-th colspan="1">
                            <div class="group-sort">
                                101s-200s
                                <span class="group-icon-sort" @click="changeSort('betB4')">
                                    <b-icon icon="caret-up-fill" :class="getSort('betB4', 0)"></b-icon>
                                    <b-icon icon="caret-down-fill" :class="getSort('betB4', 1)"></b-icon>
                                </span>
                            </div>
                        </b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-if="isBusy">
                        <b-td colspan="15" class="text-center">
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle mr-2"></b-spinner>
                                <strong>Đang tải dữ liệu...</strong>
                            </div>
                        </b-td>
                    </b-tr>
                    <b-tr v-else-if="!dataTable || (dataTable && dataTable.length === 0)">
                        <b-td colspan="15" class="text-center">
                            <strong>Không có dữ liệu</strong>
                        </b-td>
                    </b-tr>
                    <b-tr v-for="(item, index) in dataTable" :key="index">
                        <b-td>
                            <div class="cell-style">
                                <span class="link" @click="moveToThongKeTheoMember(item)">
                                    <strong class="text-1 mr-1">
                                        {{ item.userName }}
                                    </strong>
                                </span>
                                <span class="link" @click="moveToThongKeTheoMember(item, true)">
                                    <svg class="icon icon-save-add5">
                                        <use xlink:href="#icon-save-add5"></use>
                                    </svg>
                                </span>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Bóng Chính</span>:<br>
                                    <strong class="text-1">{{ item.refPercentNSoccer }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">Casino</span>:<br>
                                    <strong class="text-1">{{ item.refPercentCasino }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Bóng Ảo</span>:<br>
                                    <strong class="text-1">{{ item.refPercentVSoccer }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">Game Khác</span>:<br>
                                    <strong class="text-1">{{ item.refPercentOther }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Tổng</span>:<br>
                                    <strong class="text-2">{{ getFormatNumber(item.refTotal) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Bóng Chính</span>:<br>
                                    <strong class="text-1">{{ item.turnOverPercentNSoccer }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">Casino</span>:<br>
                                    <strong class="text-1">{{ item.turnOverPercentCasino }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Bóng Ảo</span>:<br>
                                    <strong class="text-1">{{ item.turnOverPercentVSoccer }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">Game Khác</span>:<br>
                                    <strong class="text-1">{{ item.turnOverPercentOther }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Tổng</span>:<br>
                                    <strong class="text-2">{{ getFormatNumber(item.turnOverTotal) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Nhỏ</span>:<br>
                                    <strong class="text-3">{{ getFormatNumber(item.minStake) }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">Lớn</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.maxStake) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Winloss</span>:<br>
                                    <strong :class="item.totalWinLoss > 0 ? 'text-3' : 'text-2'">
                                        {{ getFormatNumber(item.totalWinLoss) }}
                                    </strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">Com</span>:<br>
                                    <strong class="text-3">{{ getFormatNumber(item.totalCom) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div>
                                    <span class="small-text">Số Mã Cược</span>:<br>
                                    <strong class="text-1">{{ item.refPercentUnderDirty }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">TurnOver</span>:<br>
                                    <strong class="text-1">{{ item.turnOverPercentUnderDirty }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div class="link" @click="onClickBet(0, 5, item, 0)">
                                    <span class="small-text">Trùng IP</span>:<br>
                                    <strong class="text-3">{{ item.sameIp }}</strong>
                                </div>
                                <hr>
                                <div>
                                    <span class="small-text">DS Đen</span>:<br>
                                    <strong class="text-3">{{ item.banIp || "-" }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div class="link" @click="onClickBet(0, 5, item, 1)">
                                    <span class="small-text">0-5s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betA1) }}</strong>
                                </div>
                                <hr>
                                <div class="link" @click="onClickBet(21, 100, item, 1)">
                                    <span class="small-text">21s-100s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betA3) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div class="link" @click="onClickBet(6, 20, item, 1)">
                                    <span class="small-text">6s-20s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betA2) }}</strong>
                                </div>
                                <hr>
                                <div class="link" @click="onClickBet(101, 200, item, 1)">
                                    <span class="small-text">101s-200s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betA4) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div class="link" @click="onClickBet(0, 5, item, 2)">
                                    <span class="small-text">0-5s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betB1) }}</strong>
                                </div>
                                <hr>
                                <div class="link" @click="onClickBet(21, 100, item, 2)">
                                    <span class="small-text">21s-100s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betB3) }}</strong>
                                </div>
                            </div>
                        </b-td>
                        <b-td>
                            <div class="cell-style">
                                <div class="link" @click="onClickBet(6, 20, item, 2)">
                                    <span class="small-text">6s-20s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betB2) }}</strong>
                                </div>
                                <hr>
                                <div class="link" @click="onClickBet(101, 200, item, 2)">
                                    <span class="small-text">101s-200s</span>:<br>
                                    <strong class="text-4">{{ getFormatNumber(item.betB4) }}</strong>
                                </div>
                            </div>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <b-pagination v-model="pageTable" :total-rows="data.length" :per-page="pageSizeTable" first-number last-number
            align="center"></b-pagination>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "TableReportByProducts",
    props: {
        data: {
            default: [],
        },
        superId: {
            default: "",
        },
        dateFromTo: {
            default: {},
        },
        level: {
            default: 1,
        },
        isBusy: {
            default: false,
        }
    },
    data() {
        return {
            dataTable: [],
            pageTable: 1,
            pageSizeTable: 25,
            totalTable: 0,
            trungIp: true,
            cuocTheo: true,
            buonCom: true,
            isSearchInit: false,
            trungGiaiCam: true,
            giaiCam: true,
            cuocXau: true,
            cotSort: null,
            thuTuSort: null,
        };
    },
    computed: {
        ...mapState("TableReportByProducts", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    watch: {
        data(val) {
            if (val) {
                this.changePageTable();
            }
        },
        pageTable(val) {
            if (val) {
                this.changePageTable();
            }
        },
        isBusy(val) {
            if (val) {
                this.dataTable = [];
            }
        },
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.changePageTable();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
    },
    methods: {
        ...mapActions("TableReportByProducts", []),
        moveToThongKeTheoMember(row, newTab) {
            switch (this.level) {
                case 1:
                    localStorage.setItem(
                        "PARAM_FOR_LEVEL_2",
                        JSON.stringify({
                            superId: row.userName,
                            inputSuperId: this.superId,
                            domain: row.domainType,
                            domainName: row.domainName,
                            report: 0,
                            dateFromTo: this.dateFromTo,
                        })
                    );
                    if (newTab) {
                        let routeData = this.$router.resolve("thong-ke-theo-member");
                        window.open(routeData.href, "_blank");
                    } else {
                        this.$router.push("thong-ke-theo-member");
                    }
                    break;
                case 2:
                    localStorage.setItem(
                        "PARAM_FOR_LEVEL_3",
                        JSON.stringify({
                            superId: this.superId,
                            memberId: row.userName,
                            domain: row.domainType,
                            domainName: row.domainName,
                            report: this.report,
                            dateFromTo: this.dateFromTo,
                            milisFrom: this.milisFrom,
                            milisTo: this.milisTo,
                            trungIp: this.trungIp,
                            buonCom: this.buonCom,
                            cuocTheo: this.cuocTheo,
                            trungGiaiCam: this.trungGiaiCam,
                            giaiCam: this.giaiCam,
                            cuocXau: this.cuocXau,
                        })
                    );
                    if (newTab) {
                        let routeData = this.$router.resolve("report");
                        window.open(routeData.href, "_blank");
                    } else {
                        this.$router.push("report");
                    }
                    break;
            }
        },
        onClickBet(start, end, row, checkBox) {
            this.milisFrom = start.toString();
            this.milisTo = end.toString();
            // setting
            this.isSearchInit = true;
            this.trungGiaiCam = false;
            this.giaiCam = false;
            this.cuocXau = false;
            this.buonCom = false;
            this.cuocTheo = false;
            this.trungIp = false;
            if (checkBox === 0) {
                this.trungIp = true;
            }
            if (checkBox === 1) {
                this.cuocTheo = true;
            }
            if (checkBox === 2) {
                this.buonCom = true;
            }
            this.moveToThongKeTheoMember(row, true);
        },
        changePageTable() {
            let _this = this;
            let dataTable = this.data.filter((i, index) => {
                let min = (_this.pageTable - 1) * _this.pageSizeTable;
                let max = _this.pageTable * _this.pageSizeTable;
                return index >= min && index < max;
            });
            this.dataTable = this.cloneDeep(dataTable);
        },
        getSort(key, type) {
            if (this.cotSort === key && this.thuTuSort === type) {
                return "active-sort";
            }
        },
        changeSort(key) {
            this.cotSort = key;
            this.thuTuSort = this.thuTuSort ? 0 : 1;
            this.dataTable.sort((a, b) => {
                var aValue = a[key];
                var bValue = b[key];

                if (aValue < bValue) {
                    return this.thuTuSort ? 1 : -1;
                }
                if (aValue > bValue) {
                    return this.thuTuSort ? -1 : 1;
                }
                return 0;
            });
        },
    },
};
</script>

<style lang="scss">
.style-for-web .table-report-by-products {
    border: 3px solid var(--header-bao-cao-phan-tich-background);
    border-radius: 8px;
    padding: 2px;
    
    >div {
        max-height: 65vh;
        overflow: auto;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
        min-width: 1250px;
        margin: 0;

        tr {
            th {
                text-align: center;
                vertical-align: middle;
                height: 33px;
                border-top: none;
                border-right: none;
            }

            &.dong-dau-tien {
                th {
                    color: var(--white-color);
                    background-color: var(--table-dong-1-background);
                    position: sticky;
                    top: 0;
                    z-index: 2;

                    >div {
                        color: var(--white-color);
                    }

                    .group-icon-sort {
                        .active-sort {
                            color: var(--white-color);
                        }
                    }
                }
            }

            &.dong-thu-2 {
                th {
                    position: sticky;
                    top: 33px;
                    z-index: 2;
                }
            }

            &.dong-thu-3 {
                th {
                    position: sticky;
                    top: 66px;
                    z-index: 2;
                }
            }

            td {
                vertical-align: middle;
                border-top: none;
                border-right: none;

                .cell-style {
                    text-align: center;
                }
            }
        }
    }

    .text-1 {
        color: var(--text-1-color);
    }

    .text-2 {
        color: var(--text-2-color);
    }

    .text-3 {
        color: var(--text-3-color);
    }

    .text-4 {
        color: var(--text-4-color);
    }
}
</style>
