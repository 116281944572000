<template>
    <div id="thong-ke-xac-suat" class="thong-ke-xac-suat">
        <pc-header :title="title" :backTo="backTo" />
        <div class="thong-ke-xac-suat-content">
            <b-row>
                <b-col cols="2">
                    <div class="group-item-filter text-left">
                        <span class="span-nowrap mr-2">Miền:</span>
                        <b-form-radio v-model="valueVung" name="vung-loc-du-lieu" value="MIEN_BAC"
                            @change="changeVung('MIEN_BAC')">
                            Bắc
                        </b-form-radio>
                        <b-form-radio v-model="valueVung" name="vung-loc-du-lieu" value="MIEN_NAM"
                            @change="changeVung('MIEN_NAM')">
                            Nam
                        </b-form-radio>
                    </div>
                </b-col>
                <!-- <b-col cols="2">
                    <div class="group-item-filter text-left">
                        <span class="span-nowrap mr-2">Tỉnh:</span>
                        <b-input-group>
                            <multi-select class="multi-select" v-model="valueTinh" deselect-label="Không thể bỏ chọn"
                                track-by="id" label="name" placeholder="" :options="dataTinh" :searchable="false"
                                :allow-empty="false" @input="changeTinh()">
                            </multi-select>
                        </b-input-group>
                    </div>
                </b-col> -->
                <b-col cols="3">
                    <div class="group-item-filter text-left">
                        <span class="span-nowrap mr-2">Thời Gian:</span>
                        <date-range-picker v-if="[0].includes(activeBtn)" v-model="dateFromTo"
                            :locale-data="$root.localeData" :show-dropdowns="true" :auto-apply="true" :ranges="ranges"
                            :max-date="maxDate" :linked-calendars="false" opens="center"
                            @update="changeDates()"></date-range-picker>
                        <date-range-picker v-if="[1].includes(activeBtn)" v-model="inputDate" :single-date-picker="true"
                            :locale-data="$root.localeData" :show-dropdowns="true" :auto-apply="true" :ranges="false"
                            :linked-calendars="false" opens="center" @update="changeInputDate()"></date-range-picker>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="1" class="group-button">
                    <b-button :variant="activeBtn === 0 ? 'success' : 'secondary'" @click="changeMode(0)">
                        Thống Kê Xác Suất
                    </b-button>
                    <br />
                    <b-button :variant="activeBtn === 1 ? 'success' : 'secondary'" @click="changeMode(1)">
                        Dò Số
                    </b-button>
                </b-col>
                <template v-if="[0].includes(activeBtn)">
                    <b-col cols="10">
                        <div class="table-data">
                            <b-table sticky-header hover small :items="dataBotTrain" :fields="fields"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu" :busy="isBusy" :per-page="perPage"
                                :current-page="currentPage">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle mr-2"></b-spinner>
                                        <strong>Đang tải dữ liệu...</strong>
                                    </div>
                                </template>
                                <!-- body -->
                                <template #cell(inputDate)="scope">
                                    <span>{{ getFormatDate(scope.item.inputDate, "formatDayVN") }}</span>
                                </template>
                                <template #cell(numberSeriesFirstLast)="scope">
                                    <div class="number-series">
                                        <span>{{ scope.item.numberSeriesFirstLast }}</span>
                                        <b-button v-if="scope.item.numberSeriesFirstLast" variant="primary" size="sm"
                                            class="btn-copy ml-2" @click="copyText(scope.item.numberSeriesFirstLast)">
                                            <svg class="icon icon-copy4">
                                                <use xlink:href="#icon-copy4"></use>
                                            </svg>
                                        </b-button>
                                    </div>
                                </template>
                                <template #cell(numberSeries)="scope">
                                    <div class="number-series">
                                        <span>{{ scope.item.numberSeries }}</span>
                                        <b-button v-if="scope.item.numberSeries" variant="primary" size="sm"
                                            class="btn-copy ml-2" @click="copyText(scope.item.numberSeries)">
                                            <svg class="icon icon-copy4">
                                                <use xlink:href="#icon-copy4"></use>
                                            </svg>
                                        </b-button>
                                    </div>
                                </template>
                                <template #cell(percent)="scope">
                                    <span :class="(scope.item.percent > 35) ? 'green-text' : 'red-text'">
                                        {{ scope.item.percent ? `${scope.item.percent}%` : "-" }}
                                    </span>
                                </template>
                            </b-table>
                            <b-pagination v-model="currentPage" :total-rows="dataBotTrain.length" :per-page="perPage"
                                first-number last-number align="center"></b-pagination>
                        </div>
                    </b-col>
                </template>
                <template v-if="[1].includes(activeBtn)">
                    <b-col cols="10" class="group-input">
                        <div class="item-input mb-2">
                            <div class="group-item-filter">
                                <span class="span-nowrap mr-2">Dãy Số Đánh</span>
                                <b-input-group size="lg" class="mr-2">
                                    <b-form-input type="text" v-model="daySoDanh"
                                        @change="changeDaySoDanh()"></b-form-input>
                                </b-input-group>
                            </div>
                            <div class="group-item-filter">
                                <span class="span-nowrap mr-2">Dãy Số Cần Dò</span>
                                <b-input-group size="lg">
                                    <b-form-input type="text" v-model="daySoCanDo" @change="changeDaySoDo()"></b-form-input>
                                </b-input-group>
                            </div>
                        </div>
                        <template v-if="ketQua2So">
                            <div class="item-input mb-2" v-for="(key, index) in  Object.keys(ketQua2So)" :key="index">
                                <div class="group-item-filter w-50">
                                    <span class="span-nowrap mr-2">
                                        Dãy Số {{ dataTinh.find(i => i.id === key).name }}
                                    </span>
                                    <b-input-group size="lg" class="mr-2">
                                        <b-form-input type="text" :value="ketQua2So[key] ? ketQua2So[key].join(',') : ''"
                                            disabled></b-form-input>
                                    </b-input-group>
                                </div>
                            </div>
                        </template>
                        <div v-if="ketQuaDo && daySoDanh" class="ket-qua-do">
                            <h5 class="mb-2">Kết Quả Dò Với Số Đánh</h5>
                            <div>
                                <label class="mr-1">Số trùng:</label>
                                <label class="green-text">{{ ketQuaDo.mangTrung.join(", ") }}</label>
                            </div>
                            <div>
                                <label class="mr-1">Số không trùng của Số đánh:</label>
                                <label class="red-text">{{ ketQuaDo.mangKhongTrungA.join(", ") }}</label>
                            </div>
                            <div>
                                <label class="mr-1">Số không trùng của Số dò:</label>
                                <label class="red-text">{{ ketQuaDo.mangKhongTrungB.join(", ") }}</label>
                            </div>
                        </div>
                        <div v-if="ketQuaDo && daySoMien" class="ket-qua-do">
                            <h5 class="mb-2">Kết Quả Dò Với {{ valueVung === "MIEN_BAC" ? 27 : 18 }} Số</h5>

                        </div>
                    </b-col>
                </template>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loto from "../../../service/loto.service";

export default {
    name: "ThongKeXacSuat",
    data() {
        return {
            title: "THỐNG KÊ XÁC SUẤT",
            backTo: "menu",
            valueVung: "MIEN_BAC",
            valueTinh: {},
            ranges: {
                "1 Tuần Trước": this.getRangeDate("tuanTruoc", 1, null, true),
                "2 Tuần Trước": this.getRangeDate("tuanTruoc", 2, null, true),
                "3 Tuần Trước": this.getRangeDate("tuanTruoc", 3, null, true),
                "1 Tháng Trước": this.getRangeDate("thangTruoc", 1, null, true),
                "2 Tháng Trước": this.getRangeDate("thangTruoc", 2, null, true),
                "3 Tháng Trước": this.getRangeDate("thangTruoc", 3, null, true),
                "4 Tháng Trước": this.getRangeDate("thangTruoc", 4, null, true),
                "5 Tháng Trước": this.getRangeDate("thangTruoc", 5, null, true),
                "6 Tháng Trước": this.getRangeDate("thangTruoc", 6, null, true),
                "9 Tháng Trước": this.getRangeDate("thangTruoc", 9, null, true),
                "Năm Nay": this.getRangeDate("namNay", 1, null, true),
                "1 Năm Trước": this.getRangeDate("namTruoc", 1, null, true),
                "2 Năm Trước": this.getRangeDate("namTruoc", 2, null, true),
                "3 Năm Trước": this.getRangeDate("namTruoc", 3, null, true),
            },
            maxDate: new Date(),
            dateFromTo: {
                startDate: this.getRangeDate("thangNay")[0],
                endDate: this.getRangeDate("thangNay")[1],
            },
            activeBtn: 0,
            dataTinh: [],
            dataBotTrain: [],
            fields: [
                // { key: "index", label: "STT" },
                { key: "inputDate", label: "Ngày" },
                { key: "tinhName", label: "Tỉnh" },
                { key: "total", label: "Số Đánh" },
                { key: "numberSeriesFirstLast", label: "Đầu đuôi" },
                { key: "numberSeries", label: "Dãy Số Đánh" },
                { key: "totalWin", label: "Số Trúng" },
                { key: "numberSeriesWin", label: "Dãy Số Trúng" },
                { key: "percent", label: "Tỉ Lệ", sortable: true },
            ],
            currentPage: 1,
            perPage: 25,
            isBusy: false,
            // mode 1
            daySoDanh: "",
            daySoMien: "",
            daySoCanDo: "30,31,32,34,35,37,38,40,41,42,44,45,47,48,60,61,62,64,65,67,68,70,71,72,74",
            tinhId: "",
            inputDate: {
                startDate: this.getRangeDate("homQua")[1],
                endDate: this.getRangeDate("homQua")[1],
            },
            ketQuaDo: null,
            ketQua2So: null,
            ketQuaDo2So: null,
        };
    },
    computed: {
        ...mapState("ThongKeXacSuat", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);

        var now = new Date();
        var hours = now.getHours();
        // Kiểm tra nếu trước 6h
        if (hours <= 18) {
            this.inputDate = {
                startDate: this.getRangeDate("homQua")[1],
                endDate: this.getRangeDate("homQua")[1],
            };
        } else {
            this.inputDate = {
                startDate: this.getRangeDate("homNay")[1],
                endDate: this.getRangeDate("homNay")[1],
            };
        }
        
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListTinh();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    watch: {},
    methods: {
        ...mapActions("ThongKeXacSuat", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            let isTextarea = e.target.tagName === "TEXTAREA";
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && !isTextarea) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    e.preventDefault();
                    break;
            }
        },
        getListTinh() {
            let _this = this;
            Loto.getListTinh(_this.$root.url)
                .then((response) => {
                    _this.dataTinh = response.data;
                    _this.valueTinh = _this.dataTinh[0];
                    _this.getBotTrain();
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getBotTrain() {
            let fromDate;
            let toDate;
            switch (this.activeBtn) {
                case 0:
                    fromDate = this.getFormatDate(this.dateFromTo.startDate, "formatDateAPI");
                    toDate = this.getFormatDate(this.dateFromTo.endDate, "formatDateAPI");
                    break;
                case 1:
                    fromDate = this.getFormatDate(this.inputDate.startDate, "formatDateAPI");
                    toDate = this.getFormatDate(this.inputDate.endDate, "formatDateAPI");
                    break;
            }
            this.isBusy = true;
            let data = {
                fromDate: fromDate,
                toDate: toDate,
                mien: this.valueVung,
                // tinhId: this.valueTinh.id,
            };
            let _this = this;
            Loto.getBotTrain(_this.$root.url, data)
                .then((response) => {
                    _this.dataBotTrain = response.data.map((item, index) => {
                        item.index = index + 1;
                        return item;
                    });
                    let index = _this.dataBotTrain.findIndex((item) => item.numberSeries);
                    if (index !== -1) {
                        _this.daySoDanh = _this.dataBotTrain[index].numberSeries;
                    }
                    if ([1].includes(_this.activeBtn)) {
                        _this.soSanhDaySo(_this.daySoDanh, _this.daySoCanDo);
                    }
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        doSoTrung() {
            let data = {
                inputDate: this.getFormatDate(this.inputDate.endDate, "formatDateAPI"),
                mien: this.valueVung,
                daySo: this.daySoCanDo ? this.daySoCanDo : "00",
                // tinhId: this.valueTinh.id,
            };
            let _this = this;
            Loto.doSoTrung(_this.$root.url, data)
                .then((response) => {
                    _this.ketQua2So = {};
                    for (const item of response.data) {
                        _this.ketQua2So[item.tinh] = _this.ketQua2So[item.tinh] ? _this.ketQua2So[item.tinh] : [];
                        let ketQua = item.ketQua;
                        for (const key of Object.keys(ketQua)) {
                            ketQua[key] = ketQua[key].split("\t");
                            let haiSoCuoi = _this.getHaiSoCuoi(ketQua[key]);
                            _this.ketQua2So[item.tinh] = _this.ketQua2So[item.tinh].concat(haiSoCuoi);
                        }
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getHaiSoCuoi(numberArr) {
            let dataReturn = [];
            for (const item of numberArr) {
                dataReturn.push(item.slice(-2));
            }
            return dataReturn;
        },
        soSanhDaySo(strNumA, strNumB) {
            if (!strNumA || !strNumB) {
                return;
            }
            let listA = strNumA.split(",");
            let listB = strNumB.split(",");
            // Mảng chứa các phần tử trùng nhau
            var mangTrung = listA.filter(function (element) {
                return listB.includes(element);
            });

            // Mảng chứa các phần tử không trùng nhau
            var mangKhongTrungA = listA.filter(function (element) {
                return !listB.includes(element);
            });

            var mangKhongTrungB = listB.filter(function (element) {
                return !listA.includes(element);
            });

            // Trả về một đối tượng chứa cả ba mảng
            this.ketQuaDo = {
                mangTrung: mangTrung,
                mangKhongTrungA: mangKhongTrungA,
                mangKhongTrungB: mangKhongTrungB
            };
        },
        changeVung() {
            this.changeMode(this.activeBtn);
        },
        changeTinh() {
            this.changeMode(this.activeBtn);
        },
        changeDates() {
            this.changeMode(this.activeBtn);
        },
        changeMode(mode) {
            this.activeBtn = mode;
            this.getBotTrain();
            if ([1].includes(mode)) {
                this.doSoTrung();
            }
        },
        changeDaySoDanh() {
            this.daySoDanh = this.formatDaySo(this.daySoDanh);
            this.soSanhDaySo(this.daySoDanh, this.daySoCanDo);
        },
        changeDaySoDo() {
            this.daySoCanDo = this.formatDaySo(this.daySoCanDo);
            this.soSanhDaySo(this.daySoDanh, this.daySoCanDo);
        },
        changeInputDate() {
            this.getBotTrain();
        },
        copyText(textToCopy) {
            this.showToast(`Đã copy dãy số`, "Thông Báo", this, "success");
            navigator.clipboard.writeText(textToCopy);
        },
        formatDaySo(numbers) {
            let daySo = numbers
                .replaceAll("\n", ",")
                .replaceAll(", ", ",")
                .replaceAll(" ", ",")
                .replaceAll(".", ",")
                .replace(/[^\d,]/g, "");
            daySo = Array.from(new Set(daySo.split(",")))
                .filter(Boolean)
                .sort((a, b) => {
                    let numberA = Number(a);
                    let numberB = Number(b);
                    if (numberA < numberB) {
                        return -1;
                    }
                    if (numberA > numberB) {
                        return 1;
                    }
                    return 0;
                });
            return daySo.join(",");
        },
    },
};
</script>

<style lang="scss">
.style-for-web .thong-ke-xac-suat {
    .number-series {
        display: flex;
    }

    .group-button {
        button {
            width: 100%;
        }
    }

    .group-input {
        padding: 0.75rem;

        .item-input {
            display: flex;

            >div {
                width: 50%;
            }

            span {
                width: 200px;
                text-align: right;
            }
        }
    }

    .ket-qua-do {
        padding: 0.75rem;
        border: 3px solid var(--header-bao-cao-phan-tich-background);
        border-radius: 8px;

        >div {
            display: flex;

            :first-child {
                width: 200px;
            }
        }
    }
}
</style>
