import ApiService from "./api.service";

const Account = {
    initScreen: async (url, data) => {
        url = url + "/account/agent/initScreen";
        return ApiService.get(url, data);
    },
    getListSuper: async (url, data) => {
        url = url + "/account/player/getListSuper";
        return ApiService.get(url, data);
    },
    searchByString: async (url, data) => {
        url = url + "/account/player/searchByString";
        return ApiService.get(url, data);
    },
    insertSuperAndSubView: async (url, data) => {
        url = url + "/account/player/insertSuperAndSubView";
        return ApiService.put(url, data);
    },
    updateSuperAndSubView: async (url, data) => {
        url = url + "/account/player/updateSuperAndSubView";
        return ApiService.put(url, data);
    },
    insertAgent: async (url, data) => {
        url = url + "/account/agent/insert";
        return ApiService.put(url, data);
    },
    updateAgent: async (url, data) => {
        url = url + "/account/agent/update";
        return ApiService.put(url, data);
    },
    deleteAgent: async (url, data) => {
        url = url + "/account/agent/delete";
        return ApiService.delete(url, data);
    },
    insert: async (url, data) => {
        url = url + "/account/player/insert";
        return ApiService.put(url, data);
    },
    update: async (url, data) => {
        url = url + "/account/player/update";
        return ApiService.put(url, data);
    },
    delete: async (url, data) => {
        url = url + "/account/player/delete";
        return ApiService.delete(url, data);
    },
};

export default Account;
