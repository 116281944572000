import ApiService from "./api.service";

const Betscan = {
    getTimeUpdate: async (url, data) => {
        url = url + "/betscan/getTimeUpdate";
        return ApiService.get(url, data);
    },
    getReportByProduct: async (url, data) => {
        url = url + "/betscan/getReportByProduct";
        return ApiService.get(url, data);
    },
    getReportByProductMember: async (url, data) => {
        url = url + "/betscan/getReportByProductMember";
        return ApiService.get(url, data);
    },
    getReportByChoice: async (url, data) => {
        url = url + "/betscan/getReportByChoice";
        return ApiService.get(url, data);
    },
    getReportByChoiceMember: async (url, data) => {
        url = url + "/betscan/getReportByChoiceMember";
        return ApiService.get(url, data);
    },
    getMemberInfo: async (url, data) => {
        url = url + "/betscan/getMemberInfo";
        return ApiService.get(url, data);
    },
    getMemberSameIp: async (url, data) => {
        url = url + "/betscan/getMemberSameIp";
        return ApiService.get(url, data);
    },
    getMemberSameBet: async (url, data) => {
        url = url + "/betscan/getMemberSameBet";
        return ApiService.get(url, data);
    },
    getMemberBeforeCrashPrice: async (url, data) => {
        url = url + "/betscan/getMemberBeforeCrashPrice";
        return ApiService.get(url, data);
    },
    getMemberCrashPriceBefore: async (url, data) => {
        url = url + "/betscan/getMemberCrashPriceBefore";
        return ApiService.get(url, data);
    },
    getMemberCrashPriceAfter: async (url, data) => {
        url = url + "/betscan/getMemberCrashPriceAfter";
        return ApiService.get(url, data);
    },
    getMemberUnderDirty: async (url, data) => {
        url = url + "/betscan/getMemberUnderDirty";
        return ApiService.get(url, data);
    },
    getMemberDirtyCom: async (url, data) => {
        url = url + "/betscan/getMemberDirtyCom";
        return ApiService.get(url, data);
    },
    getMemberBetRisk: async (url, data) => {
        url = url + "/betscan/getMemberBetRisk";
        return ApiService.get(url, data);
    },
    getMemberSpecial: async (url, data) => {
        url = url + "/betscan/getMemberSpecial";
        return ApiService.get(url, data);
    },
    getMemberDirtySameMatch: async (url, data) => {
        url = url + "/betscan/getMemberDirtySameMatch";
        return ApiService.get(url, data);
    },
    getTopMember: async (url, data) => {
        url = url + "/betscan/getTopMember";
        return ApiService.get(url, data);
    },
};

export default Betscan;
