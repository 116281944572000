<template>
    <div id="tai-khoan" class="tai-khoan">
        <pc-header :title="title" :backTo="backTo" />
        <div class="tai-khoan-content">
            <b-row>
                <b-col cols="2" class="title-item">
                    <div class="group-tree-data">
                        <div class="mb-2 group-filter-tree-data">
                            <div class="button-action">
                                <b-button variant="primary" @click="clickAddAgent('addDaiLy')">
                                    <svg class="icon icon-add-square5">
                                        <use xlink:href="#icon-add-square5"></use>
                                    </svg>
                                    <span class="ml-2">Thêm Đại Lý</span>
                                </b-button>
                                <b-button variant="primary" @click="clickAddListPlayer('addHangMoi')">
                                    <svg class="icon icon-add-square5">
                                        <use xlink:href="#icon-add-square5"></use>
                                    </svg>
                                    <span class="ml-2">Thêm Hàng Mới</span>
                                </b-button>
                                <b-button variant="primary" @click="searchHangNhapVe()">
                                    <svg class="icon icon-search-normal-15">
                                        <use xlink:href="#icon-search-normal-15"></use>
                                    </svg>
                                    <span class="ml-2">Tìm Hàng Nhập</span>
                                </b-button>
                                <b-button variant="primary" @click="openModalSearch()">
                                    <svg class="icon icon-search-normal-15">
                                        <use xlink:href="#icon-search-normal-15"></use>
                                    </svg>
                                    <span class="ml-2">Tìm Tài Khoản</span>
                                </b-button>
                            </div>
                            <b-input-group>
                                <b-form-input type="search" v-model="filterTreeData"
                                    placeholder="Tìm Đại Lý"></b-form-input>
                            </b-input-group>
                        </div>
                        <light-vue-tree ref="light-vue-tree" :treeData="treeData" :searchVal="filterTreeData.toUpperCase()"
                            @on-selected-change="onSelectedChange" />
                    </div>
                </b-col>
                <b-col cols="1">
                    <div class="group-nha-cai">
                        <div class="title">NHÀ CÁI</div>
                        <div v-for="(item, index) in dataLoaiChoi" :key="index" class="group-type mb-1">
                            <div class="type-name">{{ item }}</div>
                            <div v-for="(item1, index1) in dataNhaCai[item]" :key="index1" class="type-btn mt-1">
                                <b-button block @click="onClickSearchDomain(item1)">{{ item1.codeName }}</b-button>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="9">
                    <!-- group-thong-tin-dai-ly -->
                    <div v-if="currentAgentData.code" class="group-thong-tin-dai-ly mb-3">
                        <div class="title">THÔNG TIN ĐẠI LÝ</div>
                        <div class="thong-tin-dai-ly">
                            <div>
                                <div class="group-item">
                                    <label class="title-item">Mã đại lý</label>
                                    <label class="value-item">{{ currentAgentData.code }}</label>
                                </div>
                                <div class="group-item">
                                    <label class="title-item">Cấp đại lý</label>
                                    <label class="value-item">{{ currentAgentData.nameDisplay }}</label>
                                    <label class="title-item">Quyền đại lý</label>
                                    <label class="value-item">{{ currentAgentData.agentRoleName }}</label>
                                </div>
                                <div class="group-item">
                                    <label class="title-item">Tên Telegram</label>
                                    <label class="value-item">{{ currentAgentData.telegramName }}</label>
                                    <label class="title-item">Nhóm Telegram</label>
                                    <label class="value-item">{{ currentAgentData.telegramGroupName }}</label>
                                </div>
                                <div class="group-item">
                                    <label class="title-item">Phone Telegram</label>
                                    <label class="value-item">{{ currentAgentData.telegramPhone }}</label>
                                    <label class="title-item">Ghi chú</label>
                                    <label class="value-item">{{ currentAgentData.note }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="btn-action">
                            <b-button variant="primary">
                                <svg class="icon icon-gallery5">
                                    <use xlink:href="#icon-gallery5"></use>
                                </svg>
                                <span class="ml-2">Chụp Nhanh</span>
                            </b-button>
                            <b-button variant="primary" @click="clickEditAgent('editDaiLy')">
                                <svg class="icon icon-edit-25">
                                    <use xlink:href="#icon-edit-25"></use>
                                </svg>
                                <span class="ml-2">Chỉnh Sửa</span>
                            </b-button>
                            <b-button variant="primary" @click="clickAddAgent('addDownLine')">
                                <svg class="icon icon-save-add5">
                                    <use xlink:href="#icon-save-add5"></use>
                                </svg>
                                <span class="ml-2">Thêm DownLine</span>
                            </b-button>
                            <b-button variant="danger" @click="clickDeleteAgent('deleteDaiLy')">
                                <svg class="icon icon-trash5">
                                    <use xlink:href="#icon-trash5"></use>
                                </svg>
                                <span class="ml-2">Xóa</span>
                            </b-button>
                        </div>
                    </div>
                    <!-- group-webcc -->
                    <div class="group-webcc">
                        <div class="tab-btn">
                            <div>
                                <b-button size="sm" :variant="btnHangNhap ? 'primary' : 'light'"
                                    v-if="flgBtnAgentDelete && !showInforAgent" @click="onClickBtnHangNhap()">
                                    HÀNG NHẬP
                                </b-button>
                                <b-button size="sm" :variant="btnHangGiao ? 'primary' : 'light'"
                                    v-if="flgBtnAgentDelete && !showInforAgent" @click="onClickBtnHangGiao()">
                                    HÀNG ĐÃ GIAO
                                </b-button>
                                <b-button size="sm" :variant="btnHangNhapCC ? 'primary' : 'light'"
                                    v-if="flgBtnHangNhapGiaoCC && this.showInforAgent" @click="onClickBtnHangNhapCC()">
                                    WEBCC THẦU
                                </b-button>
                                <b-button size="sm" :variant="btnHangGiaoCC ? 'primary' : 'light'"
                                    v-if="flgBtnHangNhapGiaoCC && this.showInforAgent" @click="onClickBtnHangGiaoCC()">
                                    WEBCC GIAO
                                </b-button>
                                <b-button size="sm" :variant="btnSubView ? 'primary' : 'light'"
                                    @click="onClickBtnSubView()">
                                    SUBVIEW SUPPORT
                                </b-button>
                            </div>
                            <b-button size="sm" variant="primary" class="btn-out-right"
                                v-if="btnHangGiaoCC || btnHangNhapCC || btnSubView" @click="addPlayer('add')">
                                <svg class="icon icon-add-square5">
                                    <use xlink:href="#icon-add-square5"></use>
                                </svg>
                                <span class="ml-2">Thêm Người Chơi</span>
                            </b-button>
                        </div>
                        <div class="title mt-3">
                            {{ titleTale
                            }}<b-spinner v-if="isBusy" class="align-middle ml-2"></b-spinner>
                        </div>
                        <div v-for="(item, index) in dataTable" :key="index" class="group-table mt-3">
                            <div class="ten-nha-cai">{{ item.name || "Khác" }}</div>
                            <b-table sticky-header hover small :items="item.data" :fields="fields"
                                :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                                empty-text="Không có dữ liệu" :busy="isBusy" :per-page="item.perPage"
                                :current-page="item.currentPage">
                                <template #empty="scope">
                                    <strong>{{ scope.emptyText }}</strong>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle mr-2"></b-spinner>
                                        <strong>Đang tải dữ liệu...</strong>
                                    </div>
                                </template>
                                <!-- body -->
                                <template #cell(receiverId)="scope">
                                    <div>
                                        <svg class="icon" :class="scope.item.receiverId
                                            ? 'icon-tick-circle5 green-text'
                                            : 'icon-close-circle5 red-text'
                                            ">
                                            <use :xlink:href="scope.item.receiverId
                                                ? '#icon-tick-circle5'
                                                : '#icon-close-circle5'
                                                "></use>
                                        </svg>
                                    </div>
                                </template>
                                <template #cell(loginId)="scope">
                                    <div class="btn-link" @click="copyText(scope.item.loginId, $event)">
                                        {{ scope.item.loginId }}
                                    </div>
                                </template>
                                <template #cell(passWord)="scope">
                                    <div class="btn-link" @click="copyText(scope.item.passWord, $event)">
                                        {{ scope.item.passWord }}
                                    </div>
                                </template>
                                <template #cell(passCode)="scope">
                                    <div class="btn-link" @click="copyText(scope.item.passCode, $event)">
                                        {{ scope.item.passCode }}
                                    </div>
                                </template>
                                <template #cell(note)="scope">
                                    <div>
                                        <b-form-textarea v-model="scope.item.note" rows="2" max-rows="2"
                                            disabled></b-form-textarea>
                                    </div>
                                </template>
                                <template #cell(action)="scope">
                                    <div class="button-action text-left">
                                        <b-button size="sm" v-if="flgSuperBtnDistributor &&
                                            btnHangGiao &&
                                            scope.item.receiverId !== null
                                            " variant="primary" @click="thuHoiPlayer('thuHoi', scope.item)"
                                            title="Thu Hồi">
                                            <svg class="icon icon-money-recive5">
                                                <use xlink:href="#icon-money-recive5"></use>
                                            </svg>
                                        </b-button>
                                        <b-button size="sm" v-if="scope.item.typeSub === 0 &&
                                            flgSuperBtnReceiver &&
                                            scope.item.receiverId === null
                                            " variant="primary" @click="editPlayer('giaoThau', scope.item)"
                                            title="Giao Thầu">
                                            <svg class="icon icon-money-send5">
                                                <use xlink:href="#icon-money-send5"></use>
                                            </svg>
                                        </b-button>
                                        <b-button size="sm" v-if="flgSuperBtnEdit" variant="primary"
                                            @click="editPlayer('edit', scope.item)" title="Chỉnh Sửa">
                                            <svg class="icon icon-edit-25">
                                                <use xlink:href="#icon-edit-25"></use>
                                            </svg>
                                        </b-button>
                                        <b-button size="sm" v-if="flgSuperBtnDelete && !btnHangGiao" variant="danger"
                                            @click="deletePlayer(scope.item)" title="Xóa">
                                            <svg class="icon icon-trash5">
                                                <use xlink:href="#icon-trash5"></use>
                                            </svg>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                            <b-pagination v-model="item.currentPage" :total-rows="item.totalRows" :per-page="item.perPage"
                                first-number last-number align="center"></b-pagination>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-modal centered id="modal-add-and-edit" :title="titleModal" scrollable :size="getSizeModal()"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <div v-if="['addDaiLy', 'editDaiLy', 'addDownLine'].includes(modeModal)">
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>UpLine</div>
                    </b-col>
                    <b-col cols="4">
                        <div>
                            <strong>{{ dataRowAgent.upline }}</strong>
                        </div>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Cấp đại lý</div>
                    </b-col>
                    <b-col cols="4">
                        <div>
                            <strong>{{ dataRowAgent.capDaiLy }}</strong>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Mã đại lý</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group :prepend="dataRowAgent.codeParentAgent">
                            <b-form-input type="text" v-model="dataRowAgent.codeAgentNew"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Quyền đại lý</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="dataRowAgent.agentRoleObj"
                            deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                            :options="quyenDaiLyOptions" :searchable="false" :allow-empty="false">
                        </multi-select>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>User Telegram</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataRowAgent.telegramName"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Nhóm Telegram</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataRowAgent.telegramGroupName"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Phone Telegram</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataRowAgent.telegramPhone"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Ghi Chú</div>
                    </b-col>
                    <b-col cols="4">
                        <b-form-textarea v-model="dataRowAgent.note" rows="3" max-rows="3"></b-form-textarea>
                    </b-col>
                </b-row>
            </div>
            <div v-if="['addHangMoi'].includes(modeModal)" class="group-hang-moi">
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Nhà Cái</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="dataAddListPlayer.domainObj"
                            deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                            :options="domainOptions" :searchable="false" :allow-empty="false">
                        </multi-select>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Nhà Cung Cấp</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="dataAddListPlayer.supplierObj"
                            deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                            :options="nhaCungCapOptions" :searchable="false" :allow-empty="false">
                        </multi-select>
                    </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col cols="2" class="title-item">
                        <div>Nhà Nhận Hàng</div>
                    </b-col>
                    <b-col cols="4">
                        <tree-select :options="treeData" :multiple="false" @select="changeGiaoThauAddListPlayer"
                            v-model="dataAddListPlayer.distributorId" />
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Đại Lý Cấp Dưới</div>
                    </b-col>
                    <b-col cols="4">
                        <tree-select :options="treeDataDownLine" :multiple="false" @select="changeGiaoChildAddListPlayer"
                            v-model="dataAddListPlayer.receiverId" />
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row header-row">
                    <b-col cols="2"> </b-col>
                    <b-col cols="2">
                        <div class="pl-3">Tài Khoản Chính</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">Kế Toán</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">Support</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">Thống Kê</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">Backup</div>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Tên Của Super</div>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutTKChinh.userName"
                                placeholder="Tài khoản sẽ quản lý trên tên này"
                                @input="inputAccoutTKChinhUserName"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutKeToan.userName"
                                placeholder="Lấy từ TK chính" disabled></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutSupport.userName"
                                placeholder="Lấy từ TK chính" disabled></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutThongKe.userName"
                                placeholder="Lấy từ TK chính" disabled></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutBackup.userName"
                                placeholder="Lấy từ TK chính" disabled></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Cấp Bật</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">SUPPER</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">SUPPER</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">SUPPER</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">SUPPER</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">SUPPER</div>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Trạng Thái</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">OPEN</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">OPEN</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">OPEN</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">OPEN</div>
                    </b-col>
                    <b-col cols="2">
                        <div class="pl-3">OPEN</div>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Tên Đăng Nhập (Login Id)</div>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutTKChinh.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutKeToan.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutSupport.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutThongKe.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutBackup.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Mật Khẩu</div>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutTKChinh.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutKeToan.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutSupport.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutThongKe.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutBackup.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Mật Khẩu Code</div>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutTKChinh.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutKeToan.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutSupport.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutThongKe.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutBackup.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Quản Lý</div>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <multi-select class="multi-select" v-model="dataAddListPlayer.accoutTKChinh.managerObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="managerOptions" :searchable="false" :allow-empty="false" @input="changeQuanLy()">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <multi-select class="multi-select" v-model="dataAddListPlayer.accoutKeToan.managerObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="managerOptions" :searchable="false" :allow-empty="false" @input="changeQuanLy()">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <multi-select class="multi-select" v-model="dataAddListPlayer.accoutSupport.managerObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="managerOptions" :searchable="false" :allow-empty="false" @input="changeQuanLy()">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <multi-select class="multi-select" v-model="dataAddListPlayer.accoutThongKe.managerObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="managerOptions" :searchable="false" :allow-empty="false" @input="changeQuanLy()">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <multi-select class="multi-select" v-model="dataAddListPlayer.accoutBackup.managerObj"
                                deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                                :options="managerOptions" :searchable="false" :allow-empty="false" @input="changeQuanLy()">
                            </multi-select>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2 style-row">
                    <b-col cols="2">
                        <div>Ghi Chú</div>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutTKChinh.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutKeToan.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutSupport.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutThongKe.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2">
                        <b-input-group>
                            <b-form-input type="text" v-model="dataAddListPlayer.accoutBackup.note"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <br />
                <br />
            </div>
            <div v-if="[
                'add',
                'edit',
                'giaoThau',
                'thuHoi',
                'editThau',
                'editGiao',
                'editInModal',
            ].includes(modeModal)
                ">
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>UpLine</div>
                    </b-col>
                    <b-col cols="4">
                        <tree-select :options="treeData" :multiple="false" @select="changeGiaoThau"
                            v-model="seletedRow.distributorId" />
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>DownLine</div>
                    </b-col>
                    <b-col cols="4">
                        <tree-select :options="treeDataDownLine" :multiple="false" @select="changeGiaoChild"
                            v-model="seletedRow.receiverId" />
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Nhà Cung Cấp</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="seletedRow.supplierObj"
                            deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                            :options="nhaCungCapOptions" :searchable="false" :allow-empty="false">
                        </multi-select>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Website Name</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="seletedRow.domainObj" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="domainOptions" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Tên Tài Khoản</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="seletedRow.userName"
                                :disabled="['edit', 'editInModal'].includes(modeModal)"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Level</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="seletedRow.levelObj" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="levelPlayerOptions"
                            :searchable="false" :allow-empty="false">
                        </multi-select>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Trạng Thái</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="seletedRow.statusObj" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="statusOptions" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Id Đăng Nhập</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="seletedRow.loginId"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Mật Khẩu</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="seletedRow.passWord"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Mật Khẩu Code</div>
                    </b-col>
                    <b-col cols="4">
                        <b-input-group>
                            <b-form-input type="text" v-model="seletedRow.passCode"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col v-if="modeBtnActive === 3" cols="2" class="title-item">
                        <div>Quản Lý</div>
                    </b-col>
                    <b-col v-if="modeBtnActive === 3" cols="4">
                        <multi-select class="multi-select" v-model="seletedRow.modeObj" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="modeOptions" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </b-col>
                    <b-col cols="2" class="title-item">
                        <div>Quản Lý</div>
                    </b-col>
                    <b-col cols="4">
                        <multi-select class="multi-select" v-model="seletedRow.managerObj"
                            deselect-label="Không thể bỏ chọn" track-by="codeKey" label="codeName" placeholder=""
                            :options="managerOptions" :searchable="false" :allow-empty="false">
                        </multi-select>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col cols="2" class="title-item">
                        <div>Ghi Chú</div>
                    </b-col>
                    <b-col cols="4">
                        <b-form-textarea v-model="seletedRow.note" rows="3" max-rows="3"></b-form-textarea>
                    </b-col>
                </b-row>
            </div>
            <div v-if="['delete'].includes(modeModal)">
                Bạn Chắc Chắn Muốn Xóa Người Chơi <strong>{{ seletedRow.userName }}</strong>?
            </div>
            <div v-if="['deleteDaiLy'].includes(modeModal)">
                Bạn Chắc Chắn Muốn Xóa Đại Lý <strong>{{ dataRowAgent.codeAgentNew }}</strong>?
            </div>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col offset="4" cols="4">
                        <b-button id="btn-khong" block @click="closeModal('modal-add-and-edit')">
                            {{ ["delete", "deleteDaiLy"].includes(modeModal) ? "Không" : "Hủy" }}
                        </b-button>
                    </b-col>
                    <b-col cols="4">
                        <b-button id="btn-co" :variant="['delete', 'deleteDaiLy'].includes(modeModal) ? 'danger' : 'primary'
                            " block @click="submit()">
                            {{ getLabelSubmit(modeModal) }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>

        <b-modal centered id="modal-search" title="TÌM TÀI KHOẢN" scrollable size="xl"
            :content-class="$root.isMobile ? 'style-for-mobile' : 'style-for-web'">
            <b-row class="mb-2">
                <b-col cols="5">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Tên Người Chơi</span>
                        <b-input-group size="lg">
                            <b-form-input type="text" v-model="searchString"></b-form-input>
                        </b-input-group>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="searchByString()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table sticky-header hover small :items="dataTableModalSearch" :fields="fieldsModalSearch"
                        :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty empty-text="Không có dữ liệu"
                        :busy="isBusyModalSearch" :per-page="perPageModalSearch" :current-page="currentPageModalSearch">
                        <template #empty="scope">
                            <strong>{{ scope.emptyText }}</strong>
                        </template>
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle mr-2"></b-spinner>
                                <strong>Đang tải dữ liệu...</strong>
                            </div>
                        </template>
                        <!-- body -->
                        <template #cell(typeSub)="scope">
                            <span>{{ scope.item.typeSub === 0 ? "Super" : "Subview" }}</span>
                        </template>
                        <template #cell(note)="scope">
                            <div>
                                <b-form-textarea v-model="scope.item.note" rows="2" max-rows="2" disabled></b-form-textarea>
                            </div>
                        </template>
                        <template #cell(action)="scope">
                            <div class="button-action text-left">
                                <b-button variant="primary" @click="editPlayer('editInModal', scope.item)">
                                    <svg class="icon icon-edit-25">
                                        <use xlink:href="#icon-edit-25"></use>
                                    </svg>
                                </b-button>
                                <b-button variant="danger" @click="deletePlayer(scope.item)">
                                    <svg class="icon icon-trash5">
                                        <use xlink:href="#icon-trash5"></use>
                                    </svg>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                    <b-pagination v-if="dataTableModalSearch.length" v-model="currentPageModalSearch"
                        :total-rows="dataTableModalSearch.length" :per-page="perPageModalSearch" first-number last-number
                        align="center"></b-pagination>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-row class="w-100 mt-3">
                    <b-col offset="10" cols="2">
                        <b-button id="btn-khong" block @click="closeModal('modal-search')">
                            Đóng
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Master from "../../../service/master.service";
import Account from "../../../service/account.service";

export default {
    name: "TongDaiLy",
    data() {
        return {
            title: "QUẢN LÝ ĐẠI LÝ VÀ DANH SÁCH TÀI KHOẢN",
            backTo: "menu",
            dataLoaiChoi: ["SPORT", "CASINO", "COOKFIGHTS", "LOTTO"],
            dataNhaCai: [],
            nhaCungCap: {},
            nhaCungCapOptions: [
                {
                    codeKey: "00",
                    codeName: "Khác",
                },
            ],
            levelPlayer: {},
            levelPlayerOptions: [
                {
                    codeKey: "",
                    codeName: "",
                },
            ],
            domain: {},
            domainOptions: [
                {
                    codeKey: "00",
                    codeName: "Tất cả",
                },
            ],
            modeOptions: [
                {
                    codeKey: 2,
                    codeName: "Support",
                },
                {
                    codeKey: 3,
                    codeName: "Kế toán",
                },
                {
                    codeKey: 4,
                    codeName: "Thống kê",
                },
                {
                    codeKey: 5,
                    codeName: "Backup",
                },
            ],
            statusOptions: [
                {
                    codeKey: 0,
                    codeName: "Open",
                },
                {
                    codeKey: 1,
                    codeName: "Closed",
                },
                {
                    codeKey: 2,
                    codeName: "Suspend",
                },
            ],
            managerOptions: [
                {
                    codeKey: 0,
                    codeName: "Công Ty",
                },
                {
                    codeKey: 1,
                    codeName: "Ngoài Công Ty",
                },
            ],
            quyenDaiLyOptions: [
                {
                    codeKey: 2,
                    codeName: "Xác nhận",
                },
                {
                    codeKey: 4,
                    codeName: "Toàn quyền",
                },
            ],
            showInforAgent: false,
            flgBtnAgentAddAccount: false,
            flgBtnAgentAddDownline: false,
            flgBtnAgentDelete: false,
            flgBtnAgentEdit: false,
            flgBtnHangNhapGiao: false,
            flgBtnHangNhapGiaoCC: false,
            flgSuperBtnDelete: false,
            flgSuperBtnDistributor: false,
            flgSuperBtnEdit: false,
            flgSuperBtnReceiver: false,
            filterTreeData: "",
            treeData: [],
            treeDataDownLine: [],
            modeLogin: 3,
            btnHangNhap: false,
            btnHangGiao: false,
            btnHangNhapCC: false,
            btnHangGiaoCC: false,
            btnSubView: false,
            typeSub: 0,
            typeDomain: 0,
            agentId: 0,
            distributorId: 0,
            receiverId: 0,
            modeBtnSearch: 0,
            fields: [
                { key: "supplierName", label: "NCC" },
                { key: "userName", label: "TK Chính" },
                { key: "levelAccountName", label: "Cấp Bậc" },
                { key: "statusName", label: "Trạng Thái" },
                { key: "loginId", label: "Id Đăng Nhập" },
                { key: "passWord", label: "Mật Khẩu" },
                { key: "passCode", label: "Mật Khẩu Code" },
                { key: "typeManagerName", label: "Quản lý" },
                { key: "note", label: "Ghi Chú" },
            ],
            dataTable: [],
            isBusy: false,
            perPage: 25,
            currentPage: 1,
            currentAgentData: {},
            titleModal: "",
            seletedRow: {
                supplierObj: {},
                domainObj: {},
                levelObj: {},
                statusObj: {},
                modeObj: {},
                managerObj: {},
            },
            modeModal: "",
            dataModal: {},
            modeBtnActive: 0,
            isShowColumnDownLine: false,
            isShowColumnAction: false,
            isShowColumnDaGiao: false,
            isShowColumnUpLine: false,
            dataRowAgent: {
                agentRoleObj: {},
            },
            searchString: "",
            fieldsModalSearch: [
                { key: "upLine", label: "UpLine" },
                { key: "downLine", label: "DownLine" },
                { key: "supplierName", label: "NCC" },
                { key: "typeDomainName", label: "Web" },
                { key: "levelAccountName", label: "Cấp Bậc" },
                { key: "userName", label: "TK Chính" },
                { key: "typeSub", label: "Loại Sub" },
                { key: "loginId", label: "Id Đăng Nhập" },
                { key: "passWord", label: "Mật Khẩu" },
                { key: "passCode", label: "Mật Khẩu Code" },
                { key: "typeManagerName", label: "Quản lý" },
                { key: "note", label: "Ghi Chú" },
                { key: "action", label: "" },
            ],
            dataTableModalSearch: [],
            isBusyModalSearch: false,
            perPageModalSearch: 25,
            currentPageModalSearch: 1,
            dataAddListPlayer: {
                domainObj: {},
                supplierObj: {},
                accoutTKChinh: {},
                accoutKeToan: {},
                accoutSupport: {},
                accoutThongKe: {},
                accoutBackup: {},
            },
        };
    },
    computed: {
        ...mapState("TongDaiLy", []),
        titleTale() {
            if (this.btnHangNhap) {
                return "WEBCC THẦU OWNER (tất cả super)";
            }
            if (this.btnHangGiao) {
                return "WEBCC GIAO OWNER (tất cả super đã được giao)";
            }
            if (this.btnHangNhapCC) {
                return "WEBCC THẦU";
            }
            if (this.btnHangGiaoCC) {
                return "WEBCC GIAO";
            }
            if (this.btnSubView) {
                return "WEBCC SUB GIAO OWNER";
            }
            return "HÀNG MỚI NHẬP";
        },
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener("keydown", this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListMasterCodeByClass("00002");
        this.getListMasterCodeByClass("00007");
        this.getListMasterCodeByClass("00008");
        this.initScreen();
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener("keydown", this.keydown);
    },
    watch: {
        isShowColumnUpLine(val) {
            if (val) {
                this.fields.splice(0, 0, { key: "upline", label: "UpLine" });
            } else {
                let index = this.fields.findIndex((i) => i.key === "upline");
                this.fields.splice(index, 1);
            }
        },
        isShowColumnDownLine(val) {
            if (val) {
                let index = this.fields.findIndex((i) => i.key === "upline");
                if (index === -1) {
                    index = 0;
                } else {
                    index = 1;
                }
                this.fields.splice(index, 0, { key: "downLine", label: "DownLine" });
            } else {
                let index = this.fields.findIndex((i) => i.key === "downLine");
                this.fields.splice(index, 1);
            }
        },
        isShowColumnDaGiao(val) {
            if (val) {
                let index = this.fields.findIndex((i) => i.key === "passCode");
                this.fields.splice(index + 1, 0, { key: "receiverId", label: "Đã giao" });
            } else {
                let index = this.fields.findIndex((i) => i.key === "receiverId");
                this.fields.splice(index, 1);
            }
        },
        isShowColumnAction(val) {
            if (val) {
                let index = this.fields.findIndex((i) => i.key === "note");
                this.fields.splice(index + 1, 0, { key: "action", label: "" });
            } else {
                let index = this.fields.findIndex((i) => i.key === "action");
                this.fields.splice(index, 1);
            }
        },
    },
    methods: {
        ...mapActions("TongDaiLy", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes("multiselect");
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown && document.getElementById("modal-add-and-edit")) {
                        this.submit();
                    } else if (!isForcusDropdown && document.getElementById("modal-search")) {
                        this.searchByString();
                    } else if (!isForcusDropdown) {
                        this.searchHangNhapVe();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    if (document.getElementById("modal-add-and-edi")) {
                        this.closeModal("modal-add-and-edi");
                    } else if (document.getElementById("modal-search")) {
                        this.closeModal("modal-search");
                    }
                    e.preventDefault();
                    break;
                case 112: // f1
                    this.clickAddAgent("addDaiLy");
                    e.preventDefault();
                    break;
                case 113: // f2
                    this.clickAddListPlayer("addHangMoi");
                    e.preventDefault();
                    break;
                case 114: // f3
                    if (this.btnHangGiaoCC || this.btnHangNhapCC || this.btnSubView) {
                        this.addPlayer("add");
                    }
                    e.preventDefault();
                    break;
                case 115: // f4
                    if (this.currentAgentData.code) {
                        this.clickAddAgent("addDownLine");
                    }
                    e.preventDefault();
                    break;
            }
        },
        getListMasterCodeByClass(codeClass) {
            let _this = this;
            Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    let data = [];
                    switch (codeClass) {
                        case "00002":
                            data = [
                                {
                                    codeKey: "00",
                                    codeName: "Tất cả",
                                },
                            ];
                            break;
                        case "00007":
                            data = [
                                {
                                    codeKey: "00",
                                    codeName: "Tất cả",
                                },
                            ];
                            break;
                        case "00008":
                            data = [
                                {
                                    codeKey: "00",
                                    codeName: "Tất cả",
                                },
                            ];
                            break;
                    }
                    response.data.forEach((item) => {
                        let codeType = _this.dataLoaiChoi[Number(item.codeValue) - 1];
                        data.push({
                            codeKey: item.codeKey,
                            codeName: item.codeName,
                            codeValue: item.codeValue,
                            codeType: codeType,
                        });
                    });
                    switch (codeClass) {
                        case "00002":
                            _this.domainOptions = _this.cloneDeep(data);
                            _this.dataNhaCai = _this.groupBy(data, "codeType");
                            break;
                        case "00007":
                            _this.nhaCungCapOptions = _this.cloneDeep(data);
                            break;
                        case "00008":
                            _this.levelPlayerOptions = _this.cloneDeep(data);
                            break;
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        initScreen() {
            let _this = this;
            Account.initScreen(_this.$root.url)
                .then((response) => {
                    let treeData = [];
                    response.data.listTree.forEach((item) => {
                        treeData.push(_this.cloneDeep(item));
                    });
                    _this.treeData = _this.cloneDeep(treeData);
                    _this.treeDataDownLine = _this.cloneDeep(treeData);
                    if (!_this.modeModal) {
                        _this.flgBtnAgentEdit = response.data.flgBtnAgentEdit;
                        _this.flgBtnAgentDelete = response.data.flgBtnAgentDelete;
                        _this.flgBtnAgentAddDownline = response.data.flgBtnAgentAddDownline;
                        _this.flgBtnAgentAddAccount = response.data.flgBtnAgentAddAccount;
                        _this.flgBtnHangNhapGiao = response.data.flgBtnHangNhapGiao;
                        _this.flgBtnHangNhapGiaoCC = response.data.flgBtnHangNhapGiaoCC;
                        _this.flgSuperBtnEdit = response.data.flgSuperBtnEdit;
                        _this.flgSuperBtnDelete = response.data.flgSuperBtnDelete;
                        _this.flgSuperBtnDistributor = response.data.flgSuperBtnDistributor;
                        _this.flgSuperBtnReceiver = response.data.flgSuperBtnReceiver;

                        _this.modeLogin = response.data.mode;
                        switch (_this.modeLogin) {
                            case 1: // admin và manager
                                _this.btnHangNhap = true;
                                _this.isShowColumnAction = true;
                                _this.onClickBtnHangNhap();
                                break;
                            case 2: // support
                                _this.btnHangNhapCC = true;
                                _this.isShowColumnAction = true;
                                _this.onClickBtnHangNhapCC();
                                break;
                            default: // khác mode support và admin
                                _this.btnSubView = true;
                                _this.isShowColumnAction = false;
                                _this.onClickBtnSubView();
                                break;
                        }
                    }
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getListSuper(isNoBusy) {
            this.isBusy = isNoBusy ? !isNoBusy : true;
            let params = {
                typeSub: this.typeSub,
                typeDomain: this.domain.codeKey,
                agentId: this.agentId,
                distributorId: this.distributorId,
                receiverId: this.receiverId,
                modeBtnSearch: this.modeBtnSearch,
            };
            let _this = this;
            Account.getListSuper(_this.$root.url, params)
                .then((response) => {
                    _this.dataTable = response.data.map((i) => {
                        i.totalRows = i.data.length;
                        i.perPage = 15;
                        i.currentPage = 1;
                        return i;
                    });
                    _this.isBusy = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy = false;
                });
        },
        searchByString() {
            if (!this.searchString) {
                return;
            }
            this.isBusyModalSearch = true;
            let _this = this;
            Account.searchByString(_this.$root.url, { searchString: this.searchString })
                .then((response) => {
                    _this.dataTableModalSearch = response.data;
                    _this.perPageModalSearch = 25;
                    _this.currentPageModalSearch = 1;
                    _this.isBusyModalSearch = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusyModalSearch = false;
                });
        },
        searchHangNhapVe() {
            this.currentAgentData = {
                agentId: 0,
            };
            this.setStatusGroupBtnHang(1);
            this.btnHangNhap = true;
            this.showInforAgent = false;
            this.getListSuper();
        },
        onSelectedChange(node) {
            this.showInforAgent = true;
            this.domain = {
                codeKey: "00",
                codeName: "Tất Cả",
            }; // search tất cả domain của agent đó
            this.currentAgentData = node;
            if (this.modeLogin == 3) {
                this.onClickBtnSubView();
            } else {
                this.onClickBtnHangNhapCC();
            }
        },
        onClickSearchDomain(domain) {
            this.domain = domain;
            this.getListSuper();
        },
        onClickBtnHangNhap() {
            this.setStatusGroupBtnHang(1);
            this.distributorId = 0;
            this.getListSuper();
        },
        onClickBtnHangGiao() {
            this.setStatusGroupBtnHang(2);
            this.getListSuper();
        },
        onClickBtnHangNhapCC() {
            this.setStatusGroupBtnHang(3);
            this.getListSuper();
        },
        onClickBtnHangGiaoCC() {
            this.setStatusGroupBtnHang(4);
            this.getListSuper();
        },
        onClickBtnSubView() {
            this.setStatusGroupBtnHang(5);
            this.getListSuper();
        },
        setStatusGroupBtnHang(num) {
            // One Click button in group active
            this.btnHangNhap = false;
            this.btnHangGiao = false;
            this.btnHangNhapCC = false;
            this.btnHangGiaoCC = false;
            this.btnSubView = false;
            // One Click button in group active
            this.receiverId = 0;
            this.distributorId = 0;
            this.agentId = 0;
            // Setting param search to default
            this.domain = "00";
            this.nhaCungCap = "00";
            this.typeSub = "0";
            this.isShowColumnUpLine = false;
            this.isShowColumnDownLine = false;
            this.isShowColumnDaGiao = false;
            this.modeBtnSearch = num;
            switch (num) {
                case 1:
                    this.btnHangNhap = true;
                    break;
                case 2:
                    this.btnHangGiao = true;
                    break;
                case 3:
                    this.btnHangNhapCC = true;
                    this.distributorId = this.currentAgentData.parentId; // có thể null
                    this.receiverId = this.currentAgentData.agentId || 0;
                    break;
                case 4:
                    this.btnHangGiaoCC = true;
                    this.distributorId = this.currentAgentData.agentId || 0;
                    this.receiverId = this.currentAgentData.agentId || 0;
                    this.agentId = this.currentAgentData.agentId || 0;
                    break;
                case 5:
                    this.btnSubView = true;
                    this.typeSub = "1";
                    this.receiverId = this.currentAgentData.agentId;
                    break;
                default:
                    break;
            }
            // setting column by mode
            if (this.btnHangGiao || this.btnHangGiaoCC || this.btnSubView) {
                this.isShowColumnDownLine = true;
            }
            if (this.btnHangNhap) {
                this.isShowColumnDaGiao = true;
            }
            if (this.btnHangNhapCC) {
                this.isShowColumnUpLine = true;
            }
        },
        clickAddAgent(mode) {
            switch (mode) {
                case "addDaiLy":
                    this.dataRowAgent = {
                        agentId: "",
                        agentRoleId: 2,
                        code: "",
                        level: 0,
                        note: "",
                        parentId: "",
                        phoneArea: "",
                        telegramGroupName: "",
                        telegramName: "",
                        telegramPhone: "",
                        upline: "Công ty",
                        capDaiLy: "Tổng đại lý",
                        codeParentAgent: "",
                        agentRoleObj: {},
                        codeAgentNew: "",
                    };
                    this.titleModal = "THÊM ĐẠI LÝ";
                    break;
                case "addDownLine":
                    this.dataRowAgent = {
                        parentId: this.currentAgentData.agentId,
                        agentId: "",
                        agentRoleId: 2,
                        code: "",
                        level: this.currentAgentData.level,
                        note: "",
                        phoneArea: "",
                        telegramGroupName: "",
                        telegramName: "",
                        telegramPhone: "",
                        upline: this.currentAgentData.code,
                        capDaiLy: "Đại lý cấp " + this.currentAgentData.level,
                        codeParentAgent: this.currentAgentData.code + "-",
                        codeAgentNew: "",
                        agentRoleObj: this.quyenDaiLyOptions.filter(
                            (i) => i.codeKey === this.currentAgentData.agentRoleId
                        ),
                    };
                    this.dataRowAgent.code =
                        this.dataRowAgent.codeParentAgent + this.dataRowAgent.codeAgentNew;
                    this.titleModal = "THÊM DOWNLINE";
                    break;
            }
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        clickEditAgent(mode) {
            this.dataRowAgent = {
                agentId: this.currentAgentData.agentId,
                parentId: this.currentAgentData.parentId,
                agentRoleId: this.currentAgentData.agentRoleId,
                code: "",
                level: this.currentAgentData.level - 1,
                note: this.currentAgentData.note,
                phoneArea: this.currentAgentData.phoneArea,
                telegramGroupName: this.currentAgentData.telegramGroupName,
                telegramName: this.currentAgentData.telegramName,
                telegramPhone: this.currentAgentData.telegramPhone,
                upline: "Công ty",
                capDaiLy: "Tổng đại lý",
                codeParentAgent: "",
                codeAgentNew: "",
                agentRoleObj: this.quyenDaiLyOptions.filter(
                    (i) => i.codeKey === this.currentAgentData.agentRoleId
                ),
            };
            if (this.currentAgentData.level == 1) {
                this.dataRowAgent.codeParentAgent = "";
                this.dataRowAgent.codeAgentNew = this.currentAgentData.code;
            } else {
                this.dataRowAgent.capDaiLy = "Đại lý cấp " + (this.currentAgentData.level - 1);
                this.dataRowAgent.codeParentAgent = this.currentAgentData.parentCode + "-";
                this.dataRowAgent.codeAgentNew = (this.currentAgentData.code + "").replace(
                    this.dataRowAgent.codeParentAgent,
                    ""
                );
            }
            this.dataRowAgent.code =
                this.dataRowAgent.codeParentAgent + this.dataRowAgent.codeAgentNew;
            this.titleModal = "SỬA ĐẠI LÝ";
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        clickDeleteAgent(mode) {
            this.dataRowAgent = {
                ...this.currentAgentData,
            };
            this.titleModal = "XÁC NHẬN XÓA";
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        clickAddListPlayer(mode) {
            this.dataAddListPlayer = {
                domainObj: {},
                supplierObj: {},
                accoutTKChinh: {},
                accoutKeToan: {},
                accoutSupport: {},
                accoutThongKe: {},
                accoutBackup: {},
            };
            this.titleModal = "TẠO MỚI TÀI KHOẢN CUNG CẤP THẦU (GIAO DIỆN CHO NHÀ QUẢN LÝ)";
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        thuHoiPlayer(mode, item) {
            this.titleModal = "THU HỒI TKCC (XÓA UPLINE)";
            this.formatSelectedRow(item);
            this.seletedRow.distributorId = null;
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        addPlayer(mode) {
            let pefixTitle = "THÊM ";
            if (this.btnHangNhapCC) {
                pefixTitle = pefixTitle + "TKCC THẦU";
                this.modeBtnActive = 1;
            }
            if (this.btnHangGiaoCC) {
                pefixTitle = pefixTitle + "TKCC GIAO";
                this.modeBtnActive = 2;
            }
            if (this.btnSubView) {
                pefixTitle = pefixTitle + "TKCC SUBVIEW";
                this.modeBtnActive = 3;
            }
            this.titleModal = pefixTitle;
            this.seletedRow = {
                supplierObj: {},
                domainObj: {},
                levelObj: {},
                statusObj: {},
                modeObj: {},
                managerObj: {},
            };
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        editPlayer(mode, item) {
            let pefixTitle = "CHỈNH SỬA ";
            if (this.btnHangNhapCC) {
                pefixTitle = pefixTitle + "TKCC THẦU";
                mode = "editThau";
                this.modeBtnActive = 1;
            }
            if (this.btnHangGiaoCC) {
                pefixTitle = pefixTitle + "TKCC GIAO";
                mode = "editGiao";
                this.modeBtnActive = 2;
            }
            if (this.btnSubView) {
                pefixTitle = pefixTitle + "SUBVIEW";
                mode = "edit";
                this.modeBtnActive = 3;
            }
            this.titleModal = pefixTitle;
            this.formatSelectedRow(item);
            this.modeModal = mode;
            this.openModal("modal-add-and-edit");
        },
        deletePlayer(item) {
            this.titleModal = "XÁC NHẬN XÓA";
            this.formatSelectedRow(item);
            this.modeModal = "delete";
            this.openModal("modal-add-and-edit");
        },
        formatSelectedRow(item) {
            this.seletedRow = {
                ...this.cloneDeep(item),
                supplierObj: this.nhaCungCapOptions.filter((i) => i.codeKey === item.supplier)[0],
                domainObj: this.domainOptions.filter((i) => i.codeKey === item.typeDomain)[0],
                levelObj: this.levelPlayerOptions.filter((i) => i.codeKey === item.levelAccount)[0],
                statusObj: this.statusOptions.filter((i) => i.codeKey === item.status)[0],
                modeObj: this.modeOptions.filter((i) => i.codeKey === item.originId)[0],
                managerObj: this.managerOptions.filter((i) => i.codeKey === item.typeManager)[0],
            };
        },
        async copyText(mytext, event) {
            try {
                if (!navigator.clipboard) {
                    this.fallbackCopyTextToClipboard(mytext, event);
                    return;
                }
                await navigator.clipboard.writeText(mytext);
                this.showToast(`Đã copy "${mytext}"`, "Thông Báo", this, "success");
            } catch ($e) {
                console.log($e);
                this.showToast("Xảy ra lỗi khi copy", "Thông Báo", this, "danger");
            }
        },
        fallbackCopyTextToClipboard(text, event) {
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = event.offsetX;
            textArea.style.left = "50vw";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand("copy");
                let msg = successful ? "successful" : "unsuccessful";
                this.$toast.success(`Copy: "${text}" ${msg}`);
            } catch (err) {
                console.log(err);
                //console.error("Fallback: Oops, unable to copy", err);
            }
            document.body.removeChild(textArea);
        },
        getSizeModal() {
            let size = "";
            switch (this.modeModal) {
                case "addDaiLy":
                case "editDaiLy":
                case "addDownLine":
                case "add":
                case "edit":
                case "giaoThau":
                case "thuHoi":
                case "editThau":
                case "editGiao":
                case "editInModal":
                    size = "lg";
                    break;
                case "deleteDaiLy":
                case "delete":
                    size = "sm";
                    break;
                case "addHangMoi":
                    size = "xl";
                    break;
            }
            return size;
        },
        submit() {
            let params = null;
            let _this = this;
            switch (this.modeModal) {
                case "addDaiLy":
                case "editDaiLy":
                case "addDownLine":
                    this.dataRowAgent.code =
                        this.dataRowAgent.codeParentAgent + this.dataRowAgent.codeAgentNew;
                    this.dataRowAgent.agentRoleId = this.dataRowAgent.agentRoleObj.codeKey;
                    params = this.cloneDeep(this.dataRowAgent);
                    Account[this.modeModal !== "editDaiLy" ? "insertAgent" : "updateAgent"](
                        _this.$root.url,
                        params
                    )
                        .then((response) => {
                            console.log(response);
                            _this.initScreen();
                            if (_this.modeModal === "editDaiLy") {
                                let keys = Object.keys(_this.currentAgentData);
                                for (const key of keys) {
                                    if (key === "agentRoleName") {
                                        _this.currentAgentData.agentRoleId =
                                            _this.dataRowAgent.agentRoleObj.codeKey;
                                        _this.currentAgentData.agentRoleName =
                                            _this.dataRowAgent.agentRoleObj.codeName;
                                    } else if (_this.dataRowAgent[key] && key !== "level") {
                                        _this.currentAgentData[key] = _this.dataRowAgent[key];
                                    }
                                }
                            }
                            _this.closeModal("modal-add-and-edit");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "add":
                case "edit":
                case "giaoThau":
                case "thuHoi":
                case "editThau":
                case "editGiao":
                case "editInModal":
                    params = this.cloneDeep(this.seletedRow);
                    params.supplier = params.supplierObj ? params.supplierObj.codeKey : "";
                    params.typeDomain = params.domainObj ? params.domainObj.codeKey : "";
                    params.levelAccount = params.levelObj ? params.levelObj.codeKey : "";
                    params.status = params.statusObj ? params.statusObj.codeKey : "";
                    params.mode = params.modeObj ? params.modeObj.codeKey : "";
                    params.typeManager = params.managerObj ? params.managerObj.codeKey : "";
                    if (this.modeModal === "thuHoi") {
                        params.distributorId = null;
                    }
                    if (this.modeBtnActive === 3) {
                        params.typeSub = 1;
                        params.distributorId = null;
                    } else if (this.modeModal === "add" && this.modeBtnActive === 2) {
                        params.receiverId = this.currentAgentData.agentId;
                    } else {
                        params.typeSub = 0;
                    }
                    Account[this.modeModal === "add" ? "insert" : "update"](_this.$root.url, params)
                        .then((response) => {
                            console.log(response);
                            if (_this.modeModal === "editInModal") {
                                _this.searchByString();
                            } else {
                                _this.getListSuper(true);
                            }
                            _this.closeModal("modal-add-and-edit");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "delete":
                    params = {
                        playerId: this.seletedRow.playerId,
                        typeSub: this.seletedRow.typeSub,
                        typeDomain: this.seletedRow.typeDomain,
                        supplier: this.seletedRow.supplier,
                        typeManager: this.seletedRow.typeManager,
                        levelAccount: this.seletedRow.levelAccount,
                        originId: this.seletedRow.originId,
                        parentId: this.seletedRow.parentId,
                        distributorId: this.seletedRow.distributorId,
                        receiverId: this.seletedRow.receiverId,
                        status: this.seletedRow.status,
                        userName: this.seletedRow.userName,
                        loginId: this.seletedRow.loginId,
                        passWord: this.seletedRow.passWord,
                        passCode: this.seletedRow.passCode,
                        note: this.seletedRow.note,
                        mode: this.seletedRow.mode,
                    };
                    Account.delete(_this.$root.url, params)
                        .then((response) => {
                            console.log(response);
                            _this.getListSuper(true);
                            _this.closeModal("modal-add-and-edit");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "deleteDaiLy":
                    params = this.cloneDeep(this.currentAgentData);
                    Account.deleteAgent(_this.$root.url, params)
                        .then((response) => {
                            console.log(response);
                            _this.initScreen();
                            _this.closeModal("modal-add-and-edit");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
                case "addHangMoi":
                    params = [];
                    // nhà cái
                    this.dataAddListPlayer.accoutTKChinh.typeDomain =
                        this.dataAddListPlayer.domainObj.codeKey;
                    this.dataAddListPlayer.accoutSupport.typeDomain =
                        this.dataAddListPlayer.domainObj.codeKey;
                    this.dataAddListPlayer.accoutKeToan.typeDomain =
                        this.dataAddListPlayer.domainObj.codeKey;
                    this.dataAddListPlayer.accoutThongKe.typeDomain =
                        this.dataAddListPlayer.domainObj.codeKey;
                    this.dataAddListPlayer.accoutBackup.typeDomain =
                        this.dataAddListPlayer.domainObj.codeKey;
                    // nhà cung cấp
                    this.dataAddListPlayer.accoutTKChinh.supplier =
                        this.dataAddListPlayer.supplierObj.codeKey;
                    this.dataAddListPlayer.accoutSupport.supplier =
                        this.dataAddListPlayer.supplierObj.codeKey;
                    this.dataAddListPlayer.accoutKeToan.supplier =
                        this.dataAddListPlayer.supplierObj.codeKey;
                    this.dataAddListPlayer.accoutThongKe.supplier =
                        this.dataAddListPlayer.supplierObj.codeKey;
                    this.dataAddListPlayer.accoutBackup.supplier =
                        this.dataAddListPlayer.supplierObj.codeKey;
                    // nhà nhận hàng
                    this.dataAddListPlayer.accoutTKChinh.receiverId =
                        this.dataAddListPlayer.receiverId;
                    this.dataAddListPlayer.accoutSupport.receiverId =
                        this.dataAddListPlayer.receiverId;
                    this.dataAddListPlayer.accoutKeToan.receiverId =
                        this.dataAddListPlayer.receiverId;
                    this.dataAddListPlayer.accoutThongKe.receiverId =
                        this.dataAddListPlayer.receiverId;
                    this.dataAddListPlayer.accoutBackup.receiverId =
                        this.dataAddListPlayer.receiverId;
                    // cấp dưới của nhà nhận hàng
                    this.dataAddListPlayer.accoutTKChinh.distributorId =
                        this.dataAddListPlayer.distributorId;
                    this.dataAddListPlayer.accoutSupport.distributorId =
                        this.dataAddListPlayer.distributorId;
                    this.dataAddListPlayer.accoutKeToan.distributorId =
                        this.dataAddListPlayer.distributorId;
                    this.dataAddListPlayer.accoutThongKe.distributorId =
                        this.dataAddListPlayer.distributorId;
                    this.dataAddListPlayer.accoutBackup.distributorId =
                        this.dataAddListPlayer.distributorId;

                    params.push(this.dataAddListPlayer.accoutTKChinh);
                    params.push(this.dataAddListPlayer.accoutKeToan);
                    params.push(this.dataAddListPlayer.accoutSupport);
                    params.push(this.dataAddListPlayer.accoutThongKe);
                    params.push(this.dataAddListPlayer.accoutBackup);
                    Account.insertSuperAndSubView(_this.$root.url, params)
                        .then((response) => {
                            console.log(response);
                            _this.closeModal("modal-add-and-edit");
                        })
                        .catch((error) => {
                            _this.errorApi(error, _this);
                        });
                    break;
            }
        },
        closeModal(id) {
            this.$bvModal.hide(id);
        },
        openModal(id) {
            this.$bvModal.show(id);
        },
        changeGiaoThau(node) {
            this.seletedRow.distributorId = node.agentId;
            this.seletedRow.receiverId = node.agentId;
            this.treeDataDownLine = [];
            this.treeDataDownLine.push(node);
        },
        changeGiaoChild(node) {
            this.seletedRow.receiverId = node.agentId;
        },
        changeGiaoThauAddListPlayer(node) {
            this.dataAddListPlayer.distributorId = node.agentId;
            this.dataAddListPlayer.receiverId = node.agentId;
            this.treeDataDownLine = [];
            this.treeDataDownLine.push(node);
        },
        changeGiaoChildAddListPlayer(node) {
            this.dataAddListPlayer.receiverId = node.agentId;
        },
        openModalSearch() {
            this.openModal("modal-search");
        },
        inputAccoutTKChinhUserName() {
            this.dataAddListPlayer.accoutKeToan.userName =
                this.dataAddListPlayer.accoutTKChinh.userName;
            this.dataAddListPlayer.accoutSupport.userName =
                this.dataAddListPlayer.accoutTKChinh.userName;
            this.dataAddListPlayer.accoutThongKe.userName =
                this.dataAddListPlayer.accoutTKChinh.userName;
            this.dataAddListPlayer.accoutBackup.userName =
                this.dataAddListPlayer.accoutTKChinh.userName;
        },
        changeQuanLy() {
            let data = this.dataAddListPlayer;
            data.accoutTKChinh.typeManager = data.accoutTKChinh.managerObj
                ? data.accoutTKChinh.managerObj.codeKey
                : "";
            data.accoutKeToan.typeManager = data.accoutKeToan.managerObj
                ? data.accoutKeToan.managerObj.codeKey
                : "";
            data.accoutSupport.typeManager = data.accoutSupport.managerObj
                ? data.accoutSupport.managerObj.codeKey
                : "";
            data.accoutThongKe.typeManager = data.accoutThongKe.managerObj
                ? data.accoutThongKe.managerObj.codeKey
                : "";
            data.accoutBackup.typeManager = data.accoutBackup.managerObj
                ? data.accoutBackup.managerObj.codeKey
                : "";
        },
        getLabelSubmit(modeModal) {
            if (["delete", "deleteDaiLy"].includes(modeModal)) {
                return "Có";
            } else if (["giaoThau", "thuHoi"].includes(modeModal)) {
                return "Xác Nhận";
            } else if (
                ["add", "addHangMoi", "addDaiLy", "editDaiLy", "addDownLine"].includes(modeModal)
            ) {
                return "Thêm";
            } else if (["edit", "editThau", "editGiao", "editInModal"].includes(modeModal)) {
                return "Lưu";
            }
        },
    },
};
</script>

<style lang="scss">
.style-for-web .tai-khoan {
    .group-filter-tree-data {
        display: flex;
        flex-wrap: wrap;

        .button-action {
            flex-wrap: wrap;

            button {
                width: 100%;
                margin-right: 0;
                margin-bottom: 5px;
                justify-content: left;
            }
        }
    }

    .group-nha-cai {
        height: 90vh;
        overflow: auto;
        padding: 5px;

        .title {
            padding: 0.375rem 0.75rem;
            font-size: 18px;
            font-weight: bold;
        }

        .group-type {
            background-color: var(--btn-tree-background);
            border-radius: 10px;
            padding: 5px;
            box-shadow: 0px 2px 6px var(--active-tab-box-shadow);
        }

        .type-name {
            width: 100%;
            color: var(--text-btn-secondary-color);
            padding: 0.375rem 0.75rem;
            background-color: var(--btn-primary-color);
            border-radius: 8px;
        }

        .type-btn {
            .btn {
                background-color: var(--btn-tree-background);
                border: 1px solid var(--btn-tree-background);
                color: var(--text-color);
                justify-content: left;

                &:hover {
                    background-color: var(--btn-primary-color-hover);
                    border: 1px solid var(--btn-primary-color-hover);
                    color: var(--white-color);
                }
            }
        }
    }

    .group-tree-data {
        .vue-tree {
            height: 70vh;
            overflow: auto;
            padding-right: 5px;
        }
    }

    .group-thong-tin-dai-ly {
        display: flex;
        flex-wrap: wrap;
        padding-top: 5px;

        .title {
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 18px;
            font-weight: bold;
        }

        .thong-tin-dai-ly {
            width: 80%;

            .group-item {
                display: flex;
                border-radius: 8px;
                margin: 5px;

                label {
                    display: flex;
                    align-items: center;
                    padding: 0 15px;
                    margin: 0;
                    height: 38px;

                    &.title-item {
                        justify-content: flex-end;
                        width: 150px;
                        min-width: 150px;
                        background-color: var(--title-bao-cao-phan-tich-background);
                        border-radius: 8px 0 0 8px;
                        color: var(--text-color-light);
                    }

                    &.value-item {
                        justify-content: flex-start;
                        width: calc(100% - 140px);
                        margin-right: 5px;
                        background-color: var(--value-bao-cao-phan-tich-background);
                        border-radius: 0 8px 8px 0;
                        color: var(--text-color);
                    }
                }
            }
        }

        .btn-action {
            width: 15%;
            min-width: 200px;

            button {
                height: 38px;
                width: 100%;
                margin: 5px 0;
            }
        }
    }

    .group-webcc {
        padding-top: 5px;

        .title {
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 18px;
            font-weight: bold;

            .spinner-border {
                width: 1rem;
                height: 1rem;
            }
        }

        .ten-nha-cai {
            display: inline-block;
            margin-bottom: 5px;
            color: var(--text-btn-secondary-color);
            padding: 0.375rem 0.75rem;
            background-color: var(--btn-primary-color);
            border-radius: 8px;
        }

        .group-table {
            border: 3px solid var(--header-bao-cao-phan-tich-background);
            border-radius: 8px;
            padding: 5px;
        }
    }

    .vue-tree {
        >.child-node {
            padding: 0;
        }

        .node-content {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            background-color: transparent;
            border: transparent;
            padding: 0rem 0rem 0rem 1.5rem;
            margin: 2px 0;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 8px;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            color: var(--text-color);

            &.active-li {
                .inner-wrap {
                    background-color: var(--btn-primary-color) !important;
                    border: 1px solid var(--btn-primary-color) !important;
                    color: var(--white-color) !important;
                }
            }

            .inner-wrap {
                height: 100%;
                padding: 0.375rem 0.75rem 0.375rem 0.5rem;
                margin-left: 2px;
                background-color: var(--btn-tree-background);
                border: 1px solid var(--btn-tree-background);
                transition: all 0.3s;
                border-radius: 8px;
                box-shadow: 0px 2px 6px var(--active-tab-box-shadow);

                &:hover {
                    background-color: var(--btn-primary-color-hover);
                    border: 1px solid var(--btn-primary-color-hover);
                    color: var(--white-color);
                }
            }

            .icon-expand,
            .icon-unexpand {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 1.5rem;
                padding: 0.375rem 0 0.375rem rem 0;
                color: var(--text-color);
                border-radius: 8px;

                &:hover {
                    background-color: var(--btn-primary-color-hover);
                }
            }
        }
    }
}

#modal-add-and-edit .style-for-web {
    .title-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .group-hang-moi {
        .header-row {
            font-size: 16px;
            font-weight: bold;
            color: var(--white-color);
        }

        >div {
            &:nth-child(3) {
                background-color: var(--add-hang-moi-1-background);
            }
        }

        .style-row {
            height: 40px;
            display: flex;
            align-items: center;
            background-color: var(--add-hang-moi-2-background);
            border-radius: 8px;

            >div {
                &:first-child {
                    text-align: right;
                }
            }

            .form-control {
                height: 34px;
                min-height: 34px;
            }

            .multi-select,
            .multi-select:not(.style-for-web.modal-content .multi-select.multiselect--active) .multiselect__tags {
                height: 34px;
                min-height: 34px;
            }
        }
    }
}

#modal-search .modal-dialog {
    max-width: 85vw;
}
</style>
