<template>
    <div id="history-batch-job" class="history-batch-job">
        <pc-header :title="title" :backTo="backTo" />
        <div class="history-batch-job-content">
            <b-row>
                <b-col offset="1" cols="2">
                    <div class="group-item-filter">
                        <span class="span-nowrap mr-2">Mode</span>
                        <multi-select class="multi-select" v-model="valueMaster3" deselect-label="Không thể bỏ chọn"
                            track-by="codeKey" label="codeName" placeholder="" :options="dataMaster3" :searchable="false"
                            :allow-empty="false">
                        </multi-select>
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="button-action text-left">
                        <b-button variant="success" @click="onClickSearchBtn()">
                            <svg class="icon icon-search-normal-15">
                                <use xlink:href="#icon-search-normal-15"></use>
                            </svg>
                            <span class="ml-2">Tìm Kiếm</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col cols="6">
                    <div class="table-data">
                        <b-table ref="data-job-history" sticky-header fixed hover small :items="dataJobHistory.list"
                            :fields="fields1" :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                            empty-text="Không có dữ liệu" selectable select-mode="single" @row-selected="onRowSelected"
                            :busy="isBusy1">
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <template #cell(status)="scope">
                                <div class="text-with-back" :class="scope.item.status === 'SUCCESS' ? 'success-text' : 'error-text'">
                                    <strong>
                                        {{ scope.item.status === 'SUCCESS' ? 'Thành Công' : 'Thất Bại' }}
                                    </strong>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination v-model="pageTable1" :total-rows="totalTable1" :per-page="pageSizeTable1" first-number
                            last-number align="center" @input="changePageTable('1')"></b-pagination>
                    </div>
                </b-col>
                <b-col cols="6">
                    <div class="table-data">
                        <b-table sticky-header fixed hover small :items="dataJobHistoryDetail.list" :fields="fields2"
                            :head-variant="$root.theme" :dark="$root.theme === 'dark'" show-empty
                            empty-text="Không có dữ liệu" class="job-history-detail" :busy="isBusy2">
                            <template #empty="scope">
                                <strong>{{ scope.emptyText }}</strong>
                            </template>
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle mr-2"></b-spinner>
                                    <strong>Đang tải dữ liệu...</strong>
                                </div>
                            </template>
                            <template #cell(status)="scope">
                                <div class="text-with-back" :class="scope.item.status === 'SUCCESS' ? 'success-text' : 'error-text'">
                                    <strong>
                                        {{ scope.item.status === 'SUCCESS' ? 'Thành Công' : 'Thất Bại' }}
                                    </strong>
                                </div>
                            </template>
                        </b-table>
                        <b-pagination v-model="pageTable2" :total-rows="totalTable2" :per-page="pageSizeTable2" first-number
                            last-number align="center" @input="changePageTable('2')"></b-pagination>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Master from "../../../service/master.service";
import Crawldata from "../../../service/crawldata.service";

export default {
    name: "HistoryBatchJobPage",
    data() {
        return {
            title: "LỊCH SỬ CÀO",
            backTo: "menu",
            dataMaster3: [],
            valueMaster3: {},
            dataJobHistory: [],
            fields1: [
                { key: 'jobId', label: 'Job ID', sortable: true, },
                { key: 'jobCount', label: 'Tổng Tài Khoản', sortable: true, },
                { key: 'jobRun', label: 'Tổng Lần Chạy', sortable: true, },
                { key: 'status', label: 'Trạng Thái', sortable: true, },
                { key: 'timeStartFormat', label: 'Bắt Đầu', sortable: true, },
                { key: 'timeEndFormat', label: 'Kết Thúc', sortable: true, },
            ],
            pageTable1: 1,
            pageSizeTable1: 25,
            totalTable1: 0,
            isBusy1: false,
            dataJobHistoryDetail: [],
            fields2: [
                { key: 'no', label: 'STT', sortable: true, },
                { key: 'jobName', label: 'Job Name', sortable: true, },
                { key: 'error', label: 'Lỗi', sortable: true, },
                { key: 'status', label: 'Trạng Thái', sortable: true, },
                { key: 'timeStartFormat', label: 'Bắt Đầu', sortable: true, },
                { key: 'timeEndFormat', label: 'Kết Thúc', sortable: true, },
            ],
            pageTable2: 1,
            pageSizeTable2: 25,
            totalTable2: 0,
            isBusy2: false,
        };
    },
    computed: {
        ...mapState("HistoryBatchJobPage", []),
    },
    beforeCreate() {
        console.log("I have not been created yet!");
    },
    created() {
        console.log("I have just been created!");
        this.forceMovePage();
        window.addEventListener('keydown', this.keydown);
    },
    beforeMount() {
        console.log("I am about to be mounted to the DOM!");
    },
    mounted() {
        console.log("I am mounted to the DOM!");
        this.getListMasterCodeByClass('00003');
    },
    beforeDestroy() {
        console.log("I am about to be removed from the DOM!");
    },
    destroyed() {
        console.log("I no longer exist...");
        window.removeEventListener('keydown', this.keydown);
    },
    methods: {
        ...mapActions("HistoryBatchJobPage", []),
        keydown(e) {
            let key = e.keyCode || e.which;
            let isForcusDropdown = e.target.className.includes('multiselect');
            switch (key) {
                case 13: // enter
                    if (!isForcusDropdown) {
                        this.onClickSearchBtn();
                    }
                    if (!isForcusDropdown) {
                        e.preventDefault();
                    }
                    break;
                case 27: // esc
                    break;
                case 112: // f1
                    break;
            }
        },
        getListMasterCodeByClass(codeClass) {
            let _this = this;
            Master.getListMasterCodeByClass(_this.$root.url, { codeClass: codeClass })
                .then((response) => {
                    _this.dataMaster3 = response.data;
                    _this.valueMaster3 = _this.dataMaster3.filter((item) => item.codeKey === '4')[0];
                    _this.getJobHistory();
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                });
        },
        getJobHistory() {
            this.isBusy1 = true;
            let data = {
                page: this.pageTable1,
                pageSize: this.pageSizeTable1,
            }
            let _this = this;
            Crawldata.getJobHistory(_this.$root.url, data)
                .then((response) => {
                    _this.dataJobHistory = response.data;
                    _this.dataJobHistory.list.forEach((i, index) => {
                        i.index = index;
                        return i;
                    });
                    _this.seletedRowTable1 = _this.dataJobHistory.list[0];
                    _this.totalTable1 = _this.dataJobHistory.total;
                    _this.isBusy1 = false;
                    _this.getJobHistoryDetail();
                    setTimeout(() => {
                        _this.$refs['data-job-history'].selectRow(0);
                    }, 300);
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy1 = false;
                });
        },
        getJobHistoryDetail() {
            this.isBusy2 = true;
            let data = {
                page: this.pageTable2,
                pageSize: this.pageSizeTable2,
                jobId: this.seletedRowTable1.jobId,
            }
            let _this = this;
            Crawldata.getJobHistoryDetail(_this.$root.url, data)
                .then((response) => {
                    _this.dataJobHistoryDetail = response.data;
                    _this.totalTable2 = _this.dataJobHistoryDetail.total;
                    _this.isBusy2 = false;
                })
                .catch((error) => {
                    _this.errorApi(error, _this);
                    _this.isBusy2 = false;
                });
        },
        onClickSearchBtn() {
            this.getJobHistory();
        },
        onRowSelected(items) {
            if (items[0]) {
                this.seletedRowTable1 = items[0];
                this.getJobHistoryDetail();
            } else {
                this.$refs['data-job-history'].selectRow(this.seletedRowTable1.index);
            }
        },
        changePageTable(number) {
            switch (number) {
                case '1':
                    this.getJobHistory();
                    break;
                case '2':
                    this.getJobHistoryDetail();
                    break;
            }
        },
    },
};
</script>

<style lang="scss">
.style-for-web .history-batch-job {
    .job-history-detail {
        .b-table {
            >thead {
                >tr {
                    >th {
                        &:first-child {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }

    .b-table {
        >tbody {
            >tr {
                >td {
                    word-break: break-word;
                }
            }
        }
    }
}
</style>
