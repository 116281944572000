import ApiService from "./api.service";

const Betscanv2 = {
    listMemberRateSameIp: async (url, data) => {
        url = url + "/betscanv2/listMemberRateSameIp";
        return ApiService.get(url, data);
    },
    listMemberRateSameProduct: async (url, data) => {
        url = url + "/betscanv2/listMemberRateSameProduct";
        return ApiService.get(url, data);
    },
    listMemberRateDirtyCom: async (url, data) => {
        url = url + "/betscanv2/listMemberRateDirtyCom";
        return ApiService.get(url, data);
    },
    listMemberRateCrashPrice: async (url, data) => {
        url = url + "/betscanv2/listMemberRateCrashPrice";
        return ApiService.get(url, data);
    },
    listMemberBetRisk: async (url, data) => {
        url = url + "/betscanv2/listMemberBetRisk";
        return ApiService.get(url, data);
    },
    listMemberBetSpecial: async (url, data) => {
        url = url + "/betscanv2/listMemberBetSpecial";
        return ApiService.get(url, data);
    },
    listMemberDirtySoccer: async (url, data) => {
        url = url + "/betscanv2/listMemberDirtySoccer";
        return ApiService.get(url, data);
    },
    setHasViewData: async (url, data) => {
        url = url + "/betscanv2/setHasViewData";
        return ApiService.get(url, data);
    },
    setHasReportData: async (url, data) => {
        url = url + "/betscanv2/setHasReportData";
        return ApiService.get(url, data);
    },
};

export default Betscanv2;
